import { Button, Grid, IconButton, makeStyles, StyleRules, Theme, Typography, withStyles, WithStyles } from "@material-ui/core";
import GenericImporter from "components/generic-importer";
import { colors } from "components/generic-input-select/styles";
import { BLUE, BLUE_LIGHT_BACKGROUND, BLUE_RIGHT_HEADER, RED_LIGHT } from "containers/shared/colors";

import { LiteralsContext, withLiterals } from "containers/shared/literals";
import IoC from "contexts/ioc.context";
import { ImporterGateway } from "gateways/importer.gateway";
import { IGenericResult } from "gateways/importer.interfaces";
import ModelGateway from "gateways/model.new.gateway";
import { ICampo, IMarcaVehicIVTM, IModeloVehicIVTM, IMunicipioIVTM, ISubUsosIVTM } from "gateways/model.new.interface";
import usePage from "hooks/page.hook";
import { FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { translate } from "utils/i18n";
import { formatOptions, GenericInitialObject, getEmptyObject, getKeysRequiredCampos, Option, renderCampos, SelectoresInfo, validateCamposRequiredKeys } from "../utils";
import camposStyles from '../componentes/styles';
import FullLoading from "components/full-loading";
import { AlertsContext } from "contexts/alerts.context";
import { IDatosXMLM071, objectBbddToXMLformat_071 } from "./formaters";
import Icon from "@mdi/react";
import { mdiCar, mdiCard, mdiCreditCard, mdiCreditCardOutline, mdiFileExport, mdiFileImport, mdiFileImportOutline, mdiFilePlus, mdiTrashCan } from "@mdi/js";
import { GenericModal } from "components/generic-modal";
import { visibility } from "html2canvas/dist/types/css/property-descriptors/visibility";
import GenericCheckbox from "components/generic-checkbox";
import { display } from "html2canvas/dist/types/css/property-descriptors/display";


type TExtendedGenericResult<T> = IGenericResult<T> & {municipio:string}
interface IProps {
    goToPago: (token:string) => void;
    exportData: (idModelos: string[], tipoDoc: 'excel'|'xml', idTipoModelo: string) => void;
}
export type IPropsM701Multi = WithStyles<typeof styles> & IProps

const styles = (theme: Theme): StyleRules => ({
    title:{
        fontWeight: 'bold',
        textTransform: 'uppercase',
        textAlign: 'center',
        alignContent:'center',
        height: 48
    },
    text: {
        padding:5
    },
    header: {
        backgroundColor: BLUE_LIGHT_BACKGROUND
    },
    buttonRow: {display:'flex', flexDirection:'row', justifyContent:'flex-end'},
    row: {
        display: 'flex', 
        justifyContent: 'space-between'
    },
    msgErr: {
        margin: 10,
        backgroundColor: RED_LIGHT,
        fontWeight: 'bold',
        width: '92%',
        padding: 5
    },
    borderRight: {
        borderRight: `1.5px solid ${BLUE_LIGHT_BACKGROUND}`,
    },
    borderLeft: {
        borderLeft: `1.5px solid ${BLUE_LIGHT_BACKGROUND}`,
    },
    borderRightHeader: {
        borderRight: `1.5px solid #fff`,
    },
    grow: {
        display:'flex',
        flexGrow: 1,
        //width: '100%'
    }

});


const useCamposStyles = makeStyles(camposStyles);
const M071Lotes : FC<IPropsM701Multi> = ({ classes, goToPago, exportData }) => {
    const idTipoModelo = '071'
    // Gateways 
    const ioc = useContext(IoC);
    // const tramitesG = useMemo(() => ioc.get(TramitesGateway) as TramitesGateway, [ioc]);
    const importerG = useMemo(() => ioc.get(ImporterGateway) as ImporterGateway, [ioc]);
    const modelG = useMemo(() => ioc.get(ModelGateway) as ModelGateway, [ioc]);
    const terms = useContext(LiteralsContext);
    //const [, pageDispatcher] = usePage();
    const [, alertsDispatch] = useContext(AlertsContext);
    const classesCampos = useCamposStyles()
    //Wizard
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingModelos, setLoadingModelos] = useState<boolean>(false);
    const [open, setOpen] = useState(false);
    const isMobile = window.innerWidth <= 750;
    const isXSMobile = window.innerWidth <= 500;
    const isTablet = window.innerWidth <= 1200;
    const isBigTablet = window.innerWidth <= 1460

    const [numElementsImported, setNumElementsImported] = useState(-1);
    const [resultDataImported, setResultDataImported] = useState<IGenericResult<IDatosXMLM071>[]>([]);
    //const [importDisabled, setImportDisabled] = useState(false);
    
    const [listaMunicipios, setListaMunicipio] = useState<IMunicipioIVTM[]>([]);
    // Formulario
    const [openFormulario,setOpenFormulario] = useState(false)
    const [changes, setChanges] = useState(false)
    const [campos, setCampos]= useState<ICampo[]>([])
    const [selectoresInfo, setSelectoresInfo] = useState<SelectoresInfo| null>(null);
    const [modeloData, setModeloData] = useState<GenericInitialObject| null>(null);
    const [emptyModeloData, setEmptyModeloData] = useState<GenericInitialObject| null>(null);
    const [camposRequiredKeys, setCamposRequiredKeys] = useState<string[]| null>(null);
    const [marca, setMarca] = useState<Option | undefined>(undefined);    
    const [msgErrGenModelo,setMsgErrGenModelo] = useState<string | undefined>(undefined)
    const CLASSES_MOTOS = ['MOTOCICLETES','CICLOMOTORS']
    // Modelos importados ok
    const [listaModelos, setListaModelos] = useState<string[]>([]);
    // Modelos seleccionados por el usuario
    const [userModelos, setUserModelos] = useState<string[]>([])


    // Memos
    const clase = useMemo(() => {
        return modeloData?.clase.value
    }, [modeloData])

    const dataImportedOK = useMemo(()=> {
        const filterOK = resultDataImported.filter(item => listaModelos.includes(item.id))
        return (
            filterOK.map(item => {
                const muni  = listaMunicipios.find(m => m.acodmunigest === item.CodigoMunicipio)
                return ({
                    ...item,
                    municipio: muni?.nombre ?? ' - ',
                } as TExtendedGenericResult<IDatosXMLM071>)
            })
        )
    }, [listaModelos, resultDataImported, listaMunicipios]);
    
    // Wizard ----------------------------------------------------------------------------------------------------

    // const handlePrint = async() => {
    //     // Print justificante de modelos generados
    //     window.alert(' TODO - Print justificante ')
    //     const done = false 
    //     return done
    // }

    const handleImport = async ( dataToImport: IDatosXMLM071[], idDocumento?:string )=> {
        // Guadar el doc
        const response = await importerG.generarModeloPorLotes(dataToImport, idTipoModelo, idDocumento) 
        console.log('generarModeloPorLotes response ', response)
        if(response.ok && response.result){
            const modelos = response.result.filter( item => (item.result=== true && item.id !=='')).map(m => m.id)
            setListaModelos(curr => curr.concat(modelos))
        }
        setNumElementsImported(curr => curr + response.numElImported);
        setResultDataImported(curr => curr.concat(response.result));

        return response
    }

    const addDeleteToList = (id:string )=> {
        if(userModelos.includes(id)){
            setUserModelos( curr => {
                const newD = curr.filter(item => item !== id)
                console.log('delete result', newD)
                return newD
            })
        } else {
            setUserModelos( curr => {
                // curr.push(id)
                const newD = [...curr, id]
                console.log('add result', newD)
                return newD
            })
        }
    }
    const addDeleteAllToList = (v:boolean)=> {
        if(v){
            setUserModelos(listaModelos)
        } else {
            setUserModelos([])
        }
    }
    const renderTableResult = () => {
        //console.log(window.innerWidth, isTablet)
        //console.log('userModelos', userModelos)
        const allChecked = userModelos.length === dataImportedOK.length && userModelos.length>0
        return(
            <Grid item container direction="column" wrap="nowrap" style={{maxHeight: 300, overflowY: 'auto'}}>
                <Grid item container direction="row" alignItems="center" className={classes.header}>
                    <Grid item 
                        xs={isTablet? 1: undefined} sm={isTablet?1:undefined} md={isTablet?1:undefined} lg={(isTablet ||isBigTablet)?1:undefined} xl={undefined} 
                        className={classes.borderRightHeader}
                    >
                        <GenericCheckbox 
                            labelTerm={null}
                            value={allChecked} 
                            onChangeValue={(v:boolean) => addDeleteAllToList(v)}
                            fullWidth
                            style={{justifyContent: 'center', marginBottom: 5}}
                        /> 
                    </Grid>
                    <Grid item xs={!isXSMobile ? 4:6} sm={3}  md={isTablet ?3:2}  lg={2} xl={2}>
                        <Typography className={[classes.borderRightHeader,classes.title].join(' ')}>{translate('Tributos','TributosResumenPagoLocalizador',terms)}</Typography>
                    </Grid>
                    {!isXSMobile &&
                        <Grid item xs={3} sm={3} md={2} lg={2} xl={2}>
                            <Typography className={[classes.borderRightHeader,classes.title].join(' ')}>{translate('Tributos','TributosResumenPagoNif',terms)}</Typography>
                        </Grid>
                    }
                    {!isMobile && !isTablet && 
                        <Grid item xs={4}  md={2} lg={2} xl={2}>
                            <Typography className={[classes.borderRightHeader,classes.title].join(' ')}>{translate('Tributos','municipio',terms)}</Typography>
                        </Grid>
                    }
                    {!isMobile && 
                        <Grid item xs={3} sm={3} md={isTablet ?3:2} lg={2} xl={2}>
                            <Typography className={[classes.borderRightHeader,classes.title].join(' ')}>{translate('Tributos','bastidor',terms)}</Typography>
                        </Grid>
                    }
                    <Grid item xs={!isXSMobile ? 4:5} sm={3} md={isTablet?3:2} lg={2} xl={2}>
                        <Typography className={[classes.borderRightHeader,classes.title].join(' ')}>{translate('Tributos','importe',terms)}</Typography>
                    </Grid>
                    {!isMobile && !isTablet && 
                        <Grid item 
                            xs={isTablet? 2: undefined} sm={isTablet?3:undefined} md={isTablet? 1: undefined} lg={(isTablet || isBigTablet) ? 1: undefined} xl={undefined}
                            style={{display: 'flex', flexGrow: 1, justifyContent: 'center'}} >
                            <Typography className={classes.title}>{translate('Tributos','TributosResumenPagoFechaCreacion',terms)}</Typography>
                        </Grid>
                    }
                </Grid>

                {dataImportedOK.map(result => {
                    const isChecked = userModelos.includes(result.id)
                    return (
                        <Grid key={result.id} item container direction="row">
                            <Grid item
                                xs={isTablet? 1: undefined} sm={isTablet?1:undefined} md={isTablet?1:undefined} lg={(isTablet ||isBigTablet)?1:undefined} xl={undefined} 
                                className={[classes.borderRight, classes.borderLeft].join(" ")}
                            >
                                <GenericCheckbox 
                                    labelTerm={null}
                                    value={isChecked} 
                                    onChangeValue={(v:boolean) => addDeleteToList(result.id)}
                                    fullWidth
                                    style={{justifyContent: 'center', marginBottom: 5}}
                                /> 
                            </Grid>
                            <Grid item xs={!isXSMobile ? 4:6} sm={3}  md={isTablet ?3:2}  lg={2} xl={2}
                                className={[classes.borderRight, classes.borderLeft].join(' ')}
                            >
                                <Typography className={classes.text}>{result.id}</Typography>
                            </Grid>
                            {!isXSMobile &&
                                <Grid item xs={4} sm={3} md={2} lg={2} xl={2}
                                    className={classes.borderRight}
                                >
                                    <Typography className={classes.text}>{result.NifSujetoPasivo}</Typography>
                                </Grid>
                            }
                            {!isMobile && !isTablet &&
                                <Grid item xs={4}  md={2} lg={2} xl={2}
                                    className={classes.borderRight}
                                >
                                    <Typography className={classes.text}>{result.CodigoMunicipio}</Typography>
                                </Grid>
                            }
                            {!isMobile && 
                                <Grid item xs={3} sm={3} md={isTablet ?4:2} lg={2} xl={2}
                                    className={classes.borderRight}
                                >
                                    <Typography className={classes.text}>{result.Bastidor}</Typography>
                                </Grid>
                            }
                            <Grid item xs={!isXSMobile ? 4:6} sm={3} md={isTablet?3:2} lg={2} xl={2}
                                className={classes.borderRight}
                            >
                                <Typography className={classes.text}>{result.importe}</Typography>
                            </Grid>
                            {!isMobile && !isTablet && 
                                <Grid item 
                                    xs={isTablet? 2: undefined} sm={isTablet?3:undefined} md={isTablet? 1: undefined} lg={(isTablet || isBigTablet) ? 1: undefined} xl={undefined}
                                    style={{display: 'flex', flexGrow: 1, justifyContent: 'center'}} 
                                    className={classes.borderRight}
                                >
                                    <Typography>{result.fechaCreacion}</Typography>
                                </Grid> 
                            }
                            
                        </Grid>
                    )
                })}
            </Grid>
        )
    }

    
    // -----------------------------------------------------------------------------------------------------------

    const updateData = ( name: string, value: any) => {
        setChanges(true)
        msgErrGenModelo && setMsgErrGenModelo(undefined)
        let newData: GenericInitialObject | null= null
        if(emptyModeloData){
            newData= modeloData 
                ? {...modeloData, [name]: {...modeloData[name], value: value }}
                : {...emptyModeloData,[name]: {...emptyModeloData[name], value: value }}
        
            //Dependencia Marca/Modelo
            if( name === 'marca' && value){
                //console.log(' marca ', selectoresInfo?.marcaVehic?.find(m => m.nombre === value))
                setMarca(selectoresInfo?.marcaVehic?.find(m => m.nombre === value))
                setSelectoresInfo( curr => ({...curr, modeloVehic: null}))
                newData= {...newData, modeloVeh: {...newData['modeloVeh'], value: undefined}}
            }
        }        
        setModeloData(newData)       
    }

    const generateModelo = async() => {
        try {
            // validar campos
            setLoading(true)
            let valid = validateCamposRequiredKeys(camposRequiredKeys, modeloData)
            valid = valid 
                ? campos?.findIndex(campo => campo.isValid != undefined && !campo.isValid && campo.visible) == -1
                : false;

            if (valid && modeloData) {
                // generar modelo
                const formatedToXML: IDatosXMLM071 = objectBbddToXMLformat_071(modeloData, listaMunicipios)
                const response = await handleImport([formatedToXML])
                if(response.ok){
                    if(response.numElImported>0){
                        setChanges(false);
                        setModeloData(emptyModeloData);
                        setOpenFormulario(false)
                    } else{
                        setMsgErrGenModelo(response.result[0].errMsg ?? 'error-generar-modelo')
                    }
                } else {
                    setMsgErrGenModelo(response.msgErr  ?? 'error-generar-modelo')
                }
            } else{
                // Revisar formulario
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: translate('Tributos','FormularioError', terms),
                        variant: 'error',
                    }
                });
            }
            
        } catch (error) {
            
        } finally {
            setLoading(false)

        }
    }


    const handlePay = async(ids:string[]) => {
        try {
            setLoading(true)
            console.log('userModelos as ids ', ids)
            const idRefModeloGrupo = await modelG.getModeloCodBarLote(ids)
            //setLoading(false)
            if(!idRefModeloGrupo){
                throw new Error('not-modelo-codbar-lote')
            }
            goToPago(idRefModeloGrupo)
            
        } catch (error) {
            alertsDispatch({
                type:'show-alert',
                payload: {
                    variant:'error',
                    message: translate('Tributos', (error as Error).message ?? 'not-modelo-codbar-lote', terms)
                }
            })
        } finally{
            setLoading(false)
        }
        
    }
    const handleExport = async(ids:string[], tipo: 'excel'|'xml') => {
        exportData(ids, tipo, idTipoModelo)
    }


    // Effects
    useEffect(() => {
        (async() => {
            setLoading(true)
            const munis = await modelG.getMunicipiosIVTM();
            //const marcas = await modelG.getMarcasVehiculo(); 
            const usos = await modelG.getSubUsosIVTM();
                
            let campos = await modelG.getCamposModeloPorLote(idTipoModelo)
            campos = campos.map( c => ({...c, 
                soloLectura:  false, 
                renderSize: 'medium',
                renderGroup: ['locaMuni','nif','apeNom'].includes(c.codigo) 
                    ? 1 
                    : ['clase','marca','plazas','modeloVeh'].includes(c.codigo) ? 2 :3,
                obligatorio: ['potenciaFi','cilindrada','carga','plazas'].includes(c.codigo) ? false : c.obligatorio
            }))
            
            let emptyObjt : GenericInitialObject|null = null
            if(campos && campos.length>0){
                emptyObjt = getEmptyObject(campos)
                const keys = getKeysRequiredCampos(campos);
                setCamposRequiredKeys(keys)
            }
            
            setListaMunicipio(munis)
            setSelectoresInfo({
                municipioIVTM: munis.length > 0 ? formatOptions<IMunicipioIVTM>('acodmunigest', 'nombre', munis) : null,
                marcaVehic: null, //marcas.length>0 ? formatOptions<IMarcaVehicIVTM>('idMarca','marca', marcas) : null,
                modeloVehic: null,
                claseVehiIVTM: usos.length>0 ? formatOptions<ISubUsosIVTM>('idSubuso','nombre', usos) : null,
            })
            setCampos(campos)
            setEmptyModeloData(emptyObjt)
            setModeloData(emptyObjt)
            setLoading(false)
        })();
    },[])

    useEffect(() => {
        if(clase && clase !== ''){  
            (async () => {
            try {
                setLoadingModelos(true)
                const marcasBBDD = await modelG.getMarcasVehiculo(CLASSES_MOTOS.includes(clase))
                const marcas = marcasBBDD.length>0 ? formatOptions<IMarcaVehicIVTM>('idMarca','marca', marcasBBDD) : null
                setSelectoresInfo( curr => ({...curr, marcaVehic: marcas}))
            } catch (error) {
                alertsDispatch({
                    type:'show-alert',
                    payload: {
                        variant:'error',
                        message: translate('Tributos','errConsultaMarcas', terms)
                    }
                })
            } finally {
                setLoadingModelos(false)
            }
        })()
    }
    }, [clase])

    useEffect(() => {
        if(marca && marca.id && clase && clase !== ''){  
            (async () => {
            try {
                setLoadingModelos(true)
                const modelosBBDD = await modelG.getModelosVehiculoByMarca(marca.id, CLASSES_MOTOS.includes(clase))
                const modelos = modelosBBDD.length>0 ? formatOptions<IModeloVehicIVTM>('idModelo','modelo', modelosBBDD) : null
                setSelectoresInfo( curr => ({...curr, modeloVehic: modelos}))
            } catch (error) {
                alertsDispatch({
                    type:'show-alert',
                    payload: {
                        variant:'error',
                        message: translate('Tributos','errConsultaModelos', terms)
                    }
                })
            } finally {
                setLoadingModelos(false)
            }
        })()
    }
    }, [marca, clase])



    return (
        <>
        <Grid container direction="column" style={{marginTop: 25}}>
            <FullLoading loading={loading || loadingModelos} msg={loadingModelos? translate('Tributos', 'consultandoModelos',terms): undefined}/>
            <Grid item className={classes.row} style={{marginBottom: 15}}>
                <div>
                    <Button 
                        variant="outlined"
                        color="primary"
                        onClick={() => setOpenFormulario(true)} 
                        startIcon={<Icon size={1} color={BLUE} path={mdiFilePlus}/>}
                    >
                        {translate("Tributos",'InsertarPorFormulario', terms)}
                    </Button>
                    <Button style={{marginLeft: 10}}
                        variant="outlined"
                        color="primary"
                        onClick={() => setOpen(true)} 
                        startIcon={<Icon size={1} color={BLUE} path={mdiFileImport}/>}
                    >
                        {translate("Tributos",'ImportarXML', terms)}
                </Button>
                </div>
                {dataImportedOK.length > 0 && (
                    <div>
                        <Button
                            style={{ marginLeft: 10 }}
                            variant="outlined"
                            color="primary"
                            disabled={userModelos.length === 0}
                            onClick={() => handleExport(userModelos, "excel")}
                            startIcon={<Icon size={1} color={userModelos.length === 0 ? '#0000001f': BLUE} path={mdiFileExport} />}
                        >
                            {translate("Tributos", "ExportarExcel", terms)}
                        </Button>
                        <Button
                            style={{ marginLeft: 10 }}
                            variant="outlined"
                            color="primary"
                            disabled={userModelos.length === 0}
                            onClick={() => handleExport(userModelos, "xml")}
                            startIcon={<Icon size={1} color={userModelos.length === 0 ? '#0000001f': BLUE} path={mdiFileExport} />}
                        >
                            {translate("Tributos", "ExportarXML", terms)}
                        </Button>
                    </div>
                )}
            </Grid>
            <GenericImporter<IDatosXMLM071>
                importType="m071"
                importDocFormat={['XML']}
                title={translate('Tributos', 'mxxx_importar_lote', terms, [idTipoModelo])}
                saveDocImported= {false}
                actionHandleImport={handleImport}
                withActionPrint={false}
                hiddenCells_dataImport={[]} // Tabla de importados err/ok
                hiddenCells_resultImport={['fechaCreacion', 'importe', 'errMsg']} // Tabla resultados generados err/ok
                renderTableListadoResultados={renderTableResult} //() => renderTableResult(dataImportedOK, listaModelos)}
                withActionOpenWizard={false}
                openWizard={open}
                closeWizard={() => setOpen(false)}
            />

            {dataImportedOK.length>0 &&
                <Grid item container direction="row" justify="flex-end">
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={!userModelos.length}
                        onClick={() => handlePay(userModelos)} 
                        startIcon={<Icon size={1} color='#fff' path={mdiCreditCardOutline}/>}
                    >
                        {translate("Tributos",'Pagar', terms)}
                    </Button>
                </Grid>
            }
        </Grid>

        <GenericModal maxWidth="lg"
            open={openFormulario}  
            componentTitleTranslation={"Tributos"}
            title={"nuevaGestion"}
            onClose={() => {setOpenFormulario(false); setModeloData(emptyModeloData); setMsgErrGenModelo(undefined)}} 
            customAction={() => (
                <Button variant="outlined" color="primary" disabled={msgErrGenModelo !== undefined} onClick={generateModelo} >
                    {translate('Tributos', 'generarModelo', terms, [idTipoModelo])}
                </Button>
            )}
        >
            <Grid item container direction="column">
                <Grid item>
                {campos && modeloData && selectoresInfo &&
                    renderCampos(campos,modeloData, updateData, '','', selectoresInfo, undefined, classesCampos, true )
                }
                </Grid>
                { msgErrGenModelo && 
                    <Grid item>
                        <Typography className={classes.msgErr}>{translate('Tributos', msgErrGenModelo, terms)}</Typography>
                    </Grid>
                }
            </Grid>
        </GenericModal>
        </>
    )
}

export default  withLiterals(['Tributos','Global'])(withStyles(styles)(M071Lotes));



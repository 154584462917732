import { FC,  useContext, useEffect } from "react";
import { Grid,  WithStyles, withStyles } from "@material-ui/core";
import { translate } from "utils/i18n";
import { mdiWalletTravel } from "@mdi/js";
import usePage from "hooks/page.hook";
import {  LiteralsContext, withLiterals } from "containers/shared/literals";
import { RouteComponentProps, withRouter } from "react-router-dom";
import styles from "./styles";

import CardMenuItem, { TMenuCard, TButtonAction, TResponse } from "./cards/menu-card";

import Menu1_IMG from '../../../resources/abonoNacimiento/Menu1.png'
import Menu2_IMG from '../../../resources/abonoNacimiento/Menu2.png'
import Menu3_IMG from '../../../resources/abonoNacimiento/Menu3.png'
import Menu4_IMG from '../../../resources/abonoNacimiento/Menu4.png'
import Menu5_IMG from '../../../resources/abonoNacimiento/Menu5.png'
import Menu6_IMG from '../../../resources/abonoNacimiento/Menu6.png'


import { isCIF } from "utils/validateNif";
import BoxHome from "components/box-home";
import { TBoxHomeOptions } from "containers/inicio/helper/inicio.helper";
import { TTerm } from "containers/shared/menu-item-types";
import { FECHA_PUESTA_MARCHA } from "constants/abono-nacimiento";
import moment from "moment";



type IProps = WithStyles<typeof styles>  & RouteComponentProps 
const AbonoNacimientoSolicitudIndex : FC<IProps> = ({classes, ...props }) => {

    const terms = useContext(LiteralsContext);
    const today = new Date(moment().format('YYYY-MM-DD'));
    const fRef = new Date(FECHA_PUESTA_MARCHA);
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);
    fRef.setHours(0);
    fRef.setMinutes(0);
    fRef.setSeconds(0);
    // const ioc = useCondescription(IoC);
    // const tramitesGateway: TramitesGateway = ioc.get(TramitesGateway);    

    const [page, pageDispatcher] = usePage();

    const validatePersFisica = () => {
        //console.log('validatePersFisica ')
        if(page.jwp){
            console.log(isCIF(page.jwp.nif))
            if(isCIF(page.jwp.nif)){
                return {isValid:false, msg: 'alert_no_es_persona_fisica'}

            }else {
                return {isValid:true, msg: undefined}
            }
        } else {
            return {isValid: false, msg: 'requiredLogged'}
        }
    }

  

    const CARDS : {item: TBoxHomeOptions /*TMenuCard*/ ,itemValidate?: () => TResponse }[] = [
        {    
            item:{
                title : {component: 'AbonoNacimiento', text: 'AbonoNacimiento_solic'},
                description : {component: 'AbonoNacimiento', text: 'AbonoNacimiento_solicDescripcion'},
                link: '/tramites/abono-nacimiento/nuevo/alta-solicitud', //nuevo', 
                boxImage: Menu3_IMG,
                isExternLink: false,
                animated: true,
                disabled: today.getTime()<fRef.getTime() ? true: false
            },
            itemValidate: validatePersFisica

        },
        {   
            item: { 
                title : {component: 'AbonoNacimiento', text: 'AbonoNacimiento_reint'},
                description : {component: 'AbonoNacimiento', text: 'AbonoNacimiento_reintDescripcion'},
                link: '/tramites/abono-nacimiento/nuevo/reintegro-abonos', //nuevo', 
                boxImage: Menu4_IMG,
                isExternLink: false,
                animated: true,
                disabled: today.getTime()<fRef.getTime() ? true: false
            },
            itemValidate: validatePersFisica

        },
        {   
            item: { 
                title : {component: 'AbonoNacimiento', text: 'AbonoNacimiento_consulta'},
                description : {component: 'AbonoNacimiento', text: 'AbonoNacimiento_consultaDescripcion'},
                link: '/tramites/abono-nacimiento/consulta', //nuevo', 
                boxImage: Menu5_IMG,
                isExternLink: false,
                animated: true,
                disabled: today.getTime()<fRef.getTime() ? true: false

            },
            itemValidate: validatePersFisica
        },
        {    
            item: {
                title : {component: 'AbonoNacimiento', text: 'AbonoNacimiento_info'} as TTerm,
                description : {component: 'AbonoNacimiento', text: 'AbonoNacimiento_infoDescripcion'},
                link: '/tramites/abono-nacimiento/contenidos', //faqs/10', 
                linkState: {search: page.lang ==='CA'?  `Abonament per naixement` :`Abono por nacimiento`},
                boxImage: Menu6_IMG,
                isExternLink: false,
                animated: true
            }

        },
        {   item: {
                title : {component: 'AbonoNacimiento', text: 'AbonoNacimiento_repre'},
                description : {component: 'AbonoNacimiento', text: 'AbonoNacimiento_RepreDescripcion'},
                link: '/perfil/representaciones/gestion-de-representaciones', 
                boxImage: Menu2_IMG,
                isExternLink: false,
                animated: true
            }
        },
        {    
            item: {
                title : {component: 'AbonoNacimiento', text: 'AbonoNacimiento_FAQs'} as TTerm,
                description : {component: 'AbonoNacimiento', text: 'AbonoNacimiento_FAQsDescripcion'},
                link: '/faqs/21',  
                boxImage: Menu1_IMG,
                isExternLink: false,
                animated: true
            }

        },
    ]

    // const title: TTerm,
    // description?: TTerm,
    // boxImage?: string,
    // link: string,
    // isExternLink?: boolean
    // icon?: string,
    // animated?: boolean
   

    useEffect(() => {
        pageDispatcher({
            type: 'setHeader',
            header: {
                icon: mdiWalletTravel,
                title: translate("Global","AbonoNacimiento", terms),
                text: translate("Global","AbonoIRPFNacimientoIB_DESCRIPCION", terms),

            },
            menu: true,
        });
    }, [pageDispatcher]);

  

    return (
        <div className={classes.container}>
        <Grid container direction="row" alignItems="center" >
            {CARDS.map( (carditem, idx) => (
                <Grid key={`card_${idx}`} item xs={12} sm={6} md={6} lg={6} xl={4} className={classes.widget}>
                    {/* <CardMenuItem 
                        props={props}
                        key={carditem.item.title.description} 
                        item={carditem.item} 
                        itemActions={carditem.itemAction}
                        itemValidation={carditem.itemValidate}
                    /> */}
                    <BoxHome item={carditem.item} key={`box_optionItem${idx}`} itemValidation={carditem.itemValidate} />
                </Grid>
            ) )}
            
        </Grid>       
        </div>
    )
}


export default  withLiterals(['Global', 'Tramites','AbonoNacimiento' ])(withStyles(styles)(withRouter(AbonoNacimientoSolicitudIndex))); 

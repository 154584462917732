
// LIBRARY IMPORTS
import { FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Button, Card, MenuItem, Typography, makeStyles } from "@material-ui/core";
import { RouteComponentProps, } from "react-router-dom";
import { mdiLaptop } from "@mdi/js";
// LOCAL IMPORTS
import { LiteralsContext } from "containers/shared/literals";
import { handleInfoDispatch } from "utils/info-dispatch-mvl";
import { MoreInfoContext } from "contexts/more_info.context";
import { AlertsContext } from "contexts/alerts.context";
import { IPreciodadImporte, IRecibo } from "gateways/recibo.interfaces";
import { StaticContext } from "react-router";
import { dateFormat } from "utils/dates";
import { IDiasCortesia } from "gateways/notificaciones.interfaces";
import { download } from "utils/download";
import { translate } from "utils/i18n";
import ConceptoIcon from "components/concepto.icon";
import IoC from 'contexts/ioc.context';
import usePage from "hooks/page.hook";
import Term from "components/term";
import * as recibosMethodGateway from 'gateways/recibo.gateway';
// STYLES
import styles from '../../shared/pago-a-cuenta.styles';

const useStyles = makeStyles(styles);

interface IStatePagoACuentaConfirmacion {
    recibo: IRecibo;
    arrayPeriocidadImportes: IPreciodadImporte[];
    planSeleccionado: "automatico" | "personalizado" | undefined;
    numDiaCargoPlanAutomaticoSeleccionado: string;
    diasVoluntaria: IDiasCortesia | undefined;
}

type Props = RouteComponentProps<{}, StaticContext, IStatePagoACuentaConfirmacion>;

const PagoACuentaConfirmacion: FC<Props> = ({ history, location }) => {
    const state = location.state;
    const recibo = state.recibo;
    const arrayPeriocidadImportes = state.arrayPeriocidadImportes;
    const planSeleccionado = state.planSeleccionado;
    const numDiaCargoPlanAutomaticoSeleccionado = state.numDiaCargoPlanAutomaticoSeleccionado;
    const diasVoluntaria = state.diasVoluntaria;

    // HOOKS
    const terms = useContext(LiteralsContext);
    const classes = useStyles();

    const [, infoDispatch] = useContext(MoreInfoContext);
    const [, alertsDispatch] = useContext(AlertsContext);
    const [pageState, pageDispatcher] = usePage();

    // GATEWAYS
    const ioc = useContext(IoC);
    const recibosGateway = useMemo(() => ioc.get(recibosMethodGateway.RecibosGateway) as recibosMethodGateway.RecibosGateway, [ioc]);

    // STATES
    const [esPagoIncompleto, setEsPagoIncompleto] = useState<boolean>(true);



    // -------- USE EFFECT --------

    useEffect(() => {
        pageDispatcher({
            type: 'setHeader',
            header: {
                icon: mdiLaptop,
                title: <Term component="CarteroVirtual" text='pago_a_cuenta_confirmacion' />,
                moreInfoTemplate: 'mas_info_pago_a_cuenta_confirmacion',
                right: (
                    <MenuItem button onClick={() => handleShowMasInfo('mas_info_pago_a_cuenta_confirmacion')}>
                        <Term component="Global" text="Mas informacion" />
                    </MenuItem>

                ),
            },
            menu: true,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageDispatcher, terms]);

    useEffect(() => {
        if (!recibo || !arrayPeriocidadImportes) return;

        const totalImporte = arrayPeriocidadImportes.reduce((acc, current) => acc + parseFloat(current.importe), 0);

        if ((totalImporte).toFixed(2) === (recibo.desglose.pendiente).toFixed(2)) setEsPagoIncompleto(false);
        else setEsPagoIncompleto(true);
    }, [recibo, arrayPeriocidadImportes]);



    // -------- HANDLERS --------

    const handleCancelarConfirmacion = () => {
        history.push('/cartero-virtual/deuda-pendiente/pago-a-cuenta-domiciliado', { recibo, arrayPeriocidadImportes, planSeleccionado, numDiaCargoPlanAutomaticoSeleccionado, diasVoluntaria });
    };

    const handleShowMasInfo = useCallback((templateName: string) => {
        handleInfoDispatch(infoDispatch, 'show-info', templateName)
    }, [infoDispatch]);

    const handlePrintJustificantePagoCuentaReciboDomiciliado = async () => {
        alertsDispatch({ type: 'hide-alert' });

        const result = await recibosGateway.printJustificantePagosCuentaDomiciliacion(recibo.domiciliacion.idDomiRec);
        download(result, alertsDispatch,
            translate('PasarelaPago', 'No es posible descargar el justificante de pago', terms),
            translate('Global', 'BloqueoPantalla', terms));
    };

    const handleFinalizar = async () => {
        try {
            await recibosGateway.bajaPagoACuentaDomiciliacion(recibo.domiciliacion.idDomiRec);

            const result = await Promise.all(arrayPeriocidadImportes.map(async (periodicidadImporte) => {
                const response = await recibosGateway.pagoACuentaDomiciliacion(recibo.domiciliacion.idDomiRec, periodicidadImporte.fecha, periodicidadImporte.importe);
                return response;
            }));

            if (!result || result.length < 1 || result.some(x => !x)) {
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: <Term component="CarteroVirtual" text="error_pago_cuenta_recibo_domiciliado" />,
                        variant: 'error',
                    }
                });
                return;
            }

            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message: <Term component="CarteroVirtual" text="finalizado_pago_cuenta_domiciliacion" />,
                    variant: 'success',
                    hasCustomAction: true,
                    actionTemplateName: "Descargar justificante",
                    handleCustomAction: handlePrintJustificantePagoCuentaReciboDomiciliado
                }
            });

            history.push('/cartero-virtual/deuda-pendiente')
        }
        catch (e) {
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message: <Term component="CarteroVirtual" text="error_pago_cuenta_recibo_domiciliado" />,
                    variant: 'error',
                }
            });
        }
    };



    return (
        <Card className={[classes.wFull, classes.cardSpacing].join(' ')}>
            <Typography className={[classes.titleTextCard, classes.bold, classes.mBottom30].join(' ')}>
                <Term component={"CarteroVirtual"} text={"resumen_pago_a_cuenta"} />
            </Typography>

            {/* DATOS SUJETO */}

            <div className={[classes.gap20, classes.grid].join(' ')} style={{ gridTemplateRows: '30px auto' }}>
                <div className={[classes.borderBottomGrey, classes.flexRow].join(' ')}>
                    <Typography className={[classes.subtitleText, classes.flex1].join(' ')}>
                        <Term component="Domiciliaciones" text="Datos del sujeto" />
                    </Typography>
                    <Typography className={[classes.subtitleText, classes.flex1].join(' ')}>
                        <Term component="CarteroVirtual" text="datos_domiciliacion_pago_a_cuenta" />
                    </Typography>
                </div>

                {/* DATOS DOMICILIACIÓN PAGO A CUENTA */}

                <div className={classes.flexRow}>
                    <div className={[classes.bold, classes.flex1, classes.flexColumn].join(' ')}>
                        <Typography > D.N.I / N.I.F / N.I.E: <b>{pageState?.jwp?.nif}</b> </Typography>
                        <Typography > <Term component="Global" text="Nombre" />: <b>{pageState?.jwp?.fullname}</b> </Typography>
                    </div>
                    <div className={[classes.bold, classes.flex1, classes.flexColumn].join(' ')}>
                        <Typography > D.N.I / N.I.F / N.I.E: <b>{recibo.domiciliacion.nifTitular}</b> </Typography>
                        <Typography >  <Term component="Notificaciones" text="Titular" />: <b>{recibo.domiciliacion.nombreTitular}</b> </Typography>
                        <Typography > IBAN: <b>{recibo.domiciliacion.iban}</b> </Typography>
                    </div>
                </div>
            </div>

            {/* DEUDA SELECCIONADA */}

            <div className={[classes.borderBottomGrey, classes.flexRow, classes.mTop20].join(' ')}>
                <Typography className={[classes.subtitleText, classes.bold, classes.flex1].join(' ')}>
                    <Term component="Domiciliaciones" text="Deuda seleccionada" />
                </Typography>
            </div>

            <div key={recibo.id} className={[classes.flexRow, classes.alignCenter, classes.flexNoWrap, classes.m20, classes.relative].join(' ')}>
                <div className={classes.imagenContainerPagoCuentaConfirmacion}>
                    <ConceptoIcon concepto={recibo.concepto.idTipo}></ConceptoIcon>
                </div>
                <div className={[classes.flexColumn, classes.m20].join(' ')}>
                    <Typography style={{ fontWeight: 'bold' }}>{recibo.organismo.nombre}</Typography>
                    <Typography style={{ fontWeight: 'bold' }}>{recibo.concepto.nombre}</Typography>
                    <Typography style={{ fontWeight: 'bold' }}>{recibo.objeto}</Typography>
                    <Typography style={{ fontWeight: 'bold' }}>{recibo.direccionTributaria}</Typography>
                </div>
            </div>

            {/* DETALLE DE PAGO */}

            <div className={classes.mBottom40}>
                <div className={[classes.borderBottomGrey, classes.flexRow, classes.mTop20].join(' ')}>
                    <Typography className={[classes.subtitleText, classes.bold, classes.flex1].join(' ')}>
                        {
                            planSeleccionado === "automatico"
                                ? <Term component="CarteroVirtual" text="plan_de_pago_automatico" />
                                : <Term component="CarteroVirtual" text="plan_de_pago_personalizado" />}
                    </Typography>
                </div>
            </div>

            <div className={[classes.bold, classes.flex1, classes.flexColumn, classes.mTop20].join(' ')}>
                {arrayPeriocidadImportes.sort( (a,b) => a.fecha < b.fecha ? -1 :1).map((periodicidadImporte, index) => (

                    <div key={index} className={classes.flexRow}>
                        <Typography>
                            <Term component="Domiciliaciones" text="Periodicidad" />: <b>{dateFormat(periodicidadImporte.fecha)}</b>
                        </Typography>
                        <Typography style={{ marginLeft: 20 }}>
                            <Term component="Domiciliaciones" text="Importe" />: <b>{periodicidadImporte.importe} €</b>
                        </Typography>
                    </div>
                ))}
            </div>

            {/* CANECELAR - FINALIZAR */}

            <div className={[classes.w100, classes.flexRow, classes.justifyEnd, classes.gap20].join(' ')}>
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.buttonCancel}
                    onClick={handleCancelarConfirmacion}
                >
                    <Term component="Global" text={"Cancelar"} />
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    className={classes.buttonSuccess}
                    onClick={handleFinalizar}
                    disabled={esPagoIncompleto}
                >
                    <Term component="Global" text={"Finalizar"} />
                </Button>
            </div>
        </Card>
    );
}

export default PagoACuentaConfirmacion;
import React, { FC, useContext, useMemo, useState, useEffect } from 'react';
import { useTheme, makeStyles, Card, CardContent, CircularProgress, Dialog, DialogContent, useMediaQuery, Button } from '@material-ui/core';
import IoC from 'contexts/ioc.context';
import usePage, { getLang } from 'hooks/page.hook';
import { ProcedimientoGateway } from '../../../gateways/procedimientos.gateway';
import { IProcedimiento } from '../../../gateways/rolsac/procedimiento';
import Term from '../../../components/term';
import { mdiAccountArrowRight, mdiApplicationExport, mdiBriefcase, mdiFilePdfBox, mdiExitToApp } from '@mdi/js';
import { ITramite } from '../../../gateways/rolsac/tramite';
import { RouteComponentProps } from 'react-router';
import LabelField from '../../../components/label-field';
import { IDocumentosNormativa, IDocumentosTramite } from '../../../gateways/rolsac/documentos';
import Icon from '@mdi/react';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    container: {
        flex: 1,
        margin: '0 0.5rem'
    },
    procedimientosContainer: {
        flex: 1,
        margin: '0 0.5rem'
    },
    title: {
        color: '#004f84',
    },
    centerContent: {
        margin: '0 auto',
    },
    cardContador: {
        marginBottom: '0.5rem',
        backgroundColor: 'rgba(255,255,255,0.6)',
    },
    marginCard: {
        marginLeft: 30,
        marginRight: 30,
    },
    cardContadorContainer: {
        padding: '13px !important',
        display: 'flex',
        alignItems: 'center',
    },
    titleCardContador: {
        fontWeight: 300,
        fontSize: 16,
        marginLeft: '1rem',
        color: '#406a8d',
        flex: 1
    },
    tramiteLista: {
        fontWeight: 400,
        textDecoration: 'underline',
        cursor: 'pointer',
    },
    enlaceMiga: {
        color: '#004f84',
    },
    drawer: {
        [theme.breakpoints.up('md')]: {
            flexShrink: 0,
            width: 300,
        }
    },
    fila: {
        display: 'flex',
        marginBottom: 20,
    },
    listadoTramites: {
        display: 'flex',
        alignItems: 'center'
    },
    contenido: {
        flex: 1,
        textAlign: 'justify',
        maxWidth: '90%'
    },
    enlace: {
        color: 'black',
        textDecoration: 'underline',
        fontWeight: 400,
    },
    contenidoMax: {
        flex: 1,
        textAlign: 'justify',
    },
    procedimiento: {
        marginBottom: '0.5rem'
    },
    alignIconText: {
        display: "flex",
        gap: "0.5rem",
        alignItems: "center",
        color: "rgba(0, 0, 0, 0.87)",
        textDecoration: "underline"
    },
    [theme.breakpoints.up('md')]: {
        root: {
            display: 'flex',
        },
        container: {
            margin: '0 2rem',
        },
        procedimientosContainer: {
            margin: '0 2rem'
        },
    }
}));

const interpolate = <T extends Record<string, any>>(template: string, vars: T): string =>
    template.replace(/\$\{(\w+)\}/g, (match, key) =>
      key in vars ? String(vars[key as keyof T]) : match
    );

const Procedimiento: FC<RouteComponentProps<{ idProcedimiento: string }>> = ({ match }) => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const ioc = useContext(IoC);
    const procedimientoGateway = useMemo(() => ioc.get(ProcedimientoGateway) as ProcedimientoGateway, [ioc]);

    const [, pageDispatcher] = usePage();
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [tramite, setTramite] = useState<ITramite | null>(null);
    const [procedimiento, setProcedimiento] = useState<IProcedimiento | null>(null);
    const [lang, setLang] = useState<string>();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleOpenModal = (tramiteAux: ITramite) => {
        
        setTramite(tramiteAux);
        setOpen(true);
    }

    const handleCloseModal = () => {
        setOpen(false);
    }

    useEffect(() => {
        pageDispatcher({
            type: 'setHeader',
            header: {
                icon: mdiBriefcase,
                text: (
                    <>
                        <a className={classes.enlaceMiga} href="tramites/procedimientos"><Term component="Tramites" text="Listado" /></a>
                        <span>{' > '}{procedimiento !== null ? procedimiento.nombre : <Term component="Tramites" text="Procedimientos" />}</span>
                    </>
                ),
                title: <Term component="Tramites" text="Procedimientos" />
            },
            menu: true,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageDispatcher, procedimiento]);

    useEffect(() => {
        (async () => {
            const response = await procedimientoGateway.obtenerProcedimiento({ codigo: match.params.idProcedimiento });
            setProcedimiento(response);
            setLoading(false);
        })();
    }, [procedimientoGateway, match.params.idProcedimiento])

    useEffect(() => {
        setLang(getLang());
    },[]);


    return (
        <>
            <div className={classes.root}>
                <div className={classes.root}>
                    <div className={classes.procedimientosContainer}>
                        {
                            loading || procedimiento === null
                                ?
                                <div className={classes.centerContent}>
                                    <CircularProgress />
                                </div>
                                :
                                <>
                                    <Card className={classes.procedimiento}>
                                        <CardContent className={classes.marginCard}>
                                            <div>
                                                <h3 className={classes.title}>
                                                    <Term component="Tramites" text="DetalleDelProcedimiento" />
                                                </h3>
                                            </div>
                                            <div className={classes.fila}>
                                                <div className={classes.contenido}>
                                                    <LabelField label={<Term component="Tramites" text="codigoSia" />} text={procedimiento.codigoSIA} />
                                                </div>
                                            </div>
                                            <div className={classes.fila}>
                                                <div className={classes.contenido}>
                                                    <LabelField label={<Term component="Tramites" text="PersonasDestinatarias" />} text={procedimiento.destinatarios} />
                                                </div>
                                                <div className={classes.contenido}>
                                                    <LabelField label={<Term component="Tramites" text="OrganoCompetente" />} text={procedimiento.link_organResolutori.descripcion} />
                                                </div>
                                            </div>
                                            <div className={classes.fila}>
                                                {
                                                    procedimiento.resolucion && <div className={classes.contenido}>
                                                        <LabelField label={<Term component="Tramites" text="PlazoMaximoResolucion" />} text={procedimiento.resolucion} />
                                                    </div>
                                                }
                                                <div className={classes.contenido}>
                                                    <LabelField label={<Term component="Tramites" text="SilencioAdministrativo" />} text={procedimiento.silencio.nombre} />
                                                </div>
                                            </div>
                                            <div className={classes.fila}>
                                                <div className={classes.contenido}>
                                                    <LabelField label={<Term component="Tramites" text="FormaInicio" />} text={procedimiento.iniciacion ? procedimiento.iniciacion.nombre : ''} />
                                                </div>
                                                <div className={classes.contenido}>
                                                    <LabelField label={<Term component="Tramites" text="FinViaAdministrativa" />} text='No' />
                                                </div>
                                            </div>
                                            <div className={classes.fila}>
                                                <div className={classes.contenidoMax}>
                                                    <LabelField label={<Term component="Tramites" text="ResumenProcedimientos" />} multiline={procedimiento.resumen.replaceAll('\r\n', '<br>')} />
                                                </div>
                                            </div>
                                            {
                                                procedimiento.observaciones && <div className={classes.fila}>
                                                    <div className={classes.contenidoMax}>
                                                        <LabelField label={<Term component="Tramites" text="ObservacionesProcedimientos" />} multiline={procedimiento.observaciones.replaceAll('\r\n', '<br>')} />
                                                    </div>
                                                </div>
                                            }
                                        </CardContent>
                                    </Card>

                                    {
                                        procedimiento.normativa && procedimiento.normativa.documentos && procedimiento.normativa.documentos.length > 0
                                            ?
                                            <Card className={classes.procedimiento}>
                                                <CardContent className={classes.marginCard}>
                                                    <div className={classes.fila} style={{ alignItems: 'center' }}>
                                                        {procedimiento.normativa.documentos.map((documento: IDocumentosNormativa, index: number) => (
                                                            <>
                                                                <Icon key={`procedimiento_${procedimiento.codigoSIA}_documento_${index}_image`} path={mdiFilePdfBox}
                                                                    size={1}
                                                                />
                                                                <a className={classes.enlace}
                                                                    key={`procedimiento_${procedimiento.codigoSIA}_documento_${index}`}
                                                                    href={documento.link_archivo?.href} target="_blank" rel="noreferrer">{documento.titulo}</a>
                                                            </>
                                                        ))}
                                                    </div>
                                                </CardContent>
                                            </Card>
                                            :
                                            null
                                    }
                                    <Card className={classes.procedimiento}>
                                        <CardContent className={classes.marginCard}>
                                            <div>
                                                <h3 className={classes.title}>
                                                    <Term component="Tramites" text="Tramites_lowerCase" />
                                                </h3>
                                            </div>
                                            {procedimiento.tramites.map((tramiteIndex: ITramite, index: number) => (
                                                <div key={`procedimiento_${procedimiento.codigo}_tramite_${index}`} className={classes.listadoTramites}>
                                                    <p key={`procedimiento_${procedimiento.codigoSIA}_tramite_${index}`} className={classes.tramiteLista} onClick={() => handleOpenModal(tramiteIndex)}>{tramiteIndex.nombre}</p>
                                                    <Icon key={`procedimiento_${procedimiento.codigoSIA}_tramite_${index}_telematico`} path={mdiApplicationExport}
                                                        size={1}
                                                        color={tramiteIndex.telematico ? '#db8605' : '#d5d5d5'}
                                                        style={{ marginLeft: 10, marginRight: 10 }}
                                                    />
                                                    <Icon key={`procedimiento_${procedimiento.codigoSIA}_tramite_${index}_online`} path={mdiAccountArrowRight}
                                                        size={1}
                                                        color={tramiteIndex.presencial ? '#db8605' : '#d5d5d5'}
                                                    />
                                                </div>
                                            ))}
                                        </CardContent>
                                    </Card>
                                    <Dialog
                                        open={open}
                                        fullScreen={fullScreen}
                                        maxWidth={"md"}
                                        fullWidth={true}
                                        onClose={handleCloseModal}
                                        aria-labelledby="draggable-dialog-title"
                                    >
                                        {
                                            tramite !== null
                                                ?
                                                <DialogContent>
                                                    <div className={classes.fila}>
                                                        <h3 className={classes.title}>
                                                            {tramite.nombre}
                                                        </h3>
                                                    </div>
                                                    <div className={classes.fila}>
                                                        <div className={classes.contenidoMax}>
                                                            <LabelField label={<Term component="Tramites" text="RequisitosTramites" />} text={tramite.requisits} />
                                                        </div>
                                                    </div>
                                                    <div className={classes.fila}>
                                                        <div className={classes.contenidoMax}>
                                                            <LabelField label={<Term component="Tramites" text="Plazos" />} text={tramite.plazos} />
                                                        </div>
                                                    </div>
                                                    <div className={classes.fila}>
                                                        <div className={classes.contenido}>
                                                            <LabelField label={<Term component="Tramites" text="Presencial" />} text={tramite.presencial ?
                                                                <Term component="Tramites" text="Disponible" /> : <Term component="Tramites" text="No disponible" />} />
                                                        </div>
                                                        <div className={classes.contenido}>
                                                            <LabelField label={<Term component="Tramites" text="Telematico" />} text={(tramite.telematico && (tramite.plantilla?.plataforma?.urlAcceso || tramite.urlExterna))
                                                                ?
                                                                <a
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                    href={tramite.plantilla?.plataforma?.urlAcceso 
                                                                        ? interpolate(tramite.plantilla.plataforma.urlAcceso, {
                                                                            idTramitePlataforma: tramite.plantilla.identificador,
                                                                            versionTramitePlatorma: tramite.plantilla.version,
                                                                            idioma: lang,
                                                                            servicio: "false",
                                                                            idTramiteRolsac: tramite.codigo,
                                                                            parametros: ""
                                                                        })
                                                                        : tramite.urlExterna
                                                                    }
                                                                >
                                                                    <div className={classes.alignIconText}>
                                                                        <Term component="Tramites" text="Disponible" />
                                                                        <Icon
                                                                            path={mdiExitToApp}
                                                                            size={1}
                                                                        />
                                                                    </div>
                                                                </a>
                                                                :
                                                                <Term component="Tramites" text="No disponible" />} />
                                                        </div>
                                                    </div>
                                                    <div style={{ display: 'flex' }} className={(tramite.documentos && tramite.documentos.length > 0) ? undefined : classes.fila}>
                                                        <div className={classes.contenido}>
                                                            <LabelField label={<Term component="Tramites" text="Lugar" />} text={tramite.lugar} />
                                                        </div>
                                                        <div className={classes.contenido}>
                                                            <LabelField label={<Term component="Tramites" text="OrganoCompetente" />} text={tramite.link_organCompetent.descripcion} />
                                                        </div>
                                                    </div>
                                                    {
                                                        tramite.documentos && tramite.documentos.length > 0
                                                            ?
                                                            <div className={classes.fila} style={{ flexDirection: 'column' }}>
                                                                {tramite.documentos.map((documento: IDocumentosTramite, index: number) => (
                                                                    <div className={classes.alignIconText} style={{ flex: 1 }}>
                                                                        <a className={classes.enlace}
                                                                            key={`tramite_${procedimiento.codigoSIA}_documento_${index}`}
                                                                            href={documento.link_archivo.href} target="_blank"
                                                                            rel="noreferrer">{documento.titulo}</a>
                                                                        <Icon key={`tramite_${procedimiento.codigoSIA}_documento_${index}_image`} path={mdiFilePdfBox}
                                                                            size={1}
                                                                        />
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            :
                                                            null
                                                    }

                                                </DialogContent>
                                                :
                                                null
                                        }
                                    </Dialog>
                                </>
                        }
                    </div>
                </div>
            </div>
        </>
    );
}

export default Procedimiento;
import { ICampo } from "gateways/model.new.interface";
import { groupCampos, GroupLabel } from "../utils";
import { translate } from "utils/i18n";

const getFormulaRepeticion= (idTipoApartado: string): {
    grupo: number;
    repeticion: number;
    incrementoCasilla: number;
}[] => {
    switch (idTipoApartado) {
        case '666_B':
            return([
                {grupo: 1, repeticion:20, incrementoCasilla: 1},
                {grupo: 22, repeticion:20, incrementoCasilla: 1}
            ])
        case '666_G':
            return([
                {grupo: 1, repeticion:5, incrementoCasilla: 20},
            ])
        case '666_H':
            return([
                {grupo: 1, repeticion:5, incrementoCasilla: 20},
                {grupo: 7, repeticion:5, incrementoCasilla: 20},
                {grupo: 13, repeticion:5, incrementoCasilla: 20},
                {grupo: 19, repeticion:5, incrementoCasilla: 20},
                {grupo: 25, repeticion:5, incrementoCasilla: 20},
                {grupo: 31, repeticion:5, incrementoCasilla: 20},
                {grupo: 37, repeticion:5, incrementoCasilla: 20},
            ])
        default:
            return ([]);
    }
}

/** Procesado Campos Modelo 666
 * Todos los campos del mimo grupo pertenecena la misma Tabla
 * Los campos con fórmula son los totales y por ello serán los rehubicados como última fila (update renderGroup)
 * El resto de campos son los correspondientes a 1 Fila. Esta fila se repetirá tantas vesdes como indique su formula 
 * repetición y necesitará que se actualice su nº de casilla (incr. +1) y su render group.
 * */
export const desgloseCampos = (campos: ICampo[], tipoApartado:string) => {
    const groupRepeticion = getFormulaRepeticion(tipoApartado)
    const groupedCampos = groupCampos(campos)
    const idTipoModelo = tipoApartado.substring(0,3)
    // console.log('desgloseCampos idTipoModelo',idTipoModelo)
    // console.log('desgloseCampos - groupedCampos:',groupedCampos)
    // console.log('desgloseCampos - groupRepeticion:', groupRepeticion)

    const out = groupedCampos.map( gC => {
        const fRepeticiones = groupRepeticion.find( item => item.grupo===gC.group)?.repeticion ?? 1
        const incrCasilla = groupRepeticion.find( item => item.grupo===gC.group)?.incrementoCasilla ?? 1

        const filaRep = gC.campos.filter( c => (c.formula === null || c.formula === ''))
        const filaTotales = gC.campos.filter( c => (c.formula !== null && c.formula !== '')).map(c => ({...c, renderGroup: c.renderGroup+fRepeticiones}))
        // console.log('fRepeticiones:', fRepeticiones,'  filaRep:',filaRep, '  filaTotales: ',filaTotales)
        const camposAll: ICampo[][]=[]
        for (let index = 0; index < fRepeticiones; index++) {
            // console.log('for index: ', index)
            if(index === 0){
                camposAll.push(filaRep);
            } else {
                const updated = filaRep.map(f => {
                    const newCasilla =tipoApartado === '666_B' ? f.casilla+index :  f.casilla + (index*incrCasilla)
                    return({...f,
                        codigo: `${idTipoModelo}_c${newCasilla}`, //`${f.codigo}.${newCasilla}`, 
                        casilla: newCasilla, renderGroup: f.renderGroup+index
                    })}
                )
                // console.log('updated: ', updated)
                camposAll.push(updated)
            }  
            // console.log('camposAll: ', camposAll)
        }
        camposAll.push(filaTotales)
        return {group: gC.group, campos: camposAll.flat()}
    })

    return out
}

export const getGroupLabel = (idTipoApartado: string, terms: any, group?: number):GroupLabel => {
    switch (idTipoApartado) {
        case '666_B':
            return({
                '1S': translate('Tributos','cuotaFijaNumUnidades', terms),
                '1': 'A','2':'B.1.1','3':'B.1.2','4':'B.1.3','5':'B.1.4','6':'B.1.5','7':'B.1.6','8':'B.1.7','9':'B.2.1','10':'B.2.2',
                '11': 'B.2.3','12':'B.3.1','13':'B.3.2','14':'B.3.3','15':'B.3.4','16':'B.3.5','17':'B.3.6','18':'B.3.7','19':'B.3.8','20':'B.3.9',
                '21': 'TOTAL',
                '22S': translate('Tributos','cuotaVariableM3', terms),
                '22': 'A','23':'B.1.1','24':'B.1.2','25':'B.1.3','26':'B.1.4','27':'B.1.5','28':'B.1.6','29':'B.1.7','30':'B.2.1','31':'B.2.2',
                '32': 'B.2.3','33':'B.3.1','34':'B.3.2','35':'B.3.3','36':'B.3.4','37':'B.3.5','38':'B.3.6','39':'B.3.7','40':'B.3.8','41':'B.3.9',
                '42': 'TOTAL'
            });
        case '666_G':
        case '666_H':
            const groupRepeticion = getFormulaRepeticion(idTipoApartado)
            const labelSeccion: Record<number,string> = { 1: 'Tarifa A' }
            const labelSeccionH = 'Tarifa B.1.';
            const labelRep = translate('Tributos','bloque', terms)
            let result = {};
            console.log()
            groupRepeticion.forEach((group, idx) => {
                //console.log(group.grupo, group.repeticion)
                result = {...result, [`${group.grupo}S`]: idTipoApartado === '666_G' ?`${labelSeccion[group.grupo]}`: `${labelSeccionH}${idx+1}`}
                for (let i = 1; i <= group.repeticion; i++) {
                    result = {...result, [group.grupo+(i-1)]:`${labelRep} ${i}`}
                }
                // total
                result = {...result, [`${group.grupo+group.repeticion}`]: 'TOTAL'}

            });
            return result;
        default:
            return {};
    }
}

export const getCasillasToExtractZeroCompare = (idTipoApartado:string) => {
    switch (idTipoApartado ) {
        case '666_G':
            return([
                {conditionedCasilla: 541,valueToRecover:'666_B.161'}, 
                {conditionedCasilla: 551,valueToRecover:'666_B.241'}, 
                {conditionedCasilla: 561,valueToRecover:'666_B.181'}, 
                {conditionedCasilla: 571,valueToRecover:'666_B.261'}
            ])
        case '666_H':
            return([
                // Tarifa B.1.1
                {conditionedCasilla: 542,valueToRecover:'666_B.162'}, 
                {conditionedCasilla: 552,valueToRecover:'666_B.242'}, 
                {conditionedCasilla: 562,valueToRecover:'666_B.182'}, 
                {conditionedCasilla: 572,valueToRecover:'666_B.262'},
                // Tarifa B.1.2
                {conditionedCasilla: 543,valueToRecover:'666_B.163'}, 
                {conditionedCasilla: 553,valueToRecover:'666_B.243'}, 
                {conditionedCasilla: 563,valueToRecover:'666_B.183'}, 
                {conditionedCasilla: 573,valueToRecover:'666_B.263'},
                // Tarifa B.1.3
                {conditionedCasilla: 544,valueToRecover:'666_B.164'}, 
                {conditionedCasilla: 554,valueToRecover:'666_B.244'}, 
                {conditionedCasilla: 564,valueToRecover:'666_B.184'}, 
                {conditionedCasilla: 574,valueToRecover:'666_B.264'},
                // Tarifa B.1.4
                {conditionedCasilla: 545,valueToRecover:'666_B.165'}, 
                {conditionedCasilla: 555,valueToRecover:'666_B.245'}, 
                {conditionedCasilla: 565,valueToRecover:'666_B.185'}, 
                {conditionedCasilla: 575,valueToRecover:'666_B.265'},
                // Tarifa B.1.5
                {conditionedCasilla: 546,valueToRecover:'666_B.166'}, 
                {conditionedCasilla: 556,valueToRecover:'666_B.246'}, 
                {conditionedCasilla: 566,valueToRecover:'666_B.186'}, 
                {conditionedCasilla: 576,valueToRecover:'666_B.266'},
                // Tarifa B.1.6
                {conditionedCasilla: 547,valueToRecover:'666_B.167'}, 
                {conditionedCasilla: 557,valueToRecover:'666_B.247'}, 
                {conditionedCasilla: 567,valueToRecover:'666_B.187'}, 
                {conditionedCasilla: 577,valueToRecover:'666_B.267'},
                // Tarifa B.1.7
                {conditionedCasilla: 548,valueToRecover:'666_B.168'}, 
                {conditionedCasilla: 558,valueToRecover:'666_B.248'}, 
                {conditionedCasilla: 568,valueToRecover:'666_B.188'}, 
                {conditionedCasilla: 578,valueToRecover:'666_B.268'},
            ])
        default:
            return [];
    }
}

export const extractSingleValueXMLPrev = (formula:string, prevXML:string) => {
    if(formula.includes('_')){ // Es valor de xml previos
        const regex = new RegExp(`\<${formula}>(.+?)\<\/${formula}\>`, "g");
        const found = prevXML.replace(/(\r\n|\n|\r)/gm, "").match(regex); // quitar saltos línea
        const valueToReplace = found
          ? found[0].replace(`<${formula}>`, "").replace(`</${formula}>`, "")
          : "0";

        // console.log('extractSingleValueXMLPrev found', valueToReplace)
        return Number(valueToReplace)

    } else {
        return undefined;
    }
}
import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { ICampo, IOriginalShared, IShared } from '../../../gateways/model.new.interface';
import { Checkbox, Dialog, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, IconButton, List, ListItemText, TextField, TextFieldProps, InputAdornment } from '@material-ui/core';
import styles from './styles'
import { makeStyles } from '@material-ui/styles';
import { Option, getMaxDate, getMinDate } from '../utils';
import { translate } from 'utils/i18n';
import { ALL_LITERALS, LiteralsContext, withLiterals } from 'containers/shared/literals';
import { validateNif } from 'utils/validateNif';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Term from 'components/term';
import moment from 'moment';
import Icon from '@mdi/react';
import { mdiMagnify } from '@mdi/js';
import { BLUE } from 'containers/shared/colors';
import InfoBtn from 'components/generic-info-btn/info-btn';
import SelectEjerTipoPeriod from './campos/selectEjerTipoPeriod';
import GenericInputNumber from 'components/generic-input-number';




interface RCProps {
    idTipoApartado: string,
    campo: ICampo;
    value?: string | number | null | boolean;
    setValue?: (name: string, value: any) => void;
    options?: Option[] | null;
    isValid?: (valid?: boolean) => void;
    textActionProps?: {customAction: (v:string) => void, mdiIconAction: any, color?:string}
}


const useStyles = makeStyles(styles);

const Campo: FC<RCProps> = (props) => {
    const { idTipoApartado,campo, value, setValue, options, isValid, textActionProps } = props;
    const classes = useStyles();
    const terms = useContext(LiteralsContext);
    const [errRegExp, setErrRegexp] = useState(false);
    const [err, setErr] = useState(false);
    const [errNif, setErrNif] = useState(false);
    const CAMPOS_NIF= ['nif', 'nifNotario','nifDeposit']
    const [openSelect, setOpenSelec] = useState(false)
    const evaluateBool = (value: string | boolean) => {
        // console.log('evaluateBool ', value)
        if (typeof value === "string" && (value === "true" || value === "false")) {
            return value === "true" ? true : false
        } else if(typeof value === "boolean") { 
            return value
        } else {
            return false
        }
    }
    
    const [boolCheckbox, setBoolCheckbox] = useState(false)

    const handleChange = () => {
        //console.log('handleChange ', value)
        if (value !== undefined && setValue !== undefined) {
            setBoolCheckbox(!boolCheckbox)
            setValue(campo.codigo, !value)
        }
    }

    const render = () => {
        // console.log('render',campo.codigo, campo.tipoCampoWeb, value);

        if (campo.tipoCampoWeb === 'select') {
            return (
                <Autocomplete
                    disabled={!options || campo.soloLectura}
                    disableClearable={campo.obligatorio}
                    value={
                        campo.codigo === 'siglas' || campo.codigo === 'periodo' || campo.codigo === 'tipoExcep'
                            ? options && options.find(item => value && (item.id === value || item.id === value)) ? options.find(item => (item.id === value || item.id === value)) : null
                            : options && options.find(item => value && (item.nombre === value || item.id === value)) ? options.find(item => (item.nombre === value || item.id === value)) : null
                        }
                    getOptionLabel={(option: Option) => {
                        //console.log('option.params.componentIG ', option.params?.componentIG)
                        const translated = option.params && option.params.componentIG ? translate(option.params.componentIG, 'select_opt_'+option.id, terms) : undefined;
                        //console.log('translated ', translated)
                        //console.log('option.params.extraText  ', option.params ? option.params.extraText : '-' )
                        return (
                            translated 
                            ? translated
                            : campo.codigo === 'siglas' || campo.codigo === 'periodo' || campo.codigo === 'tipoExcep'
                                ? campo.codigo === 'tipoExcep' 
                                    ? option.id 
                                    : `${option.nombre} - ${option.id}`
                                : campo.selectRef === 'xBonificacion'  
                                    ? `${option.nombre}% ${option.params && option.params.extraText ? `(${option.params.extraText})`: ''}`
                                    : option.nombre
                        )
                    }}
                    renderOption={(option: Option) =>{ 
                        //console.log('option.params.componentIG ', option.params?.componentIG)
                        const translated = option.params && option.params.componentIG ? translate(option.params.componentIG, 'select_opt_'+option.id, terms) : undefined;
                        //console.log('translated ', translated)
                        // console.log('option.params.extraText  ', option.params ? option.params.extraText : '-' )
                        return (
                            translated 
                            ? translated
                            : campo.codigo === 'siglas' || campo.codigo === 'periodo' || campo.codigo === 'tipoExcep'
                                ? `${option.nombre} - ${option.id}`
                                : campo.selectRef === 'xBonificacion'  
                                    ? `${option.nombre}% ${option.params && option.params.extraText ? `(${option.params.extraText})`: ''}`
                                    : option.nombre
                        )
                    }}
                    onChange={(event: any, newValue: Option | null) => {
                        //console.log(' onChange', newValue)
                        let toSave = newValue ? newValue.nombre : null
                        if (campo.codigo === 'siglas' || campo.codigo === 'periodo' || campo.codigo === 'tipoExcep') {
                            toSave = newValue ? newValue.id : null
                        }
                        // console.log('campo onChange', toSave)
                        setValue && setValue(campo.codigo, toSave);
                    }}
                    options={options || []}
                    className={campo.renderSize !== null ? classes[campo.renderSize] : classes.field}
                    renderInput={(params) => (
                        <TextField {...params}
                            label={`[${campo.casilla}] ${campo.campoDescription}`}
                            variant="outlined"
                            required={campo.obligatorio}
                            onBlur={(e) =>{
                                evaluate(value?.toString())}
                            }
                            error={err || errRegExp || errNif}
                            helperText={err || errRegExp || errNif
                                ? errRegExp ? campo.regexDescription
                                    : errNif ? translate('Tributos', 'ErrorNif', terms) : translate('Tributos', 'RequiredField', terms)
                                : ""
                            }
                            // FormHelperTextProps={{
                            //     style: { position: 'absolute', bottom: '-20px' }
                            // }}  
                            style={{
                                backgroundColor: campo.obligatorio && !campo.soloLectura ? '#deebf7' : undefined,
                                borderTop: campo.obligatorio && !campo.soloLectura ? '4px solid #deebf7' : '4px solid transparent'
                            }}
                            InputLabelProps={{
                                shrink: true,
                                style: {
                                    //top: '8px',
                                    paddingRight: 5,
                                    paddingLeft: 5,
                                    backgroundColor: campo.obligatorio && !campo.soloLectura ? '#deebf7': 'white',
                                    borderColor: campo.obligatorio && !campo.soloLectura ? '#deebf7' : undefined
                                }
                            }}
                        />
                    )
                    }
                />
            )
        } else if (campo.tipoCampoWeb === 'boolean' || campo.tipoCampoWeb === 'exclusiveBool') {
            return (
                <FormControl style={{ display: 'flex', alignItems: 'flex-start' }} className={campo.renderSize !== null ? classes[campo.renderSize] : classes.field}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                {...{ inputProps: { "aria-label": `[${campo.casilla}] ${translate('Tributos', campo.codigo, terms)}` } }}
                                // classes={{
                                //     root: classes.root,
                                // }}
                                color='primary'
                                disabled={campo.soloLectura}
                                value={evaluateBool(value as string | boolean)}
                                checked={evaluateBool(value as string | boolean)}
                            />
                        }
                        label={`[${campo.casilla}] ${campo.campoDescription}`}
                        onChange={handleChange}
                    />
                </FormControl>
            )
        } else if (campo.tipoCampoWeb?.includes('date') && typeof (value) != 'boolean') {
            //console.log('campo.dateRange',campo.dateRange)
            return (
                <KeyboardDatePicker
                    disableToolbar
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    className={campo.renderSize !== null ? classes[campo.renderSize] : classes.field}
                    style={{ backgroundColor: campo.obligatorio && !campo.soloLectura ? '#deebf7' : undefined }}
                    InputLabelProps={{ 
                        shrink: true,
                        style: {
                            top: 4,
                            paddingRight: 5,
                            paddingLeft: 5,
                            backgroundColor: campo.obligatorio && !campo.soloLectura ? '#deebf7': 'white',
                            borderColor: campo.obligatorio && !campo.soloLectura ? '#deebf7' : undefined
                        }
                    }}
                    InputProps={{
                        style: { 
                            backgroundColor: campo.obligatorio && !campo.soloLectura ? '#deebf7' : undefined ,
                            borderTop: campo.obligatorio && !campo.soloLectura ? '4px solid #deebf7' : '4px solid transparent', 
                            marginTop: 0    
                        },
                    }}
                    disabled={campo.soloLectura}
                    required={campo.obligatorio}
                    error={err}
                    maxDate={getMaxDate(campo.dateRange)}
                    minDate={getMinDate(campo.dateRange)}
                    helperText={err ? translate('Tributos', 'RequiredField', terms) : ''}
                    id="datepicker-fecha"
                    label={`[${campo.casilla}] ${campo.campoDescription}`} //{<Term component="Tramites" text="fechaOtorgamiento" />}
                    value={moment(value as string, "DD/MM/YYYY")}
                    name={campo.codigo}
                    onChange={(event) => {
                        if (event != null && moment(event).isValid()) {
                            setValue && setValue(campo.codigo, moment(event).format("DD/MM/YYYY"))
                        } else {
                            setValue && setValue(campo.codigo, null)
                        }
                        setErr(true);
                    }}

                />
            )
        } else if(campo.tipoCampoWeb === 'textAction' && textActionProps){
            return (
                <div className={campo.renderSize !== null ? classes[campo.renderSize] : classes.field} style={{display:'inline-flex', alignItems:'center'}}>
                    <TextField
                        //className={campo.renderSize !== null ? classes[campo.renderSize] : classes.field}
                        style={{
                            borderTop: campo.obligatorio && !campo.soloLectura ? '4px solid #deebf7' : '4px solid transparent', 
                            marginRight:5,
                            width:'100%'
                        }}
                        disabled={campo.soloLectura}
                        multiline={(campo.renderSize == 'big' || campo.renderSize == 'xs-big') || campo.soloLectura ? true : false}
                        variant={'outlined'}
                        type={'text'}
                        classes={{root: classes.root}}
                        //FormHelperTextProps={{style: { position: 'absolute', bottom: '-20px' }}}
                        InputLabelProps={{
                            shrink: true,
                            style: {
                                paddingRight: 5,
                                paddingLeft: 5,
                                backgroundColor: campo.obligatorio && !campo.soloLectura ? '#deebf7': 'white',
                                borderColor: campo.obligatorio && !campo.soloLectura ? '#deebf7' : undefined
                            }
                        }}
                        InputProps={{style: { backgroundColor: campo.obligatorio && !campo.soloLectura ? '#deebf7' : undefined }}}
                        label={`[${campo.casilla}] ${campo.campoDescription}`}
                        helperText={err || errRegExp || errNif
                            ? errRegExp ? campo.regexDescription
                                : errNif ? translate('Tributos', 'ErrorNif', terms) : translate('Tributos', 'RequiredField', terms)
                            : ""
                        }
                        error={err || errRegExp || errNif}
                        required={campo.obligatorio}
                        name={campo.codigo}
                        value={ value !== null && value !== undefined ? value: ''}
                        onChange={(event) => {
                            const val =  event.target.value;
                            setValue && setValue(event.target.name, CAMPOS_NIF.includes(campo.codigo) || campo.codigo==='matricula' ? val.toString().toUpperCase() : val);
                            setErr(false);
                            setErrRegexp(false);
                            setErrNif(false);

                        }}
                        onBlur={(e) => evaluate(e.target.value.toString())}
                    />
                    <IconButton 
                        style={{marginLeft: 0, backgroundColor: textActionProps.color ?? BLUE, borderRadius:5}}
                        onClick={() =>{ textActionProps.customAction(value?.toString() ?? '')}}
                    >
                        <Icon path={textActionProps.mdiIconAction ?? mdiMagnify} size={1} color={'#FFF'}/>
                    </IconButton>
                </div>
            )

        } else if(campo.tipoCampoWeb === 'selectEjerTipoPeriod'){
            // Componente compuesto por 3 selectores: Ejercicio, Tipo de periodo, Periodos según el tipo
            //console.log('selectEjerTipoPeriod ',value)
            return (
                <SelectEjerTipoPeriod
                    idTipoApartado={idTipoApartado}
                    casilla={campo.casilla}
                    obligatorio={campo.obligatorio}
                    soloLectura={campo.soloLectura}
                    value={value} 
                    onChange={(newValue:string|undefined, err:boolean) => { 
                        //console.log('onChange')
                        if(value !== newValue /*&& newValue*/ && (!err || (err && newValue && (newValue.length === 4 )))){
                            //console.log('setValue campo ', campo.codigo, newValue)
                            setValue && setValue(campo.codigo, newValue)
                            evaluate(newValue, err)
                        } else {
                            if(err|| errRegExp){
                                setErr(false);
                                setErrRegexp(false)
                            }
                        }
                    }}
                    errorExterno={err || errRegExp}
                />
            )
        } else if (campo.tipoCampoWeb === 'number' || campo.tipoCampoWeb === 'decimal' || campo.tipoCampoWeb === 'decimal2' || campo.tipoCampoWeb === 'decimal3'){
            const maxDecimal = campo.tipoCampoWeb === 'number' 
                ? 0
                : campo.tipoCampoWeb ==='decimal3' 
                    ? 3 
                    : campo.tipoCampoWeb === 'decimal2' ? 2 : 6;
            const minValue = campo.soloLectura || (campo.formula && campo.formula !== '') ? undefined : 0;
            const renderSizeClass = campo.renderSize 
                ? `${campo.renderSize}${campo.moreInfoText ? '_moreInfo':''}`
                : 'field'
            
            // console.log('C',campo.casilla,':  ', value, Number(value))
            return (
                <GenericInputNumber
                    isMoney = {campo.tipoCampoWeb?.includes('decimal') ? true : false}
                    componentIG=''
                    labelTerm='' 
                    labelStr={`[${campo.casilla}] ${campo.campoDescription}`}
                    value={value && value!== '' ? Number(value) : 0} 
                    onChangeValue={(v: number, err:boolean) => {
                        //console.log('onChangeValue v ', v)
                        let valStr = v ? v.toString().replace(',', '.'): undefined
                        if(valStr && !valStr.includes('.') && campo.tipoCampoWeb?.includes('decimal')){
                            valStr= v.toFixed(2)
                        }
                        //console.log('onChangeValue v ',campo.codigo, valStr)
                        setValue && setValue( campo.codigo, valStr )
                        evaluate(valStr, err)
                    }} 
                    required={campo.obligatorio}
                    disabled={campo.soloLectura}
                    min={minValue}
                    maxDecimal={maxDecimal}
                    helperText= {''}
                    error={err || errRegExp}
                    errorMsgExterno={(err || errRegExp) 
                        ? errRegExp
                            ? campo.regexDescription
                            : translate('Tributos', 'RequiredField', terms)
                        : ""
                    }
                    style={{
                        backgroundColor: campo.obligatorio && !campo.soloLectura ? '#deebf7' : undefined,
                        borderTop: campo.obligatorio && !campo.soloLectura ? '4px solid #deebf7' : '4px solid transparent'
                    }}
                    FormHelperTextProps={{
                        classes: { root: classes.helperTextGerericComp },

                    }}
                    InputLabelProps={{
                        shrink: true,
                        style: {
                            paddingRight: 5,
                            paddingLeft: 5,
                            backgroundColor: campo.obligatorio && !campo.soloLectura ? '#deebf7': 'white',
                            borderColor: campo.obligatorio && !campo.soloLectura ? '#deebf7' : undefined
                        }
                    }}
                    InputProps={{
                        style: { 
                            backgroundColor: campo.obligatorio && !campo.soloLectura ? '#deebf7' : undefined ,
                            paddingTop: 8,
                            paddingBottom: 8,
                            //borderTop: campo.obligatorio && !campo.soloLectura ? '0px solid #deebf7' : '0px solid transparent', 
                            marginTop: 0,
                        },
                    }}
                    classes={{root: classes.root}}
                    className={[classes[renderSizeClass], classes.marginGenericCompToModelos ].join(' ')} 
                    moreInfo={ campo.moreInfoText? {template: campo.moreInfoText, size: campo.moreInfoSize}: undefined}
                    infoIconBtnStyle ={{marginTop: 15}}
                />
            )
        } else {
            return (
                <TextField
                    className={campo.renderSize !== null ? classes[campo.renderSize] : classes.field}
                    style={{
                        borderTop: campo.obligatorio && !campo.soloLectura ? '4px solid #deebf7' : '4px solid transparent'
                    }}
                    disabled={campo.soloLectura}
                    multiline={(campo.renderSize === 'big' || campo.renderSize === 'xs-big') || campo.soloLectura ? true : false}
                    variant={'outlined'}
                    type={'text'}
                    InputLabelProps={{
                        shrink: true,
                        style: {
                            //top: '0.5px',
                            paddingRight: 5,
                            paddingLeft: 5,
                            backgroundColor: campo.obligatorio && !campo.soloLectura ? '#deebf7': 'white',
                            borderColor: campo.obligatorio && !campo.soloLectura ? '#deebf7' : undefined
                        }
                    }}
                    InputProps={{
                        style: { backgroundColor: campo.obligatorio && !campo.soloLectura ? '#deebf7' : undefined },
                    }}
                    label={`[${campo.casilla}] ${campo.campoDescription}`}
                    helperText={err || errRegExp || errNif
                        ? errRegExp ? campo.regexDescription
                            : errNif ? translate('Tributos', 'ErrorNif', terms) : translate('Tributos', 'RequiredField', terms)
                        : ""
                    }
                    error={err || errRegExp || errNif}
                    required={campo.obligatorio}
                    name={campo.codigo}
                    value={value !== null && value !== undefined
                        ?  value
                        :  ''
                    }
                    onChange={(event) => {
                        //console.log('onChange Campo ', event.target.value)
                        const val =  event.target.value;
                        //console.log('val ',val, 'name ', event.target.name)

                        setValue && setValue(event.target.name, CAMPOS_NIF.includes(campo.codigo) ? val.toString().toUpperCase() : val);
                        setErr(false);
                        setErrRegexp(false);
                        setErrNif(false);

                    }}
                    onBlur={(e) => evaluate(e.target.value.toString())}
                />
                /*<TextField
                    className={campo.renderSize !== null ? classes[campo.renderSize] : classes.field}
                    style={{
                        borderTop: campo.obligatorio && !campo.soloLectura ? '4px solid #deebf7' : '4px solid transparent'
                    }}
                    disabled={campo.soloLectura}
                    multiline={(campo.renderSize === 'big' || campo.renderSize === 'xs-big') || campo.soloLectura ? true : false
                    }
                    variant={'outlined'}
                    type={(campo.tipoCampoWeb === 'number' || campo.tipoCampoWeb === 'decimal' || campo.tipoCampoWeb === 'decimal2' || campo.tipoCampoWeb === 'decimal3') ? 'number' : 'text'
                    }
                    // classes={{
                    //     root: classes.root,
                    // }}
                    // FormHelperTextProps={{
                    //     style: { position: 'absolute', bottom: '-20px' }
                    // }}
                    InputLabelProps={{
                        shrink: true,
                        //classes: {root: classes.inputLabelPadding},
                        style: {
                            //top: '0.5px',
                            paddingRight: 5,
                            paddingLeft: 5,
                            backgroundColor: campo.obligatorio && !campo.soloLectura ? '#deebf7': 'white',
                            borderColor: campo.obligatorio && !campo.soloLectura ? '#deebf7' : undefined
                        }
                    }}
                    InputProps={{
                        style: { backgroundColor: campo.obligatorio && !campo.soloLectura ? '#deebf7' : undefined },
                        inputProps:  campo.tipoCampoWeb && ['number', 'decimal','decimal2','decimal3'].includes(campo.tipoCampoWeb)
                            ? {
                                min: 0,
                                step: campo.tipoCampoWeb === 'number' ? 1 : campo.tipoCampoWeb === 'decimal3' ? 0.001: 0.01,

                            }
                            : undefined
                    }}
                    label={`[${campo.casilla}] ${campo.campoDescription}`}
                    helperText={err || errRegExp || errNif
                        ? errRegExp ? campo.regexDescription
                            : errNif ? translate('Tributos', 'ErrorNif', terms) : translate('Tributos', 'RequiredField', terms)
                        : ""
                    }
                    error={err || errRegExp || errNif}
                    required={campo.obligatorio}
                    name={campo.codigo}
                    value={
                        campo.tipoCampoWeb 
                        && ['number', 'decimal','decimal2','decimal3'].includes(campo.tipoCampoWeb)
                        && !campo.soloLectura && value === 0
                            ? ''
                            : (value !== null && value !== undefined
                                ? campo.soloLectura && (campo.tipoCampoWeb === 'decimal' || campo.tipoCampoWeb === 'decimal2'|| campo.tipoCampoWeb === 'decimal3')
                                    ? value.toString().replace('.', ',')
                                    : value
                                : (campo.tipoCampoWeb === 'number' || campo.tipoCampoWeb === 'decimal' || campo.tipoCampoWeb === 'decimal2'|| campo.tipoCampoWeb === 'decimal3') 
                                    ? campo.regex == undefined || campo.regex == null ? 0 : '' 
                                    : '')
                    }
                    onChange={(event) => {
                        //console.log('onChange Campo ', event.target.value)
                        const val =  campo.tipoCampoWeb && ['number', 'decimal','decimal2','decimal3'].includes(campo.tipoCampoWeb)
                            ? event.target.value
                                ? campo.tipoCampoWeb === 'number' ? parseInt(event.target.value) : parseFloat(event.target.value)
                                : 0
                            : event.target.value;
                        //console.log('val ',val, 'name ', event.target.name)

                        setValue && setValue(event.target.name, CAMPOS_NIF.includes(campo.codigo) ? val.toString().toUpperCase() : val);
                        setErr(false);
                        setErrRegexp(false);
                        setErrNif(false);

                    }}
                    onBlur={(e) => evaluate(e.target.value.toString())}
                />*/
            )
        }
    }

    const evaluate = (val: string | null | undefined, campoErr?:boolean) => {
        let match = true;
        let errRegex = false;
        //console.log('evaluate --- val', val, 'campo', campo, campo.regex)
        if (campo.regex !== null && val !== undefined && val && val !== '') {
            if (campo.tipoCampoWeb === 'decimal' || campo.tipoCampoWeb === 'decimal2' || campo.tipoCampoWeb === 'decimal3') {
                match = new RegExp(campo.regex).test(val.replaceAll('.', ','))
            } else {
                match = new RegExp(campo.regex).test(val)
            }
            errRegex = !match
            //console.log('regex match ?', !match ? 'no':'si' )
        }
        const errEmpty = campo.obligatorio && (
            val === undefined || val === null || val === '' 
            || (val === '0' && campo.selectRef !== 'xBonificacion') // Se admite 0% Bonificacion como válido ya que es una opción del selector
        ) ? true : false;
        setErrRegexp(errRegex);
        setErr(errEmpty);
        let isDataCampoValid = !errRegex && !errEmpty && !campoErr;
        // Acepta campo tipo bonificacion => cambio por listado de CAMPOS PRO que son nifs
        // if (new RegExp(/nif/).test(campo.codigo) && val) {
        if (CAMPOS_NIF.includes(campo.codigo) && val) {
            const isValid = validateNif(val.toUpperCase())
            setErrNif(!isValid)
            isDataCampoValid = isDataCampoValid && isValid !==false;
        }
        //console.log(campo.codigo,'errRegex',errRegex,'   campoErr',campoErr, '   isDataCampoValid', isDataCampoValid)
        isValid && isValid(isDataCampoValid);
    }


    useEffect(() => {
        if (campo.tipoCampoWeb === 'select' &&  options && options.length=== 1 /*campo.soloLectura && campo.obligatorio*/) {
            let toSave = options[0] ? options[0].nombre : null
            if (campo.codigo === 'siglas' || campo.codigo === 'periodo' || campo.codigo === 'tipoExcep') {
                toSave = options[0] ? options[0].id : null
            }
            setValue && (!value || value === "") && setValue(campo.codigo, toSave)
        } /*else if(!options || options.length === 0){
            setValue && setValue(campo.codigo, undefined)
        }*/
    }, [campo]);

    useEffect(() => {
        if ((campo.tipoCampoWeb === "boolean" || campo.tipoCampoWeb ==='exclusiveBool' ) 
            && (typeof value === 'boolean' || (typeof value === 'string' && (value === "true" || value === "false") )) && evaluateBool(value) !== boolCheckbox) {
            setBoolCheckbox(evaluateBool(value))
        } else if ( value && !campo.soloLectura) { 
            //console.log('effect evaluate ', value)
            evaluate(value.toString()) 
        } else {
            //clean errors
            setErr(false);
            setErrRegexp(false);
            setErrNif(false)
        }
        //console.log(campo.soloLectura,campo.codigo,value, setValue ? 'si': 'no')
        //setValue && setValue(campo.codigo,value)
    }, [value])

    return (
        <>
            <Dialog open={openSelect} onClose={() => setOpenSelec(false)}>
                <DialogTitle>
                    {`[${campo.casilla}] ${campo.campoDescription} `}
                </DialogTitle>
                <DialogContent>
                    <List>
                        {options && options.map(item => (
                            <ListItemText
                                key={campo.codigo + item.id}
                                onClick={() => {
                                    if (item.id === '00') {
                                        setValue && setValue(campo.codigo, null)
                                    } else {
                                        if (campo.codigo === 'siglas') {
                                            setValue && setValue(campo.codigo, item.id);
                                        } else {
                                            setValue && setValue(campo.codigo, item.nombre);
                                        }
                                    }

                                    setOpenSelec(false)
                                }}
                            >
                                {campo.codigo === 'siglas' ? `${item.id} (${item.nombre})` : item.nombre}
                            </ListItemText>
                        ))}
                    </List>
                </DialogContent>
            </Dialog>
            {campo.visible &&
                <>
                    {render()}
                    {campo.moreInfoText && <InfoBtn moreInfo={{ template: campo.moreInfoText, asMessage: true, title: String(value) }} infoIconStyle={{alignSelf: 'center', marginLeft: '-8px', padding: '6px'}}/>}
                </>
            }
        </>
    )
}

export default Campo;



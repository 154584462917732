import { FC, useEffect, useState } from "react";
import { makeStyles, TextField, useTheme } from "@material-ui/core";
import Term from "components/term";
import styles from "../perfil.styles";

const useStyles = makeStyles(styles);

interface IProps {
    emailBackUp: string;
    emailConfirmationBackUp: string;
    validationMsgEmail: string | null;
    validationMsgEmailConfirmation: string | null;
    onChangeEmail: (email: string) => void;
    onChangeEmailConfirmation: (emailConfirmation: string) => void;
}

const EmailStepValidacion: FC<IProps> = ({ emailBackUp, emailConfirmationBackUp, validationMsgEmail, validationMsgEmailConfirmation, onChangeEmail, onChangeEmailConfirmation }) => {
    const theme = useTheme();
    const classes = useStyles(theme);
    const [email, setEmail] = useState('');
    const [emailConfirmation, setEmailConfirmation] = useState('');

    const handleSetEmail = (newEmail: string) => {
        setEmail(newEmail);
    };

    const handleSetEmailConfirmation = (emailConfirmation: string) => {
        setEmailConfirmation(emailConfirmation);
    };

    useEffect(() => {
        onChangeEmail(email);
    }, [email]);

    useEffect(() => {
        onChangeEmailConfirmation(emailConfirmation);
    }, [emailConfirmation]);

    useEffect(() => {
        if (emailBackUp) setEmail(emailBackUp);
        if (emailConfirmationBackUp) setEmailConfirmation(emailConfirmationBackUp);
    }, [emailBackUp, emailConfirmationBackUp]);

    return (
        <div className={classes.emailsContainer}>
            <TextField
                id="outlined-email-input"
                label={<Term component="Perfil" text="Nuevo email" />}
                error={validationMsgEmail !== null}
                helperText={validationMsgEmail}
                value={email}
                className={classes.textFieldEmail}
                onChange={(event) => handleSetEmail(event.target.value)}
                margin="dense"
            />

            <TextField
                id="outlined-email-input"
                label={<Term component="Global" text="Confirmación email" />}
                error={validationMsgEmailConfirmation !== null}
                helperText={validationMsgEmailConfirmation}
                value={emailConfirmation}
                className={classes.textFieldEmail}
                onChange={(event) => handleSetEmailConfirmation(event.target.value)}
                margin="dense"
                onPaste={(e) => {
                    e.preventDefault()
                    return false;
                }}
                autoComplete="off"
            />
        </div>
    )
};

export default EmailStepValidacion;
import React, { FC, useContext, useMemo, useState, useEffect } from 'react';
import { ICampo, IMunicipioBonificado, XBonificacion } from '../../../gateways/model.new.interface';
import {  CircularProgress, Dialog, DialogContent, Tooltip, Typography} from '@material-ui/core';
import { Button, makeStyles } from '@material-ui/core';
import styles from './styles';
import { formatXMLtoObject, GenericInitialObject, getEmptyObject, getKeysRequiredCampos, renderCampos,  identificativoFormatter, formatOptions, SelectoresInfo, GenericObject, getInitialObject, isSameData, mixData, TPeriodo, OPTIONS_trimestre, CAMPOS_PERIODO, OPTIONS_mensual, MODELO_PERIODO, extraerValorXML, extraerCasillaValor} from '../utils';
import IoC from 'contexts/ioc.context';
import ModelGateway from 'gateways/model.new.gateway';
import { translate } from 'utils/i18n';
import { AlertsContext } from 'contexts/alerts.context';
import { LiteralsContext, withLiterals } from 'containers/shared/literals';
import { ISujeto } from 'gateways/perfil.interfaces';
import { GeoGateway } from 'gateways/geo.gateway';
import { Municipio, Provincia, Isla, Sigla} from 'gateways/geo.interface';
import EndProcessMSG from 'components/Modelos/componentes/endProcessMSG';
import { ContenidosGateway } from 'gateways/contenido.gateway';



const useStyles = makeStyles(styles);

// 666 ---------------------------------------------------------------------
// Extraer datos Bonificaciones
const checkBonificaciones666 = (datosXmlApartadosPrevios: GenericObject, datos666_K: GenericInitialObject,campos:ICampo[]) => {
    console.log('datos666_K', datos666_K)
    const datosPrevString = Object.values(datosXmlApartadosPrevios).join('')
    // 666_B: Resumen anual
    const C283 = extraerValorXML('666_B.283',datosPrevString)
    const C283_val = C283 && !isNaN(Number(C283)) ? Number(C283) : 0
    const C284 = extraerValorXML('666_B.284',datosPrevString)
    const C284_val = C284 && !isNaN(Number(C284)) ? Number(C284) : 0
    const C287 = extraerValorXML('666_B.287',datosPrevString)
    const C287_val = C287 && !isNaN(Number(C287)) ? Number(C287) : 0
    const C288 = extraerValorXML('666_B.288',datosPrevString)
    const C288_val = C288 && !isNaN(Number(C288)) ? Number(C288) : 0
    // 666_I (casillas 581, 582)
    const C581 = extraerValorXML('666_I.581',datosPrevString)
    const C581_val = C581 && !isNaN(Number(C581)) ? Number(C581) : 0
    const C582 = extraerValorXML('666_I.582',datosPrevString)
    const C582_val = C582 && !isNaN(Number(C582)) ? Number(C582) : 0
    // 666_J (casillas 583,584,585,586)
    const C583 = extraerValorXML('666_J.583',datosPrevString)
    const C583_val = C583 && !isNaN(Number(C583)) ? Number(C583) : 0
    const C584 = extraerValorXML('666_J.584',datosPrevString)
    const C584_val = C584 && !isNaN(Number(C584)) ? Number(C584) : 0
    const C585 = extraerValorXML('666_J.585',datosPrevString)
    const C585_val = C585 && !isNaN(Number(C585)) ? Number(C585) : 0
    const C586 = extraerValorXML('666_J.586',datosPrevString)
    const C586_val = C586 && !isNaN(Number(C586)) ? Number(C586) : 0
    // 666_K
    const codigos = campos.reduce<Record<string, string>>((acc, c) => {
        if ([588,589,590,591].includes(c.casilla)) {
        acc[c.casilla.toString()] = c.codigo;
        }
        return acc;
    }, {});
    console.log(codigos)
    const C588 = datos666_K[codigos['588']].value
    const C588_val = !isNaN(Number(C588)) ? Number(C588) : 0
    const C589 = datos666_K[codigos['589']].value
    const C589_val = !isNaN(Number(C589)) ? Number(C589) : 0
    const C590 = datos666_K[codigos['590']].value
    const C590_val = !isNaN(Number(C590)) ? Number(C590) : 0
    const C591 = datos666_K[codigos['591']].value
    const C591_val = !isNaN(Number(C591)) ? Number(C591) : 0
    

    // console.log( C283_val ,'suma', C581_val,C583_val,C588_val)
    const validC283 = C283_val === (C581_val+C583_val+C588_val)
    // console.log(C284_val ,'suma', C582_val,C585_val,C590_val)
    const validC284 = C284_val === (C582_val+C585_val+C590_val)
    // console.log(C287_val ,'suma',  C584_val,C589_val)
    const validC287 = C287_val === (C584_val+C589_val)
    // console.log(C288_val ,'suma', C586_val,C591_val)
    const validC288 = C288_val === (C586_val+C591_val)

    return validC283 && validC284 && validC287 && validC288
}
// ----------------------------------------------------------------------

interface Props {
    idTipoModelo: string,
    idTipoApartado: string,
    datosXmlApartadosPrevios: GenericObject,
    datosXml: string | null,
    setFinished: (value: boolean) => void,
    handleSave: (data: any) => Promise<boolean>,
    withEjercicioPeriodo: boolean

}

const Datos: FC<Props> = (props) => {
    const {
        idTipoModelo,
        idTipoApartado,
        datosXmlApartadosPrevios,
        datosXml,
        setFinished,
        handleSave,
        withEjercicioPeriodo

    } = props;
    
    const classes = useStyles();
    const [, alertsDispatch] = useContext(AlertsContext);
    const terms = useContext(LiteralsContext);
    //Gateways
    const ioc = useContext(IoC);
    const modelGateway: ModelGateway = useMemo(() => ioc.get(ModelGateway), [ioc]);
    const geoGateway: GeoGateway = useMemo(() => ioc.get(GeoGateway), [ioc]);
    const contenidosGateway: ContenidosGateway = useMemo(() => ioc.get(ContenidosGateway), [ioc]);
    //Local data
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<GenericInitialObject|null>(null);
    const [changes, setChanges] = useState(false);
    const [campos, setCampos] = useState<ICampo[] | undefined>(undefined);
    const [EMPTY_Object,setEmptyObject] = useState<GenericInitialObject| null>({});
    const [camposRequiredKeys, setCamposRequiredKeys] = useState<string[]| null>(null);
    const [selectoresInfo, setSelectoresInfo] = useState<SelectoresInfo| null>(null);
    const [saved, setSaved] = useState(false);
    const [pressed, setPressed] = useState(false);

    const [contentErrBoni, setContentErrBoni]= useState<string>(' - ');

    const [isThisPeriod, setIsThisPeriod]= useState(false);
    const [paramPeriod, setParamPeriod]= useState<string[]>([]);

    const extraInfo: {componentIG:string, termIG:string}|undefined = useMemo(() => {
        if(idTipoApartado === '666_K'){
            return ({componentIG: 'Tributos', termIG:'texto_666_K'})
        } else {
            return undefined
        }
    },[idTipoApartado])

    
    // Functions - Get data ------------------------------------------------------------------------------------------------------
    const updateData = ( name: string, value: any) => {
        setChanges(true)
        let newData=null;
        if(EMPTY_Object){
            newData = data 
            ? {...data, [name]: {...data[name], value: value }}
            : {...EMPTY_Object,[name]: {...EMPTY_Object[name], value: value }}
        }

        if(newData && newData[name].tipo === 'exclusiveBool' && value === true){
            // 1. Obtener casillas que intervienen en la formula
            const myRegExp: RegExp = /(?!\[)-?[a-zA-Z0-9_.]+(?=\])/g; //(?!\[)-?\d+(?=\])/g;
            const casillas = newData[name].formula.match(myRegExp);

            //2. setear a falso todos los valores de la formula
            if(casillas){
                for (const key in newData) {
                    if (Object.prototype.hasOwnProperty.call(newData, key)) {
                        const element = newData[key];
                        //console.log('element ', element)
                        if(casillas.includes(element.casilla.toString())){
                            newData[key] = {...newData[key], value: false}
                        }
                        
                    }
                }
            }
        }

        if(name === 'municipio' && idTipoApartado === '051_0' && newData && selectoresInfo?.muniBonificado){
            const selectedOption = selectoresInfo.muniBonificado.find((item) => item.nombre === value);
            if(selectedOption){
                const {nombre, valor} = selectedOption.params.bonificacion;
                newData = {
                    ...newData, 
                    [nombre]: { ...newData[nombre], value: valor }
                }
            }
        }

        // console.log('newData ', newData)
        setData(newData)
    }

    const onSave = ()=> { 
        (async() => {
            setLoading(true);
            // comprobar campos required 
            let valid= true;
            let boniOk=true;
            //Recalculamos reqKeys para evitar error por dinamismo en campos
            const reqKeys = campos ? getKeysRequiredCampos(campos) : [];
            if(reqKeys && reqKeys.length>0 ){
                if(data){
                    reqKeys.forEach( element => {
                        switch (data[element].tipo) {
                            case 'number':
                                if(Number(data[element].value) === 0){
                                    valid=false
                                }
                                break;
                            case 'decimal':
                            case 'decimal2':
                            case 'decimal3':
                                if(parseFloat(data[element].value) === 0){
                                    valid=false
                                }
                                break;
                            default:
                                if(!data[element].value || data[element].value === undefined || data[element].value === ""){
                                    valid=false
                                }
                                break;
                        }
                    })
                } else {
                    valid = false;
                }
            }
            if (valid) {
                valid = campos?.findIndex(campo => campo.isValid != undefined && !campo.isValid && campo.visible) == -1;
            }
            if(idTipoApartado === '666_K' && data && campos){
                boniOk = checkBonificaciones666(datosXmlApartadosPrevios, data, campos)
                valid = valid && boniOk
            }
            if(valid){
                const savedTemp = await handleSave(data); 
                setSaved(savedTemp);
                setChanges(!savedTemp);
                setPressed(true)

            } else {
                //setFinished(false);
                setChanges(true);
                alertsDispatch({
                    type: 'show-alert',
                    payload: !boniOk 
                        ? {
                            isHtml: true,
                            message: contentErrBoni,
                            variant: 'error'
                        }:{
                            message: translate('Tributos','FormularioError', terms),
                            variant: 'error',
                        }
                });

            }
            setLoading(false);

        })();
    }

    // ---------------------------------------------------------------------------------------------------------------------------
    useEffect(() => {
        (async()=> {
            try{
                setLoading(true)
                //console.log('idTipoApartado', idTipoApartado)
                const delegacion = await modelGateway.getDelegacion(translate('Tributos', 'GetSelectDelegacionError', terms))
                let camposApartado = await modelGateway.getCamposApartado(idTipoApartado, idTipoModelo, translate('Tributos', 'GetCamposApartadoError', terms))
                const periodoCampo = withEjercicioPeriodo
                    ? camposApartado.filter( c => CAMPOS_PERIODO.includes(c.codigo)) 
                    : undefined
                const content = await contenidosGateway.getContent('error_validacion_bonificaciones_666', {})
                setContentErrBoni(content[0]?.contenido)

                let emptyObjt = null;
                let newData: GenericInitialObject | null = null;
                let dataXML: GenericInitialObject | null = null;
                let dataPrevios: GenericInitialObject | null = null;
                let changes= false;
                let reqKeys: string[] = []
                const datosPrevString = Object.values(datosXmlApartadosPrevios).join('')
                //console.log('condicion', camposApartado && camposApartado.length>0 )
                if(camposApartado && camposApartado.length>0){
                    emptyObjt= getEmptyObject(camposApartado)
                    //console.log('emptyObjt', emptyObjt)
                    setEmptyObject(emptyObjt);
                    reqKeys = getKeysRequiredCampos(camposApartado);
                    
                    
                    //console.log('datosPrevString ', datosPrevString)
                    //console.log('datosXml ', datosXml)
                    if(datosXml){
                        dataXML = formatXMLtoObject(datosXml,emptyObjt);
                        //console.log('USE datosXML', dataXML)
                    } 
                    if(datosPrevString){
                        dataPrevios = getInitialObject(camposApartado, datosPrevString, idTipoApartado);
                        //console.log('useDatos datosPrevString', dataPrevios)
                    }
                    
                    //console.log('isSameData:', isSameData(dataXML, dataPrevios))
                    if(isSameData(dataXML, dataPrevios) || (idTipoApartado === "621_D" && saved)){
                        changes=false
                        setFinished(true);
                        newData= dataXML
                    } else {
                        const mixDataObjt = mixData(dataXML, dataPrevios, idTipoApartado)
                        changes=true
                        newData = mixDataObjt ?? newData
                    }

                }

                // Condiciona el componente a todo disabled si aplica a un solo periodo del ejercicio
                let apply = true
                if(withEjercicioPeriodo && periodoCampo){
                    //console.log('newData ', newData, periodoCampo)
                    if(newData && MODELO_PERIODO[idTipoModelo]){
                        // Ver si es último periodo
                        const miData = periodoCampo[0].codigo
                        const miPeriodo = MODELO_PERIODO[idTipoModelo] //OPTIONS_trimestre.find( opt => opt.id === MODELO_PERIODO[idTipoModelo])?.nombre ?? 'not-found'
                        const periodoUserSelect =  newData[miData].value
                        const ejercicio = periodoUserSelect.substring(0,4)
                        const periodo = periodoUserSelect.replace(ejercicio, '') // OPTIONS_trimestre.find( opt => opt.id === MODELO_PERIODO[idTipoModelo])?.nombre ?? 'not-found'
                        //console.log('campo Val: ', newData[miData].value, 'periodo Usr: ', periodo, miPeriodo)
                        if(miPeriodo.length > 0  && periodo) {
                            const textPeriodo = periodo.includes('T') ? OPTIONS_trimestre.find(opt => opt.id === periodo) : OPTIONS_mensual.find(opt => opt.id === periodo)
                            //console.log('textPeriodo ', textPeriodo?.nombre)
                            const txt = textPeriodo 
                                ? periodo.includes('T') 
                                    ? `${ejercicio} - ${textPeriodo.nombre}` 
                                    : `${ejercicio}/${textPeriodo.id}` 
                                :'-'
                            setParamPeriod([txt])
                        }
                        apply = miPeriodo.includes(periodo) //periodoUserSelect.includes(miPeriodo)
                        changes = apply && !pressed ? changes : false
                        //console.log('campo: ', miData, 'periodoAplica: ', miPeriodo, ' es mi periodo ? ',apply, 'periodoSeelccionado:', periodo)
                    }
                }
                
                let finalData = newData || emptyObjt;

                if(idTipoApartado === '666_K'){
                    // Fijar valor C668 a TRUE y no Editable si el check 666_D.77 está activo y hay valor en 666_D.64 o 666_D.65
                    const C77 = extraerValorXML('666_D.77',datosPrevString)
                    const C77_val = C77 && C77 === 'true'
                    const C64 = extraerValorXML('666_D.64',datosPrevString)
                    const C64_val = C64 && !isNaN(Number(C64)) ? Number(C64) : 0
                    const C65 = extraerValorXML('666_D.65',datosPrevString)
                    const C65_val = C65 && !isNaN(Number(C65)) ? Number(C65) : 0
                    const campo668 = camposApartado.find(c=> c.casilla === 668)
                    // console.log('666_K condition ', C77, C77_val, C64, C64_val, C65, C65_val)
                    // console.log('value 668 ',finalData && campo668 &&  finalData[campo668.codigo].value)
                    if(campo668 && C77_val && (C64_val>0 || C65_val>0) ){
                        camposApartado = camposApartado.map( c => c.idCampo === campo668.idCampo ?  {...c, soloLectura: true}: {...c})
                        if(finalData && !(finalData[campo668.codigo].value === true || finalData[campo668.codigo].value === "true")){
                            finalData = finalData && {...finalData, [campo668.codigo]: {...finalData[campo668.codigo], value: true}}
                            changes= true
                        }
                    }
                }
                
                setCamposRequiredKeys(reqKeys)

                //console.log('camposApartado ', camposApartado)
                setCampos(
                    withEjercicioPeriodo && !apply 
                    ? camposApartado.map(c=> ({...c, soloLectura: true}))
                    : camposApartado
                );
                setIsThisPeriod(apply)
                setData(finalData);
                const finalChanges = changes || (idTipoApartado === "621_D" && !saved)
                setChanges(finalChanges)
                setFinished(!finalChanges)

                //Empty Selectores data - si es necesario
                const provinciaBaleares = '07'
                const optionsTV = await geoGateway.getSiglas()
                const optionsProv = await geoGateway.getProvincias('')
                const selected: Provincia|null|undefined = optionsProv && newData?.provincia?.value 
                    ? optionsProv.find(opt => (opt.nombre === newData?.provincia?.value.toString() || opt.idProvincia === newData?.provincia?.value.toString())) 
                    : null
            
                const optionsMuni = await geoGateway.getMunicipios(selected?.idProvincia || provinciaBaleares)
                const optionsMuniBonificado = await modelGateway.getAllMunicipiosBonificados(idTipoModelo, 'tipoGravamen')
                let optionsXBoni:XBonificacion[] = []
                if(idTipoModelo === '071'){
                    const codMuni = extraerValorXML('071_B.100', datosPrevString)
                    optionsXBoni = await modelGateway.getXBonificacion('LIQUIDACION', 'IVTM', codMuni ?? '')
                }
                
                setSelectoresInfo({
                    municipio: optionsMuni && optionsMuni.length>0 ? formatOptions<Municipio>('idMunicipio','nombre',optionsMuni) : null,
                    muniBonificado: optionsMuniBonificado.length ? formatOptions<IMunicipioBonificado>('acodmunigest','nombre', optionsMuniBonificado) : null,
                    provincia: optionsProv.length>0 ? formatOptions<Provincia>('idProvincia','nombre', optionsProv) : null,
                    tipoVia: optionsTV.length>0 ? formatOptions<Sigla>('idSiglas','nombre', optionsTV, {componentIG: 'Modelos'}) : null,
                    oficina: delegacion,
                    xBonificacion: optionsXBoni.length>0 ? formatOptions<XBonificacion>('idBonificacion','porcentaje', optionsXBoni, undefined, 'descripcion') : null,
                })

                setLoading(false);

            } catch (error) {
                console.log('effect DATOS Error', error)
                const result = (error as Error).message;
                setLoading(false)
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: result,
                        variant: 'error',
                    }
                }); 
            }
        })();
    },[idTipoApartado, idTipoModelo, datosXml, datosXmlApartadosPrevios]);

    useEffect(() => {
        if(changes){
            setFinished(false)
        }else{
            setFinished(true)
        }
        if(idTipoApartado === "621_D" && saved){
            setChanges(false)
            setFinished(true)
        }
    },[changes])

   
    
    return(
        <div> 
            <div className={classes.rowAlignLeft}>
                {(withEjercicioPeriodo && isThisPeriod) || (!withEjercicioPeriodo)  && 
                    <Button 
                        style={{marginLeft: 10}}
                        color='primary'
                        variant='contained' 
                        disabled={ data === null || data === EMPTY_Object }
                        onClick={() => {
                            setData(EMPTY_Object)
                            setChanges(true)
                        }}
                    > 
                        {translate('Tributos','btnClean',terms)} 
                    </Button>
                }
            </div>
            <Dialog open={loading}>
                <DialogContent>
                    <CircularProgress size={35} />
                </DialogContent>
            </Dialog>

            {(withEjercicioPeriodo && isThisPeriod) || (!withEjercicioPeriodo) || loading
                ? null
                :   <EndProcessMSG 
                        type='info'
                        componentIG='Tributos'
                        termIG={'soloCumplimentarPeriodoUltimoPeriodo'}
                        paramTermIG={paramPeriod}  
                    />        
            }

            {extraInfo === undefined || loading
                ? null
                :   <EndProcessMSG 
                        type='info'
                        componentIG={extraInfo.componentIG}
                        termIG={extraInfo.termIG}
                    />        
            }
            {campos && campos.length>0 && camposRequiredKeys !== null && data && selectoresInfo &&
                renderCampos(campos, data, updateData, Object.values(datosXmlApartadosPrevios).join(''), idTipoApartado, selectoresInfo, undefined, classes )
            }

            <div className={classes.rowAlignRight}>
                <Button 
                    style={{marginLeft: 10}}
                    color='primary'
                    disabled={!changes}
                    variant='contained' 
                    onClick={onSave}
                >
                    {translate('Tributos','btnSave',terms)} 
                </Button>
            </div>
        </div>
    )
}
export default withLiterals(['Tributos','Modelos'])(Datos);




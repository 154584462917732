import { Reducer } from "react";
import {SHOW_INFO, HIDE_INFO, typeActions} from './actions'
export interface IMoreInfoState {
    info: IMoreInfoWithOpen | null;
}
 
export type TInfoTemplateSize = 'xs'|'sm'|'md'|'lg'|'xl'
export interface IMoreInfo {
    templateName?: string; // content IG
    message?: string; // if not ContentIG - message to render
    size?: TInfoTemplateSize
    title?: string
}
 
export interface IMoreInfoWithOpen extends IMoreInfo {
    open: boolean;
}
 
export type MoreInfoAction = {type: typeActions, payload?: IMoreInfo}
    // | { type: 'hide-info' }
    // | { type: 'show-info', payload: IMoreInfo }
 
 
const MoreInfoReducer: Reducer<IMoreInfoState, MoreInfoAction> = (state, action) => {
    switch (action.type) {
        case SHOW_INFO: return { ...state, info: { ...action.payload, open: true } };
        case HIDE_INFO: return { ...state, info: { ...state.info as any, open: false } };
        default: throw new Error('Unexpected action');
    }
};
 
export default MoreInfoReducer;
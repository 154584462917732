import { Dispatch, ReducerState, ReducerAction, createContext } from "react";
import ModelosActionsReducer, { IModelosActionsState } from "./modelos-action.reducer";


type ModelosActionReducerState = ReducerState<typeof ModelosActionsReducer>;
type ModelosActionDispatchAction = Dispatch<ReducerAction<typeof ModelosActionsReducer>>;
type ModelosActionContextType = [ModelosActionReducerState, ModelosActionDispatchAction];

export const initialModelosActionState: IModelosActionsState = {
    conceptoXml: null,
    ejercicio: undefined,
    ejerTipoPeri: null
}

export const ModelosActionContext = createContext<ModelosActionContextType>([ initialModelosActionState , (state) => state]);

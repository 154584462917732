import { FC, useContext, useMemo, useState, useEffect } from 'react';
import { ICampo } from '../../../gateways/model.new.interface';
import {  CircularProgress, Dialog, DialogContent} from '@material-ui/core';
import { Typography, Button, makeStyles } from '@material-ui/core';
import styles from './styles';


import { formatXMLtoObject, GenericInitialObject, GenericObject, getEmptyObject, getInitialObject, getKeysRequiredCampos, GroupLabel, isSameData, renderCampos } from '../utils';

import IoC from 'contexts/ioc.context';
import ModelGateway from 'gateways/model.new.gateway';
import { translate } from 'utils/i18n';
import { AlertsContext } from 'contexts/alerts.context';
import { LiteralsContext, withLiterals } from 'containers/shared/literals';

const useStyles = makeStyles(styles);

interface Props {
    idTipoModelo: string,
    idTipoApartado: string,
    datosXml: string | null,
    datosXmlApartadosPrevios: GenericObject,
    setFinished: (value: boolean) => void,
    handleSave: (data: any) => Promise<boolean>,
}

const Liquidacion8xx: FC<Props> = (props) => {
    const {
        idTipoModelo,
        idTipoApartado,
        datosXml,
        datosXmlApartadosPrevios,
        setFinished,
        handleSave
    } = props;
    
    const classes = useStyles();
    const [, alertsDispatch] = useContext(AlertsContext);
    const terms = useContext(LiteralsContext);
    //Gateways
    const ioc = useContext(IoC);
    const modelGateway: ModelGateway = useMemo(() => ioc.get(ModelGateway), [ioc]);
    //Local data
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<GenericInitialObject|null>(null);
    const [changes,setChanges]= useState<boolean|null>(null);
    const [campos, setCampos] = useState<ICampo[] | null>(null);
    const [EMPTY_AUTOLOQUIDACION,setEmptyAutoliquidacion] = useState<GenericInitialObject|null>(null);
    const [camposRequiredKeys, setCamposRequiredKeys] = useState<string[]| null>(null);
    
    // groupLabels -> Describe Tablas y Secciones del MODELO - textos a mostrar
    // numeroS -> títlo seccion Ej: 1S: '', 2S:''
    // numeroT -> títlo de tabla Ej: 1T: '', 2T:''
    // numero -> título previo a un conjunto de campos (ej: tablas 890 y 800 antiguo)
    const [groupLabels, setGroupLabels] = useState<GroupLabel| undefined>(undefined);
    // Convivencia modleos 593 (ejercicio 2023) y 594 (ejercicio 2024)
    const Modelo59X= ['593', '594']


    // Functions - Get data ------------------------------------------------------------------------------------------------------
    const updateData= (name:string, value: any) => {
        setChanges(true)
        let newData=null;
        if(EMPTY_AUTOLOQUIDACION){
            newData= data 
            ? {...data, [name]: {...data[name], value: value }}
            : {...EMPTY_AUTOLOQUIDACION,[name]: {...EMPTY_AUTOLOQUIDACION[name], value: value }}

        }
        setData(newData)
    }


    const onSave = ()=> { 
        (async() => {
            setLoading(true);
            // comprobar campos required 
            let valid= true;
            if(camposRequiredKeys && camposRequiredKeys.length>0 ){
                if(data){
                    camposRequiredKeys.forEach( element => {
                        //console.log('element', element)
                        switch (data[element].tipo) {
                            case 'number':
                                if(Number(data[element].value) === 0){
                                    valid=false
                                }
                                break;
                                case 'decimal':
                                case 'decimal2':
                                case 'decimal3':
                                if(parseFloat(data[element].value) === 0){
                                    valid=false
                                }
                                break;
                            default:
                                if(!data[element].value || data[element].value === undefined || data[element].value === ""){
                                    valid=false
                                }
                                break;
                        }
                    })
                } else {
                    valid = false;
                    
                }
            }
            if (valid) {
                valid = campos?.findIndex(campo => campo.isValid != undefined && !campo.isValid && campo.visible) == -1;
            }
            if(valid){
                // console.log('onSave', data)
                const saved = await handleSave(data); 
                setChanges(!saved);
                window.scrollTo(0, 0)
            } else {
                setChanges(true);
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: translate('Tributos','FormularioError', terms),
                        variant: 'error',
                    }
                });   
            }
            setLoading(false);
        })();
    }


    // ---------------------------------------------------------------------------------------------------------------------------
    useEffect(() => {
        (async()=> {
            try{
                setLoading(true)
                const camposApartado = await modelGateway.getCamposApartado(idTipoApartado, idTipoModelo, translate('Tributos', 'GetCamposApartadoError', terms))
                //const camposApartado = camposApartado? camposApartado.sort( (a,b) => a.casilla>b.casilla? 1: -1 ): null
                setCampos(camposApartado);
                let emptyObjt = null;
                let newData: GenericInitialObject | null = null;
                let dataXML: GenericInitialObject | null = null;
                let dataPrevios: GenericInitialObject | null = null;
                if(camposApartado && camposApartado?.length>0){
                    emptyObjt= getEmptyObject(camposApartado)
                    //console.log('emptyObjt', emptyObjt)
                    setEmptyAutoliquidacion(emptyObjt);
                    const keys = getKeysRequiredCampos(camposApartado);
                    //console.log('keys', keys)
                    setCamposRequiredKeys(keys)
                    const datosPrevString = Object.values(datosXmlApartadosPrevios).join('')
                    //console.log('datosPrevString', datosPrevString)
                    //console.log('datosXml', datosXml)

                    if(datosXml){ /* && (datosXmlApartadosPrevios[idTipoApartado]==='' || !datosXmlApartadosPrevios[idTipoApartado])*/
                        dataXML = formatXMLtoObject(datosXml,emptyObjt);
                        //console.log('useDatos xml', dataXML)
                    } 
                    if(datosPrevString){
                        //console.log('use datosPrevString')
                        dataPrevios = getInitialObject(camposApartado, datosPrevString, idTipoApartado);
                        //console.log('useDatos datosPrevString', dataPrevios)
                    }

                    // copare if dataXML!==dataPrevios => have changes
                    if(isSameData(dataXML, dataPrevios)){
                        setChanges(false)
                        newData= dataXML
                    }else{
                        setChanges(true)
                        newData =dataPrevios
                    }

                }

                //Mismos conceptos 593 y 594 - sgún ejercicio
                const tipoModelo = Modelo59X.includes(idTipoModelo) ? '593' : idTipoModelo;
                const conceptos = await modelGateway.getAllConcepts(
                    tipoModelo, 
                    newData && newData['ejercicio'].value ? Number(newData['ejercicio'].value) : undefined
                )
                //console.log('conceptos ', conceptos)

                if( tipoModelo === '800'){
                    setGroupLabels({
                        '1S': translate('Tributos','OperacionesNoExentas',terms),
                        '1T': translate('Tributos','DepositoVertedero',terms),
                        '1': translate('Tributos','ConRecogidaFraccionOrganicaPagoGeneracion',terms),
                        '2': translate('Tributos','SinRecogidaFraccionOrganicaPagoGeneracion',terms),
                        '3T': translate('Tributos','Incineracion',terms),
                        '3': translate('Tributos','ConRecogidaFraccionOrganicaPagoGeneracion',terms),
                        '4': translate('Tributos','SinRecogidaFraccionOrganicaPagoGeneracion',terms),
                        '5': translate('Tributos','totalCuotaDevengada',terms),
                        '6S': translate('Tributos','OperacionesExentas',terms),
                        '6': translate('Tributos','CausaMayorExtremaNecesidadCatastrofe',terms),
                        '7': translate('Tributos','TributacionSuministroAnterior',terms),
                        '8': translate('Tributos','TransportePrevioResiduosOtraIslaParaTratamiento',terms)
                    })

                    const newDataWithTG = newData && { ...newData,
                        '800C13TG': {...newData['800C13TG'] , value: conceptos.find(c => (newData && c.codigo === `C${newData['800C13TG'].casilla}`))?.importe ?? 0}, //5.00},
                        '800C14TG': {...newData['800C14TG'] , value: conceptos.find(c => (newData && c.codigo === `C${newData['800C14TG'].casilla}`))?.importe ?? 0}, //12.00},
                        '800C15TG': {...newData['800C15TG'] , value: conceptos.find(c => (newData && c.codigo === `C${newData['800C15TG'].casilla}`))?.importe ?? 0}, //2.50},
                        '800C16TG': {...newData['800C16TG'] , value: conceptos.find(c => (newData && c.codigo === `C${newData['800C16TG'].casilla}`))?.importe ?? 0}, //5.00}
                    }
                    
                    setData(newDataWithTG);
                    //console.log('newData',newDataWithTG)
                } else if( tipoModelo === '593'){
                    //newData && console.log('newData ', newData)
                    // OJO !! Depende TOTLAMENTE de como se ha dado de alta el modelo 802 y sus conceptos en BBDD
                    // Rellenar tipo de residuo y tipo de gravamen a partir de los conceptos
                    let newDataWithTG = {...newData}
                    for (const key in newData) {
                        if(key.includes('tipoGravamen')) {
                            const casilla=newData[key].casilla
                            // Sabemos por formato que es dos casillas atrás
                            const casillaTipoResiduo = casilla-2;
                            //console.log('casillaTipoResiduo',casillaTipoResiduo)
                            const c = conceptos.find(c=> c.codigo === `C${casilla}`)
                            let tGrav = c?.importe
                            let tResiduo = c?.texto
                            //console.log('tResiduo',tResiduo)
                            if(c && c.identificador.split('.').length>2){
                                // Residuo es subcategoria? - buscar texto previo
                                // Hemos establecido identificadores para tener claro:
                                // Tipo de residuo tiene un identificador del tipo n.n -- Ej: 1.1
                                // Una subcategoria de residuo tiene un identificador del tipo n.n.n -- Ej: 1.1.1, 1.1.2
                                const cp = conceptos.find(x => x.id === c.idPadre)
                                tResiduo= cp ? cp.texto+'. '+tResiduo : tResiduo
                                //console.log('tResiduo',tResiduo)
                            }
                            newDataWithTG={
                                ...newDataWithTG,
                                [key] : {...newDataWithTG[key], value: tGrav},
                                [`tipoResiduo${casillaTipoResiduo}`]: {...newDataWithTG[`tipoResiduo${casillaTipoResiduo}`], value: tResiduo}
                            }
                        }
        
                    }

                    // Tablas y Secciones del MODELO ---- Recuperar texto del concepto
                    const secciones = conceptos.filter(c => c.idPadre === null)
                    const labels: { key: string; value: string; }[]=[]
                    let numTitle= 1
                    secciones.forEach((seccion, idx) => {
                        //labels.push({key:`${numTitle}S`, value: seccion.texto});
                        const tablas = conceptos.filter(c => c.idPadre===seccion.id)
                        tablas.forEach((tabla, idxT) => {  
                            labels.push({key:`${numTitle}T`, value: tabla.texto});
                            numTitle++
                        })
                    });
                    const labelGroup = labels.reduce((obj, l) => ( {...obj, [l.key]: l.value} ), {})
                    
                    setGroupLabels(labelGroup)
                    setData(newDataWithTG)

                } else if( tipoModelo === '890'){
                    setGroupLabels({
                        '1S': translate('Tributos','OperacionesNoExentas',terms),
                        '1': translate('Tributos','trimestre1',terms),
                        '2': translate('Tributos','trimestre2',terms),
                        '3': translate('Tributos','trimestre3',terms),
                        '4': translate('Tributos','trimestre4',terms),
                        '5': translate('Tributos','totalEjercicio',terms),
                        '6S': translate('Tributos','OperacionesExentas',terms),
                        '6': translate('Tributos','CausaMayorExtremaNecesidadCatastrofe',terms),
                        '7': translate('Tributos','TributacionSuministroAnterior',terms),
                        '8': translate('Tributos','TransportePrevioResiduosOtraIslaParaTratamiento',terms),
                        '9': translate('Tributos','totalOperaciones',terms),
                    })
                    setData(newData)
                } else {
                    // Ver si hay campos de tipoGravam y buscar su valor
                    //console.log('newData',newData)
                    let newDataWithTG = {...newData}
                    for (const key in newData) {
                        if(key.includes('tipoGravam')) { //Tipo gravamen con idCampo=1187
                            const casilla=newData[key].casilla
                            const c = conceptos.find(c=> c.codigo === `C${casilla}`)
                            const value = c?.importe && c?.porcentaje 
                                ? c?.importe*(c?.porcentaje/100)
                                : c?.importe
                            newDataWithTG={
                                ...newDataWithTG,
                                [key] : {...newDataWithTG[key], value: value}
                            }
                        }
                    }
                    //console.log('newData',newData)

                    setGroupLabels(undefined)
                    setData(newDataWithTG)
                    //console.log('newData',newData)
                }

                
                
                setLoading(false)
            } catch (error) {
                //console.log('error   ', error)
                const result = (error as Error).message;
                setLoading(false)
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: result,
                        variant: 'error',
                    }
                }); 
            }
        })();
    },[idTipoApartado, idTipoModelo, datosXml, datosXmlApartadosPrevios]);
    
    useEffect(() => {
        if(changes){
            setFinished(false)
        }else{
            setFinished(true)
        }
    },[changes, data])
 
    return(
        <div> 
            
            <Dialog open={loading}>
                <DialogContent>
                    <CircularProgress size={35} />
                </DialogContent>
            </Dialog>
            {/* <Typography style={{marginBottom: 10, fontWeight: 'bold' }} >
                {translate('Tributos','OperacionesNoExentas',terms)}
            </Typography> */}
            {campos && campos.length>0 && camposRequiredKeys !== null && data &&
                renderCampos(campos, data, updateData, Object.values(datosXmlApartadosPrevios).join(''), idTipoApartado, undefined, groupLabels, classes )
            } 

            <div className={classes.rowAlignRight}>
                <Button 
                    disabled={!changes}
                    style={{marginLeft: 10}}
                    color='primary'
                    variant='contained' 
                    onClick={onSave}
                >
                    {translate('Tributos','btnConfirm', terms)}
                </Button>
            </div>
            

        </div>
    )
}
export default withLiterals(['Tributos'])(Liquidacion8xx);


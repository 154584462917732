// LIBRARY IMPORTS
import { useEffect, FC, useState, useMemo, useContext, useCallback, useRef } from 'react';
import { Card, Button, CardContent, CircularProgress, ListItem, List, ListItemText, Typography, TextField, DialogContent, Dialog, ListItemIcon, Tooltip, MenuItem } from '@material-ui/core';
import { mdiAlphaACircleOutline, mdiAlphaLCircleOutline } from '@mdi/js';
import { RouteComponentProps, withRouter } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';

// LOCAL IMPORTS
import { AUX_TIPO_MODELOS_ACTIVOS, getIconsPrintPay, MODELOS_ASISTENTE, MODELOS_ASISTENTE_PLUSVAL, PAY_CERTIFICADO_PERMITIDO, PAY_NO_LOGGED, PRINT } from './constants';
import { LiteralsContext, withLiterals } from 'containers/shared/literals';
import { IModeloGroup, ITipoModelo } from 'gateways/model.new.interface';
import { MoreInfoContext } from 'contexts/more_info.context';
import { handleInfoDispatch } from 'utils/info-dispatch-mvl';
import { AlertsContext } from 'contexts/alerts.context';
import { translate } from 'utils/i18n';
import { TipoTributo } from './utils';
import { capitalize } from 'lodash';
import ModelGateway from '../../gateways/model.new.gateway';
import IoC from 'contexts/ioc.context';
import usePage from 'hooks/page.hook';
import Icon from 'atomic/atoms/icon';
import Term from 'components/term';
import env from 'env';

// STYLES
import styles from './tributos-autonomicos.styles';

const useStyles = makeStyles(styles);

interface IParams { textTipoTributo: TipoTributo };

const HIDE_MODELO = ['594'];

const TributosList: FC<RouteComponentProps<IParams>> = ({ match, history }) => {
    // HOOKS
    const classes = useStyles()
    const terms = useContext(LiteralsContext);
    const [pageState, pageDispatcher] = usePage();
    const [, infoDispatch] = useContext(MoreInfoContext);
    const [, alertsDispatch] = useContext(AlertsContext)

    // CONSTANTS
    const isLogged = pageState.jwp !== null && pageState.jwp.level === 1;
    const isCertified = pageState.jwp !== null && pageState.jwp.level >= 2;

    // GATEWAYS
    const ioc = useContext(IoC);
    const modelGateway: ModelGateway = useMemo(() => ioc.get(ModelGateway), [ioc]);

    // STATES
    const [TIPO_MODELO, setTIPO_MODELO] = useState<string[]>([])
    const [listaModelos, setListaModelos] = useState<IModeloGroup[]>([]);
    const [loading, setLoading] = useState(false)
    const [ref, setRef] = useState<string>("")

    // SCROLL BEHAVIOUR
    const listItemRefs = useRef<HTMLDivElement[]>([]);
    const mounted = useRef(false);



    // USE EFFECTS

    useEffect(() => {
        pageDispatcher({
            type: 'setHeader',
            header: {
                icon: match.params.textTipoTributo === 'autonomicos' ? mdiAlphaACircleOutline : mdiAlphaLCircleOutline,
                title: <Term text={`Tributos ${capitalize(match.params.textTipoTributo)}`} />,
                text: <Term text={match.params.textTipoTributo === 'autonomicos' ? "DescripcionAutonomicos" : "DescripcionLocales"} component="Tributos" />,
                moreInfoTemplate: match.params.textTipoTributo === 'autonomicos' ? 'mas_info_tributos_autonomicos' : 'mas_info_tributos_locales',
                moreInfoTemplateSize: 'lg',
                right: <MenuItem button onClick={() => handleShowMasInfo(match.params.textTipoTributo)}><Term component="Global" text="Mas informacion" /></MenuItem>

            },
            menu: true,
        });
    }, [pageDispatcher, match.params.textTipoTributo]);

    useEffect(() => {
        (async () => {
            try {
                setLoading(true)
                const lista = await modelGateway.getGroupedModelos(match.params.textTipoTributo, translate('Tributos', 'GetModelosError', terms))
                setListaModelos(lista)
                const tipoModelos = await modelGateway.getAllTipoModelosVisibleWeb();
                setTIPO_MODELO(tipoModelos.length === 0 ? AUX_TIPO_MODELOS_ACTIVOS : tipoModelos);
                setLoading(false)
            } catch (error) {
                setListaModelos([])
                setLoading(false)
                const result = (error as Error).message;
                setLoading(false)
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: result,
                        variant: 'error',
                    }
                });
            }
        })();
    }, [match.params.textTipoTributo])

    useEffect(() => {
        mounted.current = true;

        setTimeout(() => {
            if (mounted) {
                const indexRef = sessionStorage.getItem('tributos-list-ref');
                const element = document.getElementById(`id_${indexRef}`);

                if (element) {
                    element.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
                }
            }
        }, 500);

        return () => {
            mounted.current = false;
        }
    }, []);



    // HANDLERS 

    const handleRecover = async () => {
        try {
            setLoading(true)
            if (ref !== undefined && ref !== "") {
                const tributo = match.params.textTipoTributo === 'autonomicos' ? 'A' : 'L'
                const modelo = await modelGateway.getModeloByRef(ref, translate('Tributos', 'GetModeloByRefError', terms));
                const steps = await modelGateway.getApartados(ref, translate('Tributos', 'GetModeloStepsError', terms));
                const idTipoModelo = modelo
                    ? modelo.idTipoModelo
                    : steps.length > 0 ? steps[0].idTipoApartado.substring(0, 3) : null;

                const tipoModelo = idTipoModelo ? await modelGateway.getTipoModelo(idTipoModelo) : null
                if (idTipoModelo && ((
                    PRINT.CERTIFICADO.includes(idTipoModelo) && !PRINT.PERMITIDO.includes(idTipoModelo)
                ) || (
                        PAY_CERTIFICADO_PERMITIDO.includes(idTipoModelo) && !PAY_NO_LOGGED.PERMITIDO.includes(idTipoModelo)
                    )) && !isCertified
                ) {
                    // Protected routes - required is loged
                    throw new Error(translate('Tributos', 'requiredLogin', terms))
                }
                
                if (idTipoModelo && Object.keys(MODELOS_ASISTENTE).includes(idTipoModelo)) {
                    const asistente = MODELOS_ASISTENTE[idTipoModelo]
                    // Asistente plusvalia
                    history.push(`${match.url}/${asistente}/${ref}`);
                } else if (tipoModelo && tipoModelo.tipoTributo === tributo) {
                    if ((modelo && modelo.idEstado !== 'ERR') || (!modelo && steps && steps.length > 0)) {
                        if (TIPO_MODELO.includes(idTipoModelo ?? '') || MODELOS_ASISTENTE_PLUSVAL.includes(idTipoModelo ?? '')) {
                            //redirecciones SEDE
                            if (modelo && modelo.idModelo) {
                                // Modelo finalizado - Ir a pago
                                setLoading(false)
                                history.push(`${match.url}/${idTipoModelo}/${ref}/pago/${modelo.idModelo}`)
                            } else {
                                // Modelo no finalizado - Ir al stepper
                                setLoading(false)
                                history.push(`${match.url}/${idTipoModelo}/${ref}`)
                            }

                        } else {
                            //redirecciones portal-web
                            setLoading(false)
                            if (tributo === 'L') {
                                window.open(`${env.ATIB_URL}TL/modelos/Default.aspx?RefRecuperacion=${ref}`)
                                setRef("")
                            } else {
                                window.open(`${env.ATIB_URL}TA/Modelos/Modelo.aspx?r=${ref}`)
                                setRef("")
                            }
                        }

                    } else {
                        setLoading(false)
                        setRef("")
                        throw new Error(translate('Tributos', 'NotFoundReference', terms));
                    }
                } else {
                    setLoading(false)
                    if (tipoModelo) {
                        throw new Error(`${translate('Tributos', 'NotCorrectModelRecoverAction', terms)}: ${tipoModelo.tipoTributo === 'A'
                            ? translate('Global', `Tributos Autonomicos`, terms)
                            : translate('Global', `Tributos Locales`, terms)
                            }`);
                    } else {
                        setRef("")
                        throw new Error(translate('Tributos', 'NotFoundReference', terms));
                    }
                }

            } else {
                setLoading(false)
                throw new Error(translate('Tributos', 'InsertReference', terms))
            }
        } catch (error) {
            const result = (error as Error).message;
            setLoading(false)
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message: result,
                    variant: 'error',
                }
            });

        }

    }

    const handleShowMasInfo = useCallback((tipoTrib: 'autonomicos' | 'locales') => {
        handleInfoDispatch(infoDispatch, 'show-info', `mas_info_tributos_${tipoTrib}`)
    }, [infoDispatch])



    // TRANSLATIONS HANDLERS

    const handleTranslation1 = (modelo: ITipoModelo) => {
        return (
            <>
                <Term component='Tributos' text='Modelo' /> {modelo.idTipoModelo}: <Term component='Modelos' text={modelo.idTipoModelo + 'Ib'} />
            </>
        );
    }

    const handleTranslation2 = (modelo: ITipoModelo) => {
        let tipoModelo = ''
        switch (modelo.idTipoModelo) {
            case '621':
                tipoModelo = '620'
                break;
            case '047':
                tipoModelo = '043-E'
                break;
            default:
                tipoModelo = modelo.idTipoModelo
                break;
        }

        return (
            <>
                <Term component='Tributos' text='Modelo' /> {tipoModelo}: <Term component='Modelos' text={modelo.idTipoModelo} />
            </>
        );
    }

    const handleTranslation3 = (modelo: ITipoModelo) => {
        return (
            <>
                <Term component='Tributos' text='Modelo' /> {modelo.idTipoModelo}: <Term component='Modelos' text={modelo.idTipoModelo + 'Multi'} />
            </>
        );
    }

    const handleTranslation4 = (modelo: ITipoModelo) => {
        return (
            <>
                <Term component='Tributos' text='Modelo' /> {modelo.idTipoModelo}: <Term component='Modelos' text={modelo.idTipoModelo} />
            </>
        );
    }

    const handleTranslation5 = (modelo: ITipoModelo) => {
        let tipoModelo = ''
        switch (modelo.idTipoModelo) {
            case '081':
                tipoModelo = 'asistente_PLV'
                break;
           
            default:
                break;
        }
        return (
            <>
                <Term component='Modelos' text={tipoModelo}/>
            </>
        );
    }



    return (
        <div className={classes.container}>
            <Dialog open={loading}>
                <DialogContent>
                    <CircularProgress size={35} />
                </DialogContent>
            </Dialog>
            <Card className={classes.cardContainer}>
                <CardContent>
                    <Typography style={{ textAlign: 'justify' }}>
                        <Term component='Tributos' text='RecoverModelTextInfo' />
                    </Typography>
                    <div className={classes.row}>
                        <TextField
                            variant='outlined'
                            margin='dense'
                            onChange={(e) => setRef(e.target.value)}
                            value={ref || ""}
                            label={<Term text='referencia' />}
                            className={classes.recoverButton}
                        />
                        <Button style={{ width: 'fit-content', margin: 10 }} variant='contained' onClick={handleRecover}>
                            <Term component='Tributos' text='btnRecover' />
                        </Button>
                    </div>
                </CardContent>
            </Card>
            <Card className={classes.cardContainer}>
                <CardContent>
                    {listaModelos.length > 0 && listaModelos.map((item: IModeloGroup, index: number) => (
                        <div
                            id={`id_${index}`}
                            key={`this_is_a_key_${index}`}
                            ref={(element: HTMLDivElement) => listItemRefs.current[index] = element}
                        >
                            {listaModelos.length > 1 &&
                                <div className={classes.title}>
                                    <Icon size={1} name={item.grupo.iconPath as any} color='#004f82' />
                                    <Term component="Tributos" text={item.grupo.descripcion ?? ''} />
                                </div>
                            }
                            <List key={item.grupo.idGrupo}>
                                {item.modelos.length > 0 && item.modelos.map((modelo, index) => {
                                    if (!HIDE_MODELO.includes(modelo.idTipoModelo)
                                        && (modelo.visibleWeb || (!modelo.visibleWeb && modelo.urlModelo !== 'not-redirect'))
                                    ) {
                                        const icons = getIconsPrintPay(modelo.idTipoModelo, isLogged, isCertified)
                                        return (
                                            <ListItem
                                                key={item.grupo.idGrupo + '-' + modelo.idTipoModelo + '-' + index}
                                                classes={{
                                                    root: classes.listItemRoot,
                                                    disabled: classes.listItemDisabled
                                                }}
                                                button
                                                onClick={() => {
                                                    if (modelo.visibleWeb) {
                                                        if (MODELOS_ASISTENTE_PLUSVAL.includes(modelo.idTipoModelo)) {
                                                            history.push(`${match.url}/plusvalia`)
                                                        } else {
                                                            if(modelo.urlModelo && modelo.urlModelo.includes('Multi')){
                                                                history.push(`${match.url}/multi/${modelo.idTipoModelo}`)
                                                            } else{
                                                                history.push(`${match.url}/${modelo.idTipoModelo}`)
                                                            }
                                                        }
                                                    } else {
                                                        window.open(modelo.urlModelo, '_blank');
                                                    }
                                                }}
                                            >
                                                <ListItemText>
                                                    {modelo.idTipoModelo === '048' || modelo.idTipoModelo === '071' ?
                                                        modelo.urlModelo && modelo.urlModelo.includes('&ib=')
                                                            ? handleTranslation1(modelo)
                                                            : modelo.urlModelo && modelo.urlModelo.includes('Multi')
                                                                ? handleTranslation3(modelo)
                                                                : handleTranslation4(modelo)
                                                        /**Llinàs diu que 621 es intern -> mostrar 620 conservar DB com 621*/
                                                        :  modelo.idTipoModelo === '081'
                                                            ? handleTranslation5(modelo)
                                                            : handleTranslation2(modelo)
                                                    }
                                                </ListItemText>

                                                <>
                                                    {icons.print &&
                                                        <Tooltip title={<Term component='Tributos' text={icons.print} />}>
                                                            <ListItemIcon className={classes.listItemIconsTA}>
                                                                <Icon size={1} name={icons.print} color='#004f82' />
                                                            </ListItemIcon>
                                                        </Tooltip>
                                                    }
                                                    {icons.pay &&
                                                        <Tooltip title={<Term component='Tributos' text={icons.pay} />}>
                                                            <ListItemIcon className={classes.listItemIconsTA}>
                                                                <Icon size={1} name={icons.pay} color='#004f82' />
                                                            </ListItemIcon>
                                                        </Tooltip>
                                                    }
                                                </>
                                            </ListItem>
                                        )
                                    }
                                })}
                            </List>
                        </div>
                    ))}
                </CardContent>
            </Card>
        </div>
    );
}

export default withRouter(withLiterals(['Tributos', 'Modelos'])(TributosList));


import { inject } from "aurelia-dependency-injection";
import { AxiosInstance, CancelTokenSource } from "axios";
import { IGenericResult, IResponseGeneraPorLotes, TModeloPorLotes } from "./importer.interfaces";

@inject('axios')
export class ImporterGateway {
    private cts: CancelTokenSource | null = null;
    constructor(
        private axios: AxiosInstance,
    ) { }

    public async importXLSX(file: File, type: string): Promise<any> {
        const data = new FormData();
        data.append('file', file);
        data.append('tipo', type);
        const response = await this.axios.post('importer/file/data', data);

        if (response.status < 200 || response.status >= 300) {
            throw new Error('Bad response status: ' + response.statusText);
        }

        return response.data;
    }
    
    public async importXML(file: File, type: 'conductor'|'m071'): Promise<{data: Record<string, any>[]; errors?: {value: string;messages: string[]}[] }> {
        const data = new FormData();
        data.append('file', file);
        data.append('tipo', type);
        const response = await this.axios.post(`importer/file/data-xml`, data);

        if (response.status < 200 || response.status >= 300) {
            throw new Error('Bad response status: ' + response.statusText);
        }

        return response.data;
    }

    public async identificarConductor(conductor: Record<string, any>, idDocumento: string): Promise<boolean> {
        const response = await this.axios.post(`importer/conductor?idDocumento=${idDocumento}`, conductor);

        if (response.status < 200 || response.status >= 300) {
            throw new Error('Bad response status: ' + response.statusText);
        }

        return response.data;
    }

    public async generarModeloPorLotes<T> (data: T[], tipoModelo: TModeloPorLotes,idDocumento?: string): Promise<IResponseGeneraPorLotes<T>>{
        //return {ok: false,  numElImported: 0, result: data.map(item => ({...item, result:false, id:''})) , msgErr: undefined}
        const response = await this.axios.post(`importer/generar-modelo-por-lotes?idDocumento=${idDocumento ?? ''}&idTipoModelo=${tipoModelo}`, data);

        if (response.status < 200 || response.status >= 300) {
            throw new Error('Bad response status: ' + response.statusText);
        }

        return response.data;

    }

    public async saveExcel(file: File): Promise<string | null> {
        const data = new FormData();
        data.append('file', file);
        const response = await this.axios.post('importer/saveExcel', data);

        if (response.status < 200 || response.status >= 300) {
            throw new Error('Bad response status: ' + response.statusText);
        }

        return response.data;
    }

    public async saveDocImporter(file: File, type: 'conductor'|'m071', extension: 'xlsx'|'xml'): Promise<string | null> {
        const data = new FormData();
        data.append('file', file);
        data.append('tipoImport', type);
        data.append('tipoFichero', extension);

        const response = await this.axios.post('importer/saveDocImporter', data);

        if (response.status < 200 || response.status >= 300) {
            throw new Error('Bad response status: ' + response.statusText);
        }

        return response.data;
    }
}
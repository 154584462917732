import React, { FC, useEffect, useContext, useState, useCallback } from 'react';
import { Dialog, Button, DialogActions, DialogContent, DialogTitle, makeStyles, CircularProgress, DialogProps } from "@material-ui/core";
import Term from './term';
import { MoreInfoContext } from 'contexts/more_info.context';
import { ContenidosGateway } from 'gateways/contenido.gateway';
import IoC from 'contexts/ioc.context';

const useStyles = makeStyles(() => ({
    root: {
        flexGrow: 1,
    },
    appBar: {
        position: 'relative',
        backgroundColor: '#2589A4',
        opacity: 20,
    },
    content: {
        margin: '0 20px',
        textAlign: 'justify',
    },
    title: {
        color: '#004f84',
        fontSize: 32,
        fontWeight: 'lighter',
        lineHeight: 'initial'
    },
    centerContent: {
        textAlign: 'center',
        padding: 50
    },
}));


const MoreInfoDialog: FC = () => {
    const [info, infoDispatch] = useContext(MoreInfoContext);
    const ioc = useContext(IoC);
    const [body, setBody] = useState('');
    const [loading, setLoading] = useState(false);
    const contenidoGateway: ContenidosGateway = ioc.get(ContenidosGateway);
    const classes = useStyles();

    const [fullWidth, setFullWidth] = React.useState(false);
    const [maxWidth, setMaxWidth] = React.useState<DialogProps['maxWidth']>('sm');

    const handleClose = () => {
        infoDispatch({ type: 'hide-info' });
    };

    const loadTemplate = useCallback(async () => {
        if (info.info && info.info.open) {
            setLoading(true);
            setBody("");
            if(info.info.templateName){
                const bodyAux = await contenidoGateway.getContent(
                    info.info.templateName,
                    {}
                );
                setBody(bodyAux[0].contenido);
            }
            if(info.info.message){
                setBody(info.info.message)
            }
            setLoading(false);
            if(info.info.size){
                setFullWidth(true)
                setMaxWidth(info.info.size)
            } else {
                setFullWidth(false)
                setMaxWidth('sm')
            }
        }
    }, [info, contenidoGateway]);

    useEffect(() => {
        loadTemplate();
    }, [info, loadTemplate]);

    return info.info
        ? (
            <Dialog 
                fullWidth={fullWidth}
                maxWidth={maxWidth}
                open={info.info.open}
                aria-labelledby="info-dialog-title"
                aria-describedby="info-dialog-description"
            >
                <DialogTitle id="responsive-dialog-title" style={{ margin: '20px 20px 0 20px' }}>
                    <span className={classes.title}>{info.info.title ? info.info.title : <Term component="Global" text="Informacion" />}</span>
                </DialogTitle>
                <DialogContent className={classes.content}>
                    {
                        !loading ?
                            <div dangerouslySetInnerHTML={{ __html: body }} />
                            :
                            <div className={classes.centerContent}>
                                <CircularProgress size={75} />
                            </div>
                    }
                </DialogContent>
                <DialogActions className={classes.content} style={{ marginBottom: 20 }}>
                    <Button
                        onClick={handleClose}
                        color="primary"
                        autoFocus
                    >
                        <Term component={'Global'} text={'Cerrar'} />
                    </Button>
                </DialogActions>
            </Dialog>
        ) :
        null
};

export default MoreInfoDialog;
import React, { useState, useEffect, useContext, useMemo } from 'react';
import usePage from 'hooks/page.hook';
import * as i18nGateway from 'gateways/i18n.gateway';
import { ILiteralsComponent } from 'gateways/i18n.response.interface';
import IoC from 'contexts/ioc.context';

const LiteralsContext = React.createContext<any>({
    terms: {}
})

const ALL_LITERALS = [
    "Global", "Acceso", "Perfil", "CarteroVirtual", "Tramites", "Notificaciones","Wizard", 
    "Domiciliaciones", "Tributos", "BienesSujeto", "DUTI","Plusvalias","AbonoNacimiento",
    "GenericComponents"
]

const withLiterals = (components: string[]) => {
    // El componente Global es genérico a todos los módulos
    components.push('Global');

    function AttachLiteralsComponent<T>(WrapedComponent: React.ComponentType<T>) {
        const LiteralsComponent = (props: any) => {
            const [state] = usePage();
            const [loadedComponents, setLoadedComponents] = useState<ILiteralsComponent>();
            const [loading, setLoading] = useState(true);
            // Services
            const ioc = useContext(IoC);
            const gateway = useMemo(() => ioc.get(i18nGateway.I18nGateway) as i18nGateway.I18nGateway, [ioc]);

            useEffect(() => {
                const promises: Array<Promise<any>> = [];

                for (const component of components) {
                    const cacheKey = ('norm_literals.' + state.lang + '.' + component).toLowerCase();
                    const cacheComponent = sessionStorage.getItem(cacheKey);
                    if (!cacheComponent) {
                        // eslint-disable-next-line no-loop-func
                        const fetchData = async () => {
                            const literals = await gateway.getComponentLiterals([component], state.lang);
                            const componentKey = Object.keys(literals)[0];
                            const componentLiterals = literals[componentKey];
                            const normLiterals: any = {};
                            for (const key in componentLiterals) {
                                if (key in componentLiterals) {
                                    normLiterals[key.toLowerCase()] = componentLiterals[key];
                                }
                            }
                            sessionStorage.setItem(cacheKey, JSON.stringify(normLiterals));
                        };
                        promises.push(fetchData());
                    }
                }

                if (promises.length > 0) {
                    setLoading(true);
                }

                Promise.all(promises).then(() => {
                    const com: any = {};
                    const preffixKey = 'norm_literals.' + state.lang + '.';
                    for (let i = 0; i < sessionStorage.length; i++) {
                        const key = sessionStorage.key(i);
                        if (key && key.indexOf(preffixKey) === 0) {
                            const componentKey = key.split(preffixKey)[1];
                            com[componentKey] = JSON.parse(sessionStorage.getItem(key) || '{}');
                        }
                    }
                    setLoadedComponents(com);
                    setLoading(false);
                });
                // eslint-disable-next-line react-hooks/exhaustive-deps
            }, [state.lang, gateway]);

            return loading ? <div /> :
                <LiteralsContext.Provider value={loadedComponents}>
                    <WrapedComponent {...props} />
                </LiteralsContext.Provider>
        }

        return LiteralsComponent;
    };

    return AttachLiteralsComponent;
}

const LiteralsContextConsumer = LiteralsContext.Consumer;

export { LiteralsContext, withLiterals, LiteralsContextConsumer, ALL_LITERALS };
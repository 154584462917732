import { useContext, useMemo, useState, useEffect } from "react";
import { ICampo } from "../../../gateways/model.new.interface";
import { CircularProgress, Dialog, DialogContent } from "@material-ui/core";
import { Button, makeStyles } from "@material-ui/core";
import styles from "./styles";
import {
  formatXMLtoObject,
  GenericInitialObject,
  GenericObject,
  getEmptyObject,
  getInitialObject,
  getKeysRequiredCampos,
  isSameData,
  renderCampos,
} from "../utils";
import IoC from "contexts/ioc.context";
import ModelGateway from "gateways/model.new.gateway";
import { translate } from "utils/i18n";
import { AlertsContext } from "contexts/alerts.context";
import { LiteralsContext, withLiterals } from "containers/shared/literals";

const useStyles = makeStyles(styles);

interface Props {
  idTipoModelo: string;
  idTipoApartado: string;
  datosXmlApartadosPrevios: GenericObject;
  datosXml: string | null;
  setFinished: (value: boolean) => void;
  handleSave: (data: any) => Promise<boolean>;
  withEjercicioPeriodo: boolean;
}

const BonificablesICIO = (props: Props) => {
  const {
    idTipoModelo,
    idTipoApartado,
    datosXml,
    datosXmlApartadosPrevios,
    setFinished,
    handleSave,
    withEjercicioPeriodo,
  } = props;

  const classes = useStyles();
  const [, alertsDispatch] = useContext(AlertsContext);
  const terms = useContext(LiteralsContext);
  const ioc = useContext(IoC);
  const modelGateway: ModelGateway = useMemo(
    () => ioc.get(ModelGateway),
    [ioc]
  );
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<GenericInitialObject | null>(null);
  const [changes, setChanges] = useState<boolean | null>(null);
  const [campos, setCampos] = useState<ICampo[] | null>(null);
  const [EMPTY_AUTOLOQUIDACION, setEmptyAutoliquidacion] =
    useState<GenericInitialObject | null>(null);
  const [camposRequiredKeys, setCamposRequiredKeys] =
    useState<string[] | null>(null);


  const updateData = (name: string, value: any) => {
    setChanges(true);
    let newData = null;
    if (EMPTY_AUTOLOQUIDACION) {
      newData =
        data && data !== EMPTY_AUTOLOQUIDACION
          ? { ...data, [name]: { ...data[name], value: value } }
          : {
              ...EMPTY_AUTOLOQUIDACION,
              [name]: { ...EMPTY_AUTOLOQUIDACION[name], value: value },
            };
    }
    setData(newData);
  };

  const onSave = () => {
    (async () => {
      setLoading(true);
      // comprobar campos required
      let valid = true;
      if (camposRequiredKeys && camposRequiredKeys.length) {
        if (data) {
          camposRequiredKeys.forEach((element) => {
            switch (data[element].tipo) {
              case "number":
                if (Number(data[element].value) === 0) {
                  valid = false;
                }
                break;
              case "decimal":
              case "decimal2":
              case "decimal3":
                if (parseFloat(data[element].value) === 0) {
                  valid = false;
                }
                break;
              default:
                if (
                  !data[element].value ||
                  data[element].value === undefined ||
                  data[element].value === ""
                ) {
                  valid = false;
                }
                break;
            }
          });
        } else {
          valid = false;
        }
      }
      if (valid) {
        valid = campos?.findIndex(campo => campo.isValid != undefined && !campo.isValid && campo.visible) == -1;
      }
      if (valid) {
        const saved = await handleSave(data);
        setChanges(!saved);
        window.scrollTo(0, 0);
      } else {
        setChanges(true);
        alertsDispatch({
          type: "show-alert",
          payload: {
            message: translate("Tributos", "FormularioError", terms),
            variant: "error",
          },
        });
      }
      setLoading(false);
    })();
  };


  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        const camposApartado = await modelGateway.getCamposApartado(
          idTipoApartado,
          idTipoModelo,
          translate("Tributos", "GetCamposApartadoError", terms)
        );

        const bonificaciones = await modelGateway.getAllMunicipiosBonificados(
          idTipoModelo,
          "bonificaciones"
        );

        let emptyObjt: GenericInitialObject | null = null;
        let newData: GenericInitialObject | null = null;
        let dataXML: GenericInitialObject | null = null;
        let dataPrevios: GenericInitialObject | null = null;
        if (camposApartado && camposApartado.length) {
          emptyObjt = getEmptyObject(camposApartado);
          const keys = getKeysRequiredCampos(camposApartado);
          setCamposRequiredKeys(keys);
          const datosPrevString = Object.values(datosXmlApartadosPrevios).join(
            ""
          );

          if (datosXml) {
            dataXML = formatXMLtoObject(datosXml, emptyObjt);
          }
          if (datosPrevString) {
            dataPrevios = getInitialObject(
              camposApartado,
              datosPrevString,
              idTipoApartado
            );
          }

          if (idTipoModelo === "051") {
            const datosBonificacion: {
              [key: string]: {
                [casilla: number]: {
                  value: string | number;
                  moreInfoText?: string;
                };
              };
            } = {};

            bonificaciones.forEach((item, index) => {
              const initCasilla = 60;
              const incremento = 6 * index;
              const casillaBase = initCasilla + incremento;

              datosBonificacion[item.codigo] = {
                [casillaBase]: { value: item.codigo }, // casilla 60, 66, 72, 78 en modelo 051
                [casillaBase + 1]: {
                  value: item.descripcion,
                  moreInfoText: item.texto,
                }, // casilla 61, 67, 73, 79 en modelo 051
                [casillaBase + 4]: { value: item.bonificacion }, // casilla 64, 70, 76, 82 en modelo 051
              };
            });

            const updatedCampos = camposApartado.map((campo) => {
              const { codigo, renderGroup, casilla } = campo;
              const datos = datosBonificacion[renderGroup];

              if (dataPrevios && datos && casilla in datos) {
                dataPrevios[codigo].value = datos[casilla].value;
                if (datos[casilla].moreInfoText) {
                  return {
                    ...campo,
                    moreInfoText: datos[casilla].moreInfoText,
                  };
                }
              }
              return campo;
            });

            setCampos(updatedCampos);
          } else {
            setCampos(camposApartado);
          }

          setEmptyAutoliquidacion(dataPrevios);
          if (isSameData(dataXML, dataPrevios)) {
            setChanges(false);
            newData = dataXML;
          } else {
            setChanges(true);
            newData = dataPrevios;
          }
        }
        setData(newData);
        setLoading(false);
      } catch (error) {
        const result = (error as Error).message;
        setLoading(false);
        alertsDispatch({
          type: "show-alert",
          payload: {
            message: result,
            variant: "error",
          },
        });
      }
    })();
  }, [idTipoApartado, idTipoModelo, datosXml, datosXmlApartadosPrevios]);

  useEffect(() => {
    if (changes) {
      setFinished(false);
    } else {
      setFinished(true);
    }
  }, [changes, data]);

  return (
    <>
      {!withEjercicioPeriodo && (
        <Button
          style={{ marginLeft: 10 }}
          color="primary"
          variant="contained"
          className={classes.rowAlignLeft}
          disabled={data === null || data === EMPTY_AUTOLOQUIDACION}
          onClick={() => {
            setData(EMPTY_AUTOLOQUIDACION);
            setChanges(true);
          }}
        >
          {translate("Tributos", "btnClean", terms)}
        </Button>
      )}
      <Dialog open={loading}>
        <DialogContent>
          <CircularProgress size={35} />
        </DialogContent>
      </Dialog>
      {campos &&
        campos.length &&
        camposRequiredKeys !== null &&
        data &&
        renderCampos(
          campos,
          data,
          updateData,
          Object.values(datosXmlApartadosPrevios).join(""),
          idTipoApartado,
          undefined,
          undefined,
          classes
        )}

      <div className={classes.rowAlignRight}>
        <Button
          disabled={!changes}
          style={{ marginLeft: 10 }}
          color="primary"
          variant="contained"
          onClick={onSave}
        >
          {translate("Tributos", "btnSave", terms)}
        </Button>
      </div>
    </>
  );
};

export default withLiterals(["Tributos"])(BonificablesICIO);

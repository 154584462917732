import { FC, useContext, useMemo } from 'react';
import { Divider, Table, TableBody, TableCell, TableHead, TableRow, Typography, withStyles, WithStyles } from '@material-ui/core';
import getAllKeys from 'utils/wizard';
import { IGenericResult, IResult } from 'gateways/importer.interfaces';
import Icon from 'atomic/atoms/icon';
import moment from 'moment';
import Term from 'components/term';
import LinearWithValueLabel from 'components/linear-value-label';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import { ALL_LITERALS, LiteralsContext, withLiterals } from 'containers/shared/literals';
import { translate } from 'utils/i18n';
import styles from "./styles";
import Alert from '@material-ui/lab/Alert';

type TArr<T> = (keyof IGenericResult<T>)[]

interface IPropsWRes<P> {
    data: IGenericResult<P>[];
    numElementsImported: number;
    hiddenCells: TArr<P>;
}
type StyledIProps<T> = IPropsWRes<T> & WithStyles<typeof styles>;

const moveToStart = <T extends Record<string, any>>(
    arr: (keyof T)[],
    value: keyof T
): (keyof T)[] => {
    const index = arr.indexOf(value);  // Encuentra la posición del valor
    if (index > -1) {
        arr.splice(index, 1);  // Elimina el valor del array
        arr.unshift(value);    // Inserta el valor al inicio
    }
    return arr;
};

const GenericTableWizardResult = <T,>({ data, numElementsImported,hiddenCells, classes} : StyledIProps<T>) => {
    const terms = useContext(LiteralsContext);

    const initialValue = data.length >0 ? parseFloat((100 / data.length).toFixed(2)) : 0;
    const keysToRender = useMemo(() => {
        if(data && data.length>0){
            const keys = Object.keys(data[0]).filter(k => !hiddenCells.includes(k as keyof IGenericResult<T>)) as TArr<T>
            const order =  moveToStart<IGenericResult<T>>(keys, 'result');
            return order
        } else {
            return []
        }
        
    },[data, hiddenCells])

    console.log('GenericTableWizardResult data', data)

    const getRowValue = (item: IGenericResult<T>, index: number): any => {
        //console.log('getRowValue ', item)
        return (
            <>
            <TableRow key={`columnWizard${index}`} className={item.errMsg ? classes.rowError : ''}>
                {keysToRender.map((key, i) =>{ 
                    const typedKey = key as keyof IGenericResult<T>;
                        return (
                            typedKey !== 'result' ? (
                                <TableCell key={i} className={classes.nowrap}>
                                    {item[typedKey]}
                                </TableCell>
                            ) : (
                                <TableCell key={i}>
                                    { item.result != null &&
                                        <Icon 
                                            name={item.result ? 'check-ok' : 'check-error'} 
                                            size={1} 
                                            color={item.result ? '#004f82' : '#f44336'} 
                                        />
                                    }
                                </TableCell>
                            )
                        )
                    })
                }
            </TableRow>
            {item.errMsg && (
                <TableRow key={index}>
                    <TableCell colSpan={keysToRender.length} className={classes.alertCellColor}>
                        <div className={classes.row}>
                            <span className={classes.bold} style={{marginRight: 5}}>
                                <Term component={"Wizard"} text={'errMsg'} />{': '}
                            </span>
                            <Term component={"Wizard"} text={item.errMsg} />
                        </div>
                    </TableCell>
                </TableRow>
            )}
            </>
        );
    }

    return (
        data.length >0 ?
            <>
                <div className={classes.identificacionDate}>{translate('GenericComponents','fechaImportacion',terms).toUpperCase}: {moment().format('DD/MM/YYYY')}</div>
                <LinearWithValueLabel initialValue={initialValue} numElements={numElementsImported} />        
                <Divider variant="inset" />
                <Table>
                    <TableHead>
                        <TableRow className={classes.header}>
                            {
                                data && keysToRender && /*&& getAllKeys(data as any[])*/
                                keysToRender.map((key, index) => (
                                    <TableCell key={`${key as string}-${index}`} className={classes.bold}>
                                        <Term component={"Wizard"} text={key as string} />
                                    </TableCell>
                                ))
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            data && data.map((item: any, index: any) => (getRowValue(item, index)))
                        }
                    </TableBody>
                </Table>
            </>
        : 
            <div className={classes.divMsg}>
                <Typography>{translate('GenericComponents','nothingImported',terms)}</Typography>
            </div>

    );
};


export default withLiterals(ALL_LITERALS)(withStyles(styles)(GenericTableWizardResult)) as <T>(props: IPropsWRes<T>) => JSX.Element;

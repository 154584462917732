// LIBRARY IMPORTS
import { FC, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Button, Card, CardActions, CardContent, CardHeader, Drawer, FormControl, FormControlLabel, Grid, Hidden, InputLabel, MenuItem, Radio, RadioGroup, Select, Typography, makeStyles } from "@material-ui/core";
import { RouteComponentProps, } from "react-router-dom";
import { mdiCalendar, mdiLaptop } from "@mdi/js";
import { StaticContext } from "react-router";
import Icon from "@mdi/react";
// LOCAL IMPORTS
import { LiteralsContext } from "containers/shared/literals";
import { handleInfoDispatch } from "utils/info-dispatch-mvl";
import { MoreInfoContext } from "contexts/more_info.context";
import { IPreciodadImporte, IRecibo, IReciboDetallePagoBonificado } from "gateways/recibo.interfaces";
import { dateFormat, dateTransformIntoRedeableFormat } from "utils/dates";
import { ITributo } from "gateways/tributos.interfaces";
import { IDiaCortesiaDetalle, IDiasCortesia } from "gateways/notificaciones.interfaces";
import Term from "components/term";
import usePage from "hooks/page.hook";
import useLoadDiasVoluntaria from "hooks/domiciliaciones/dias-voluntaria.hook";
import DialogSeleccionarImportePorPeriodo from "./dialogs/dialog-seleccion-importe-por-periodo";
import Calendar from "containers/shared/calendar";
import IconButton from "atomic/atoms/icon-button";
import * as recibosMethodGateway from 'gateways/recibo.gateway';
// STYLES
import styles from '../../shared/pago-a-cuenta.styles';
import { AlertsContext } from "contexts/alerts.context";
import IoC from "contexts/ioc.context";

const useStyles = makeStyles(styles);

interface IStatePagoACuentaDomiciliado {
    recibo: IRecibo;
    arrayPeriocidadImportes: IPreciodadImporte[];
    planSeleccionado: "automatico" | "personalizado" | undefined;
    numDiaCargoPlanAutomaticoSeleccionado: string;
    diasVoluntaria: IDiasCortesia | undefined;
}

type Props = RouteComponentProps<{}, StaticContext, IStatePagoACuentaDomiciliado>;

const PagoACuentaDomiciliado: FC<Props> = ({ history, location }) => {
    const state = location.state;
    const recibo = state.recibo;
    const arrayPeriocidadImportesState = state.arrayPeriocidadImportes;
    const planSeleccionadoState = state.planSeleccionado;
    const numDiaCargoPlanAutomaticoSeleccionadoState = state.numDiaCargoPlanAutomaticoSeleccionado;
    const diasVoluntariaState = state.diasVoluntaria;

    // CONSTANTS
    const lang = localStorage.getItem('lang');
    const limiteDiasSeleccionablesPlanAutomatico = 31;

    const tributo: ITributo = {
        idRecibo: recibo.id,
        nif: recibo.domiciliacion.nifTitular,
        objeto: recibo.objeto,
        tipo: recibo.concepto.idTipo.toString(),
        direccionTributaria: recibo.direccionTributaria,
        concepto: recibo.concepto,
        organismo: recibo.organismo,
        domiciliacion: recibo.domiciliacion,
        pagoCarta: recibo.pagoCarta ? recibo.pagoCarta : null
    }

    // GATEWAYS
    const ioc = useContext(IoC);
    const gateway = useMemo(() => ioc.get(recibosMethodGateway.RecibosGateway) as recibosMethodGateway.RecibosGateway, [ioc]);

    // HOOKS
    const terms = useContext(LiteralsContext);
    const classes = useStyles();

    const [, infoDispatch] = useContext(MoreInfoContext);
    const [, pageDispatcher] = usePage();
    const [, alertsDispatch] = useContext(AlertsContext);

    const { diasVoluntaria, availableYearMonth, isLoadingDiasVoluntaria, onChangeDiasVoluntaria, onUnselectAllDiasVoluntaria } = useLoadDiasVoluntaria({ tributos: [tributo] });
    const diasVoluntariaRef = useRef<IDiaCortesiaDetalle[]>(null);

    // MEMOS
    const limiteDiasSeleccionablesPlanPersonalizado = useMemo(() => { return availableYearMonth.reduce((acc, yearMonth) => acc + yearMonth.months.length, 0); }, [availableYearMonth]);

    // STATES
    const [numContainerGridRows, setNumContainerGridRows] = useState(0);

    const [numDiaCargoPlanAutomaticoSeleccionado, setNumDiaCargoPlanAutomaticoSeleccionado] = useState<string>('');
    const [planSeleccionado, setPlanSeleccionado] = useState<"automatico" | "personalizado" | undefined>();

    const [isVisibleCalendars, setIsVisibleCalendars] = useState(false);
    const [isDisabledOnClickCalendar, setIsDisabledOnClickCalendar] = useState(false);
    const [isOpenDialogSeleccionarImportePorPeriodo, setIsOpenDialogSeleccionarImportePorPeriodo] = useState(false);

    const [fechaSeleccionada, setFechaSeleccionada] = useState('');
    const [itemSeleccionado, setItemSeleccionado] = useState<string | undefined>();
    const [arrayPeriocidadImportes, setArrayPeriocidadImportes] = useState<IPreciodadImporte[]>([]);

    const [importePendiente, setImportePendiente] = useState(recibo.desglose.pendiente.toString());
    const [importeUsuario, setImporteUsuario] = useState('0');
    const [porcentajeBonificacion, setPorcentajeBonificacion] = useState(0);



    // -------- USE EFFECT --------

    useEffect(() => {
        pageDispatcher({
            type: 'setHeader',
            header: {
                icon: mdiLaptop,
                title: <Term component="CarteroVirtual" text='Seleccionar forma de pago' />,
                moreInfoTemplate: 'mas_info_forma_de_pago',
                right: (
                    <MenuItem button onClick={() => handleShowMasInfo('mas_info_forma_de_pago')}>
                        <Term component="Global" text="Mas informacion" />
                    </MenuItem>

                ),
            },
            menu: true,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageDispatcher, terms]);

    useEffect(() => {
        if (limiteDiasSeleccionablesPlanPersonalizado > 3) setNumContainerGridRows(2);
        else if (limiteDiasSeleccionablesPlanPersonalizado > 7) setNumContainerGridRows(3);
        else if (limiteDiasSeleccionablesPlanPersonalizado > 11) setNumContainerGridRows(4);
        else setNumContainerGridRows(1);
    }, [limiteDiasSeleccionablesPlanPersonalizado]);

    useEffect(() => {
        if (planSeleccionado !== "automatico" || !diasVoluntaria) return;

        const diasSeleccionados = diasVoluntaria.dias.filter(dia => dia.seleccionada);
        if (diasSeleccionados.length < 1) return;

        const importes: string[] = [];

        diasSeleccionados.forEach((dia, index) => {
            if (diasSeleccionados.length === index + 1) {

                const sumaImportes = importes.reduce((acc, item) => {
                    const itemValue = parseFloat(item);

                    if (!isNaN(itemValue)) return acc + itemValue;
                    else return acc;
                }, 0);

                const importeFinal = (recibo.desglose.pendiente - sumaImportes).toFixed(2);
                importes.push(importeFinal);
            }
            else importes.push((recibo.desglose.pendiente / diasSeleccionados.length).toFixed(2));
        });

        const arrayPeriocidadImportesPersonalizadosTemp = diasSeleccionados.map((dia, index) => {
            return { fecha: dia.fecha, htmlItem: undefined, importe: importes[index] }
        });

        setArrayPeriocidadImportes(arrayPeriocidadImportesPersonalizadosTemp);
    }, [planSeleccionado, diasVoluntaria]);

    useEffect(() => {
        if (isLoadingDiasVoluntaria) return;
        if (arrayPeriocidadImportesState && arrayPeriocidadImportesState.length > 0) setArrayPeriocidadImportes(arrayPeriocidadImportesState);

        if (planSeleccionadoState === 'automatico')
            handleRestorePlanAutomatico();
        else if (planSeleccionadoState === 'personalizado')
            handleRestorePlanPersonalizado();
    }, [isLoadingDiasVoluntaria]);

    useEffect(() => {
        (async () => {
            const response: IReciboDetallePagoBonificado | null = await gateway.getImportesReciboDomiciliadoBonificado(recibo.id);

            if (!response) return;

            if (response.importeDomiciliado > 0 && response.porcentajeBonificacion > 0) {
                setPorcentajeBonificacion(response.porcentajeBonificacion);
                setImportePendiente(response.importeDomiciliado.toString());
            }
        })()
    }, [recibo]);



    // -------- HANDLERS --------
    const handleCleanUpValues = () => {
        setArrayPeriocidadImportes([]);
        onUnselectAllDiasVoluntaria();
    };

    const handleShowMasInfo = useCallback((templateName: string) => {
        handleInfoDispatch(infoDispatch, 'show-info', templateName)
    }, [infoDispatch]);

    const handleNavigateToPagoACuentaConfirmacion = () => {
        if (planSeleccionado === 'personalizado' && parseFloat(importePendiente) !== 0) {
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message: <Term component="CarteroVirtual" text="importe_no_contempla_totalidad_importe_recibo" />,
                    variant: 'warning',
                    handleCustomAction: () => {
                        alertsDispatch({ type: 'hide-alert' })
                    },
                }
            });
        } else {
            history.push('/cartero-virtual/deuda-pendiente/pago-a-cuenta-confirmacion', { recibo, arrayPeriocidadImportes, planSeleccionado, numDiaCargoPlanAutomaticoSeleccionado, diasVoluntaria });
        }
    };

    const handleVerPlanAutomatico = () => {
        handleDiaCalendarioSeleccionadoPlanAutomatico(numDiaCargoPlanAutomaticoSeleccionado);
        setIsVisibleCalendars(true);
    }

    const handleDiasCargoSeleccionados = (event: React.ChangeEvent<{ value: unknown }>) => {
        setNumDiaCargoPlanAutomaticoSeleccionado(event.target.value as string);
        handleCleanUpValues();
        setIsVisibleCalendars(false);
    };

    const handlePlanSeleccionado = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value === "personalizado") {
            setNumDiaCargoPlanAutomaticoSeleccionado('');
            setIsDisabledOnClickCalendar(false);
            setIsVisibleCalendars(true);
            setArrayPeriocidadImportes([]);
            onUnselectAllDiasVoluntaria();
        }
        else if (event.target.value === "automatico") {
            setIsDisabledOnClickCalendar(true);
            setIsVisibleCalendars(false);
            setImportePendiente(recibo.desglose.pendiente.toString());
            setImporteUsuario('0');
            setArrayPeriocidadImportes([]);
            onUnselectAllDiasVoluntaria();
        }

        if (event.target.value === "automatico" || event.target.value === "personalizado") setPlanSeleccionado(event.target.value);
    }



    // -------- HANDLRE RESTORES PLANES DURANTE NAVEGACIÓN --------

    const handleRestorePlanAutomatico = () => {
        setPlanSeleccionado(planSeleccionadoState);
        setNumDiaCargoPlanAutomaticoSeleccionado(numDiaCargoPlanAutomaticoSeleccionadoState);

        setIsDisabledOnClickCalendar(true);
        setIsVisibleCalendars(true);

        if (diasVoluntariaState) onChangeDiasVoluntaria(diasVoluntariaState);
    }

    const handleRestorePlanPersonalizado = () => {
        let importeUsuario = arrayPeriocidadImportesState.reduce((acc, item) => acc + parseFloat(item.importe), 0);

        setImporteUsuario(importeUsuario.toFixed(2).toString());
        setImportePendiente((recibo.desglose.pendiente - importeUsuario).toFixed(2).toString());

        setPlanSeleccionado(planSeleccionadoState);
        setIsDisabledOnClickCalendar(false);
        setIsVisibleCalendars(true);

        if (!diasVoluntariaState) return;

        const diasVoluntariaStateTemp = diasVoluntariaState.dias.map(dia => {
            const isDayInArrayPeriocidadImportes = arrayPeriocidadImportesState.find(item => item.fecha === dia.fecha);
            if (isDayInArrayPeriocidadImportes) dia.seleccionada = true;
            else dia.seleccionada = false;
            return dia;
        });

        onChangeDiasVoluntaria({ ...diasVoluntariaState, dias: diasVoluntariaStateTemp });
    };



    // -------- CALENDAR - PLAN AUTOMÁTICO --------

    const handleDiaCalendarioSeleccionadoPlanAutomatico = (numDiaDeseado: string) => {
        if (!diasVoluntaria || diasVoluntaria.dias.length < 1) return;

        const diasModificados = diasVoluntaria.dias.flatMap(item => {
            const date = new Date(item.fecha);
            const day = date.getDate();

            if (day === parseInt(numDiaDeseado) && item.modificable) {
                item.seleccionada = true;
                return [item];
            }
            else if (day === parseInt(numDiaDeseado)) {
                const substitutoDiaSeleccionadoModificable = handleDiaDeseadoNoSeleccionable(item);
                if (!substitutoDiaSeleccionadoModificable) return [item];

                substitutoDiaSeleccionadoModificable.seleccionada = true;
                return [item, substitutoDiaSeleccionadoModificable];
            }
            return [item];
        });

        const diasModificadosUnique = diasModificados.filter((item, index, self) =>
            index === self.findIndex((t) => (
                t.fecha === item.fecha
            ))
        );

        onChangeDiasVoluntaria({ ...diasVoluntaria, dias: diasModificadosUnique });
    }

    const handleDiaDeseadoNoSeleccionable = (item: IDiaCortesiaDetalle): IDiaCortesiaDetalle | null => {
        if (!diasVoluntaria || diasVoluntaria.dias.length < 1) return null;
        const selectedDate = new Date(item.fecha);
        const diasVoluntariaDelMismoMes = diasVoluntaria.dias.filter(dia => new Date(dia.fecha).getMonth() === selectedDate.getMonth());
        const diasVoluntariaDelMismoMesModificables = diasVoluntariaDelMismoMes.filter(dia => dia.modificable);

        diasVoluntariaDelMismoMesModificables.sort((a, b) => {
            const diffA = Math.abs(new Date(a.fecha).getTime() - selectedDate.getTime());
            const diffB = Math.abs(new Date(b.fecha).getTime() - selectedDate.getTime());
            return diffA - diffB;
        });

        return diasVoluntariaDelMismoMesModificables.length > 0 ? diasVoluntariaDelMismoMesModificables[0] : null;
    };



    // -------- CALENDAR - PLAN PERSONALIZADO --------

    const handleDiaCalendarioSeleccionadoPlanPersonalizado = (itemSeleccionado: HTMLDivElement, fechaSeleccionada: string) => {
        const diasSeleccionadosCopy = [...arrayPeriocidadImportes]
        const indexFechaInArraySeleccionados = diasSeleccionadosCopy.findIndex(fecha => fecha.fecha === fechaSeleccionada);
        const indexFechaMismoMesInArraySeleccionados = diasSeleccionadosCopy.findIndex(fecha => new Date(fecha.fecha).getMonth() === new Date(fechaSeleccionada).getMonth());

        // 1.- Si la fecha ya se encuentra en el array de seleccionados, la eliminamos
        if (indexFechaInArraySeleccionados !== -1) {
            handleCancelarDiaCalendarioeleccionada(indexFechaInArraySeleccionados);
            return;
        }
        // 2.- Si la fecha no se encuentra en el array pero hay una fecha del mismo mes en el array, 
        // eliminamos la fecha previa del mismo mes e insertamos la nueva
        else if (indexFechaMismoMesInArraySeleccionados !== -1) {
            diasSeleccionadosCopy.splice(indexFechaMismoMesInArraySeleccionados, 1);
            handleCancelarDiaCalendarioeleccionada(indexFechaMismoMesInArraySeleccionados);

            setFechaSeleccionada(fechaSeleccionada);
            setIsOpenDialogSeleccionarImportePorPeriodo(true);
        }
        // 3.- Si la fecha no se encuentra en el array y no hay una fecha del mismo mes en el array, insertamos la nueva fecha seleccionada
        else {
            setFechaSeleccionada(fechaSeleccionada);
            setIsOpenDialogSeleccionarImportePorPeriodo(true);
        }

        setItemSeleccionado(itemSeleccionado.id);
        setArrayPeriocidadImportes(diasSeleccionadosCopy);
    };

    const handleCancelarDiaCalendarioeleccionada = (index: number) => {
        const diaSeleccionado = arrayPeriocidadImportes[index];
        const importe = diaSeleccionado.importe;
        const itemSeleccionado = diaSeleccionado.htmlItem;

        if (!itemSeleccionado || itemSeleccionado === '') return;
        const htmlElement = document.getElementById(itemSeleccionado);

        if (!htmlElement) return;
        htmlElement.style.backgroundColor = 'white';
        htmlElement.style.color = 'black';

        const diasSeleccionadosCopy = [...arrayPeriocidadImportes];
        diasSeleccionadosCopy.splice(index, 1);

        setArrayPeriocidadImportes(diasSeleccionadosCopy);
        setImportePendiente((parseFloat(importePendiente) + parseFloat(importe)).toFixed(2).toString());
        setImporteUsuario((parseFloat(importeUsuario) - parseFloat(importe)).toFixed(2).toString());
    };



    // -------- DIALOG --------

    const handleToggleDialogSeleccionarImportePorPeriodo = () => {
        setIsOpenDialogSeleccionarImportePorPeriodo(!isOpenDialogSeleccionarImportePorPeriodo);
    };

    const handleCancelarDialogSeleccionarImportePorPeriodo = () => {
        if (!diasVoluntaria || diasVoluntaria.dias.length < 1) return;

        const diasVoluntariaCopy = diasVoluntaria;
        const indexFechaSeleccionada = diasVoluntariaCopy.dias.findIndex(dia => dia.fecha === fechaSeleccionada);
        diasVoluntariaCopy.dias[indexFechaSeleccionada].seleccionada = false;

        if (!itemSeleccionado || itemSeleccionado === '') return;
        const htmlElement = document.getElementById(itemSeleccionado);

        if (!htmlElement) return;
        htmlElement.style.backgroundColor = 'white';
        htmlElement.style.color = 'black';

        setIsOpenDialogSeleccionarImportePorPeriodo(false);
    };

    const handleAddImportePersonalizado = (importe: string) => {
        let importeSanitized = importe === '' || !importe ? '0' : importe;
        setArrayPeriocidadImportes([...arrayPeriocidadImportes, { fecha: fechaSeleccionada, htmlItem: itemSeleccionado, importe: importeSanitized }]);
        setImportePendiente((parseFloat(importePendiente) - parseFloat(importeSanitized)).toFixed(2).toString());
        setImporteUsuario((parseFloat(importeUsuario) + parseFloat(importeSanitized)).toFixed(2).toString());

        handleToggleDialogSeleccionarImportePorPeriodo();
    };



    return (
        <>
            <div className={classes.containerPagoCuentaDomi}>
                <div className={[classes.wFull, classes.fitContent, classes.flexRow, classes.containerPlanPagos,].join(' ')}>

                    {/* CARD PLAN AUTOMÁTICO */}

                    <Card className={classes.cardPlanPago}>
                        <Typography variant="h6" className={classes.bold}>
                            <Term component="CarteroVirtual" text="plan_de_pagos" />
                        </Typography>
                        <CardContent className={classes.cardContentPlanPagoAutomatico}>
                            <RadioGroup className={classes.radioBtn} onChange={handlePlanSeleccionado}>
                                <FormControlLabel
                                    value={"automatico"}
                                    checked={planSeleccionado === "automatico"}
                                    control={<Radio size="small" color="primary" />}
                                    label={
                                        <Typography className={[classes.fontM, classes.bold, classes.grey].join(' ')}>
                                            <Term component="CarteroVirtual" text="plan_automatico" />
                                        </Typography>
                                    }
                                />
                            </RadioGroup>
                            <Typography className={classes.fontM} style={{ width: '65%' }}>
                                <Term component="CarteroVirtual" text="plan_automatico_descripcion" />
                            </Typography>
                        </CardContent>

                        <CardActions className={classes.conatienrCardActions}>
                            <div className={classes.containerSelectorAndIcon}>
                                <Icon color='#0d6394' className={[classes.w30, classes.fitContent].join(' ')} path={mdiCalendar} />
                                <FormControl>
                                    <InputLabel>
                                        <Term component="CarteroVirtual" text="dias_de_cargo" />
                                    </InputLabel>
                                    <Select
                                        disabled={planSeleccionado !== "automatico"}
                                        value={numDiaCargoPlanAutomaticoSeleccionado}
                                        onChange={handleDiasCargoSeleccionados}
                                        style={{ minWidth: 175 }}
                                    >
                                        {
                                            Array.from({ length: limiteDiasSeleccionablesPlanAutomatico }, (_, i) => i + 1).map((day) => {
                                                return <MenuItem key={day} value={day}>{day}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </div>
                            <Button className={classes.verPlanBtn} disabled={planSeleccionado !== "automatico" || numDiaCargoPlanAutomaticoSeleccionado === ''} onClick={handleVerPlanAutomatico}>
                                <Term component="CarteroVirtual" text="ver_plan" />
                            </Button>
                        </CardActions>
                    </Card>

                    {/* CARD PLAN PERSONALIZADO */}

                    <Card className={classes.cardPlanPago}>
                        <CardHeader />
                        <CardContent className={classes.cardContentPlanPago}>
                            <div style={{ display: 'flex' }}>
                                <RadioGroup className={classes.radioBtn} onChange={handlePlanSeleccionado}>
                                    <FormControlLabel
                                        value={"personalizado"}
                                        checked={planSeleccionado === "personalizado"}
                                        control={<Radio size="small" color="primary" />}
                                        label={
                                            <Typography className={[classes.fontM, classes.bold, classes.grey].join(' ')}>
                                                <Term component="CarteroVirtual" text="plan_personalizado" />
                                            </Typography>
                                        }
                                    />
                                </RadioGroup>
                                <Typography className={classes.fontM} style={{ width: '65%' }}>
                                    <Term component="CarteroVirtual" text="plan_personalizado_descripcion" />
                                </Typography>
                            </div>

                            {arrayPeriocidadImportes.length > 0 && planSeleccionado === "personalizado" &&
                                <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>

                                    {/* IMPORTE PENDIENTE */}

                                    <div>
                                        <Typography className={[classes.mB0, classes.blueSEDE, classes.bold, classes.fontM].join(' ')}>
                                            <Term component="CarteroVirtual" text='importe_pendiente' />
                                        </Typography>
                                        <Typography style={{ textAlign: 'center' }} className={[classes.bold, classes.fontS].join(' ')}>{`${importePendiente} €`}</Typography>
                                    </div>

                                    {/* IMPORTE USUARIO */}

                                    <div>
                                        <Typography className={[classes.mB0, classes.blueSEDE, classes.bold, classes.fontM].join(' ')}>
                                            <Term component="CarteroVirtual" text='importe_usuario' />
                                        </Typography>
                                        <Typography style={{ textAlign: 'center' }} className={[classes.bold, classes.fontS].join(' ')}>{`${importeUsuario} €`}</Typography>
                                    </div>
                                </div>
                            }
                        </CardContent>
                    </Card>
                </div>

                {/* COMPONENTE CALENDARIO */}

                {
                    isVisibleCalendars &&
                    <Card className={[classes.wFull, classes.p20].join(' ')}>
                        <Typography variant="h6" className={classes.bold}>
                            <Term component="CarteroVirtual" text="periocidad_e_importes" />
                        </Typography>

                        {/* LEYENDA CALENDARIO */}

                        <div className={classes.containerGridCalendar} style={{ gridTemplateRows: `repeat(${numContainerGridRows}, 1fr)` }}>
                            <div id="leyenda-calendario" className={classes.gridLeyendaCalendario}>
                                <div className={classes.containerSpanLeyenda}>
                                    <span className={classes.spanLeyendaCalendario} style={{ backgroundColor: 'blue' }} />
                                    <Typography>
                                        <Term component="CarteroVirtual" text="dia_seleccionado" />
                                    </Typography>
                                </div>
                                <div className={classes.containerSpanLeyenda}>
                                    <span className={classes.spanLeyendaCalendario} style={{ border: '1px solid black' }} />
                                    <Typography>
                                        <Term component="CarteroVirtual" text="dias_disponibles" />
                                    </Typography>
                                </div>
                                <div className={classes.containerSpanLeyenda}>
                                    <span className={classes.spanLeyendaCalendario} style={{ backgroundColor: 'grey' }} />
                                    <Typography>
                                        <Term component="CarteroVirtual" text="dias_no_disponibles" />
                                    </Typography>
                                </div>
                            </div>

                            {/* CALENDARIO */}

                            {
                                availableYearMonth.map((yearMonth) => {
                                    return yearMonth.months.map((month) => {
                                        const firstDate = new Date(yearMonth.year, month, (new Date().getDay() + 2));
                                        return (
                                            <Typography component="div" key={month}>
                                                <Calendar
                                                    key={month}
                                                    firstDate={firstDate}
                                                    diasCortesia={diasVoluntaria ? diasVoluntaria.dias : []}
                                                    diasCortesiaRef={diasVoluntariaRef ? diasVoluntariaRef : []}
                                                    limiteDiasSeleccionables={1}
                                                    isActivatedReturnDiaDeseleccionado={true}
                                                    isDisabledOnClick={isDisabledOnClickCalendar}
                                                    isSmall={true}
                                                    setDiaSeleccionado={handleDiaCalendarioSeleccionadoPlanPersonalizado}
                                                />
                                            </Typography>
                                        );
                                    });
                                })
                            }
                        </div>
                    </Card>
                }
            </div>

            {/* PANEL LATERAL - RECIBO SELECCIONADO */}

            <div className={classes.drawer}>
                <Hidden smDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        anchor="right"
                        open>
                        <div className={classes.p20}>
                            <Typography variant="h6" className={classes.m0}>
                                <Term component="CarteroVirtual" text="seleccionado" />
                            </Typography>

                            {/* INFORMACIÓN RECIBO */}

                            <div className={classes.containerDetalleRecibo}>
                                <Typography className={[classes.bold, classes.fontS].join(' ')}>{`${recibo.organismo.nombre} | ${recibo.ejercicio}`}</Typography>
                                <Typography className={[classes.bold, classes.fontS].join(' ')}>{`${recibo.concepto.nombre}`}</Typography>
                                <Typography className={[classes.bold, classes.fontS].join(' ')}>{`${recibo.referencia}`}</Typography>
                            </div>

                            <div className={classes.containerDetalleReciboDomiciliacion}>
                                <Typography className={classes.fontS}>{`${recibo.direccionTributaria}`}</Typography>
                                {
                                    recibo.fechaInicio && recibo.fechaFin &&
                                    <Typography className={classes.fontS}>{`del ${dateFormat(recibo.fechaInicio)} al ${dateFormat(recibo.fechaFin)}`}</Typography>
                                }
                            </div>

                            {/* DATOS BANCARIOS */}

                            <Typography variant="h6" className={classes.mB0}>
                                <Term component="CarteroVirtual" text="datos_bancarios" />
                            </Typography>

                            <div className={classes.containerDetalleReciboDomiciliacion}>
                                <Typography className={classes.fontM}>{`${recibo.domiciliacion.iban}`}</Typography>
                                <Typography className={classes.fontM}>{`${recibo.domiciliacion.nombreTitular}`}</Typography>
                                <Typography className={classes.fontM}>{`${recibo.domiciliacion.nifTitular}`}</Typography>
                            </div>

                            {/* IMPORTE TOTAL */}

                            <div className={classes.containerDetalleReciboDomiciliacion}>
                                <Typography className={[classes.mB0, classes.blueSEDE, classes.bold, classes.fontL].join(' ')}>
                                    <Term component="CarteroVirtual" text='total_a_pagar' />
                                </Typography>
                                <Typography className={classes.bold}>{`${recibo.desglose.pendiente.toString()} €`}</Typography>
                            </div>

                            {/* BONIFICACIÓN */}

                            {porcentajeBonificacion > 0 &&
                                <div className={classes.containerDetalleReciboDomiciliacion}>
                                    <Typography className={[classes.mB0, classes.blueSEDE, classes.bold, classes.fontL].join(' ')}>
                                        <Term component="CarteroVirtual" text='bonificacion' />
                                    </Typography>
                                    <Typography className={classes.bold}>{`${porcentajeBonificacion.toString()} %`}</Typography>
                                </div>
                            }

                            {/* IMPORTES SELECCIONADOS */}

                            {isVisibleCalendars &&
                                <div className={classes.containerDetalleReciboDomiciliacion}>
                                    {arrayPeriocidadImportes.sort( (a,b) => a.fecha < b.fecha ? -1 :1).map((item, index) => {
                                        return (
                                            <div key={`_${index}`} className={classes.containerPeriocidadEImportes}>
                                                <div className={classes.childContainerPeriocidadEImportes}>
                                                    <span className={classes.spanPeriocidadEImportes} />
                                                    <div>
                                                        <Typography className={[classes.fontM, classes.bold].join(' ')}>{dateTransformIntoRedeableFormat(item.fecha, lang)}</Typography>
                                                        <Typography style={{ fontSize: 14 }}>{`Importe ${item.importe}€`}</Typography>
                                                    </div>
                                                </div>
                                                {planSeleccionado === "personalizado" &&
                                                    <IconButton color='grey' icon="trash-outline" onClick={() => handleCancelarDiaCalendarioeleccionada(index)} />
                                                }
                                            </div>
                                        )
                                    })}
                                </div>
                            }
                        </div>
                    </Drawer>
                </Hidden>
            </div>

            {/* CARRITO */}

            <Grid container className={[classes.rootWidth100,
            arrayPeriocidadImportes.length > 0 && isVisibleCalendars
                ? classes.display
                : ''
            ].join(' ')}>
                <Grid item xs={10} className={classes.payContainerCarrito}>
                    <div className={classes.wrapper}>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.buttonPagoACuenta}
                            disabled={arrayPeriocidadImportes.length < 1}
                            onClick={handleNavigateToPagoACuentaConfirmacion}
                        >
                            <Term component="Global" text="Continuar" />
                        </Button>
                    </div>
                </Grid>
            </Grid>

            {/* DIALOGS */}

            <DialogSeleccionarImportePorPeriodo
                isOpen={isOpenDialogSeleccionarImportePorPeriodo}
                date={fechaSeleccionada}
                importeTotal={importePendiente}

                onContinuar={handleAddImportePersonalizado}
                onClose={handleCancelarDialogSeleccionarImportePorPeriodo}
            />
        </>
    );
}

export default PagoACuentaDomiciliado;
import { useEffect, FC, useState, useMemo, useContext } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import usePage from 'hooks/page.hook';
import { LiteralsContext, withLiterals } from 'containers/shared/literals';
import IoC from 'contexts/ioc.context';

import { makeStyles } from '@material-ui/core/styles';
import styles from './tributos-autonomicos.styles';
import { Card, Grid, CardContent, CircularProgress, Typography, Dialog, DialogContent, CardHeader, Button, Tooltip } from '@material-ui/core';
import IconAtomic from 'atomic/atoms/icon'
import Icon from '@mdi/react';
import { mdiAlphaACircleOutline, mdiFileDocumentMultipleOutline, mdiAccountBox } from '@mdi/js';
import GetAppIcon from '@material-ui/icons/GetApp';
import PaymentsIcon from '@material-ui/icons/Payment';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import ModelGateway from '../../gateways/model.new.gateway';
import { IModelo, ITipoModelo } from 'gateways/model.new.interface';
import { AlertsContext } from 'contexts/alerts.context';
import moment from 'moment';
import Term from 'components/term';
import { handleCopyRef } from './utils';
import { translate } from 'utils/i18n';
import { download } from 'utils/download';
import { TributosAutonomicosGateway } from 'gateways/tributos.autonomicos.gateway';
import { PAY_CERTIFICADO_PERMITIDO, PAY_CERTIFICADO_PROHIBIDO, PAY_LOGGED, PAY_NO_LOGGED, AVISOS_MODELOS, Type_AvisosModelo } from './constants';
import { ContenidosGateway } from 'gateways/contenido.gateway';
import { PaymentGateway } from 'gateways/payment.gateway';
import { formatNumber } from 'utils/number';



const useStyles = makeStyles(styles);

interface IParams {
    idTipoModelo: string,
    idModelo: string,
    textTipoTributo: string,
    action: string, // ==> action: nuevo| string -> string === referencia
};


const ModelosAction: FC<RouteComponentProps<IParams>> = ({ match, history }) => {

    const [pageState, pageDispatcher] = usePage();
    const [, alertsDispatch] = useContext(AlertsContext)
    const classes = useStyles()
    const terms = useContext(LiteralsContext);
    //Gatways
    const ioc = useContext(IoC);
    const modelGateway: ModelGateway = useMemo(() => ioc.get(ModelGateway), [ioc]);
    const paymentGateway: PaymentGateway = useMemo(() => ioc.get(PaymentGateway), [ioc]);
    const tributosAutonomicosGateway: TributosAutonomicosGateway = useMemo(() => ioc.get(TributosAutonomicosGateway), [ioc]);
    const contenidosGateway = useMemo(() => ioc.get(ContenidosGateway), [ioc]);

    const [ref, setRef] = useState<string | undefined>(undefined)
    const [tipoModeloInfo, setTipoModeloInfo] = useState<ITipoModelo | null>(null);
    const [data, setData] = useState<IModelo | null>(null)
    const [loading, setLoading] = useState(false)

    //Recuperamos el listado de modelos que podemos pagar según el modo de acceso del usuario 
    //TODO NEUS - Revisar que funciona al añadir otros modelos !== 046 - Solo aplica a Tributos Autonómicos
    const [PAY_Options, setPAY_Options] = useState<string[]>([])
    const [PAY_Prohibido_Options, setPAY_Prohibido_Options] = useState<string[]>([])
    const [avisosModelos, setAvisosModelo] = useState<Type_AvisosModelo[]>([]);

    const handlePrint = async () => {
        setLoading(true);
        let result = {} as Blob;
        if (data) {
            if (data.idTipoModelo === '090') {
                result = await paymentGateway.getJustificanteModeloPago(data.idModelo);
            } else {
                //Resto de modelos (incl. pago ficticio)
                result = await tributosAutonomicosGateway.print(
                    data.idReferencia,
                    data.idTipoModelo,
                    data.idModelo
                );
                
            }        
        }
        download(result, alertsDispatch,
            translate('PasarelaPago', 'No es posible descargar el justificante', terms),
            translate('Global', 'BloqueoPantalla', terms));
        setLoading(false);
    };

    const handleValidate = async () => {
        try {
            setLoading(true)
            const response = await modelGateway.confirmarPagoFicticio(match.params.action, translate('Tributos', 'confirmarPagoFicticioError', terms))
            if (response.textoError) {
                throw new Error(translate('Tributos', response.textoError, terms));
            }
            setLoading(false)
            history.push(`/pasarela-pago/vueltaEntidad/${response.token}`)
        } catch (error) {
            //console.log('ERROR -- confirmarPagoFicticio ', error)
            const result = (error as Error).message;
            setLoading(false)
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message: result,
                    variant: 'error',
                }
            });
        }
    }

    const handleValidateAndPresent = async () => {
        try {
            setLoading(true)
            const redirect = data
                ? data.idEstado === 'FIN' ? true : false
                : false

            if (redirect) {
                setLoading(false)
                history.push(`/tributos/presentaciones`)
            } else {
                const response = data ? await modelGateway.updateModeloState(data.idModelo, 'FIN', translate('Tributos', 'UpdateModeloStateError', terms)) : false
                if (response === false) {
                    throw new Error(translate('Tributos', 'confirmarPagoFicticioError', terms));
                }
                const d = await modelGateway.getModeloById(match.params.idModelo, translate('Tributos', 'GetModeloByIdError', terms))
                setData(d)
                setLoading(false)
            }


        } catch (error) {
            //console.log('ERROR -- confirmarPagoFicticio ', error)
            const result = (error as Error).message;
            setLoading(false)
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message: result,
                    variant: 'error',
                }
            });
        }
    }

    const handlePay = async (idReferencia: string) => {
        try {
            setLoading(true)
            const token = await modelGateway.getTokenPago(idReferencia, translate('Tributos', 'GetTokenPagoError', terms));
            setLoading(false)
            history.push(`/pasarela-pago/pago/${token}`/*, {fromTributos: true}*/)
        } catch (error) {
            //console.log('ERROR -- getTokenPago ', error)
            const result = (error as Error).message;
            setLoading(false)
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message: result,
                    variant: 'error',
                }
            });
        }
    }

    useEffect(() => {
        let numModelo;
        switch (match.params.idTipoModelo) {
            case '621':
                numModelo='620'
                break;
            case '047':
                numModelo = '043-E'
                break;
            default:
                numModelo = match.params.idTipoModelo
                break;
        }
        pageDispatcher({
            type: 'setHeader',
            header: {
                icon: mdiAlphaACircleOutline,
                title: `${translate('Tributos', 'Modelo', terms)} ${numModelo}: ${tipoModeloInfo?.valor ? tipoModeloInfo.valor : ''}`,
                text: <div>
                    {match.params.action ?
                        <div style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                            <Tooltip title={translate('Tributos', 'copyRef', terms)}>
                                <Button onClick={() => handleCopyRef(match.params.action)} className={classes.buttonCopyRef}>
                                    <Icon path={mdiFileDocumentMultipleOutline} size={1} className={classes.iconCopy}></Icon>
                                </Button>
                            </Tooltip>
                            <Typography variant='body1'><Term text="Referencia" /></Typography>
                            <Typography variant='body1' style={{ marginRight: 5 }}>:</Typography>
                            <span>{match.params.action}</span>

                        </div>
                        : null}
                </div>
                //moreInfoTemplate: 'mas_info_',
            },
            menu: true,
        });
    }, [pageDispatcher, match.params.idTipoModelo, tipoModeloInfo]);

    useEffect(() => {
        (async () => {
            try {
                setLoading(true)
                const modelInfo = await modelGateway.getModeloInfo(match.params.idTipoModelo, translate('Tributos', 'GetModeloInfoError', terms))
                setTipoModeloInfo(modelInfo)
                //console.log('modelInfo', modelInfo)
                setRef(match.params.action)
                const d = await modelGateway.getModeloById(match.params.idModelo, translate('Tributos', 'GetModeloByIdError', terms))
                setData(d)

                // Gestión Pagar según acceso:  -------------------------------------------------------------
                let optionsPay: string[] = [];
                let optionsProhibidoPay: string[] = [];
                optionsPay = /** use Clave */
                    pageState.jwp !== null && pageState.jwp.level === 1
                        ? PAY_LOGGED.PERMITIDO
                        : pageState.jwp !== null && pageState.jwp.level >= 2 /** use certificado */
                            ? PAY_CERTIFICADO_PERMITIDO
                            : PAY_NO_LOGGED.PERMITIDO;
                optionsProhibidoPay = /** use Clave */
                    pageState.jwp !== null && pageState.jwp.level === 1
                        ? PAY_LOGGED.PROHIBIDO
                        : pageState.jwp !== null && pageState.jwp.level >= 2 /** use certificado */
                            ? PAY_CERTIFICADO_PROHIBIDO
                            : PAY_NO_LOGGED.PROHIBIDO;
                setPAY_Options(optionsPay);
                setPAY_Prohibido_Options(optionsProhibidoPay);

                //console.log('optionsPay', optionsPay)
                //console.log('optionsProhibidoPay', optionsProhibidoPay)

                //-------------------------------------------------------------------------------------------
                setLoading(false)
            } catch (error) {
                //console.log('ERROR -- getCampoApartado ', error)
                const result = (error as Error).message;
                setLoading(false)
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: result,
                        variant: 'error',
                    }
                });
            }
        })();
    }, [match.params.idTipoModelo, match.params.action, match.params.idModelo])

    useEffect(() => {
        console.log('data ', data)
        if (!data) return;

        const avisosModelos = AVISOS_MODELOS.filter(aviso => aviso.idModelo === data.idTipoModelo);

        avisosModelos.forEach(async (aviso) => {
            const contenido = await contenidosGateway.getContent(aviso.idTextoAviso, {});
            setAvisosModelo(avisos => [...avisos, { ...aviso, textoAviso: contenido }]);
        });
    }, [data]);
    console.log('data ', data)

    return (
        <div className={classes.container}>
            <Card className={classes.cardContainerMargin}>
                <CardHeader
                    title={translate('Tributos', 'TributosResumenPagoTitle', terms)}
                    subheader={
                        data?.idEstado === 'FIN'
                            ? translate('Tributos', 'ModeloFinalizado', terms)
                            : (data && !PAY_Options.includes(data.idTipoModelo))
                                ? !PAY_Prohibido_Options.includes(data.idTipoModelo) ? translate('Tributos', 'pay-certificado', terms) : translate('Tributos', 'pay-prohibido', terms)
                                : undefined}
                />
                <CardContent>
                    {data &&
                        <Grid container direction='column' spacing={5} alignItems='center'>
                            <Grid item style={{ width: '100%', display: 'inline-flex', justifyContent: 'space-between' }}>
                                <div>
                                    <div style={{ display: 'inline-flex' }}>
                                        <Typography style={{ marginRight: 10 }}>{`${translate('Tributos', 'TributosResumenPagoLocalizador', terms)}: `}</Typography>
                                        <Typography className={classes.boldText}>{data.idModelo}</Typography>
                                    </div>
                                    <div style={{ display: 'inline-flex', padding: 10 }}>
                                        <Typography style={{ marginRight: 10 }}>{`${translate('Tributos', 'TributosResumenPagoFechaCreacion', terms)}: `}</Typography>
                                        <Typography className={classes.boldText}>{moment.utc(data.fechaCreacion).format('DD/MM/YYYY')}</Typography>
                                    </div>
                                    <div style={{ display: 'inline-flex', padding: 10 }}>
                                        <Typography style={{ marginRight: 10 }}>{`${translate('Tributos', 'TributosResumenPagoNif', terms)}: `}</Typography>
                                        <Typography className={classes.boldText}>{data.nifSujetoPasivo}</Typography>
                                    </div>
                                </div>
                                <div style={{ display: 'inline-flex', padding: 10 }}>
                                    <Typography style={{ marginRight: 10 }}>{`${translate('Tributos', 'TributosResumenPagoImporte', terms)}: `}</Typography>
                                    <Typography className={classes.boldText}>
                                        {formatNumber(data.importeAIngresar, {minDecimalLength: 2,maxDecimalLength: 2})} €
                                    </Typography>
                                </div>
                            </Grid>
                            <Grid item className={classes.rowAlignRight}>

                                {/** TODO Mjoras - Ajustar con flags en TIPO_MODELO, sustituir idTipoModleo==='890' */}

                                {(data.idEstado === 'FIN') &&
                                    <Button
                                        style={{ marginLeft: 10 }}
                                        variant='contained'
                                        onClick={handlePrint}
                                        startIcon={<GetAppIcon />}
                                    >
                                        {translate('Tributos', 'btnDownload', terms)}
                                    </Button>
                                }

                                {data.idTipoModelo === '890' && data.idEstado !== 'FIN' &&
                                    <Button
                                        style={{ marginLeft: 10 }}
                                        color='primary'
                                        variant='contained'
                                        onClick={handleValidateAndPresent}
                                        //disabled={data.idEstado === 'FIN'}
                                        endIcon={data.idEstado === 'FIN' ? <Icon path={mdiAccountBox} color='white' size={1} /> : <ThumbUpAltIcon />}
                                    >
                                        {data.idEstado === 'FIN' ? translate('Tributos', 'btnPresent', terms) : translate('Tributos', 'btnValidate', terms)}
                                    </Button>
                                }
                                {data.idTipoModelo !== '890' && data.importeAIngresar === 0 && // match.params.textTipoTributo === 'autonomicos' &&
                                    <Button
                                        style={{ marginLeft: 10 }}
                                        color='primary'
                                        variant='contained'
                                        onClick={handleValidate}
                                        disabled={data.idEstado === 'FIN'}
                                        endIcon={<ThumbUpAltIcon />}
                                    >
                                        {translate('Tributos', 'btnValidate', terms)}
                                    </Button>
                                }
                                {
                                    data.importeAIngresar > 0 && data.idReferencia &&
                                    <Button
                                        style={{ marginLeft: 10 }}
                                        color='primary'
                                        variant='contained'
                                        onClick={() => handlePay(data.idReferencia)}
                                        disabled={data.idEstado === 'FIN'}
                                        endIcon={
                                            PAY_Options.includes(data.idTipoModelo)
                                                ? <PaymentsIcon />
                                                : <IconAtomic name={'pay-certificado'} color='white' />
                                        }
                                    >
                                        {translate('Tributos', 'btnPay', terms)}
                                    </Button>
                                }
                            </Grid>

                            {avisosModelos.length > 0 &&
                                <Grid item style={{ width: '100%' }}>
                                    <div className={classes.containerAvisoModelo}>
                                        {avisosModelos.map((avisoModelo, i) => (
                                            avisoModelo.textoAviso && avisoModelo.textoAviso.length > 0 && 
                                            <div key={`avisosModelo${avisoModelo.idTextoAviso}-${i}`} dangerouslySetInnerHTML={{ __html: avisoModelo.textoAviso[0].contenido }} 
                                            />
                                        ))}
                                    </div>
                                </Grid>
                            }
                        </Grid>
                    }
                </CardContent>
            </Card>

            <Dialog open={loading}>
                <DialogContent>
                    <CircularProgress size={35} />
                </DialogContent>
            </Dialog>
        </div>
    );
}

export default withRouter(withLiterals(['Tributos'])(ModelosAction));


import { Reducer, ReactNode } from "react";

export interface IAlertsState {
    alert: IAlertWithOpen | null;
}

export interface IAlert {
    message: string | ReactNode;
    variant: 'success' | 'warning' | 'error' | 'info';
    autoHideDuration?: number;
    isHtml?: boolean;
    hideButtonAction?: boolean;
    hasCustomAction?: boolean;
    actionTemplateName?: string;
    handleCustomAction?: () => void;
    defaultCloseActionTemplateName?: string;
    handlePreCloseCustomAction?: () => void;
    size?: "sm" | "xs" | "md" | "lg" | "xl" 
}

export interface IAlertWithOpen extends IAlert {
    open: boolean;
}

export type AlertsAction =
    | { type: 'hide-alert' }
    | { type: 'show-alert', payload: IAlert }

const AlertsReducer: Reducer<IAlertsState, AlertsAction> = (state, action) => {
    switch (action.type) {
        case 'show-alert': return { ...state, alert: { ...action.payload, open: true } };
        case 'hide-alert': return { ...state, alert: { ...state.alert as any, open: false } };
        default: throw new Error('Unexpected action');
    }
};

export default AlertsReducer;
import { Button, Grid } from "@material-ui/core";
import { mdiFileImportOutline } from "@mdi/js";
import Icon from "@mdi/react";
import GenericTableWizard from "./table-wizard";
import GenericTableWizardResult from "./table-wizard-result";
import WizardImport, { DEFAULT_ALLOWED_FORMAT } from "./wizard";

import { ALL_LITERALS, LiteralsContext, withLiterals } from "containers/shared/literals";
import IoC from "contexts/ioc.context";
import { ImporterGateway } from "gateways/importer.gateway";
import { IGenericResult, IGenericResultExtend, IMessageResponse, IResult } from "gateways/importer.interfaces";
import usePage from "hooks/page.hook";
import React, { FC, useContext, useEffect, useMemo, useState } from "react";
import { translate } from "utils/i18n";
import { getExtension } from "./utils";

export type TImporterType = 'm071'
export type TButtonImport = {
    withActionOpenWizard: true; openWizard?: never, closeWizard?: never
} | { withActionOpenWizard?: false; openWizard: boolean, closeWizard: () => void
}
export type TButtonPrint = {
    withActionPrint?: false; actionHandlePrint?: never
} | { withActionPrint: true; actionHandlePrint: () => Promise<boolean>
}
export interface IProps<P> {
    importType: TImporterType,
    importDocFormat: string[]
    title: string,
    saveDocImported: boolean // requiere guardado en DOC del docuemnto importado
    actionHandleImport: (dataToImport: P[], idDocumento?:string) => Promise<{ok: boolean, msgErr?: string, numElImported: number, result: IGenericResult<P>[]}>;
    hiddenCells_dataImport?: (keyof P)[]
    hiddenCells_resultImport?: (keyof IGenericResult<P>)[]
    renderTableListadoResultados?: () => JSX.Element
} 

export type IPropsImport<P> = IProps<P> & TButtonImport & TButtonPrint


const GenericImporter = <T,>({ 
    importType, title, saveDocImported, actionHandleImport, withActionPrint, actionHandlePrint, 
    withActionOpenWizard = true, openWizard = undefined, closeWizard = undefined,
    importDocFormat = DEFAULT_ALLOWED_FORMAT, hiddenCells_dataImport, hiddenCells_resultImport,
    renderTableListadoResultados=undefined
}: IPropsImport<T>) => { 
    // Gateways 
    const ioc = useContext(IoC);
    const importerG = useMemo(() => ioc.get(ImporterGateway) as ImporterGateway, [ioc]);
    const terms = useContext(LiteralsContext);
    const [, pageDispatcher] = usePage();
    //Wizard
    //const [loading, setLoading] = useState<boolean>(false);
    const [open, setOpen] = useState(false);
    const [dataToImport, setDataToImport] = useState<T[] | null>(null); // resultado procesado del fichero adj.
    const [resultData, setResultData] = useState<IGenericResult<T>[]>([]); // Resultado importacion
    const [errorsFile, setErrorsFile] = React.useState<IMessageResponse[]>([]);
    const [errorData, setErrorData] = React.useState<string>("");
    const [loadingUpload, setLoadingUpload] = useState(false);
    const [hasErrors, setHasErrors] = useState(false);
    const [file, setFile] = useState<File | null>(null);
    const [numElementsImported, setNumElementsImported] = useState(1);
    const [importDisabled, setImportDisabled] = useState(false);
    //const [listaModelos, setListaModelos] = useState<any[]>();

    //Wizard
    const handlePrevious = () => {
        //console.log('handlePrevious ', file, dataToImport)
        if(!file){
            setErrorsFile([]);
            setDataToImport(null);
        }
    }

    const handleNew =() => {
        setErrorsFile([]);
        setDataToImport(null);
        setHasErrors(false);
        setFile(null);
        setNumElementsImported(1);
    }

    const handleClose = () => {
        console.log('handle close ', withActionOpenWizard)
        setErrorsFile([]);
        setDataToImport(null);
        if(withActionOpenWizard === false && closeWizard){
            closeWizard()
        } else{
            setOpen(false);
        }
        setHasErrors(false);
        setFile(null);
        setNumElementsImported(1);
    } 

    const handleUpload = async (file: File) => {
        try {
            setLoadingUpload(true);
            setFile(file);
            // console.log('file', file)
            const format = file.name.split(".").slice(-1).pop()?.toUpperCase()
            
            const result = format === 'XML' 
                ? await importerG.importXML(file, importType)
                : await importerG.importXLSX(file, importType);

            console.log('handleUpload result', result)

            if (result && result.data && result.data.length > 0) {
                setDataToImport(result.data);
                setResultData([]);
                const resultFilter=(result.data as IGenericResultExtend<T>[]).filter(
                    (item) => !item.errors || item.errors.length === 0
                )  
                setResultData(
                    resultFilter.map((item: IGenericResultExtend<T>)=> {
                        const {errors, ...rest} = item;
                        return rest as IGenericResult<T>
                    })
                );
                setHasErrors((result.data as IGenericResultExtend<T>[]).some(item => item.errors && item.errors.length > 0));
                setErrorData("");
            } else if (result && result.errors.length > 0) {
                setErrorsFile(result.errors);
            }
        } catch (error) {
            // console.log('error ', (error as any).message)
            pageDispatcher({
                type: 'show-notification',
                payload: {
                    message: (error as any).message,
                    variant: 'error',
                }
            });
        } finally {
            setLoadingUpload(false);
        }
    }

    const handlePrint = async() => {
        // imprime el listado de datos que corresponda
        const done = actionHandlePrint && await actionHandlePrint()
        setOpen(!done)
    }

    const handleImport = async () => {
        try {
            //setLoading(true)
            if (!dataToImport || !file) {
                return;
            }
            let idDocumento= undefined;
            if(saveDocImported){
                // Guadar el doc
                idDocumento = await importerG.saveDocImporter(file,importType,getExtension(importType));
                if(!idDocumento){ 
                    throw new Error( translate('GenericComponents','error_importar_datos_idDoc',terms))
                }
            }
            
            const importResult = await actionHandleImport(resultData, idDocumento)
            console.log('importResult', importResult)
            if(!importResult.ok){
                throw new Error(importResult.msgErr ?? translate('GenericComponents','error_importar_datos',terms))
            }

            setNumElementsImported(importResult.numElImported)
            setResultData(importResult.result)
            
        } catch (error) {
            //console.log('error ', (error as any).message)
            pageDispatcher({
                type: 'show-notification',
                payload: {
                    message: (error as any).message,
                    variant: 'error',
                }
            });
            setNumElementsImported(0);
            setResultData(curr => curr.map(c => ({...c, result: false})))
        } finally {
            setImportDisabled(false);
            //setLoading(false)
        }
    }

    // useEffect(() =>{
    //     openWizard !== undefined && setOpen(openWizard);
    // }, [openWizard])

    return (
        <Grid container direction="column" >
            {withActionOpenWizard && 
                <Grid item>
                    <Button 
                        variant="outlined"
                        color="primary"
                        onClick={() => setOpen(true)} 
                        startIcon={<Icon size={1} path={mdiFileImportOutline}/>}
                    >
                        {translate("Tributos",'ImportarXML', terms)}
                    </Button>
                </Grid>
            }

           {renderTableListadoResultados && 
                <Grid item style={{marginTop: 10, marginBottom: 10}}>
                    {renderTableListadoResultados()}
                </Grid> 
            }
            <WizardImport
                open={ withActionOpenWizard ? open : openWizard}
                title={title}
                showIcon={true}
                file={file}
                dataElement={dataToImport 
                    ? <GenericTableWizard<T> data={dataToImport} hiddenCells={hiddenCells_dataImport ?? []} /> 
                    : null
                }
                dataElementResult={resultData 
                    ? <GenericTableWizardResult<T> data={resultData} numElementsImported={numElementsImported} hiddenCells={hiddenCells_resultImport ?? []}  /> 
                    : null
                }
                disabledStep1={(errorsFile && errorsFile.length > 0) || !dataToImport}
                loadingUpload={loadingUpload}
                hasErrors={hasErrors}
                importDisabled={importDisabled}
                errorsFile={errorsFile}
                errorData={errorData}
                onUpload={handleUpload}
                onSubmit={handleImport}
                onPrevious={handlePrevious}
                onNew={handleNew}
                onClose={handleClose}
                withActionPrint={withActionPrint}
                onPrintJustificante={handlePrint} 
                allowedFormat={importDocFormat}
            />
        </Grid>
    )
}

export default withLiterals(ALL_LITERALS)(GenericImporter) as <T>(props: IPropsImport<T>) => JSX.Element;



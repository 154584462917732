import { FC, useContext, useMemo, useState, useEffect } from 'react';
import { ICampo } from '../../../gateways/model.new.interface';
import {  CircularProgress, Dialog, DialogContent, Grid, GridSize, IconButton} from '@material-ui/core';
import { Typography, Button, makeStyles } from '@material-ui/core';
import styles from './styles';


import { calculateValueCampo, extraerValorXML, formatXMLMultipletoObject, formatXMLtoObject, GenericInitialObject, GenericObject, getEmptyObject, getInitialObject, getKeysRequiredCampos, GroupLabel, renderCampos, SelectoresInfo } from '../utils';

import IoC from 'contexts/ioc.context';
import ModelGateway from 'gateways/model.new.gateway';
import { translate } from 'utils/i18n';
import { AlertsContext } from 'contexts/alerts.context';
import { LiteralsContext, withLiterals } from 'containers/shared/literals';
import AddIcon from '@material-ui/icons/Add'
import { cloneDeep } from 'lodash';
import DeleteIcon  from '@material-ui/icons/Delete';
import moment from 'moment';

const useStyles = makeStyles(styles);

interface Props {
    idTipoModelo: string,
    idTipoApartado: string,
    datosXml: string | null,
    datosXmlApartadosPrevios: GenericObject,
    setFinished: (value: boolean) => void,
    handleSave: (data: GenericInitialObject[]) => Promise<boolean>,
}

const ApartadoMultiple: FC<Props> = (props) => {
    const {
        idTipoModelo,
        idTipoApartado,
        datosXml,
        datosXmlApartadosPrevios,
        setFinished,
        handleSave
    } = props;
    
    const classes = useStyles();
    const [, alertsDispatch] = useContext(AlertsContext);
    const terms = useContext(LiteralsContext);
    //Gateways
    const ioc = useContext(IoC);
    const modelGateway: ModelGateway = useMemo(() => ioc.get(ModelGateway), [ioc]);
    //Local data
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<GenericInitialObject|null>(null);
    const [changes,setChanges]= useState<boolean|null>(null);

    const [campos, setCampos] = useState<ICampo[] | null>(null);
    const [EMPTY,setEmpty] = useState<GenericInitialObject|null>(null);
    const [camposRequiredKeys, setCamposRequiredKeys] = useState<string[]| null>(null);

    const [dataList, setDataList] = useState<GenericInitialObject[]>([]);

    const [infoSelect, setInfoSelect]= useState<SelectoresInfo | null>({
        residuotipoExcepcion: [ 
            {id: 'EA', nombre: translate('Tributos', 'residuoExencion_EA', terms)},
            {id: 'EB', nombre: translate('Tributos', 'residuoExencion_EB', terms)},
            {id: 'EC', nombre: translate('Tributos', 'residuoExencion_EC', terms)},
            {id: 'ED', nombre: translate('Tributos', 'residuoExencion_ED', terms)},
            {id: 'EE', nombre: translate('Tributos', 'residuoExencion_EE', terms)},
            {id: 'EF', nombre: translate('Tributos', 'residuoExencion_EF', terms)},
            {id: 'EG', nombre: translate('Tributos', 'residuoExencion_EG', terms)},
        ],
    })
    // Apartados múltiples - Requieren 1 item
    const required1Element: {[m: string]: boolean} = {
        '890': true,
        '593': false,
        '594': false,
    }
    // Apartados múltiples - Modelo actual - Requiere 1 item
    const dynamicRequired1Element = useMemo(() => {
        if(idTipoApartado === '666_L'){
            // Mínimo 1 item si el check Casilla 668 está activo
            const val = extraerValorXML('666_K.668',Object.values(datosXmlApartadosPrevios).join(''))
            //console.log('dynamicRequired1Element ', typeof val)
            return val ==='true' 
        }
        return false
    },[idTipoApartado,datosXmlApartadosPrevios]);

    // GrisSize de la tabla de datos - Generico apartado campos Multiples
    const XS: {[m: string]: {[x: string]: GridSize}} = {
        '890': { 'nif':2,'ApeNomDenS':4, 'cpSiglas':1, 'importeReducido':2} ,
        '593': { 'nifDeposit':2,'NomDeposit':4, 'residuCant':2, 'tipoExcep':2 },
        '594': { 'nifDeposit':2,'ApeNomDenS':4, 'residuCant':2, 'tipoExcep':2 },
        '666': idTipoApartado === '666_L'
                ? { 'numAbonado':1,'nifContribuyente':1,'nombreContrib':2,'numRecibo':1, 'fechaPretenCobro':2, 'impCanonSaneam':2}
                : { 'numAbonado':1,'nifContribuyente':1,'nombreContrib':2,'numRecibo':1, 'fechaPretenCobro':2, 'fechaCobro':2 ,'impCanonSaneam':2 }
    }
    // Functions - Get data ------------------------------------------------------------------------------------------------------
    const updateData= (name:string, value: any) => {
        let newData=null;
        if(EMPTY){
            newData= data 
            ? {...data, [name]: {...data[name], value: value }}
            : {...EMPTY,[name]: {...EMPTY[name], value: value }}

        }
        setData(newData)
        setChanges(true)
    }

    const onAdd = () => { // Auto-Save when ADD item
        (async()=> {
            setLoading(true);
            // comprobar campos required 
            let valid= true;
            let esAnterior = true;
            if(camposRequiredKeys && camposRequiredKeys.length>0 ){
                if(data){
                    camposRequiredKeys.forEach( element => {
                        //console.log('element', element)
                        switch (data[element].tipo) {
                            case 'number':
                                if(Number(data[element].value) === 0){
                                    valid=false
                                }
                                break;
                                case 'decimal':
                                case 'decimal2':
                                case 'decimal3':
                                if(parseFloat(data[element].value) === 0){
                                    valid=false
                                }
                                break;
                            default:
                                if(!data[element].value || data[element].value === undefined || data[element].value === ""){
                                    valid=false
                                }
                                break;
                        }
                    })
                } else {
                    valid = false;
                }
            }
            if (valid) {
                valid = campos?.findIndex(campo => campo.isValid != undefined && !campo.isValid && campo.visible) == -1;
            }
            //console.log('valid', valid);
            
            if(idTipoApartado === '666_L'){
                const campoFecha602 = campos?.find(c => c.casilla === 602)
                if(campoFecha602 && data){
                    const fecha = data[campoFecha602.codigo].value
                    //console.log('666_L - fecha ', fecha)
                    esAnterior = moment(fecha, 'DD/MM/YYYY').startOf('day').isBefore(moment(), 'day');
                    //console.log('esAnterior', esAnterior)
                }
                valid = valid && esAnterior
            }
            if(idTipoApartado === '666_M'){
                const campoFecha608 = campos?.find(c => c.casilla === 608)
                const campoFecha609 = campos?.find(c => c.casilla === 609)
                if(campoFecha608 && campoFecha609 && data){
                    const fecha608 = data[campoFecha608.codigo].value
                    const fecha609 = data[campoFecha609.codigo].value
                    const esAnterior1 = moment(fecha608, 'DD/MM/YYYY').startOf('day').isBefore(moment(), 'day');
                    const esAnterior2 = moment(fecha609, 'DD/MM/YYYY').startOf('day').isBefore(moment(), 'day');
                    esAnterior= esAnterior1 && esAnterior2
                    //console.log('esAnterior', esAnterior)
                }
                valid = valid && esAnterior
            }

            if(valid){
                const newDataList= cloneDeep(dataList);
                data && newDataList.push(data);
                setData(EMPTY);
                //console.log('call handleSave -- ', newDataList)
                const saved = await handleSave(newDataList); 
                setChanges(!saved);
                //setDataList(newDataList)
                //setChanges(false);
            } else {
                if(!esAnterior){
                    alertsDispatch({
                        type: 'show-alert',
                        payload: {
                            message: idTipoApartado === '666_L' 
                                ?translate('Tributos','errorFechaPretenCobro', terms)
                                :translate('Tributos','errorFechaPretenCobro2', terms),
                            variant: 'error',
                        }
                    });
                } else {
                    alertsDispatch({
                        type: 'show-alert',
                        payload: {
                            message: translate('Tributos','FormularioError', terms),
                            variant: 'error',
                        }
                    });   
                }
                setChanges(true);
            }

            setLoading(false);
        })();
    }

    const onDelete = async (index: number ) => {
        setLoading(true)
        const newDataList= cloneDeep(dataList)
        newDataList.splice(index,1)
        const saved = await handleSave(newDataList); 
        setChanges(!saved);
        //setDataList(newDataList)
        //setChanges(true)
        setLoading(false)
    }

    // const onSave = ()=> { 
    //     (async() => {
    //         setLoading(true);
    //         //console.log('onSave', data)
    //         const saved = await handleSave(dataList); 
    //         setChanges(!saved);
    //         setLoading(false);
    //     })();
    // }

    // ---------------------------------------------------------------------------------------------------------------------------
    useEffect(() => {
        (async()=> {
            try {
                setLoading(true)
                const camposApartado = await modelGateway.getCamposApartado(idTipoApartado, idTipoModelo, translate('Tributos', 'GetCamposApartadoError', terms))
                console.log('camposApartado', camposApartado)
                

                setCampos(camposApartado);
                let emptyObjt = null;
                let newData: GenericInitialObject | null = null;
                
                let newDataList: GenericInitialObject[] = [];
                if(camposApartado && camposApartado?.length>0){
                    emptyObjt= getEmptyObject(camposApartado)
                    //console.log('emptyObjt', emptyObjt)
                    setEmpty(emptyObjt);
                    const keys = getKeysRequiredCampos(camposApartado);
                    setCamposRequiredKeys(keys)  
                    if(datosXml){
                        //console.log('USE datosXML', datosXml)
                        newDataList = formatXMLMultipletoObject(datosXml,emptyObjt) as GenericInitialObject[];
                        setDataList(newDataList)
                        setChanges(newDataList && newDataList.length===0 && (required1Element[idTipoModelo] || dynamicRequired1Element)) 
                    } else{
                        setChanges(true && (required1Element[idTipoModelo] || dynamicRequired1Element))  
                        setDataList([]) 
                    }    
                    newData = emptyObjt
                }

                setData(newData);
                setLoading(false)
            } catch (error) {
                const result = (error as Error).message;
                setLoading(false)
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: result,
                        variant: 'error',
                    }
                }); 
            }
        })();
    },[idTipoApartado, idTipoModelo, datosXml, datosXmlApartadosPrevios]);
    
    useEffect(() => {
        // Comprobamos que tenemos alemnos un item si el modelo lo requiere
        if(changes && (required1Element[idTipoModelo] || dynamicRequired1Element ) && dataList.length === 0){
            setFinished(false)
        }else{
            setFinished(true)
        }
    },[changes, data])
 
    return(
        <div> 
            
            <Dialog open={loading}>
                <DialogContent>
                    <CircularProgress size={35} />
                </DialogContent>
            </Dialog>
            <div className={classes.row} style={{display: 'inline-flex', paddingBottom: 20}}>
                {campos && campos.length>0 && camposRequiredKeys !== null && data && infoSelect &&
                    renderCampos(campos, data, updateData, '', idTipoApartado, infoSelect, undefined, classes)
                } 
                
                    <Button 
                        disabled={!changes}
                        style={{marginLeft: 10}}
                        color='primary'
                        variant='contained' 
                        onClick={onAdd}
                        startIcon={<AddIcon />}
                    >
                        {translate('Tributos','btnAdd', terms)}
                    </Button>
                
            </div>     

            {/* <div style= {{maxHeight: 350, overflowY: 'auto', overflowX: 'hidden'}}> */}
                {dataList.length>0 && campos && 
                    <Grid container direction='column' style={{marginLeft: 10, }}>
                        <Grid item container direction='row' >
                            { 
                                Object.keys(XS[idTipoModelo]).map( (k, idx, {length}) => {
                                    // console.log(k)
                                    const isLast= idx+1 === length;
                                    const isFirst= idx === 0;

                                    return(
                                    <Grid item xs={XS[idTipoModelo][k]} 
                                        className={isLast ? classes.itemHeaderTableLastInRow : classes.itemHeaderTable} 
                                        style={{ borderTopRightRadius: isLast? 10: 0, borderTopLeftRadius: isFirst ? 10: 0 }}
                                    >
                                        <Typography className={classes.itemTitle}>{campos?.find( item => item.codigo===k)?.campoDescription}</Typography>
                                    </Grid>
                                )})  
                            }
                        </Grid>
            
                        {dataList.map( (item, index) => { 
                            const keys = Object.keys(item);
                            return (
                                <Grid item container direction='row' >
                                    {
                                        keys.map( (k, idx, {length}) =>  {
                                            const isLast= idx+1 === length;
                                            // console.log(idTipoModelo,k, XS[idTipoModelo][k])
                                            return (
                                            <Grid item xs={XS[idTipoModelo][k]} className={isLast ? classes.itemTableLastInRow: classes.itemTable}>
                                                <Typography>{item[k].value}</Typography>
                                            </Grid>
                                        )})
                                    }
                                    <Grid item>
                                        <IconButton onClick={() => onDelete(index)} style={{padding:5}}>
                                            <DeleteIcon htmlColor='#004f82'/>
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            )}
                        )}
                    </Grid>
                }
            {/* </div> */}
                  

        </div>
    )
}
export default withLiterals(['Tributos'])(ApartadoMultiple);


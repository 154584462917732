import {
  ICampo,
  IConcepto,
  ISujetoCenso,
  TipoCampoWeb,
} from "../../gateways/model.new.interface";
import {
  ListItem,
  ListItemText,
  Button,
  Typography,
  Grid,
} from "@material-ui/core";
import { ClassNameMap } from "@material-ui/styles";
import * as icons from "@material-ui/icons";
import stringSimilarity from "string-similarity";
import Campo from "./componentes/campo";
import { ISujeto } from "gateways/perfil.interfaces";
import moment from "moment";
import { TDataPopUp } from "./modelos-tributos";
import { translate } from "utils/i18n";
import { IDatosCuota, IDatosVehiculo } from "gateways/funciones-621.interfaces";
import { formatNumber } from "utils/number";

export const TIPOS_TRIBUTO = { autonomicos: "autonomicos", locales: "locales" };
export const TIPO_CAMPO = {
  localizador: "localizador",
  nifSujetoPasivo: "nifSujetoPasivo",
  fechaCreacion: "fechaCreacion",
  importeAIngresar: "importeAIngresar",
};

export type TipoTributo = "autonomicos" | "locales";
export type TipoAsistente= 'plusvalia';
export type GenericObject = Record<string, any>; // Record <TypeKeys, typeValues>
/** Para definir un objeto con keys desconocidas pero estructura de dtaos conocida:
 * interface MyType extends Record<string,any> { name: string, value: number}
 * Ejemplo:
 * const misValores: Record<string,MyType> = {
 *      juan: { name: mates, value: 7.5 },
 *      lola: { name: biologia, value: 8},
 * } */
export type GenericInitialObject = Record<
  string,
  { value: any; casilla: number; formula: string; tipo: TipoCampoWeb, valid?: boolean }
>; // Record <TypeKeys, typeValues>

/** SELECTORES */
export function formatOptions<T extends Record<string, any>>(
  idName: keyof T,
  textName: keyof T,
  list: T[],
  params?:any, // Param común a todos los items - Ej: componentIG
  fieldAsParamExtraText?: keyof T, // Prmite añadir otro campo del itemList como texto concatenado al nombre de la option
): Option[] {
  const out = list.map((item: T) => {
    const p = fieldAsParamExtraText ? {extraText: item[fieldAsParamExtraText]}: undefined
    const option: Option = {
      id: item[idName],
      nombre: item[textName].toString(),
      params: params || p ? {...params, ...p} : undefined
    };

    if (params) option.params = params
    
    if ('bonificacion' in item) {
      option.params = {
          'bonificacion': {
            nombre: 'a051_c63',
            valor: item['bonificacion']
          }
      }
    };

    return option;
  });

  return out as Option[];
}

// Crear un objeto con las keys de los campos
export const getEmptyObject = (
  camposList: ICampo[],
  illesBalearsAsDefault: boolean = true,
): GenericInitialObject => {
  //console.log('getEmptyObject ', camposList)
  const out = camposList.reduce((obj, campo) => {    
    return {
      ...obj,
      [campo.codigo]: {
        value: defaultTipo(campo.tipoCampoWeb, null, campo.regex, campo.codigo, illesBalearsAsDefault, campo.iniBoolValue ?? undefined),
        casilla: campo.casilla,
        formula: campo.formula,
        tipo: campo.tipoCampoWeb,
        valid: campo.obligatorio ? false : true
      },
    };
  }, {});
  return out;
};
// Crear un objeto con las keys de los campos y valors iniciales
export const getInitialObject = (
  camposList: ICampo[],
  prevXml: string,
  idTipoApartado: string
): GenericInitialObject => {
	//console.log('prevXml', prevXml)
	const out = camposList.reduce((obj, campo) => {
	if (campo.formula) {
		let myValue;
		if(campo.tipoCampoWeb !== 'exclusiveBool'){
			// 1. Obtener casillas que intervienen en la fomrula
			const myRegExp: RegExp = /(?!\[)-?[a-zA-Z0-9_.]+(?=\])/g; //(?!\[)-?\d+(?=\])/g;
			const prev_casillas = campo.formula.match(myRegExp);
			/* 2. Si existe referencia a una casilla del apartado actual (idTipoApartado=046_0) 
						en formato idTipoApartado.Casillaextraemos el valor de la casilla */
			const casillas = prev_casillas?.map((item) =>
				item.replace(`${idTipoApartado}.`, "")
			);
			//console.log('casillas', casillas)
			/* 3. Eliminar [] de la formula. Repetimos el paso 2 en la fomrula */
			let formulaBraquets: string = campo.formula /*.replace(/[\[\]']+/g,'')*/
				.replaceAll(`${idTipoApartado}.`, "");
			/* 4. Reemplazamos los numeros de casilla por sus valores: 
						Nota: valores propios del apartado formato CASILLA (22), referencias a otros paratados TipoApartado.Casilla (Ej: 046_0.22)*/
			casillas?.forEach((item) => {
				let valueToReplace;
				if (item.includes("_")) {
				// 4.1 _ Dependencia con valores anteriores
				const regex = new RegExp(`\<${item}>(.+?)\<\/${item}\>`, "g");
				const found = prevXml.replace(/(\r\n|\n|\r)/gm, "").match(regex);
				//console.log('found', found)
				valueToReplace = found
					? found[0].replace(`<${item}>`, "").replace(`</${item}>`, "")
					: "0";
				} else {
				// 4.2 _ Valores del apartado pueden ser conocidos o no.
				//    Nota: Si hay formula será de tipo numero por lo que por defecto ponemos un 0
				const find = Object.values(obj).find(
					(v) => v.casilla === Number(item)
				);
				//console.log('find', find)
        valueToReplace = find && find.value && !isNaN(find.value) ? find.value : "0";
				}
        formulaBraquets = formulaBraquets.replace(`[${item}]`, valueToReplace);
			});
	
			if (casillas?.length === 1) {
			// Si solo interviene una casilla no hay que ejecutar una operación
			// Descripción de conceptor pueden ir concatenadas con >: .replaceAll('&gt;', '\>')
			myValue = formulaBraquets.replaceAll("&gt;", ">");
			//console.log('myValue', myValue)
			} else {
			myValue = formulaBraquets !== "" ? eval(formulaBraquets) : 0;
			}
	
			myValue =(campo.tipoCampoWeb === "decimal2" || campo.tipoCampoWeb === "decimal3")
				? campo.tipoCampoWeb === "decimal2" ? Number(myValue).toFixed(2) :  Number(myValue).toFixed(3)
				: myValue;
		} else {
			// exclusiveBool tiene fórmula pero o debe calcularse ya que es la relacion de casillas con las que tiene exclusividad
			// extraemos el valor del xml
			const regex = new RegExp(`\<${idTipoApartado}.${campo.casilla}>(.+?)\<\/${idTipoApartado}.${campo.casilla}\>`, "g");
			const found = prevXml.replace(/(\r\n|\n|\r)/gm, "").match(regex);
			//console.log(regex,'found ', found)
			myValue = found
					? found[0].replace(`<${idTipoApartado}.${campo.casilla}>`, "").replace(`</${idTipoApartado}.${campo.casilla}>`, "") === 'true'? true : false
					: false;
		}
		

		return {
			...obj,
			[campo.codigo]: {
				...obj[campo.codigo],
				value: myValue,
				casilla: campo.casilla,
				formula: campo.formula,
				tipo:campo.tipoCampoWeb,
				valid: true
			}
		} as GenericInitialObject;
    } else {
		return {
			...obj,
			[campo.codigo]: {
				...obj[campo.codigo],
				value:  defaultTipo(campo.tipoCampoWeb, campo.tipoCampo, campo.regex),
				casilla: campo.casilla,
				formula: campo.formula,
				tipo:campo.tipoCampoWeb,
				valid: true
			}
		} as GenericInitialObject;
    }
  }, {} as GenericInitialObject);
  //console.log('out', out)

  return out;
};

export const defaultTipo = (tipoCampoWeb: TipoCampoWeb | null, tipoCampo?: string|null, regex?: RegExp | null, codigo?: string, illesBalearsAsDefault?: boolean, iniBoolValue?:boolean) => {
  if(codigo === "exentosValor" ||codigo === "nosujetosValor"){
    return 1
  }
  if(codigo === "provincia" && illesBalearsAsDefault){
    return "ILLES BALEARS"
  }
  if(tipoCampo !== null && tipoCampo === "fechaCreacion"){
    return moment().format("DD/MM/YYYY")
  }
  switch (tipoCampoWeb) {
    case "number":
      return (regex == undefined || regex == null) ? 0 : undefined;
    case "decimal":
      return Number(0).toFixed(2);
    case "decimal2":
      return Number(0).toFixed(2);
    case "decimal3":
      return Number(0).toFixed(2);
    case "date":
      return moment().format("DD/MM/YYYY");
    case "text": 
    case "textAction":
      return "";
    case "boolean":
      return iniBoolValue ?? false;
    case "exclusiveBool":
        return false;
    case "select":
        return "";
    case "selectEjerTipoPeriod":
      return undefined;
    case undefined:
      return "";
    case null:
      return "";
  }
  return 0;
}

/** Traido desde PaaS  ---------------------------------------------------- */
// Comparar objetos: data1 from xml, data2 from datos apartados previos
// Solo si el apartado depende de valores anteriores
export const isSameData = (
  data1: GenericInitialObject | null,
  data2: GenericInitialObject | null
) => {
  if (!data1 || !data2) return false;
  //Array de true/false inficando si son iguales
  const out = [];
  for (const key in data1) {
    const value1 = data1[key].value;
    const value2 = data2[key].value;
    //console.log('key:',key,' values:   ',value1 + ' equal ' + value2)
    out.push(
      value1 == value2 ||
        //Igualamos valores undefined a valores iniciales ('', 0)
        (value1 === undefined && (value2 === "" || value2 == 0 || value2 == 0.0)) ||
        (value2 === undefined && (value1 === "" || value1 == 0 || value2 == 0.0)) ||
        // Igualamos valors que son distintos porque hay un valor en xml que no existe en datos previos
        (value1 !== undefined &&
          value1 !== null &&
          (value2 === undefined ||
            value2 === "" ||
            value2 == 0 ||
            value2 == 0.0))
    );
  }

  // console.log(out)
  if (out.includes(false)) {
    return false;
  } else {
    return true;
  }
};

// Comparar objetos: data1 from xml, data2 from datos apartados previos
// Unir ambos datos en base a si son calculados o dependientes de datos anteriores
export const mixData = (
  data1: GenericInitialObject | null,
  data2: GenericInitialObject | null,
  idTipoApartado: string
) => {
	if (!data1 || !data2) {
		return data1 ? data1 : data2;
	}
	let out: GenericInitialObject = {};
	// Buscamos todos los valores dependientes de apartadoa anteriores
	for (const key in data1) {
		const value1 = data1[key].value; // xml valor
		const value2 = data2[key].value; // dataPrevio valor
		// console.log(`key= ${key} value1=${value1} == value2=${value2}`)
		if (
			data1[key].formula &&
			data1[key].formula !== "" && data1[key].tipo !== 'exclusiveBool' &&
			data1[key].formula.includes("_")
		) {
		// si hay fórmula guardamos valor que viene de datosPrevios
		// console.log('set ', data2[key])
		out = { ...out, [key]: { ...data2[key] } };
		}
	}
	// console.log(`out`,out)
	// Sustituimos valores que no tienen formula o son exclusiveBool (si tienen formula) <- vienen de xml
  for (const key in data1) {
		// const value1 = data1[key].value; // xml valor
		// const value2 = data2[key].value; // dataPrevio valor
		// console.log(`value1=${value1} == value2=${value2}`)
		if ((!data1[key].formula || data1[key].formula === "") && data1[key].tipo !== 'exclusiveBool') {
			out = { ...out, [key]: { ...data1[key] } };
		}
		if(data1[key].tipo === 'exclusiveBool'){
			out = { ...out, [key]: { ...data1[key] } };
		}
	}
	// console.log(`out 1 `,out)
	// evaluamos calculos de items actuales
	for (const key in data1) {
		// const value1 = data1[key].value; // xml valor
		// const value2 = data2[key].value; // dataPrevio valor
		//console.log(`value1=${value1} == value2=${value2}`)
		if (data1[key].tipo !== 'exclusiveBool' && data1[key].formula && !data1[key].formula.includes("_")) {
			out = { ...out, [key]: { ...data1[key], value: undefined } };
			let myValue;
			// 1. Obtener casillas que intervienen en la fomrula
			const myRegExp: RegExp = /(?!\[)-?[a-zA-Z0-9_.]+(?=\])/g; //(?!\[)-?\d+(?=\])/g;
			const casillasFormula = data1[key].formula.match(myRegExp);
			// 2. Eliminar 'xxx_x.' para tener referencia [numCasilla].
			const casillasFinal = casillasFormula?.map((item) =>
				item.replace(`${idTipoApartado}.`, "")
			);
			/* 2. Eliminar 'xxx_x.' de la formula para tener referencia [numCasilla]. Repetimos el paso 2 en la fomrula */
			let formulaBraquets = data1[key].formula.replaceAll(
				`${idTipoApartado}.`,
				""
			);
			casillasFinal?.forEach((item) => {
				let valueToReplace;
				//Valores del apartado pueden ser conocidos o no - por defecto ponemos un 0
				const find = Object.values(out).find((v) => v.casilla === Number(item));
				valueToReplace = find && find.value && !isNaN(find.value) ? find.value : "0";
        formulaBraquets = formulaBraquets.replace(`[${item}]`, valueToReplace);
				//console.log('Render To eval: ', formulaBraquets)
			});
			//console.log('Render To eval: ', formulaBraquets)
			if (casillasFinal?.length === 1) {
				// Si solo interviene una casilla no hay que ejecutar una operación
				myValue = formulaBraquets;
			} else {
				//console.log('formulaBraquets',formulaBraquets)
				myValue = formulaBraquets !== "" ? eval(formulaBraquets) : 0;
			}
			if (data1[key].tipo === "decimal2") {
				myValue = Number(myValue).toFixed(2);
			}
			if (data1[key].tipo === "decimal3") {
				myValue = Number(myValue).toFixed(3);
			}
			out = { ...out, [key]: { ...data1[key], value: myValue } };
		}
	}
	// console.log(`out 2`,out)

	return out;
};
/** FIN Traido desde PaaS  ---------------------------------------------------- */

// -----------------------------------------------------------------------------------------------------------------------------------
// GENERACION XML to OBJECT y EXTRACCIÓN OBJECT to XML
// -----------------------------------------------------------------------------------------------------------------------------------
// Constantes - Modelos con apartado de tipo MULTIPLE
const multiStarTag = `<P N="1">`;
const multiEndTag = `</P>`;
const LETTER_MULTIPLE = "S"; //: GenericObject = {'890': 'S'}

// EXTRAER de datosXML los valores - XML to OBJECT
// 1. Extraer la relación Casilla-Valor
export const extraerCasillaValor = (myXML: string) => {
  const casillas = myXML.match(/<C_\d*>/g); // obtener casillas que intervienen
  //console.log('myXML'+myXML)
  //console.log('casillas'+casillas)
  const casilla_valor = casillas?.map((element) => {
    const c = element.replace(">", "").replace("<", "");
    const c_num = c.replace("C_", "");
    //Buscamos <C_nn>valor</C_nn>
    const regex = new RegExp(`\<${c}>(.+?)\<\/${c}\>`, "g"); //`(?!\<C_22>)(.+?)(?=\<\/C_22>)` //`<C_22\>(.+?)<\/C_22\>`;
    const reg_value = myXML.match(regex);
    // Eliminamos <C_nn> y </C_nn> para obtener el valor
    let value =
      reg_value && reg_value.length > 0
        ? reg_value[0].replace(`<${c}>`, "").replace(`</${c}>`, "")
        : "";
    // Es numerico y contiene , como separador ¿?
    if (/^[0-9]+([,][0-9]+)?$/.test(value)) {
      const finalValue = value.replace(",", ".");
      //console.log('finalValue', finalValue)
      return { casilla: c_num, valor: finalValue };
    } else {
      //Descripción de conceptor pueden ir concatenadas con >: .replaceAll('&gt;', '\>')
      return { casilla: c_num, valor: value.replaceAll("&gt;", ">") };
    }
  });

  return casilla_valor;
};
// 2. Extraer el objeto SIMPLE a partir de XML
export const formatXMLtoObject = (
  xml: string,
  emptyObjt: GenericInitialObject
): GenericInitialObject => {
  const myXML = xml.replace(/(\r\n|\n|\r)/gm, ""); // eliminar saltos de linea
  const casilla_valor = extraerCasillaValor(myXML);
  const prev_out: GenericObject = casilla_valor
    ? casilla_valor.reduce((obj, item) => {
        return {
          ...obj,
          [item.casilla]: item.valor,
        };
      }, {})
    : {};
  let out: GenericInitialObject = {};
  if (prev_out) {
    for (const key in emptyObjt) {
      let foundValue = prev_out[emptyObjt[key].casilla];
      if (emptyObjt[key].tipo === "decimal2") {
        foundValue = !isNaN(foundValue) ? Number(foundValue).toFixed(2): undefined;
      }
      if ( emptyObjt[key].tipo === "decimal3") {
        foundValue =!isNaN(foundValue) ? Number(foundValue).toFixed(3): undefined;
      }
      if (emptyObjt[key].tipo === "boolean" || emptyObjt[key].tipo === "exclusiveBool") {
        foundValue = foundValue === 'true' ? true : false
      }
      const value =
        (emptyObjt[key].tipo === "decimal2" || emptyObjt[key].tipo === "decimal3")
          ? emptyObjt[key].tipo === "decimal2"  
            ? !isNaN(emptyObjt[key].value) ? Number(emptyObjt[key].value).toFixed(2): undefined
            : !isNaN(emptyObjt[key].value) ? Number(emptyObjt[key].value).toFixed(3): undefined
          : emptyObjt[key].value;
      out = {
        ...out,
        [key]: { ...emptyObjt[key], value: foundValue !== undefined && foundValue !== null ? foundValue : value, valid: true },
      };
    }
  }

  return out;
};
// 3. Extraer conjunto de sujetos
const extraerSujetosMultiple = (myXML: string) => {
  const sujetos = myXML.match(/<S_\d*>/g); // obtener sujetos que intervienen
  //console.log('myXML'+myXML)
  //console.log('sujetos'+sujetos)
  const casilla_valor = sujetos?.map((element) => {
    const c = element.replace(">", "").replace("<", "");
    const c_num = c.replace("S_", "");
    //Buscamos <S_nn>campos sujeto</S_nn>
    const regex = new RegExp(`\<${c}>(.+?)\<\/${c}\>`, "g"); //`(?!\<C_22>)(.+?)(?=\<\/C_22>)` //`<C_22\>(.+?)<\/C_22\>`;
    const reg_value = myXML.match(regex);
    // Eliminamos <S_nn> y </S_nn> para obtener el valor
    let value =
      reg_value && reg_value.length > 0
        ? reg_value[0].replace(`<${c}>`, "").replace(`</${c}>`, "")
        : "";
    // Es numerico y contiene , como separador ¿?
    if (/^[0-9]+([,][0-9]+)?$/.test(value)) {
      const finalValue = value.replace(",", ".");
      //console.log('finalValue', finalValue)
      return { casilla: c_num, valor: finalValue };
    } else {
      //Descripción de conceptor pueden ir concatenadas con >: .replaceAll('&gt;', '\>')
      return { casilla: c_num, valor: value.replaceAll("&gt;", ">") };
    }
  });

  return casilla_valor;
};

// 4. Extraer el objeto MULTIPLE a partir de XML
export const formatXMLMultipletoObject= (xml:string, emptyObjt: GenericInitialObject, isFixedMultpleObject: boolean = false): GenericInitialObject[] => {
    const myXML = xml.replace(/(\r\n|\n|\r)/gm,""); // eliminar saltos de linea
    const sujetos= extraerSujetosMultiple(myXML)
    //console.log('sujetos ', sujetos )
    //console.log('emptyObjt',emptyObjt);
    const finalCasillaValor = sujetos? sujetos.map(item => {
            const values = extraerCasillaValor(item.valor)
            return {casilla: item.casilla, valor: values}
        }) : []
    //console.log('finalCasillaValor', finalCasillaValor)
    const out = finalCasillaValor.map( item => {
        let object = !isFixedMultpleObject ? {...emptyObjt}: {};
        item.valor?.forEach((element) => {
            for (const key in emptyObjt) {
                if(emptyObjt[key].casilla === Number(element.casilla)){
                    let foundValue = element.valor
                    
                    if(emptyObjt[key].tipo === 'decimal2'){
                        foundValue = Number(foundValue).toFixed(2)
                    }
                    if(emptyObjt[key].tipo === 'decimal3'){
                      foundValue = Number(foundValue).toFixed(3)
                  }
                    const value = (emptyObjt[key].tipo === 'decimal2'|| emptyObjt[key].tipo === 'decimal3')
                      ? (emptyObjt[key].tipo === 'decimal2') ? Number(emptyObjt[key].value).toFixed(2) : Number(emptyObjt[key].value).toFixed(3) 
                      : emptyObjt[key].value;
                    //console.log('key:' + key + '    foundValue : '+ foundValue+ '    value: '+value)
                    object = { 
                        ...object,
                        [key]: {...emptyObjt[key], value: foundValue ? foundValue : value }
                    }
                }
            }
        });
        //console.log('object',object);
        return object as GenericInitialObject
    })
    
    //console.log('out',out)
    return out;
}

export const formatXMLFixedMultipletoObject= (xml:string, emptyObjt: GenericInitialObject): GenericInitialObject[] => {
    const myXML = xml.replace(/(\r\n|\n|\r)/gm,""); // eliminar saltos de linea
    const sujetos= extraerSujetosMultiple(myXML)
    //console.log('sujetos ', sujetos )
    //console.log('emptyObjt',emptyObjt);
    const finalCasillaValor = sujetos? sujetos.map(item => {
            const values = extraerCasillaValor(item.valor)
            return {casilla: item.casilla, valor: values}
        }) : []
    let out = [];
    for (const key in emptyObjt) {
        let object = {};
        const casillaValorItem = finalCasillaValor.find(item => item.valor?.findIndex(itemValor => Number(itemValor.casilla) == emptyObjt[key].casilla) != -1);
        if(casillaValorItem != undefined) {
            let foundValue = casillaValorItem.valor?.find(itemValor => Number(itemValor.casilla) == emptyObjt[key].casilla)?.valor;
                  
            if(emptyObjt[key].tipo === 'decimal2'){
                foundValue = Number(foundValue).toFixed(2)
            }
            if(emptyObjt[key].tipo === 'decimal3'){
              foundValue = Number(foundValue).toFixed(3)
          }
            const value =(emptyObjt[key].tipo === 'decimal2' || emptyObjt[key].tipo === 'decimal3' )
              ? emptyObjt[key].tipo === 'decimal2' ? Number(emptyObjt[key].value).toFixed(2) : Number(emptyObjt[key].value).toFixed(3) 
              : emptyObjt[key].value;
            //console.log('key:' + key + '    foundValue : '+ foundValue+ '    value: '+value)
            object = { 
                ...object,
                [key]: {...emptyObjt[key], value: foundValue ? foundValue : value }
            }
        } else {
            object = { 
                ...object,
                [key]: {...emptyObjt[key], value: emptyObjt[key].value }
            }
        }
        out.push(object);
    }
    //console.log('out',out)
    return out;
}

// Función para extraer una casilla en especifico del xml previo, el cual tiene un formato peculiar (000_A.1)
export const extractCasillaFromPreviousXml = (xml: string, casilla: string) => {
  const regex = new RegExp(`(?<=(<${casilla}>))(.*)(?=(<\/${casilla}>))`, "g");
  const found = xml.match(regex);
  return found !== null ? found[0] : "";
};
// GENERAR XML a partir de los datos del formulario (xml Apartados) - OBJECT to XML ------------------------------------------------------
// 1. Generación xml de las casillas de un apartado
const getXmlCasillas = (data: GenericInitialObject,prevXml: string, idTipoApartado: string) => {
    let casillasXML = ''
    //console.log('data getXmlCasillas',data);
    for (const key in data) {
        const genItem = data[key];
        if (genItem.casilla) {
            let myValue = genItem.value
            //console.log('c'+item.casilla+'    '+item.value + 'formula:'+item.formula)
            if (genItem.formula && genItem.tipo !== 'exclusiveBool') {
                // 1. Obtener casillas que intervienen en la fomrula 
                const myRegExp: RegExp = /(?!\[)-?[a-zA-Z0-9_.]+(?=\])/g //(?!\[)-?\d+(?=\])/g; 
                const prev_casillas = genItem.formula.match(myRegExp);
                /* 2. Si existe referencia a una casilla del apartado actual (idTipoApartado=046_0) 
                    en formato idTipoApartado.Casillaextraemos el valor de la casilla */
                const casillas = prev_casillas?.map((item) =>
                    item.replace(`${idTipoApartado}.`, "")
                );
                /* 3. Eliminar [] de la formula. Repetimos el paso 2 en la fomrula */
                let formulaBraquets = genItem.formula /*.replace(/[\[\]']+/g,'')*/
                    .replaceAll(`${idTipoApartado}.`, "");
                /* 4. Reemplazamos los numeros de casilla por sus valores: 
                        Nota: valores propios del apartado formato CASILLA (22), referencias a otros paratados TipoApartado.Casilla (Ej: 046_0.22)*/
                casillas?.forEach((item) => {
                    let valueToReplace;
                    if (item.includes("_")) {
                        // 4.1 _ Dependencia con valores anteriores
                        const regex = new RegExp(`\<${item}>(.+?)\<\/${item}\>`, "g");
                        const found = prevXml.replace(/(\r\n|\n|\r)/gm, "").match(regex);
                        valueToReplace = found
                        ? found[0].replace(`<${item}>`, "").replace(`</${item}>`, "")
                        : defaultTipo(genItem.tipo);
                    } else {

                        // 4.2 _ Valores del apartado pueden ser conocidos o no.
                        //    Nota: Si hay formula será de tipo numeroco por lo que por defecto ponemos un 0
                        const find = Object.values(data).find(
                            (v) => v.casilla === Number(item)
                        );
                        valueToReplace = find && find.value && !isNaN(find.value) ? find.value : "0";
                    }
                    formulaBraquets = formulaBraquets.replace(
                        `[${item}]`,
                        valueToReplace
                    );
                    //console.log('Render To eval: ', formulaBraquets)
                });

                if (casillas?.length === 1) {
                    // Si solo interviene una casilla no hay que ejecutar una operación
                    myValue = formulaBraquets;
                } else {
                    //console.log('formulaBraquets',formulaBraquets)
                    myValue = formulaBraquets !== "" ? eval(formulaBraquets) : 0;
                }
            }

            if (genItem.tipo === "decimal2") {
              myValue = Number(myValue ?? 0).toFixed(2);
            }
            if (genItem.tipo === "decimal3") {
              myValue = Number(myValue ?? 0).toFixed(3);
            }
            //console.log('myValue', myValue)
            const casilla =
                myValue === null || myValue === undefined
                ? `<C_${genItem.casilla}/>`
                : `<C_${genItem.casilla}>${myValue}</C_${genItem.casilla}>`;
            casillasXML += casilla;
        }
    }
    return casillasXML;
};

export const encodeXmlValues = (xmlString: string): string => {
  const regex = />([^<]*)</g; // Buscar entre > y </ => />([^<]+?)(?=<\/)/g;
  let match;
  try {
    while ((match = regex.exec(xmlString)) !== null) {
      xmlString = xmlString.replace(match[1], encodeHtml(match[1]));
    }
  } catch (error) {
    console.log(error as string);
  }

  return xmlString;
}

export const encodeHtml = (stringToEncode: string): string => {
  const div = document.createElement('div');
  div.innerText = stringToEncode;
  return div.innerHTML;
}

export const decodeHtml = (html: string): string => {
  var txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
}

export const encodeModeloValues = (datos: TDataPopUp[]): TDataPopUp[] => {
  try {
    datos.forEach((dato) => {
      if (dato.data) {
        for (const key in dato.data) {
          if (Object.hasOwnProperty.call(dato.data, key)) {
            const valorDato = dato.data[key];
            if (valorDato.value && valorDato.value !== '') {
              valorDato.value = encodeHtml(valorDato.value);
            }
          }
        }
      }
      if (dato.dataMultiple) {
        dato.dataMultiple.forEach((datoMultiple) => {
          for (const key in datoMultiple) {
            if (Object.hasOwnProperty.call(datoMultiple, key)) {
              const valorDato = datoMultiple[key];
              if (valorDato.value && valorDato.value !== '') {
                valorDato.value = encodeHtml(valorDato.value);
              }
            }
          }
        });
      }
    });
  } catch (error) {
    console.log(error as string);
  }

  return datos;
}

//2. Generación xml completo
export const getXML = (data: GenericInitialObject[],prevXml: string, idTipoApartado: string, isMultiple:boolean) => {
    
    //console.log('data',data)
    //console.log('idTipoApartado',idTipoApartado)
    //console.log('prevXml',prevXml)
    //console.log('isMultiple',isMultiple)
    const startTag=`<A_${idTipoApartado}>`; 
    const endTag =`</A_${idTipoApartado}>`;
    // Para apartados con Elementos múltiples en los que cada elemento se encapsula con <C_X></C_X> o <S_X></S_X>
    // donde X es el número del item. LETTER_MULTIPLE define el letra bajo la que se agrupan
    const idTipoModelo = idTipoApartado.substring(0,3)
    //console.log('LETTER_MULTIPLE select' +idTipoModelo +': '+ LETTER_MULTIPLE[idTipoModelo])

    if(isMultiple){
        const apartadoXml = data.map( (dataItem, index) => {   
            //console.log('dataItem', dataItem) 
            const itemStartTag=`<${LETTER_MULTIPLE}_${index+1}>`;  //`<${LETTER_MULTIPLE[idTipoModelo]}_${index+1}>`; 
            const itemEndTag =`</${LETTER_MULTIPLE}_${index+1}>`; //`</${LETTER_MULTIPLE[idTipoModelo]}_${index+1}>`; 
            const casillasXML = getXmlCasillas(dataItem,prevXml,idTipoApartado);
            //console.log('casillasXML', casillasXML)
            return itemStartTag + casillasXML + itemEndTag
        })
        //console.log('apartadoXml',apartadoXml)
        //console.log('out--> ',startTag + multiStarTag + apartadoXml.join('') + multiEndTag + endTag)
        return startTag + multiStarTag + apartadoXml.join('') + multiEndTag + endTag;
    } else {
        const casillasXML = getXmlCasillas(data[0],prevXml,idTipoApartado) 
        //console.log('casillasXML', casillasXML);
        return startTag + casillasXML + endTag;
    }
}
// -----------------------------------------------------------------------------------------------------------------------------------
// Fin GENERACION XML to OBJECT y EXTRACCIÓN OBJECT to XML
// -----------------------------------------------------------------------------------------------------------------------------------

// -----------------------------------------------------------------------------------------------------------------------------------
// FORMATTERS
// -----------------------------------------------------------------------------------------------------------------------------------

/** Adaptación de fomratos por incoherencia BBDD ---------------------------------------------------------------------------------- */
// 1 - CAMPOS Y CONCEPTOS - Modelo 060 y 046
// Ajusta que en la definición de CAMPO el registro código contenga como
// valor la key del campo en el que encontrar su valor
// Ejemplo: Campo = {esRepetible: false, incrementoCasilla: 0, idCampo: 70, codigo: 'impSinIVA', idRegex: ... }
//          Concepto: debe tener un campo impSinIVA del que obtener el valor
//   Adaptacion Columnas tabla CONCEPTO :
//   Def campos   - Concepto
//     IdConcepto - id
//     impUnidad  - porUnidades o importe
//     concepto   - codigo
//     nomConcept - texto
//     descripcio - texto
//     unidades   - no existe (por defecto 1)
//     impSinIVA  - importe
//     porcentaje - porcentaje

export const conceptoFormatter = (
  data: GenericInitialObject,
  concepto: IConcepto,
  conceptoPadre: IConcepto,
  tipoModelo: string
): GenericInitialObject => {
  const impUnidadValue =
    concepto?.porUnidades && concepto?.porUnidades !== ""
      ? (concepto?.porUnidades as number)
      : concepto.importe;

  const formattedSelected = {
    ...data,
    IdConcepto: { ...data["IdConcepto"], value: concepto.id },
    impUnidad: {
      ...data["impUnidad"],
      value: impUnidadValue
        ? (data["impUnidad"].tipo === "decimal2" ||data["impUnidad"].tipo === "decimal3")
          ? data["impUnidad"].tipo === "decimal2"  ? Number(impUnidadValue).toFixed(2) : Number(impUnidadValue).toFixed(3)
          : impUnidadValue
        : null,
    },
    concepto: {
      ...data["concepto"],
      value:
        tipoModelo === "060"
          ? `${conceptoPadre.codigo}-${concepto?.codigo}`
          : concepto?.codigo,
    },
    nomConcept: { ...data["nomConcept"], value: concepto?.texto },
    descripcio: { ...data["descripcio"], value: concepto?.texto },
    unidades: { ...data["unidades"], value: 1 },
    impSinIVA: {
      ...data["impSinIVA"],
      value:
        (concepto?.importe && data["impSinIVA"].tipo === "decimal2" )|| (concepto?.importe && data["impSinIVA"].tipo === "decimal3")
          ? data["impSinIVA"].tipo === "decimal2" ? Number(concepto.importe).toFixed(2): Number(concepto.importe).toFixed(3)
          : concepto.importe,
    },
    porcentaje: { ...data["porcentaje"], value: concepto?.porcentaje },
  };
  //console.log('Result Concepto+Data => ', formattedSelected)
  return formattedSelected;
};
//Busca si elcampo descripción
export const evaluateDescripcion = (
  data: GenericInitialObject,
  name: "nomConcept" | "descripcio",
  numConceptosInTree: number
) => {
  const descripcionSplit = data[name].value.split(" > ");
  //console.log(descripcionSplit)
  //console.log(descripcionSplit.length)
  //console.log('tree',numConceptosInTree)
  if (descripcionSplit.length === numConceptosInTree) {
    return { data: data, infoAdiocinal: "" };
  } else {
    const descripcion = descripcionSplit.slice(0, numConceptosInTree);
    const newData = {
      ...data,
      [name]: { ...data[name], value: descripcion.join(" > ") },
    };
    return {
      data: newData,
      infoAdiocinal: descripcionSplit[numConceptosInTree],
    };
  }
};

// 2 - CAMPOS SUJETO PASIVO / DECLARANTE
//   Def campos         -   LoggedSujeto
// Mail: undefined      - email
// apeNom: undefined    - nombre
// cp: undefined        - direccionNotificacion.codigoPostal
// escalera: undefined  - direccionNotificacion.escalera
// letra: undefined     - direccionNotificacion.
// locaMuni: undefined  -
// nif: undefined       - nif
// nomVia: undefined    - direccionNotificacion.via
// numero: undefined    - direccionNotificacion.numero
// piso: undefined      - direccionNotificacion.piso
// provincia: undefined - direccionNotificacion.provincia
// puerta: undefined    - direccionNotificacion.puerta
// siglas: undefined    - direccionNotificacion.sigla
// telefono: undefined  -
export const sujetoFormatter = (
  data: GenericInitialObject,
  sujeto: ISujeto,
): GenericInitialObject => {
  const formattedSelected = {
    ...data,
    Mail: { ...data["Mail"], value: sujeto.email, valid:true },
    apeNom: { ...data["apeNom"], value: sujeto.nombre, valid:true },
    cp: { ...data["cp"], value: sujeto.direccionNotificacion.codigoPostal, valid:true },
    escalera: {
      ...data["escalera"],
      value: sujeto.direccionNotificacion.escalera, valid:true
    },
    letra: { ...data["letra"], value: undefined , valid:true},
    locaMuni: {
      ...data["locaMuni"],
      value: sujeto.direccionNotificacion.codigoMunicipio, valid:true
    },
    nif: { ...data["nif"], value: sujeto.nif, valid:true },
    nomVia: { ...data["nomVia"], value: sujeto.direccionNotificacion.via, valid:true },
    numero: { ...data["numero"], value: sujeto.direccionNotificacion.numero, valid:true },
    piso: { ...data["piso"], sujeto, value: sujeto.direccionNotificacion.piso, valid:true },
    provincia: {
      ...data["provincia"],
      value: sujeto.direccionNotificacion.provincia, valid:true
    },
    puerta: { ...data["puerta"], value: sujeto.direccionNotificacion.puerta, valid:true },
    siglas: { ...data["siglas"], value: sujeto.direccionNotificacion.sigla, valid:true },
    telefono: { ...data["telefono"], value: sujeto.movil?.replace('+34',''), valid:true },
  };
  //console.log('Result Sujeto+Data => ', formattedSelected)
  return formattedSelected;
};

export const identificativoFormatter = (
  data: GenericInitialObject,
  sujeto: ISujeto
): GenericInitialObject => {
  const formattedSelected = {
    ...data,
    apeNom: { ...data["apeNom"], value: sujeto.nombre },
    ApeNomDenS: { ...data["ApeNomDenS"], value: sujeto.nombre },
    nif: { ...data["nif"], value: sujeto.nif },
    provincia: {
      ...data["provincia"],
      value: sujeto.direccionNotificacion.provincia,
    },
    municipio: {
      ...data["municipio"],
      value: sujeto.direccionNotificacion.codigoMunicipio,
    },
    cp: { ...data["cp"], value: sujeto.direccionNotificacion.codigoPostal },
    telefono: { ...data["telefono"], value: sujeto.movil?.replace('+34','') },
    correoElectronico: { ...data["correoElectronico"], value: sujeto.email },
  };
  /** TODO NEUS clear los campos casilla undefined */
  //console.log('identificativoFormatter Result', formattedSelected)
  return formattedSelected;
};

export const sujetoFormatterCenso = (
  data: GenericInitialObject,
  sujeto: ISujetoCenso
): GenericInitialObject => {
  const formattedSelected = {
    ...data,
    Mail: { ...data["Mail"], value: null },
    apeNom: { ...data["apeNom"], value: sujeto.nombre },
    codigo: { ...data["codigo"], value: sujeto.codigo },
    cp: { ...data["cp"], value: sujeto.cp.charAt(0) === "0" ? sujeto.cp : "0" + sujeto.cp },
    escalera: { ...data["escalera"], value: sujeto.escalera },
    letra: { ...data["letra"], value: sujeto.letra },
    locaMuni: { ...data["locaMuni"], value: sujeto.poblacion },
    nif: { ...data["nif"], value: sujeto.nif },
    nomVia: { ...data["nomVia"], value: sujeto.calle },
    numero: { ...data["numero"], value: sujeto.numero },
    provincia: { ...data["provincia"], value: "ILLES BALEARS" },
    puerta: { ...data["puerta"], value: sujeto.puerta },
    siglas: { ...data["siglas"], value: sujeto.siglas },
    telefono: { ...data["telefono"], value: null },
  };
  return formattedSelected;
};

export const vendedorFormatterDGT = (
  data: GenericInitialObject,
  datosVehiculo: IDatosVehiculo
): GenericInitialObject => {
  let formattedSelected;
  let esPersonaFisica =
                      datosVehiculo.datosGenerales.titular.datosPersona.personaFisica.nombre !== null &&
                      datosVehiculo.datosGenerales.titular.datosPersona.personaFisica.nombre !== undefined;
  if(datosVehiculo.datosGenerales.domicilioVehiculoIne?.via){
    formattedSelected = {
      ...data,
      Mail: { ...data["Mail"], value: null },
      apeNom: { ...data["apeNom"], value: esPersonaFisica
      ?
      datosVehiculo.datosGenerales.titular.datosPersona.personaFisica.apellido1 + " " + 
      (datosVehiculo.datosGenerales.titular.datosPersona.personaFisica.apellido2 !== null ? datosVehiculo.datosGenerales.titular.datosPersona.personaFisica.apellido2 + " " : "") + 
      datosVehiculo.datosGenerales.titular.datosPersona.personaFisica.nombre 
      :
      datosVehiculo.datosGenerales.titular.datosPersona.personaJuridica.razonSocial
    },
      cp: { ...data["cp"], value: datosVehiculo.datosGenerales.titular.domicilioIne?.codPostal !== null 
      ? "0"+ datosVehiculo.datosGenerales.titular.domicilioIne?.codPostal 
      : "0"+ datosVehiculo.datosGenerales.titular.domicilio?.codPostal},
      locaMuni: { ...data["locaMuni"], value: datosVehiculo.datosGenerales.titular.domicilioIne?.municipio },
      nif: { ...data["nif"], value: esPersonaFisica
      ?
      datosVehiculo.datosGenerales.titular.datosPersona.personaFisica.idDocumento 
      :
      datosVehiculo.datosGenerales.titular.datosPersona.personaJuridica.cif },
      nomVia: { ...data["nomVia"], value: datosVehiculo.datosGenerales.titular.domicilioIne?.via },
      provincia: { ...data["provincia"], value: datosVehiculo.datosGenerales.titular.domicilioIne?.provincia?.descripcion },
      telefono: { ...data["telefono"], value: null },
      numero: { ...data["numero"], value: null},
    };
  }
  else{
    formattedSelected = {
      ...data,
      Mail: { ...data["Mail"], value: null },
      apeNom: { ...data["apeNom"], value: esPersonaFisica
      ?
      datosVehiculo.datosGenerales.titular.datosPersona.personaFisica.apellido1 + " " + 
      (datosVehiculo.datosGenerales.titular.datosPersona.personaFisica.apellido2 !== null ? datosVehiculo.datosGenerales.titular.datosPersona.personaFisica.apellido2 + " " : "") + 
      datosVehiculo.datosGenerales.titular.datosPersona.personaFisica.nombre 
      :
      datosVehiculo.datosGenerales.titular.datosPersona.personaJuridica.razonSocial
    },
      cp: { ...data["cp"], value: datosVehiculo.datosGenerales.titular.domicilioIne?.codPostal !== null 
      ? "0"+datosVehiculo.datosGenerales.titular.domicilioIne?.codPostal 
      : "0"+datosVehiculo.datosGenerales.titular.domicilio?.codPostal},
      locaMuni: { ...data["locaMuni"], value: datosVehiculo.datosGenerales.titular.domicilio?.municipio },
      nif: { ...data["nif"], value: esPersonaFisica
      ?
      datosVehiculo.datosGenerales.titular.datosPersona.personaFisica.idDocumento 
      :
      datosVehiculo.datosGenerales.titular.datosPersona.personaJuridica.cif },
      nomVia: { ...data["nomVia"], value: datosVehiculo.datosGenerales.titular.domicilioIne?.via },
      provincia: { ...data["provincia"], value: datosVehiculo.datosGenerales.titular.domicilio?.provincia?.descripcion },
      telefono: { ...data["telefono"], value: null },
      numero: { ...data["numero"], value: null},
    };
  }
  return formattedSelected;
};

export const vendedorFormatterDGTNotificado = (
  data: GenericInitialObject,
  datosVehiculo: IDatosVehiculo,
  nombre: string,
  nif: string
): GenericInitialObject => {
  let formattedSelected;
  if(datosVehiculo.datosGenerales.domicilioVehiculoIne?.via){
    formattedSelected = {
      ...data,
      Mail: { ...data["Mail"], value: null },
      apeNom: { ...data["apeNom"], value: nombre},
      cp: { ...data["cp"], value: datosVehiculo.datosGenerales.titular.domicilioIne?.codPostal !== null 
      ? "0"+datosVehiculo.datosGenerales.titular.domicilioIne?.codPostal 
      : "0"+datosVehiculo.datosGenerales.titular.domicilio?.codPostal},
      locaMuni: { ...data["locaMuni"], value: datosVehiculo.datosGenerales.titular.domicilioIne.municipio },
      nif: { ...data["nif"], value: nif },
      nomVia: { ...data["nomVia"], value: datosVehiculo.datosGenerales.titular.domicilioIne?.via },
      provincia: { ...data["provincia"], value: datosVehiculo.datosGenerales.titular.domicilioIne?.provincia?.descripcion },
      telefono: { ...data["telefono"], value: null },
      numero: { ...data["numero"], value: null },
    };
  }
  else{
    formattedSelected = {
      ...data,
      Mail: { ...data["Mail"], value: null },
      apeNom: { ...data["apeNom"], value: nombre
    },
      cp: { ...data["cp"], value: datosVehiculo.datosGenerales.titular.domicilioIne?.codPostal !== null 
      ? "0"+datosVehiculo.datosGenerales.titular.domicilioIne?.codPostal 
      : "0"+datosVehiculo.datosGenerales.titular.domicilio?.codPostal},
      locaMuni: { ...data["locaMuni"], value: datosVehiculo.datosGenerales.titular.domicilio?.municipio },
      nif: { ...data["nif"], value: nif },
      nomVia: { ...data["nomVia"], value: datosVehiculo.datosGenerales.titular.domicilioIne?.via },
      provincia: { ...data["provincia"], value: datosVehiculo.datosGenerales.titular.domicilio?.provincia?.descripcion },
      telefono: { ...data["telefono"], value: null },
      numero: { ...data["numero"], value: null },
    };
  }
  return formattedSelected;
};

export const copySujetoPas = (data: GenericInitialObject | null, setData: (data: GenericInitialObject) => void, dataXML: GenericObject, validateDatosCenso: () => void) => {
  if(data !== null){
    data["nif"].value  =extractCasillaFromPreviousXml(dataXML["621_A"], "621_A.5")
    data["apeNom"].value  =extractCasillaFromPreviousXml(dataXML["621_A"], "621_A.6")
    setData(data)
    validateDatosCenso()
  }
}

export const copyTransm = (data: GenericInitialObject | null, setData: (data: GenericInitialObject) => void, dataXML: GenericObject, validateDatosCenso: () => void) => {
  if(data !== null){
    data["nif"].value = extractCasillaFromPreviousXml(dataXML["621_B"], "621_B.19")
    data["apeNom"].value = extractCasillaFromPreviousXml(dataXML["621_B"], "621_B.20")
    setData(data)
    validateDatosCenso()
  }
}

export const previosTecnicosFormatter = (
  data: GenericInitialObject,
  datosVehiculo: IDatosVehiculo,
  cet: string,
  co2: string,
  datosCuota: IDatosCuota,
  tipoMotor: string | null,
  idTipo: string,
  tipoVehiculo: string,
  tipo621: string | undefined
): GenericInitialObject => {
  const formattedSelected = {
    ...data,
    bastidorSV: { ...data["bastidorSV"], value: datosVehiculo.datosGenerales.descripcionVehiculo.bastidor },
    cilindrada: { ...data["cilindrada"], value: datosVehiculo.datosTecnicos.potencias.cilindrada.toString() },
    // fechaMatri: { ...data["fechaMatri"], value: moment(new Date(
    //       datosVehiculo.datosGenerales.matriculacion.fechaMatriculacion
    // )).format('DD/MM/YYYY') },

    fechaMatri: { ...data["fechaMatri"], value: moment(new Date(
      datosVehiculo.datosGenerales.fechasControl.fechaPrimeraMatriculacion ??
          datosVehiculo.datosGenerales.fechasControl.fechaMatriculacion,
    )).format('DD/MM/YYYY') },

    potenciaFi: { ...data["potenciaFi"], value: datosVehiculo.datosTecnicos.potencias.potenciaFiscal.toString() },
    marcaVeh: { ...data["marcaVeh"], value: datosVehiculo.datosGenerales.descripcionVehiculo.marca.descripcion },
    numMatricu: { ...data["numMatricu"], value: datosVehiculo.datosGenerales.matriculacion.matricula },
    locaMuni: { ...data["modeloVeh"], value: datosVehiculo.datosGenerales.descripcionVehiculo.modelo },
    motorGasol: { ...data["motorGasol"], value:tipoMotor === "Gasolina"},
    motorDiese: { ...data["motorDiese"], value: tipoMotor === "Diesel" },
    cet0621_c503: { ...data["cet0621_c503"], value: cet },
    a620_c83: { ...data["a620_c83"], value: co2 },
    baseImponi: {...data["baseImponi"], value: datosCuota.baseImponi},
    CuotaSola: {...data["CuotaSola"], value: datosCuota.CuotaSola},
    cuota: {...data["cuota"], value: datosCuota.cuota},
    recargo: {...data["recargo"], value: datosCuota.recargo},
    interesesD: {...data["interesesD"], value: datosCuota.interesesD},
    totalIngre: {...data["totalIngre"], value: datosCuota.totalIngre},
    a620_c84: {...data["a620_c84"], value: datosCuota.a620_c84},
    reduccionA: {...data["reduccionA"], value: datosCuota.reduccionA},
    a620_c4: {...data["a620_c4"], value: "TAU"},
    IDTIPO: {...data["IDTIPO"], value: idTipo},
    tipoVeh: {...data["tipoVeh"], value: tipoVehiculo},
    anoFabrica: {...data["anoFabrica"], value: datosVehiculo.datosGenerales.descripcionVehiculo.anyoFabricacion},
    cvf : {...data["cvf"], value: datosVehiculo.datosTecnicos.potencias.potenciaFiscal.toString()},
    tipo621: {...data["tipo621"], value: tipo621},
  };
  return formattedSelected;
};

export const getCategoriaElectrica = (categoriaElectrica: string) => {
  if(categoriaElectrica === "HEV" || categoriaElectrica === "GNC" ||categoriaElectrica === "GNL" ||categoriaElectrica === "GLP" ){
    return "Etiqueta eco"
  }
  if(categoriaElectrica === "BEV" || categoriaElectrica === "REEV" ||categoriaElectrica === "PHEV"){
    return "Etiqueta 0 emissions"
  }
  else{
    return null;
  }
}



// -----------------------------------------------------------------------------------------------------------------------------------
// Fin FORMATTERS
// -----------------------------------------------------------------------------------------------------------------------------------

export const getKeysRequiredCampos = (list: ICampo[], withSoloLectura?: boolean, withNoVisible?: boolean) => {
  const filtered = list.filter(
    (item) =>
      item.obligatorio === true && (item.visible === true || (withNoVisible && !item.visible)) && (!item.soloLectura || (withSoloLectura && item.soloLectura))
  );
  const out = filtered.length > 0 ? filtered.map((item) => item.codigo) : [];
  return out;
};

export const getMinDate = (value: string | null) =>  {
    //console.log('value',value);
    let minDate;
    if (value && value.length>0) {
        switch(value) {
            case 'beforeToday':
                minDate = undefined
                break
            default:
                minDate = undefined
        }
    }
    return minDate;
}

export const getMaxDate = (value: string | null) =>  {
    //console.log('value',value);
    let maxDate;
    if (value && value.length>0) {
        switch(value) {
            case 'beforeToday':
                maxDate = new Date()
                break
            default:
                maxDate = undefined
        }
    }
    return maxDate;
}

export const groupCampos = (list: ICampo[]) => {
  function filterArray(inputArr: number[]) {
    var found: GenericObject = {};
    var out = inputArr.filter(function (element) {
      return found.hasOwnProperty(element) ? false : (found[element] = true);
    });
    return out;
  }
  const groups = filterArray(list.map((item) => item.renderGroup));
  if (groups.length === 1) {
    //console.log('outSortByGroup', {group: 1, campos: list});
    return [{ group: 1, campos: list }];
  } else {
    const out = groups.map((g) => {
      const campos = list.filter((item) => item.renderGroup === g);
      return { group: g, campos };
    });

    const outSortByGroup = out
      ? out.sort((a, b) => (a.group > b.group ? 1 : -1))
      : [];
    //console.log('outSortByGroup', outSortByGroup)
    return outSortByGroup;
  }
};

// Step RENDERS ---------------------------------------------------------------------
export const renderConceptos = (
  list: IConcepto[],
  setSelected: (value: IConcepto | null) => void,
  classes: ClassNameMap<string>,
  confirm: (val: IConcepto) => void,
  terms: any,
  selected?: number
) => {
  const out: JSX.Element[] =
    list && list.length > 0
      ? list.map((item: IConcepto) => (
          <ListItem
            classes={{
              root: classes.listItemRootInverse,
              selected: classes.listItemSelected,
            }}
            key={item.id}
            button
            selected={selected && item.id === selected ? true : false}
            onClick={() => setSelected(item)}
          >
            <Grid
              container
              direction="row"
              alignItems="center"
              justify="flex-start"
            >
              <Grid item xs={item.codigo && item.idPadre !== null ? 7 : 10}>
                <ListItemText style={{ marginRight: 10 }}>
                  {`${item.texto}`}{" "}
                </ListItemText>
              </Grid>
              {item.codigo && item.idPadre !== null && (
                <Grid item xs={3} style={{ display: "inline-flex" }}>
                  <ListItemText>{`${item.identificador} `} </ListItemText>
                  <ListItemText>
                    {`${item.codigo ? item.codigo : ""}`}{" "}
                  </ListItemText>
                  <ListItemText>
                    {`${item.importe ? formatNumber(item.importe) : "0,00"}€`}{" "}
                  </ListItemText>
                </Grid>
              )}
              {selected && item.id === selected && (
                <Grid item xs={2} style={{ textAlign: "right" }}>
                  <Button
                    className={classes.outlinedButtonWhite}
                    variant="outlined"
                    onClick={() => confirm(item)}
                  >
                    {translate("Tributos", "btnConfirm", terms)}
                  </Button>
                </Grid>
              )}
            </Grid>
          </ListItem>
        ))
      : [];

  return out;
};

export type Option = { id: string; nombre: string, params?: any };
export type SelectoresInfo = {
  municipio?: Option[] | null;
  municipioIVTM?: Option[] | null;
  muniBonificado?: Option[] | null;
  provincia?: Option[] | null;
  tipoVia?: Option[] | null;
  isla?: Option[] | null;
  trimestre?: Option[] | null;
  ejercTrimestre?: Option[] | null;
  oficina?: Option[] | null;
  residuotipoExcepcion?: Option[] | null;
  delegacion?: Option[] | null;
  cir?: Option[] | null;
  tipoVehiculo?: Option[] | null;
  tipoMotor?: Option[] | null;
  tipoLiqIVTM?: Option[] | null;
  marcaVehic?: Option[] | null;
  modeloVehic?: Option[] | null;
  codeConstrITVM?: Option[] | null;
  codeUtilidITVM?: Option[] | null;
  claseVehiIVTM?: Option[] | null;
  actividadCanonAguas?: Option[] | null;
  xBonificacion?: Option[] | null;
};

// Constantes para modelos que aplican por periodo ---------------------------------------------
// Array de codigos de campo que hacen referencia a selectores de periodo o ejercicio+periodo
export const CAMPOS_PERIODO =  ['ejerPeri','periodo','ejerTipoPeri']
export type TPeriodo = '1T' | '2T' | '3T' | '4T';
export type TPerMensual = '01'|'02'|'03'|'04'|'05'|'06'|'07'|'08'|'09'|'10'|'11'|'12';
export type TPerAnual = '0A';

export const MODELO_PERIODO: Record<string,(TPeriodo | TPerMensual | TPerAnual)[]> = {
  '656': ['4T','12','0A'],
  '666': ['4T','12','0A']
}

export const OPTIONS_trimestre : Option[]= [ 
  {id: '1T', nombre: '1er Trimestre'},
  {id: '2T', nombre: '2º Trimestre'}, 
  {id: '3T', nombre: '3er Trimestre'}, 
  {id: '4T', nombre: '4º Trimestre'}, 
]

export const OPTIONS_tipoPeriodo : Option[]= [ 
  {id: 'trimestral', nombre: 'Trimestral'},
  {id: 'mensual', nombre: 'Mensual'}, 
  {id: 'anual', nombre: 'Anual'},  
]


const meses = ['ENE', 'FEB', 'MAR', 'ABR','MAY','JUN','JUL','AGO','SEP','OCT','NOV','DIC']
export const OPTIONS_mensual : Option[]= meses.map( (mes:string, index:number) =>({
  id: (index + 1).toString().padStart(2, '0'), // Genera '01', '02', etc.
  nombre: mes
}))
// Selector de actividad Cánon de Saneamiento de aguas
export const actividadCanonAguas_OPT: Option[] = [ 
    {id: 'xarxa', nombre: 'Subministrament per xarxa / Suministro por red', params: {componentIG: 'Modelos'}},
    {id: 'transport', nombre: 'Transport / Transporte', params: {componentIG: 'Modelos'}}, 
  ]

// ------------------------------------------------------------------------------------------------

export interface GroupLabel {
  [group: string]: string;
}

export const renderCampos = (camposList: ICampo[], camposData: GenericInitialObject , updateCamposData: (campo: string, value:any) => void, 
                            prevXml: string, idTipoApartado: string, selectoresInfo: SelectoresInfo | undefined, groupLabel: GroupLabel | undefined, 
                            classes: ClassNameMap<string>, orderCamposByCasilla?: boolean
                            ) => {
    const camposListGrouped : {group: number;campos: ICampo[];}[] = groupCampos(camposList.sort( 
      (a,b) =>orderCamposByCasilla === true 
        ? (a.casilla) > (b.casilla) ? 1: -1 
        : (a.orderWeb ?? a.casilla) > ( b.orderWeb ?? b.casilla) ? 1: -1 
    ))
    const modelo = idTipoApartado.split('_')[0]
    //console.log('camposListGrouped', camposListGrouped)
    //console.log('camposData', camposData)
    // console.log('groupLabel', groupLabel)
    //console.log('selectoresInfo', selectoresInfo)
    //console.log('prevXml ', prevXml)

    return(
        <div> 
            {camposListGrouped && camposListGrouped.length>0 &&
                camposListGrouped.map( (group, idx) => (
                    <div key={'group_'+group+'_'+idx}>
                        {groupLabel && groupLabel[`${group.group}S`] && 
                            <Typography className={
                                modelo === '656' && groupLabel[`${group.group}S`] !==' ' 
                                ? classes.section656
                                : classes.section
                              }
                            >
                              {groupLabel[`${group.group}S`].toUpperCase()}
                            </Typography>
                        }
                        {groupLabel && groupLabel[`${group.group}T`] && 
                            <Typography className={classes.tabble}>
                                {groupLabel[`${group.group}T`].toUpperCase()}
                            </Typography>
                        }
                        
                        <Grid item container direction='row' alignItems='flex-start' style={{ flexWrap: 'wrap', maxWidth: group.campos.find(campo => campo.renderSize === 'grow') ? 960 : 'unset'}}>
                            {groupLabel && groupLabel[`${group.group}`] && 
                                <Typography 
                                    className={
                                        ['800','890'].includes(modelo)
                                          ? group.campos.length>1 
                                            ? classes[`titlerow_multiple_Width_${modelo}`]
                                            : group.campos.length===1 ? classes[`titlerow_simple_Width_${modelo}`]: classes.titlerow
                                          : ['623'].includes(modelo) 
                                            ? classes[`titlerow_Width_${modelo}`]
                                            : ['666_B','666_G','666_H'].includes(idTipoApartado)
                                              ? classes[`titlerow_Width_${idTipoApartado !== '666_H' ? idTipoApartado : '666_G'}`]
                                              : classes.titlerow
                                    }
                                >
                                    {groupLabel[`${group.group}`]}
                                </Typography>
                            }
                            {group.campos && group.campos.length>0 &&
                                group.campos.map( item => {
                                    const myValue= calculateValueCampo(item, camposList, camposData, prevXml, idTipoApartado)
                                    const options = selectoresInfo && item.tipoCampoWeb === 'select' && item.selectRef !== null 
                                        ? selectoresInfo[item.selectRef as keyof SelectoresInfo] 
                                        : null

                                    return ( 
                                        <Campo 
                                            key={modelo + item.idCampo + item.casilla} 
                                            campo={item} 
                                            value={myValue}
                                            setValue={ (name, value) => updateCamposData(name, value) }
                                            options={options}
                                            isValid={(isValid) => item.isValid = isValid}
                                            textActionProps={item.textActionProps}
                                            idTipoApartado={idTipoApartado}
                                        />
                                    )
                                })
                            }
                        </Grid>
                    </div>

                ))
            }
        </div>
  );
};

export const calculateValueCampo = (
  item: ICampo,
  camposList: ICampo[],
  camposData: GenericInitialObject,
  prevXml: string,
  idTipoApartado: string
) => {
  let myValue = camposData[item.codigo]?.value ?? undefined; 
  // Nuevo campo bolean con formula - suponer formula null si es campo bool
  const formula = camposData[item.codigo] && camposData[item.codigo].formula 
    ? camposData[item.codigo].formula 
    : null;

  // console.log('myValue ', item)
  // console.log('formula ', formula)

  if (idTipoApartado!== '' && camposData && formula && formula !== "" && camposData[item.codigo].tipo !== 'exclusiveBool') {
      // 1. Obtener casillas que intervienen en la fomrula
      const myRegExp: RegExp = /(?!\[)-?[a-zA-Z0-9_.]+(?=\])/g; //(?!\[)-?\d+(?=\])/g;
      const prev_casillas = formula.match(myRegExp);
      // 2. Si existe referencia a una casilla del apartado actual (idTipoApartado=046_0)
      //    en formato idTipoApartado.Casillaextraemos el valor de la casilla
      const casillas = prev_casillas?.map((item) =>
        item.replace(`${idTipoApartado}.`, "")
      );
      // 3. Eliminar [] de la formula. Repetimos el paso 2 en la fomrula
      let formulaBraquets = formula.replaceAll(`${idTipoApartado}.`, "");
      // 4. Reemplazamos los numeros de casilla por sus valores:
      //    Nota: valores propios del apartado formato CASILLA (22), referencias a otros paratados TipoApartado.Casilla (Ej: 046_0.22)
      casillas?.forEach((itemC) => {
        let valueToReplace;
        if (itemC.includes("_")) {
          // 4.1 _ Dependencia con valores anteriores
          const regex = new RegExp(`\<${itemC}>(.+?)\<\/${itemC}\>`, "g");
          const found = prevXml.replace(/(\r\n|\n|\r)/gm, "").match(regex); // quitar saltos línea
          //console.log('found', found, regex)
          valueToReplace = found
            ? found[0].replace(`<${itemC}>`, "").replace(`</${itemC}>`, "")
            : "0";
        } else {
          // 4.2 _ Valores del apartado pueden ser conocidos o no.
          //    Nota: Si hay formula será de tipo numerico por lo que por defecto ponemos un 0
          const byCodigo = camposList.find(
            (campo) => campo.casilla === parseInt(itemC)
          );
          //console.log('byCodigo ', byCodigo, camposData ,'camposData[byCodigo.codigo] ', byCodigo && camposData ? camposData[byCodigo.codigo]: ' no hay ponemos 0')
          valueToReplace =
            byCodigo && camposData
              ? camposData[byCodigo.codigo].value
                ? String(camposData[byCodigo.codigo].value)
                : "0"
              : "0";

          //console.log('valueToReplace no _ ', valueToReplace)
        }
        formulaBraquets = formulaBraquets.replace(`[${itemC}]`, valueToReplace);
      });
      if (casillas?.length === 1) {
        // Si solo interviene una casilla no hay que ejecutar una operación
        // Descripción de conceptor pueden ir concatenadas con >: .replaceAll('&gt;', '\>')
        myValue = formulaBraquets.replaceAll("&gt;", ">");
      } else {
        //console.log('formulaBraquets ', formulaBraquets)
        myValue = formulaBraquets !== "" ? eval(formulaBraquets) : 0;
      }

    if (item.tipoCampoWeb === "decimal2") {
      myValue = Number(myValue).toFixed(2);
    }
    if (item.tipoCampoWeb === "decimal3") {
      myValue = Number(myValue).toFixed(3);
    }
  }
  return myValue;
};

/** Extrae un valor de XML
 * casillaExtraer: formato 35 o 666_A.35 dependiendo de si es un valor del apartado actual o anterior
 * xml: xml del que extraer el valor
 */
export const extraerValorXML = (casillaExtraer:string ,xml:string) => {
  // 4.1 _ Dependencia con valores anteriores
  const regex = new RegExp(`\<${casillaExtraer}>(.+?)\<\/${casillaExtraer}\>`, "g");
  const found = xml.replace(/(\r\n|\n|\r)/gm, "").match(regex); // quitar saltos línea
  // console.log('found', found, regex)
  const valueToReplace = found
    ? found[0].replace(`<${casillaExtraer}>`, "").replace(`</${casillaExtraer}>`, "")
    : undefined;
  return valueToReplace
}

export const renderResumen = (popUp: TDataPopUp[] | null) => {
  //console.log('RENDER popUp ',popUp)
  return popUp && popUp.length > 0 ? (
    <Grid container direction="column">
      {popUp.map((item) => {
        let items: JSX.Element[] = [];
        if (item.campos && item.data) {
          items = item.campos.map((campo: ICampo) => (
            <Grid item style={{ display: "inline-flex" }}>
              <Typography>{`[${campo.casilla}] ${campo.campoDescription}`}</Typography>
              <Typography>{item.data && item.data[campo.codigo]}</Typography>
            </Grid>
          ));
        }
        return items;
      })}
    </Grid>
  ) : (
    <Typography>Error recovering data</Typography>
  );
};
// ----------------------------------------------------------------------------------
/** Copia la referencia del modelo */
export const handleCopyRef = (idReferencia: string) => {
  navigator.clipboard.writeText(idReferencia);
  // internet explorer 11 and older browsers
  /**
   * window.clipboardData.setData("Text", idReferencia)
   */
};
// Icons dynamic  -------------------------------------------------------------------
export const getIcons = (iconName: string) => {
  const iconsNames = Object.keys(icons);
  //console.log("iconsNames", iconsNames);
  const matches = stringSimilarity.findBestMatch(iconName, iconsNames);
  const bestMathch = matches.bestMatch.target;
  //console.log("bestMathch", matches.bestMatch.target);

  const Icon = <></>; // Type error  --->  icons[bestMathch];
  return Icon;
};

// ----------------------------------------------------------------------------------
export const convertirTipoCodigo = (tipo: string) => {
  if(tipo.length > 1){
    let tipoVehiculoTemp = tipo;
    switch(tipo){
        case 'Turismo / Furgoneta':
        case 'Turisme / Furgoneta':
            tipoVehiculoTemp = 'T'
            break;
        case 'Todoterreno':
        case 'Tot terreny':
            tipoVehiculoTemp = 'D'
            break;
        case 'Motocicleta':
            tipoVehiculoTemp = 'M'
            break;
        case 'Remolque': 
        case 'Remolc':
            tipoVehiculoTemp = 'R'
            break;
    }
    return tipoVehiculoTemp;
  }
  return tipo
}

export const convertirTipoUniversal = (tipo: string) => {
  let tipoVehiculoTemp = tipo;
  switch(tipo){
      case 'Turismo / Furgoneta': 
      case 'Turisme / Furgoneta':
      case 'T':
          tipoVehiculoTemp = 'TURISMO'
          break;
      case 'Todoterreno': 
      case 'Tot terreny':
      case 'D':
          tipoVehiculoTemp = 'TODOTERRENO'
          break;
      case 'Motocicleta':
      case 'M':
          tipoVehiculoTemp = 'MOTOCICLETA'
          break;
      case 'Remolque':
      case 'Remolc':
      case 'R':
          tipoVehiculoTemp = 'REMOLQUE'
          break;
  }
  return tipoVehiculoTemp;
}

//Validar ---------------------------------------------------------------------------
/** Función que se repite varias veces a lo largo de los modelos para comprobar que las casillas tengan datos */
export const validateCamposRequiredKeys = (camposRequiredKeys: string[] | null, data: GenericInitialObject|null) => {
  let valid = true
  if(data && camposRequiredKeys && camposRequiredKeys.length>0){
    camposRequiredKeys.forEach( element => {
        switch (data[element].tipo) {
            case 'number':
                if(Number(data[element].value) === 0){
                    valid=false
                }
                break;
            case 'decimal': 
            case 'decimal2':
            case 'decimal3':
                if(parseFloat(data[element].value) === 0){
                    valid=false
                }
                break;
            default:
                if(!data[element].value || data[element].value === undefined || data[element].value === ""){
                    valid=false
                }
                break;
        }
    })
  }
  else {
    valid = false
  }
  return valid
}

export const getXMLValueByFormula = (formula: string, xmlData: any): string | number | null => {
    let value: string | number | null = null;
    let replacedFormula: string = formula;
    const myRegExp: RegExp = /(?!\[)-?[a-zA-Z0-9_.]+(?=\])/g //(?!\[)-?\d+(?=\])/g; 
    const prev_casillas =  formula.match(myRegExp);
    let valueToReplace: any;
    prev_casillas?.forEach((casillaItem: string) => {
        if (casillaItem.includes('_')){
            const idApartado = casillaItem.split('.')[0];
            const idApartadoData = xmlData[idApartado];
            //console.log('idApartadoData',idApartadoData);
            const regex = new RegExp(`\<${casillaItem}>(.+?)\<\/${casillaItem}\>`,'g');
            const found = idApartadoData.replace(/(\r\n|\n|\r)/gm,"").match(regex); // quitar saltos línea
            valueToReplace = found 
                ? found[0].replace(`<${casillaItem}>`,'').replace(`</${casillaItem}>`,'') 
                : '0';
            //console.log('valueToReplace', valueToReplace);
        }
        replacedFormula = replacedFormula.replace(`[${casillaItem}]`, valueToReplace); 
    });
    //console.log('replacedFormula', replacedFormula);
    value = valueToReplace ? eval(replacedFormula) : 0;
    //console.log('value',value);
    return value
}
// ----------------------------------------------------------------------------------

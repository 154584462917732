import { FC, useContext, useMemo, useState, useEffect } from 'react';
import { ICampo, IMunicipioBonificado } from '../../../gateways/model.new.interface';
import {  CircularProgress, Dialog, DialogContent} from '@material-ui/core';
import { Button, makeStyles } from '@material-ui/core';
import styles from './styles';
import { formatXMLtoObject, GenericInitialObject, getEmptyObject, getKeysRequiredCampos, renderCampos,  formatOptions, SelectoresInfo, GenericObject, getInitialObject, isSameData, mixData, GroupLabel, calculateValueCampo} from '../utils';
import IoC from 'contexts/ioc.context';
import ModelGateway from 'gateways/model.new.gateway';
import { translate } from 'utils/i18n';
import { AlertsContext } from 'contexts/alerts.context';
import { LiteralsContext, withLiterals } from 'containers/shared/literals';
import { GeoGateway } from 'gateways/geo.gateway';
import { Municipio, Provincia, Sigla} from 'gateways/geo.interface';
import { desgloseCampos, extractSingleValueXMLPrev, getCasillasToExtractZeroCompare, getGroupLabel } from './datos666_utils';



const useStyles = makeStyles(styles);

interface Props {
    idTipoModelo: string,
    idTipoApartado: string,
    datosXmlApartadosPrevios: GenericObject,
    datosXml: string | null,
    setFinished: (value: boolean) => void,
    handleSave: (data: any) => Promise<boolean>,
    withEjercicioPeriodo: boolean

}

const Datos666: FC<Props> = (props) => {
    const {
        idTipoModelo,
        idTipoApartado,
        datosXmlApartadosPrevios,
        datosXml,
        setFinished,
        handleSave,
        withEjercicioPeriodo

    } = props;
    
    const classes = useStyles();
    const [, alertsDispatch] = useContext(AlertsContext);
    const terms = useContext(LiteralsContext);
    //Gateways
    const ioc = useContext(IoC);
    const modelGateway: ModelGateway = useMemo(() => ioc.get(ModelGateway), [ioc]);
    const geoGateway: GeoGateway = useMemo(() => ioc.get(GeoGateway), [ioc]);
    //Local data
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<GenericInitialObject|null>(null);
    const [changes, setChanges] = useState(false);
    const [campos, setCampos] = useState<ICampo[] | undefined>(undefined);
    const [camposGrouped, setCamposGrouped] = useState<{group:number,campos:ICampo[]}[]| []>([]);
    const [groupLabel,setGroupLabel] = useState<GroupLabel>({})

    const [EMPTY_Object,setEmptyObject] = useState<GenericInitialObject| null>({});
    const [camposRequiredKeys, setCamposRequiredKeys] = useState<string[]| null>(null);
    //const [selectoresInfo, setSelectoresInfo] = useState<SelectoresInfo| null>(null);
    //const [saved, setSaved] = useState(false)
    const [checkValues, setCheckValues] = useState<{casilla: number; valorEsperado: number;}[] | null>(null)




  

    
    // Functions - Get data ------------------------------------------------------------------------------------------------------
    const updateData = ( name: string, value: any) => {
        setChanges(true)
        let newData=null;
        
        if(EMPTY_Object){
            newData= data 
            ? {...data, [name]: {...data[name], value: value }}
            : {...EMPTY_Object,[name]: {...EMPTY_Object[name], value: value }}
        }

        if(newData && newData[name].tipo === 'exclusiveBool' && value === true){
            // 1. Obtener casillas que intervienen en la formula
            const myRegExp: RegExp = /(?!\[)-?[a-zA-Z0-9_.]+(?=\])/g; //(?!\[)-?\d+(?=\])/g;
            const casillas = newData[name].formula.match(myRegExp);
            //2. setear a falso todos los valores de la formula
            if(casillas){
                for (const key in newData) {
                    if (Object.prototype.hasOwnProperty.call(newData, key)) {
                        const element = newData[key];
                        if(casillas.includes(element.casilla.toString())){
                            newData[key] = {...newData[key], value: false}
                        }
                        
                    }
                }
            }
        }
        //console.log(newData)
        setData(newData)
    }

    const onSave = ()=> { 
        (async() => {
            setLoading(true);
            // comprobar campos required 
            let valid= true;
            //console.log('on save camposRequiredKeys', camposRequiredKeys)
            if(camposRequiredKeys && camposRequiredKeys.length>0 ){
                if(data){
                    camposRequiredKeys.forEach( element => {
                        switch (data[element].tipo) {
                            case 'number':
                                if(Number(data[element].value) === 0){
                                    valid=false
                                }
                                break;
                            case 'decimal':
                            case 'decimal2':
                            case 'decimal3':
                                if(parseFloat(data[element].value) === 0){
                                    valid=false
                                }
                                break;
                            default:
                                if(!data[element].value || data[element].value === undefined || data[element].value === ""){
                                    valid=false
                                }
                                break;
                        }
                    })
                } else {
                    valid = false;
                }
            }
            if (valid) {
                valid = campos?.findIndex(campo => campo.isValid != undefined && !campo.isValid && campo.visible) == -1;
            }
            /** Revisar valores con tabla 666_B */
            //console.log('checkValues',checkValues)
            let showAlertTarifa = false
            if(checkValues && valid && campos){
                for (const key in data) {
                    const tocheck = checkValues.find( itm => itm.casilla === data[key].casilla)
                    // Evaluamos el valor actual del campo si tiene formula o lo extraemos de data
                    const campo = campos.find( c => c.codigo === key) ?? {} as ICampo
                    const value = Number(campo.formula 
                        ? calculateValueCampo(campo, campos, data, '', idTipoApartado)
                        : data[key].value)
                    //tocheck && console.log('compare ',  value, '--  esperado -->', tocheck.valorEsperado,value !== tocheck.valorEsperado)
                    // Revisamos si el valor esperado para la tarifa coincide con su resumen anual
                    if(tocheck &&  value !== tocheck.valorEsperado){
                        valid = false
                        showAlertTarifa=true
                    }
                }
            }

            if(valid){
                const savedTemp = await handleSave(data); 
                setChanges(!savedTemp);
            } else {
                setChanges(true);
                if(showAlertTarifa){
                    alertsDispatch({
                        type: 'show-alert',
                        payload: {
                            message: translate('Tributos','FormularioTarifa666Error', terms),
                            variant: 'error',
                        }
                    });
                } else {
                    alertsDispatch({
                        type: 'show-alert',
                        payload: {
                            message: translate('Tributos','FormularioError', terms),
                            variant: 'error',
                        }
                    });
                }
            }
            setLoading(false);

        })();
    }

    // ---------------------------------------------------------------------------------------------------------------------------
    useEffect(() => {
        (async()=> {
            try{
                setLoading(true)
                //console.log('idTipoApartado', idTipoApartado)
                //const delegacion = await modelGateway.getDelegacion(translate('Tributos', 'GetSelectDelegacionError', terms))
                const camposApartadoBBDD = await modelGateway.getCamposApartado(idTipoApartado, idTipoModelo, translate('Tributos', 'GetCamposApartadoError', terms))
                
                const camposApartadoGroups = desgloseCampos(camposApartadoBBDD, idTipoApartado);
                //console.log('camposApartadoGroups ', camposApartadoGroups)
                const camposApartado = (camposApartadoGroups.map( cap => cap.campos) as ICampo[][]).flat()
                //console.log('camposApartado ', camposApartado)
                
                let emptyObjt = null;
                let newData: GenericInitialObject | null = null;
                let dataXML: GenericInitialObject | null = null;
                let dataPrevios: GenericInitialObject | null = null;
                let changes= false;

                //console.log('condicion', camposApartado && camposApartado.length>0 )
                if(camposApartado && camposApartado.length>0){
                    emptyObjt= getEmptyObject(camposApartado)
                    //console.log('emptyObjt', emptyObjt)
                    setEmptyObject(emptyObjt);
                    const keys = getKeysRequiredCampos(camposApartado);
                    setCamposRequiredKeys(keys)
                    const datosPrevString = Object.values(datosXmlApartadosPrevios).join('')
                    //console.log('datosPrevString ', datosPrevString)
                    //console.log('datosXml ', datosXml)
                    if(datosXml){
                        dataXML = formatXMLtoObject(datosXml,emptyObjt);
                        //console.log('USE datosXML', dataXML)
                    } 
                    if(datosPrevString){
                        dataPrevios = getInitialObject(camposApartado, datosPrevString, idTipoApartado);
                        //console.log('useDatos datosPrevString', dataPrevios)
                        if(['666_G', '666_H']. includes(idTipoApartado)){
                            const casillasToEval = getCasillasToExtractZeroCompare(idTipoApartado)
                            const casillasEval = casillasToEval?.map( item => {
                                const v = extractSingleValueXMLPrev( item.valueToRecover,datosPrevString )
                                return({casilla: item.conditionedCasilla, valorEsperado: v ?? 0})
                            })
                            setCheckValues(casillasEval)
                            //console.log('casillasEval ', casillasEval)
                        }
                    }
                    
                    //console.log('isSameData:', isSameData(dataXML, dataPrevios))
                    if(isSameData(dataXML, dataPrevios)){
                        changes=false
                        setFinished(true);
                        newData= dataXML
                    } else {
                        const mixDataObjt = mixData(dataXML, dataPrevios, idTipoApartado)
                        changes=true
                        newData = mixDataObjt ?? newData
                    }

                }

                setCampos(camposApartado);
                setCamposGrouped(camposApartadoGroups);
                setGroupLabel(getGroupLabel(idTipoApartado, terms))
                setData(newData || emptyObjt);
                setChanges(changes)
                setLoading(false);

            } catch (error) {
                console.log('effect DATOS Error', error)
                const result = (error as Error).message;
                setLoading(false)
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: result,
                        variant: 'error',
                    }
                }); 
            }
        })();
    },[idTipoApartado, idTipoModelo, datosXml, datosXmlApartadosPrevios]);

    useEffect(() => {
        if(changes){
            setFinished(false)
        }else{
            setFinished(true)
        }
    },[changes])

   
    
    return(
        <div> 

            <Dialog open={loading}>
                <DialogContent>
                    <CircularProgress size={35} />
                </DialogContent>
            </Dialog>

            {campos && campos.length>0 && camposRequiredKeys !== null && data &&
                renderCampos(campos, data, updateData, Object.values(datosXmlApartadosPrevios).join(''), idTipoApartado, undefined, groupLabel, classes )
            }

            <div className={classes.rowAlignRight}>
                <Button 
                    style={{marginLeft: 10}}
                    color='primary'
                    disabled={!changes}
                    variant='contained' 
                    onClick={onSave}
                >
                    {translate('Tributos','btnSave',terms)} 
                </Button>
            </div>
        </div>
    )
}
export default withLiterals(['Tributos'])(Datos666);




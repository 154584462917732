import React, { useEffect, FC, useState, useMemo, useContext } from "react";
import { RouteComponentProps } from "react-router";
import usePage from "hooks/page.hook";
import Icon from "@mdi/react";
import {
  mdiAlphaACircleOutline,
  mdiAlphaLCircleOutline,
  mdiFileDocumentMultipleOutline
} from '@mdi/js';
import {
  Typography,
  Button,
  Tooltip,
  Dialog,
  DialogActions,
  Checkbox,
  FormControlLabel,
  DialogContent,
  CircularProgress,
  DialogTitle,
  Card,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { translate } from "utils/i18n";
import {
  extraerCasillaValor,
  formatXMLMultipletoObject,
  formatXMLtoObject,
  GenericInitialObject,
  GenericObject,
  getEmptyObject,
  getXML,
  getXMLValueByFormula,
  handleCopyRef,
  TipoTributo,
} from "./utils";

import { LiteralsContext, withLiterals } from "containers/shared/literals";

import styles from "./tributos-autonomicos.styles";

import ModelGateway from "../../gateways/model.new.gateway";
import { SujetosGateway } from "../../gateways/sujetos.gateway";
import { ICampo, IModeloVehiculo, IStep, ITipoModelo } from "gateways/model.new.interface";
import { ISujeto } from "gateways/perfil.interfaces";

import IoC from "contexts/ioc.context";
import { AlertsContext } from "contexts/alerts.context";
import { ModelosActionContext } from "contexts/modelos-action.context";
import { TInfoTemplateSize } from "contexts/more_info.reducer";

import StepperModels, { TStepsContent } from "../../components/Modelos/stepper-models";

import Term from "components/term";
import SujetoDeclarante from "./componentes/sujeto-declarante";
import Autoliquidacion from "./componentes/autoliquidacion";
import FechaFinalizacion from "./componentes/fecha-finalizacion";
import SujetoLogueado from './componentes/sujeto-logueado';
import FixedApartadoMultiple from './componentes/fixed-apartado-multiple';
import Conceptos from "./componentes/conceptos";
import Identificativo from "./componentes/identificativo";
import DeclaracionTrimestral from "./componentes/declaracion-trimestral";
import Liquidacion8xx from "./componentes/liquidacion-8xx";
import ApartadoMultiple from "./componentes/apartado-multiple";
import Datos from "./componentes/datos";
import PreviosTecnicos from "./componentes/previos-tecnicos";
import Liquidacion621 from "./componentes/liquidacion621";
import { TIPOS_ASISTENTE } from "./constants";
import Datos656D from "./componentes/datos656D";
import Vehiculo071 from "./componentes/vehiculo071";
import SujetoPasivo071 from "./componentes/HOCs/withSujetoPasivo071";
import getISODay from "date-fns/getISODay";
import { getStepperConselleriaIMG } from "./images-utils";
import { Alert } from "react-bootstrap";
import M071Lotes from "./componentesPorLotes/M071";
import { download } from "utils/download";

const useStyles = makeStyles(styles);
//Modelos que usan IMG en el stepper
const MODELOS_WITHIMG = ['046'];

export type TDataPopUp = {
  campos: ICampo[];
  data: GenericInitialObject | null;
  dataMultiple: GenericInitialObject[] | null;
  apartado: string;
};

interface IParams {
  textTipoTributo: TipoTributo;
  idTipoModelo: string;
  action: "nuevo" | string;
}
type Props = RouteComponentProps<IParams>;

const ModelosTributosPorLotes: FC<Props> = ({ match, history }) => {
  const classes = useStyles();
  const [pageState, pageDispatcher] = usePage();
  const isLogged = pageState.jwp !== null;
  const [, alertsDispatch] = useContext(AlertsContext);
  const terms = useContext(LiteralsContext);
  //States - Context
  //Gatways
  const ioc = useContext(IoC);
  const modelGateway: ModelGateway = useMemo(
    () => ioc.get(ModelGateway),
    [ioc]
  );

  //Functions
  const redirectToPago = (token: string) => {
    history.push(`/pasarela-pago/pago/${token}`, {modeloMultiple: true})
  };

  const exportarModelos = async (idModelos: string[], tipoDoc: 'excel'|'xml', idTipoModelo: string) => {
    try {
      const result = await modelGateway.exportarModelos(idModelos, tipoDoc, idTipoModelo)
      download(result.blob, alertsDispatch,
        translate('Global', 'No se ha podido exportar', terms),
        translate('Global', 'Exportar', terms), 
        result.fileName
      );
    } catch (error) {
      alertsDispatch({
        type: 'show-alert',
        payload: {
          variant:'error',
          message: translate('Global', 'No se ha podido exportar', terms)
        }
      })
    }

  }


  
  useEffect(() => {
    const title = `${translate("Tributos", "Modelo", terms)} ${match.params.idTipoModelo}: ${translate("Modelos", `${match.params.idTipoModelo}Multi`,terms )}`;
    pageDispatcher({
      type: "setHeader",
      header: {
        icon: match.params.textTipoTributo === 'autonomicos'? mdiAlphaACircleOutline: mdiAlphaLCircleOutline,
        title: title,
      },
      menu: true,
    });
  }, [pageDispatcher, match.params.idTipoModelo]);



  // ----------------------------------------------------------------------------------

  return (
    <div className={classes.container}>
      <Card className={classes.cardContainerModAction}>
        {match.params.idTipoModelo === '071' &&
          <M071Lotes goToPago={redirectToPago} exportData={exportarModelos}/>
        }
      </Card>
    </div>

  );
};

export default withLiterals(["Tributos", "Modelos", "Global"])(ModelosTributosPorLotes);



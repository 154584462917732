
import { FC, useContext, useMemo, useRef, useState } from "react";
import { Button, StyleRules, Theme, Typography, WithStyles, withStyles } from "@material-ui/core";
import { translate } from "utils/i18n";
import { LiteralsContext, withLiterals } from "containers/shared/literals";

import { ISolicitud } from "gateways/tramites/abonoNacimiento.interfaces";

import { AlertsContext } from "contexts/alerts.context";
import GenericCard from "components/generic-card";
import moment from "moment";
import { AbonoNacimientoGateway } from "gateways/tramites/abonoNacimiento.gateway";
import IoC from "contexts/ioc.context";
import FullLoading from "components/full-loading";
import { download } from "utils/download";
import Icon from "@mdi/react";
import { mdiCard, mdiCreditCard, mdiPrinter, mdiSignatureFreehand } from "@mdi/js";

import aprovadoIMG from "../../../../resources/abonoNacimiento/aprovado.png"
import rechazadoIMG from "../../../../resources/abonoNacimiento/rechazado.png"
import iniciadaIMG from "../../../../resources/abonoNacimiento/iniciada.png"
import pendFirmaIMG from "../../../../resources/abonoNacimiento/firmar.png"
import despresentadaIMG from "../../../../resources/abonoNacimiento/Cancelado.png"


import reintFirmaIMG from "../../../../resources/abonoNacimiento/ReintegroFirmar.png"
import reintPagoIMG from "../../../../resources/abonoNacimiento/reintegroPagar.png"

import reintFinIMG from "../../../../resources/abonoNacimiento/devolucionFinalizada.png"


import { EstadoSolicitud } from "../enums/estadoSolicitud.enum";
import { TributosAutonomicosGateway } from "gateways/tributos.autonomicos.gateway";




type IProps = WithStyles<typeof styles>  & {info: ISolicitud, handleFirma: (info: ISolicitud) => void,  handlePagar: (info: ISolicitud) => void, handlePrint: (info:ISolicitud, tipo: 'justificante' | 'resolucion') => void}
const CardSolicitudDetalle : FC<IProps> = ({classes, info, handleFirma ,handlePagar, handlePrint}) => {

    const terms=useContext(LiteralsContext)
    const [,alertsDispatch] = useContext(AlertsContext);

    // Gateways
    const ioc = useContext(IoC);
    const abonoNacimientoGateway: AbonoNacimientoGateway = useMemo(() => ioc.get(AbonoNacimientoGateway), [ioc]);   
    const tributosAutonomicosGateway: TributosAutonomicosGateway = useMemo(() => ioc.get(TributosAutonomicosGateway), [ioc]); 

    
    // const [page, pageDispatcher] = usePage();
    const [loading, setLoading] = useState(false)

    // -- Memos ----------------------------------------------------------------------------------------------------------------------
    const showBtnFirma = useMemo(() => {
        if(!info){ return false}
        if(EstadoSolicitud['RPF']=== info.estado.id || EstadoSolicitud['SPF']=== info.estado.id){ return true }
        return false
    },[info]);

    // -- Functions-------------------------------------------------------------------------------------------------------------------
    const getIconoEstado = (estado: number)=>{
        switch (EstadoSolicitud[estado]) {
            case 'SPF':
                return pendFirmaIMG;
            case 'SAA':
                return aprovadoIMG;
            case 'SDE':
                return rechazadoIMG;
            case 'RPF':
                return reintFirmaIMG;
            case 'RPP':
                return reintPagoIMG;
            case 'RPI' :
                return reintFinIMG;
            case 'SDP':
                return despresentadaIMG;
            default:
                return iniciadaIMG;
        }
    }

    // -- Effects --------------------------------------------------------------------------------------------------------------------

    return (
        <GenericCard withTitle={false} withActions={false} withLeftIcon={false}>
            <div className={[classes.rowContainer].join(' ')}>
                <div className={[classes.row, classes.center].join(' ')}>
                    {/** Estado icono */}
                    <div className={[classes.row, classes.bottom, classes.paddingIMG].join(' ')}>
                        <img src={getIconoEstado(info.estado.id)}/>
                        {/* <Typography> ICONO </Typography> */}
                    </div>
                    {/** Detalle  */}
                    <div className={[classes.column, classes.marginDetail].join(' ')}>
                        <div className={[classes.row, classes.bottom].join(' ')}>
                            <Typography className={classes.label}>{translate('AbonoNacimiento','refSolicitud',terms)}:</Typography>
                            <Typography className={classes.text}>{`${info.ejercicio}/${info.numSolicitud}`}</Typography>
                        </div>
                        <div className={[classes.row, classes.bottom].join(' ')}>
                            <Typography className={classes.label}>{translate('AbonoNacimiento','estado',terms)}:</Typography>
                            <Typography className={classes.text}>{translate('AbonoNacimiento',info.estado.term,terms)}</Typography>
                        </div>
                        <div className={[classes.row, classes.bottom].join(' ')}>
                            <Typography className={classes.label}>{translate('AbonoNacimiento','tipoDocumento',terms)}:</Typography>
                            <Typography className={classes.text}>{translate('AbonoNacimiento',info.tipoDocumento.term,terms)}</Typography>
                        </div>
                        {info.modalidad?.id &&
                            <div className={[classes.row, classes.bottom].join(' ')}>
                                <Typography className={classes.label}>{translate('AbonoNacimiento','tipoModalidad',terms)}:</Typography>
                                <Typography className={classes.text}>{translate('AbonoNacimiento',info.modalidad.term,terms)}</Typography>
                            </div>
                        }
                        <div className={[classes.row, classes.bottom].join(' ')}>
                            <Typography className={classes.label}>{translate('AbonoNacimiento','fechaAlta',terms)}:</Typography>
                            <Typography className={classes.text}>{moment.utc(info.fechaAlta).format('DD/MM/YYYY HH:mm')}</Typography>
                        </div>
                        {info.idModelo && 
                            <div className={[classes.row, classes.bottom].join(' ')}>
                                <Typography className={classes.label}>{translate('AbonoNacimiento','localizador',terms)}:</Typography>
                                <Typography className={classes.text}>{info.idModelo}</Typography>
                                {info.modeloImporte && 
                                    <div className={[classes.row, classes.bottom].join(' ')} style={{marginLeft: 10}}>
                                        <Typography className={classes.label}>{translate('AbonoNacimiento','importeAingresar',terms)}:</Typography>
                                        <Typography className={classes.text}>{info.modeloImporte} €</Typography>
                                    </div>
                                }
                            </div>
                        }
                        {info.tipoDocumento.id === 50 && info.importe &&
                            <div className={[classes.row, classes.bottom].join(' ')}>
                                <Typography className={classes.label}>{translate('AbonoNacimiento','importeSolicitud',terms)}:</Typography>
                                <Typography className={classes.text}>{info.importe} €</Typography>
                            </div>
                        }
                    
                        
                    </div>
                </div>
                <div className={classes.column}>
                    {EstadoSolicitud['RPP'] === info.estado.id ?
                        <Button style={{marginBottom: 10}}
                            variant="contained" 
                            color="primary" 
                            onClick={() => handlePagar(info)}
                            startIcon= { <Icon path={mdiCreditCard} size={1} color={"#fff"} /> } 
                        >
                            {translate('Global', 'Pagar', terms)}
                        </Button>
                    : null}
                    {showBtnFirma ?
                        <Button style={{marginBottom: 10}}
                            variant="contained" 
                            color="primary" 
                            onClick={()=>handlePrint(info, 'justificante')} // () => handleFirma(info)}
                            startIcon= { <Icon path={mdiSignatureFreehand} size={1} color={"#fff"} /> } 
                        >
                            {translate('Global', 'Presentar'/*'Firmar'*/, terms)}
                        </Button>
                    : null}
                    <Button 
                        variant="contained" 
                        color="primary" 
                        onClick={() => handlePrint(info, 'justificante')}
                        disabled={ !([EstadoSolicitud['SPT'],EstadoSolicitud['SAP'], EstadoSolicitud['SAA'], EstadoSolicitud['RPI'], EstadoSolicitud['SDE'], EstadoSolicitud['AAE']].includes(info.estado.id)) }
                        startIcon= { <Icon path={mdiPrinter} size={1} color={"#fff"} /> } 
                    >
                        {translate('Global', 'Imprimir', terms)}
                    </Button>
                    {EstadoSolicitud['SDE'] === info.estado.id && 
                        <Button 
                            variant="outlined" 
                            color="primary" 
                            style={{marginTop: 10}}
                            onClick={() => handlePrint(info, 'resolucion')}
                            startIcon= { <Icon path={mdiPrinter} size={1} color={"#2196f3"} /> } 
                        >
                            {translate('Global', 'ImprimirResolucion', terms)}
                        </Button>
                    }
                </div>
            </div>
            <FullLoading loading={loading}/>
        </GenericCard>

    )
}

const styles = (theme: Theme): StyleRules => ({
    container: {
        height: '100%',
        overflowY: 'auto'
    },
    root: {
        flexGrow: 1,
        overflowY:'auto',
        margin: '0px 10px 0px 10px',
        
    },
    textContainer:{
        padding: '0px 10px 0px 10px'
    },
    row: {
        display:'flex',
        flexDirection:'row',
    },
    column: {
        minWidth: '218px',
        display:'flex',
        flexDirection:'column',
    },
    rowContainer:{
        display:'flex',
        flexDirection:'row',
        gap: '10px',
        justifyContent: 'space-between',
        alignItems:'center'
    },
    bottom: {
        alignItems: 'flex-end'
    },
    center: {
        alignItems: 'center'
    },
    marginDetail: {
        marginLeft: 20,
    },
    text:{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        // color: '#000',
        // fontSize: 14,
        fontWeight: 700
    },
    label: {
        color: '#000',
        fontSize: 14,
        marginTop: 5,
        //marginBottom: 5,
        marginRight: 5
    },
    paddingIMG: {
        paddingRight: 10,
        paddingLeft: 10
    }

   
});

export default  withLiterals(['Global', 'Tramites','AbonoNacimiento' ])(withStyles(styles)(CardSolicitudDetalle)); 

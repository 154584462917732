import React, {CSSProperties, FC, useContext} from "react"
import { Checkbox, FormControl, FormControlLabel,FormHelperText, makeStyles, StyleRules, Theme, Typography, withStyles } from "@material-ui/core";

import { ALL_LITERALS, LiteralsContext, withLiterals } from "containers/shared/literals";
import { translate } from "utils/i18n";
import { TInfoTemplateSize } from "contexts/more_info.reducer";
import GenericLabelWithInfo from "components/generic-label-with-info";
import styles from "./styles";

interface IGenericCheckbox {
    componentIG:string
    labelTerm: string | null; // Label a la derecha del check
    renderComponentLabel?: () => JSX.Element; // Si en lugar de label se quiere renderizar un componente
    value: boolean; // valor de check
    onChangeValue: (v:boolean) => void // cambia valor del check
    moreInfo?: {template?: string, size?: TInfoTemplateSize},
    error?:boolean,
    errorMsg?:string,
    addToLabel?: string
    disabled?: boolean,
    boldLabel?: boolean,
    style?: CSSProperties,
    fullWidth?:boolean,
}
const stylesComponent = (theme: Theme): StyleRules => ({
    rootCheckBox: {
        margin: 0
    }
})
const useStylesComponent = makeStyles(stylesComponent);
const GenericCheckbox: FC<IGenericCheckbox> = ({
    value, onChangeValue, labelTerm, renderComponentLabel,  moreInfo, error ,errorMsg, addToLabel, disabled, style,fullWidth=false, boldLabel=false, componentIG="GenericComponents" 
})=>{
    const terms = useContext(LiteralsContext);
    const classesComponent= useStylesComponent();

    return (
        <FormControl component="fieldset" style={{paddingTop: 5, display: fullWidth ? 'flex' : undefined}} >
            
            <div style={{...style, display:'flex', flexDirection: 'row', alignItems:'center', padding:0}}>
                <FormControlLabel 
                    disabled={disabled}
                    control={
                        <Checkbox style={{alignSelf: 'flex-start', paddingRight: 10,  paddingLeft: 10, paddingTop: 0, paddingBottom: 0}}
                            checked={value}
                            onChange={(ev, checked) => { 
                                onChangeValue(checked) }}
                            name="checkedB"
                            color="primary"
                        />
                    }
                    label={
                        labelTerm !== null ?
                            <Typography style={{fontWeight: boldLabel ? 'bold': 'normal',margin:0}} >
                                { translate(componentIG ,labelTerm,terms).concat(addToLabel ?? '')}
                            </Typography>
                            : renderComponentLabel ? renderComponentLabel() : ''
                        }
                    classes={{
                        root: labelTerm || renderComponentLabel ? undefined : classesComponent.rootCheckBox 
                    }}
                />
                <GenericLabelWithInfo componentIG={componentIG} type="field" labelTerm={""} moreInfo={moreInfo} />
            </div>
            {error && <FormHelperText style={{paddingLeft:33}} error={true} >{errorMsg}</FormHelperText> }
            
        </FormControl>
    )
}

export default withLiterals(ALL_LITERALS)(withStyles(styles)(GenericCheckbox));
      
import { Theme } from "@material-ui/core";
import { StyleRules } from "@material-ui/styles";
import { BLUE, BLUE_SECONDARY } from "containers/shared/colors";

export const colors = {
   blueIconInfo: BLUE_SECONDARY,
   white: '#FFF',
   blueBtn: BLUE,
}
const styles =  (theme: Theme): StyleRules => ({
    radioBtn_Field: {
        fontWeight: 'bold'
    }
})

export default styles
import React, { FC, useContext, useMemo, useState, useEffect } from 'react';
import { ICampo, IMunicipioBonificado } from '../../../gateways/model.new.interface';
import {  CircularProgress, Dialog, DialogContent, Tooltip, Typography} from '@material-ui/core';
import { Button, makeStyles } from '@material-ui/core';
import styles from './styles';
import { formatXMLtoObject, GenericInitialObject, getEmptyObject, getKeysRequiredCampos, renderCampos,  identificativoFormatter, formatOptions, SelectoresInfo, GenericObject, getInitialObject, isSameData, mixData, TPeriodo, OPTIONS_trimestre, CAMPOS_PERIODO, OPTIONS_mensual, MODELO_PERIODO} from '../utils';
import IoC from 'contexts/ioc.context';
import ModelGateway from 'gateways/model.new.gateway';
import { translate } from 'utils/i18n';
import { AlertsContext } from 'contexts/alerts.context';
import { LiteralsContext, withLiterals } from 'containers/shared/literals';
import { ISujeto } from 'gateways/perfil.interfaces';
import { GeoGateway } from 'gateways/geo.gateway';
import { Municipio, Provincia, Isla, Sigla} from 'gateways/geo.interface';
import EndProcessMSG from 'components/Modelos/componentes/endProcessMSG';



const useStyles = makeStyles(styles);

interface Props {
    idTipoModelo: string,
    idTipoApartado: string,
    datosXmlApartadosPrevios: GenericObject,
    datosXml: string | null,
    setFinished: (value: boolean) => void,
    handleSave: (data: any) => Promise<boolean>,
    withEjercicioPeriodo: boolean

}

const Devolucion: FC<Props> = (props) => {
    const {
        idTipoModelo,
        idTipoApartado,
        datosXmlApartadosPrevios,
        datosXml,
        setFinished,
        handleSave,
        withEjercicioPeriodo

    } = props;
    
    const classes = useStyles();
    const [, alertsDispatch] = useContext(AlertsContext);
    const terms = useContext(LiteralsContext);
    //Gateways
    const ioc = useContext(IoC);
    const modelGateway: ModelGateway = useMemo(() => ioc.get(ModelGateway), [ioc]);
    const geoGateway: GeoGateway = useMemo(() => ioc.get(GeoGateway), [ioc]);
    //Local data
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<GenericInitialObject|null>(null);
    const [changes, setChanges] = useState(false);
    const [campos, setCampos] = useState<ICampo[] | undefined>(undefined);
    const [EMPTY_Object,setEmptyObject] = useState<GenericInitialObject| null>({});
    const [camposRequiredKeys, setCamposRequiredKeys] = useState<string[]| null>(null);
    const [selectoresInfo, setSelectoresInfo] = useState<SelectoresInfo| null>(null);


    const [isThisPeriod, setIsThisPeriod]= useState(false);
    const [paramPeriod, setParamPeriod]= useState<string[]>([])

    const CAMPOS_COND_73 = [75,98]
    const [camposCond73Codigo, setCamposCond73Codigo]= useState<string[]>([])


  

    
    // Functions - Get data ------------------------------------------------------------------------------------------------------
    const updateData = ( name: string, value: any) => {
        setChanges(true)
        let newData=null;
        if(EMPTY_Object){
            newData = data 
            ? {...data, [name]: {...data[name], value: value }}
            : {...EMPTY_Object,[name]: {...EMPTY_Object[name], value: value }}
        }

        if(newData && newData[name].tipo === 'exclusiveBool' && value === true){
            // 1. Obtener casillas que intervienen en la formula
            const myRegExp: RegExp = /(?!\[)-?[a-zA-Z0-9_.]+(?=\])/g; //(?!\[)-?\d+(?=\])/g;
            const casillas = newData[name].formula.match(myRegExp);

            //2. setear a falso todos los valores de la formula
            if(casillas){
                for (const key in newData) {
                    if (Object.prototype.hasOwnProperty.call(newData, key)) {
                        const element = newData[key];
                        //console.log('element ', element)
                        if(casillas.includes(element.casilla.toString())){
                            newData[key] = {...newData[key], value: false}
                        }
                        
                    }
                }
            }
        }

       
        if(idTipoApartado === '656_E' && newData && (newData[name].casilla === 72 || newData[name].casilla === 73) ){
            if(newData[name].casilla === 72){
                //console.log('C72', value, '  --- camposCond73Codigo ', camposCond73Codigo)
                if(value === true){
                    // Resetear valores campos C75 y C98 si está el check C72 activo
                    for( const key of camposCond73Codigo){
                        newData[key] = {...newData[key], value: undefined}
                    }
                }
            } 
            // 656_E : Actualizar visibilidad de los campos C75 y C98 solo si está el check C73 activo
            setCampos( curr => { 
                const newC = curr?.map( c => CAMPOS_COND_73.includes(c.casilla) 
                    ? ({...c, visible: newData[name].casilla === 73 && value === true ? true : false })
                    : ({...c})
                );
                return newC
            })            
        }

        console.log('newData ', newData)
        setData(newData)
    }

    const onSave = ()=> { 
        (async() => {
            setLoading(true);
            // comprobar campos required 
            let valid= true;
            //Recalculamos reqKeys para evitar error por dinamismo en campos
            const reqKeys = campos ? getKeysRequiredCampos(campos) : [];
            if(reqKeys && reqKeys.length>0 ){
                if(data){
                    reqKeys.forEach( element => {
                        switch (data[element].tipo) {
                            case 'number':
                                if(Number(data[element].value) === 0){
                                    valid=false
                                }
                                break;
                            case 'decimal':
                            case 'decimal2':
                            case 'decimal3':
                                if(parseFloat(data[element].value) === 0){
                                    valid=false
                                }
                                break;
                            default:
                                if(!data[element].value || data[element].value === undefined || data[element].value === ""){
                                    valid=false
                                }
                                break;
                        }
                    })
                } else {
                    valid = false;
                }
            }
            if (valid) {
                valid = campos?.findIndex(campo => campo.isValid != undefined && !campo.isValid && campo.visible) == -1;
            }
            if(valid){
                const savedTemp = await handleSave(data); 
                setChanges(!savedTemp);


            } else {
                //setFinished(false);
                setChanges(true);
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: translate('Tributos','FormularioError', terms),
                        variant: 'error',
                    }
                });

            }
            setLoading(false);

        })();
    }

    // ---------------------------------------------------------------------------------------------------------------------------
    useEffect(() => {
        (async()=> {
            try{
                setLoading(true)
                //console.log('idTipoApartado', idTipoApartado)
                const delegacion = await modelGateway.getDelegacion(translate('Tributos', 'GetSelectDelegacionError', terms))
                let camposApartado = await modelGateway.getCamposApartado(idTipoApartado, idTipoModelo, translate('Tributos', 'GetCamposApartadoError', terms))
                const periodoCampo = withEjercicioPeriodo
                    ? camposApartado.filter( c => CAMPOS_PERIODO.includes(c.codigo)) 
                    : undefined
                

                
                let emptyObjt = null;
                let newData: GenericInitialObject | null = null;
                let dataXML: GenericInitialObject | null = null;
                let dataPrevios: GenericInitialObject | null = null;
                let changes= false;
                let reqKeys: string[] = []

                //console.log('condicion', camposApartado && camposApartado.length>0 )
                if(camposApartado && camposApartado.length>0){
                    emptyObjt= getEmptyObject(camposApartado)
                    //console.log('emptyObjt', emptyObjt)
                    setEmptyObject(emptyObjt);
                    reqKeys = getKeysRequiredCampos(camposApartado);
                    
                    const datosPrevString = Object.values(datosXmlApartadosPrevios).join('')
                    //console.log('datosPrevString ', datosPrevString)
                    //console.log('datosXml ', datosXml)
                    if(datosXml){
                        dataXML = formatXMLtoObject(datosXml,emptyObjt);
                        //console.log('USE datosXML', dataXML)
                    } 
                    if(datosPrevString){
                        dataPrevios = getInitialObject(camposApartado, datosPrevString, idTipoApartado);
                        //console.log('useDatos datosPrevString', dataPrevios)
                    }
                    
                    //console.log('isSameData:', isSameData(dataXML, dataPrevios))
                    if(isSameData(dataXML, dataPrevios)){
                        changes=false
                        newData= dataXML
                    } else {
                        const mixDataObjt = mixData(dataXML, dataPrevios, idTipoApartado)
                        changes=true
                        newData = mixDataObjt ?? newData
                    }

                }

                // Condiciona el componente a todo disabled si aplica a un solo periodo del ejercicio
                let apply = true
                if(withEjercicioPeriodo && periodoCampo){
                    //console.log('newData ', newData, periodoCampo)
                    if(newData && MODELO_PERIODO[idTipoModelo]){
                        // Ver si es último periodo
                        const miData = periodoCampo[0].codigo
                        const miPeriodo = MODELO_PERIODO[idTipoModelo] //OPTIONS_trimestre.find( opt => opt.id === MODELO_PERIODO[idTipoModelo])?.nombre ?? 'not-found'
                        const periodoUserSelect =  newData[miData].value
                        const ejercicio = periodoUserSelect.substring(0,4)
                        const periodo = periodoUserSelect.replace(ejercicio, '') // OPTIONS_trimestre.find( opt => opt.id === MODELO_PERIODO[idTipoModelo])?.nombre ?? 'not-found'
                        //console.log('campo Val: ', newData[miData].value, 'periodo Usr: ', periodo, miPeriodo)
                        if(miPeriodo.length > 0  && periodo) {
                            const textPeriodo = periodo.includes('T') ? OPTIONS_trimestre.find(opt => opt.id === periodo) : OPTIONS_mensual.find(opt => opt.id === periodo)
                            //console.log('textPeriodo ', textPeriodo?.nombre)
                            const txt = textPeriodo 
                                ? periodo.includes('T') 
                                    ? `${ejercicio} - ${textPeriodo.nombre}` 
                                    : `${ejercicio}/${textPeriodo.id}` 
                                :'-'
                            setParamPeriod([txt])
                        }
                        apply = miPeriodo.includes(periodo) //periodoUserSelect.includes(miPeriodo)
                        changes = apply ? changes : false
                        //console.log('campo: ', miData, 'periodoAplica: ', miPeriodo, ' es mi periodo ? ',apply, 'periodoSeelccionado:', periodo)
                    }
                }
                let finalData = newData || emptyObjt;
                
                
                if(idTipoApartado === '656_E'){
                    //console.log('camposApartado PRE UPDATED ', camposApartado)
                    // 656_E:Solo hay devolición si casilla 98 llega con valor negativo
                    const campoC98 = camposApartado.find(c => c.casilla === 98)?.codigo
                    if(finalData && campoC98){
                        const value98 = campoC98 && finalData && finalData[campoC98] ? Number(finalData[campoC98].value ?? 0) : 0
                        
                        finalData = {
                            ...finalData, 
                            [campoC98]  : {...finalData[campoC98], value: value98 < 0 ? Math.abs(value98): "0.00", formula: '' }
                        }
                        
                    }
                    // 656_E : Visibilidad de los campos C75 y C98 solo si está el check C73 activo
                    //         Eliminar fórmula de la C98 - ya se ha gestionado su valor
                    const campoC73 = camposApartado.find(c => c.casilla === 73) 
                    const val = campoC73 && finalData ? ( finalData[campoC73.codigo].value === true ? true: false ) : false;
                    const campCondCodigo: string[] = []
                    camposApartado = camposApartado.map( c => { 
                        console.log(c.codigo, c.casilla, c.casilla === 98)
                        if(CAMPOS_COND_73.includes(c.casilla)){
                            campCondCodigo.push(c.codigo)
                            return ({...c, visible: val /*, formula: c.casilla === 98? '': c.formula*/ })
                        } else {
                            return ({...c})
                        }  
                    })
                    setCamposCond73Codigo(campCondCodigo)
                    reqKeys = getKeysRequiredCampos(camposApartado)

                    //console.log('final compare ', dataXML, finalData, isSameData(dataXML, finalData))
                    changes = !isSameData(dataXML, finalData)
                    
                }

                setCamposRequiredKeys(reqKeys)

                // console.log('finalData ', finalData)
                // console.log('camposApartado ', camposApartado)
                setCampos(
                    withEjercicioPeriodo && !apply 
                    ? camposApartado.map(c=> ({...c, soloLectura: true}))
                    : camposApartado
                );
                setIsThisPeriod(apply)
                setData(finalData);
                setChanges(changes)

                //Empty Selectores data - si es necesario
                const provinciaBaleares = '07'
                const optionsTV = await geoGateway.getSiglas()
                const optionsProv = await geoGateway.getProvincias('')
                const selected: Provincia|null|undefined = optionsProv && newData?.provincia?.value 
                    ? optionsProv.find(opt => (opt.nombre === newData?.provincia?.value.toString() || opt.idProvincia === newData?.provincia?.value.toString())) 
                    : null
            
                const optionsMuni = await geoGateway.getMunicipios(selected?.idProvincia || provinciaBaleares)
                const optionsMuniBonificado = await modelGateway.getAllMunicipiosBonificados(idTipoModelo, 'tipoGravamen')
                setSelectoresInfo({
                    municipio: optionsMuni && optionsMuni.length>0 ? formatOptions<Municipio>('idMunicipio','nombre',optionsMuni) : null,
                    muniBonificado: optionsMuniBonificado.length ? formatOptions<IMunicipioBonificado>('acodmunigest','nombre', optionsMuniBonificado) : null,
                    provincia: optionsProv.length>0 ? formatOptions<Provincia>('idProvincia','nombre', optionsProv) : null,
                    tipoVia: optionsTV.length>0 ? formatOptions<Sigla>('idSiglas','nombre', optionsTV, {componentIG: 'Modelos'}) : null,
                    oficina: delegacion
                })

                setLoading(false);

            } catch (error) {
                console.log('effect DATOS Error', error)
                const result = (error as Error).message;
                setLoading(false)
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: result,
                        variant: 'error',
                    }
                }); 
            }
        })();
    },[idTipoApartado, idTipoModelo, datosXml, datosXmlApartadosPrevios]);

    useEffect(() => {
        if(changes){
            setFinished(false)
        }else{
            setFinished(true)
        }
    },[changes])

   
    
    return(
        <div> 
            <div className={classes.rowAlignLeft}>
                {(withEjercicioPeriodo && isThisPeriod) || (!withEjercicioPeriodo)  && 
                    <Button 
                        style={{marginLeft: 10}}
                        color='primary'
                        variant='contained' 
                        disabled={ data === null || data === EMPTY_Object }
                        onClick={() => {
                            setData(EMPTY_Object)
                            setChanges(true)
                        }}
                    > 
                        {translate('Tributos','btnClean',terms)} 
                    </Button>
                }
            </div>
            <Dialog open={loading}>
                <DialogContent>
                    <CircularProgress size={35} />
                </DialogContent>
            </Dialog>

            {(withEjercicioPeriodo && isThisPeriod) || (!withEjercicioPeriodo) || loading
                ? null
                :   <EndProcessMSG 
                        type='info'
                        componentIG='Tributos'
                        termIG={'soloCumplimentarPeriodoUltimoPeriodo'}
                        paramTermIG={paramPeriod}  
                    />        
            }
            {campos && campos.length>0 && camposRequiredKeys !== null && data && selectoresInfo &&
                renderCampos(campos, data, updateData, Object.values(datosXmlApartadosPrevios).join(''), idTipoApartado, selectoresInfo, undefined, classes )
            }

            <div className={classes.rowAlignRight}>
                <Button 
                    style={{marginLeft: 10}}
                    color='primary'
                    disabled={!changes}
                    variant='contained' 
                    onClick={onSave}
                >
                    {translate('Tributos','btnSave',terms)} 
                </Button>
            </div>
        </div>
    )
}
export default withLiterals(['Tributos','Modelos'])(Devolucion);




import React, { FC, useContext, useMemo, useState, useEffect, useCallback } from 'react';
import { ICampo, IFuncionesValidar, IModelo, IModeloVehiculo } from '../../../gateways/model.new.interface';
import { CircularProgress, Dialog, DialogContent, FormControl, InputLabel, ListItemText, MenuItem, Select, TextField} from '@material-ui/core';
import { Button, makeStyles } from '@material-ui/core';
import styles from './styles';
import { extractCasillaFromPreviousXml, formatXMLtoObject, GenericInitialObject, GenericObject, getEmptyObject, getKeysRequiredCampos, renderCampos, SelectoresInfo, validateCamposRequiredKeys, convertirTipoCodigo, previosTecnicosFormatter, convertirTipoUniversal, getCategoriaElectrica } from '../utils';
import IoC from 'contexts/ioc.context';
import ModelGateway from 'gateways/model.new.gateway';
import { translate } from 'utils/i18n';
import { AlertsContext } from 'contexts/alerts.context';
import { LiteralsContext, withLiterals } from 'containers/shared/literals';
import { ISujeto } from 'gateways/perfil.interfaces';
import moment from 'moment';
import { ContenidosGateway } from 'gateways/contenido.gateway';
import { DescripcionVehiculo } from 'gateways/funciones-621.interfaces';
import Term from 'components/term';


const useStyles = makeStyles(styles);

interface Props {
    idTipoModelo: string,
    idTipoApartado: string,
    datosXml: string | null,
    loggedSujeto: ISujeto | null,
    datosXmlApartadosPrevios: GenericObject,
    setFinished: (value: boolean) => void,
    handleSave: (data: any) => Promise<boolean>,
    modeloSelectedExternal: number | null,
    setModeloSelectedExternal: (value: number | null) => void,
    tipoMotorSelectedExternal: string | null,
    setTipoMotorSelectedExternal: (value: string | null) => void,
    modelosVehiculoExternal: IModeloVehiculo[],
    setModelosVehiculoExternal: (value: IModeloVehiculo[]) => void,
    modeloVehiculoExternal: IModeloVehiculo | null,
    setModeloVehiculoExternal: (value: IModeloVehiculo | null) => void,
    showModelosExternal: boolean,
    setShowModelosExternal: (value: boolean) => void,
    modeloBase: string;
    setModeloBase: (value: string) => void;
    etiquetaEcoExternal: string;
    setEtiquetaEcoExternal: (value: string) => void;
}

const PreviosTecnicos: FC<Props> = (props) => {
    const {
        idTipoModelo,
        idTipoApartado,
        loggedSujeto,
        datosXml,
        datosXmlApartadosPrevios,
        setFinished,
        handleSave,
        modeloSelectedExternal,
        setModeloSelectedExternal,
        tipoMotorSelectedExternal,
        setTipoMotorSelectedExternal,
        modelosVehiculoExternal,
        setModelosVehiculoExternal,
        modeloVehiculoExternal,
        setModeloVehiculoExternal,
        etiquetaEcoExternal,
        setEtiquetaEcoExternal,
        showModelosExternal,
        setShowModelosExternal,
        modeloBase,
        setModeloBase,
    } = props;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const VALIDAR_DATOS = ['621']
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const needsValidation = useMemo(( ) => VALIDAR_DATOS.includes(idTipoModelo), [VALIDAR_DATOS, idTipoModelo])
    const [validado, setValidado] = useState(!needsValidation)
    const classes = useStyles();
    const [, alertsDispatch] = useContext(AlertsContext);
    const terms = useContext(LiteralsContext);
    const [body, setBody] = useState('');
    //Gateways
    const ioc = useContext(IoC);
    const contenidoGateway: ContenidosGateway = ioc.get(ContenidosGateway);
    const modelGateway: ModelGateway = useMemo(() => ioc.get(ModelGateway), [ioc]);
    //Local data
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<GenericInitialObject|null>(null);
    const [changes, setChanges] = useState(false);
    const [campos, setCampos] = useState<ICampo[] | null>(null);
    const [EMPTY,setEMPTY] = useState<GenericInitialObject| null>({});
    const [camposRequiredKeys, setCamposRequiredKeys] = useState<string[]| null>(null);
    const [modeloSelected, setModeloSelected] = useState<number | null>(modeloSelectedExternal);
    const [tipoMotorSelected, setTipoMotorSelected] = useState<string | null>(tipoMotorSelectedExternal);
    const [tipoMotor, ] = useState<string[]>(['Diesel',  'Gasolina'])
    const [etiquetaEco, setEtiquetaEco] = useState(etiquetaEcoExternal)
    const [modelosVehiculo, setModelosVehiculo] = useState<IModeloVehiculo[]>(modelosVehiculoExternal); 
    const [modeloVehiculo, setModeloVehiculo] = useState<IModeloVehiculo | null>(modeloVehiculoExternal); 
    const [fabricante, setFabricante] = useState<string>(extractCasillaFromPreviousXml(Object.values(datosXmlApartadosPrevios).join(''), "621_0.48")); 
    const [modelo, setModelo] = useState<string>(extractCasillaFromPreviousXml(Object.values(datosXmlApartadosPrevios).join(''), "621_0.49")); 
    const [cilindrada, setCilindrada] = useState<string>(extractCasillaFromPreviousXml(Object.values(datosXmlApartadosPrevios).join(''), "621_0.53")); 
    const [fechaMatriculacion, setFechaMatriculacion] = useState<string>(extractCasillaFromPreviousXml(Object.values(datosXmlApartadosPrevios).join(''), "621_0.68")); 

    const [showModelos, setShowModelos] = useState<boolean>(showModelosExternal); 
    const [infoSelect, setInfoSelect]= useState<SelectoresInfo | null>({
        tipoVehiculo: [ 
            {id: 'T', nombre: translate('Global', 'Turismo/Furgoneta', terms)},
            {id: 'M', nombre: translate('Global', 'Motocicleta', terms)}, 
            {id: 'D', nombre: translate('Global', 'Todoterreno', terms)}, 
            {id: 'R', nombre: translate('Global', 'Remolque', terms)}, 
        ],          
    });

    const loadTemplate = useCallback(async () => {
            setLoading(true);
            setBody("");
            const bodyAux = await contenidoGateway.getContent(
                "EligeModelo",
                {}
            );
            setBody(bodyAux[0].contenido);
            setLoading(false);
    }, [contenidoGateway]);

    useEffect(() => {
        loadTemplate();
    }, [ loadTemplate]);
    // Functions - Get data ------------------------------------------------------------------------------------------------------
    const updateData = ( name: string, value: any, isValid?: boolean, second?: string, secondValue?: any) => {
        setChanges(true)
        setValidado(!needsValidation)
        let newData=null;
        if(EMPTY){
            newData= data 
            ? {...data, [name]: {...data[name], value: value }}
            : {...EMPTY,[name]: {...EMPTY[name], value: value }}
        }
        if(second !== undefined && newData !== null){
            newData = {...newData, [second]: {...newData[second], value: secondValue }}
        }
        setData(newData)
    }
    const handleChangeTipoMotor = (value: string) => {
        setTipoMotorSelected(value);
        setTipoMotorSelectedExternal(value)
        if(data !== null && value === "Diesel"){
            updateData("motorDiese", true, true, "motorGasol", false)
        }else{
            updateData("motorDiese", false , true, "motorGasol", true)
        }
    };
    const handleChangeModelo = useCallback((value: number) => {
        setModeloSelected(value);
        setModeloSelectedExternal(value);
        setChanges(true);
        setValidado(!needsValidation);
    }, [modeloSelected, modeloSelectedExternal, changes, needsValidation]);

    const handleClear = () => {
        setData(EMPTY)
        setChanges(true)
        setValidado(!needsValidation);
        setModeloSelected(null);
        setModelosVehiculo([]);
        setModeloVehiculo(null);
        setModelosVehiculoExternal([]);
        setModeloVehiculoExternal(null);
        setModeloSelectedExternal(null);
        setTipoMotorSelected(null);
        setTipoMotorSelectedExternal(null);
        setEtiquetaEcoExternal('');
        setShowModelos(false);
        setShowModelosExternal(false);
        setEtiquetaEco('');
        setModeloBase('');
    }
    const checkExternal = () => {
        if(modelosVehiculoExternal.length === 0){
            setChanges(true)
            setValidado(!needsValidation)
            setFinished(false)
        }
    }

    const getModeloVehiculo = (modelosVehiculo: any, descripcionVehiculo: DescripcionVehiculo) => {
        try {            
            if (modeloSelected) {
                let modeloVehiculo = modelosVehiculo.find((modelo: any) => modelo.idModelo === modeloSelected);
                if (modeloVehiculo) {
                    setModeloVehiculo(modeloVehiculo);
                    setModeloVehiculoExternal(modeloVehiculo);
                } else {
                    setShowModelos(true);
                    setShowModelosExternal(true);
                    modeloVehiculo = modelosVehiculo[0];
                }

                return modeloVehiculo;
            } else {
                let modeloVehiculo = modelosVehiculo.find((modelo: any) => modelo.nombre.replace(/\s/g, '').toUpperCase() === descripcionVehiculo.modelo.replace(/\s/g, '').toUpperCase());
                if (modeloVehiculo) {
                    setModeloVehiculo(modeloVehiculo);
                    setModeloVehiculoExternal(modeloVehiculo);
                } else {
                    setShowModelos(true);
                    setShowModelosExternal(true);
                    modeloVehiculo = modelosVehiculo[0];
                }

                return modeloVehiculo;
            }
        } catch (error) {
            return modelosVehiculo[0];
        }
    }

    const validatePrevios = async () => {
        try{
        setLoading(true)
        let errorMsg: string = ""
        let valid = validateCamposRequiredKeys(camposRequiredKeys, data)
        let nifVendedor = extractCasillaFromPreviousXml(Object.values(datosXmlApartadosPrevios).join(''), "621_B.19")
        let nifComprador = extractCasillaFromPreviousXml(Object.values(datosXmlApartadosPrevios).join(''), "621_A.5")
        let nombreVendedor = extractCasillaFromPreviousXml(Object.values(datosXmlApartadosPrevios).join(''), "621_B.20")
        let tipoVehiculoTemp: string = ''
        if(nombreVendedor && nifVendedor && valid && data && nifComprador) {
            tipoVehiculoTemp = convertirTipoCodigo(data['IDTIPO'].value)
            let response = await modelGateway.validateConsultaDGT(extractCasillaFromPreviousXml(Object.values(datosXmlApartadosPrevios).join(''), "621_B.504") as string, nombreVendedor.split(' ')[0], nifVendedor, true)
                if(response.ok){
                    let datosVehiculo = response.data;
                    let modelosResponse = await modelGateway.getModelosVehiculo(10, tipoVehiculoTemp, datosVehiculo, new Date())
                    if(modelosResponse.ok) {
                    const modelosVehiculo = modelosResponse.data;
                    const modeloVehiculo = getModeloVehiculo(modelosVehiculo, datosVehiculo.datosGenerales.descripcionVehiculo);
                    const marca = datosVehiculo.datosGenerales.descripcionVehiculo.marca.descripcion
                    const cvf = datosVehiculo.datosTecnicos.potencias.potenciaFiscal.toString() 
                    // const fechaMatriculacion = moment(datosVehiculo.datosGenerales.matriculacion.fechaMatriculacion).toDate()
                    
                    const fechaMatriculacion = moment(datosVehiculo.datosGenerales.fechasControl.fechaPrimeraMatriculacion !== undefined 
                        ? datosVehiculo.datosGenerales.fechasControl.fechaPrimeraMatriculacion 
                        : datosVehiculo.datosGenerales.fechasControl.fechaMatriculacion).toDate()

                    const cilindrada = datosVehiculo.datosTecnicos.potencias.cilindrada
                    const fechaDeven = moment(data["fechaDeven"].value, "DD/MM/YYYY").toDate()
                    setFabricante(datosVehiculo.datosGenerales.descripcionVehiculo.marca.descripcion)   
                    setModelo(datosVehiculo.datosGenerales.descripcionVehiculo.modelo)
                    setFechaMatriculacion(moment(fechaMatriculacion).format("DD/MM/YYYY"))
                    setCilindrada(cilindrada.toString())
                    const bastidor = datosVehiculo.datosGenerales.descripcionVehiculo.bastidor
                    let importeResponse = await modelGateway.calcularImporte(
                        marca, 
                        modeloVehiculo.idModelo as number, 
                        cvf, 
                        convertirTipoUniversal(data['IDTIPO'].value), 
                        cilindrada, 
                        fechaDeven,
                        fechaMatriculacion, 
                        data['valorDecla'].value
                        )

                        let codigoCET = await modelGateway.getCodigoCET(fechaDeven, bastidor, nifComprador)
                        let co2 = await modelGateway.getCO2( bastidor)
                        setModelosVehiculo(modelosVehiculo);
                        setModelosVehiculoExternal(modelosVehiculo);
                        if(modeloSelected === null || modelosVehiculo.find((x: IModelo) => x.idModelo === modeloVehiculo.idModelo) === undefined){
                            setModeloSelected(modeloVehiculo.idModelo)
                            setModeloSelectedExternal(modeloVehiculo.idModelo)
                        }
                        
                        setModeloBase(datosVehiculo.datosGenerales.descripcionVehiculo.modelo)
                        setEtiquetaEcoExternal(getCategoriaElectrica(datosVehiculo.datosTecnicos.combustibleEmisiones.categoriaElectrica) || '')
                        setEtiquetaEco(getCategoriaElectrica(datosVehiculo.datosTecnicos.combustibleEmisiones.categoriaElectrica) || '')
                        setValidado(needsValidation);
                        setChanges(false);
                        if(importeResponse.ok){
                            let datosCuota = await modelGateway.getCuota(
                            moment(fechaDeven).format("DD/MM/YYYY"),
                            moment(fechaMatriculacion).format("DD/MM/YYYY"),
                            datosVehiculo.datosGenerales.matriculacion.matricula,
                            datosVehiculo.datosTecnicos?.combustibleEmisiones?.categoriaElectrica,
                            cilindrada.toString(),
                            data['valorDecla'].value,
                            cvf,
                            co2,
                            tipoVehiculoTemp,
                            datosVehiculo.datosGenerales.descripcionVehiculo.tipoVehiculo.descripcion
                            )
                            let tipoMotor: string | null = null;
                            if(tipoMotorSelected === null && datosVehiculo.datosTecnicos.combustibleEmisiones.propulsion !== null){
                                if(datosVehiculo.datosTecnicos.combustibleEmisiones.propulsion.descripcion === "DIESEL" ){
                                    setTipoMotorSelected('Diesel')
                                    setTipoMotorSelectedExternal('Diesel')
                                    tipoMotor = "Diesel"
                                }
                                else if (datosVehiculo.datosTecnicos.combustibleEmisiones.propulsion.descripcion === "GASOIL" || 
                                datosVehiculo.datosTecnicos.combustibleEmisiones.propulsion.descripcion === "GASOLINA") {
                                    setTipoMotorSelected('Gasolina')
                                    setTipoMotorSelectedExternal('Gasolina')
                                    tipoMotor = "Gasolina"
                                }
                            }
                            else{
                                tipoMotor = tipoMotorSelected
                            }
                            let vehiculosEco = ['HEV', 'GNC', 'GNL', 'GLP']
                            let zeroEmisiones = ['BEV', 'REEV', 'PHEV']
                            let tipo;
                            if (cilindrada > 50)
                            {
                                if (new Date(fechaDeven).getFullYear() >= 2016) {
                                tipo = 4;
                                if (parseFloat(cvf) > 15) {
                                    tipo = 8;
                                }
                                if (vehiculosEco.includes(datosVehiculo.datosTecnicos.combustibleEmisiones.categoriaElectrica)) {
                                    tipo = 2;
                                }
                                if (zeroEmisiones.includes(datosVehiculo.datosTecnicos.combustibleEmisiones.categoriaElectrica)) {
                                    tipo = 0;
                                }
                                //total = (total * CalcularDepreciacion()) / 100.00;    
                            }
                        }
                        const newData = previosTecnicosFormatter(data, datosVehiculo, codigoCET.data, co2, datosCuota, tipoMotor, tipoVehiculoTemp, data['IDTIPO'].value, tipo?.toFixed(2))
                        setData(newData);
                        setValidado(needsValidation);
                    }
                    else{
                        errorMsg = importeResponse.errorMessage;    
                    }
                }else{
                    errorMsg = modelosResponse.errorMessage;
                }
            }else{
             errorMsg = response.errorMessage;   
            }
        }else{
            errorMsg = 'FormularioError';
        }
        if(errorMsg !== ""){
            setChanges(true);
            setValidado(!needsValidation)
            const bodyAux = await contenidoGateway.getContent(
                errorMsg,
                {}
            );
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    isHtml: true,
                    message: bodyAux[0].contenido,
                    variant: 'error',
                }
            });
            setLoading(false);
        }
        setLoading(false)
    }
    catch(e){
        const bodyAux = await contenidoGateway.getContent(
            'ErrorDesconocido2',
            {}
          );
        alertsDispatch({
            type: 'show-alert',
            payload: {
                isHtml: true,
                message: bodyAux[0].contenido,
                variant: 'error',
            }
        });
        setLoading(false)
    }
    }

    const onSave = ()=> { 
        (async() => {
            setLoading(true);
            // comprobar campos required 
            let valid= true;
            //console.log('on save camposRequiredKeys', camposRequiredKeys)
            let prueba = 'decimal2'
            if(camposRequiredKeys && camposRequiredKeys.length>0 ){
                if(data){
                    camposRequiredKeys.forEach( element => {
                        switch (prueba) {
                            case 'number':
                                if(Number(data[element].value) === 0){
                                    valid=false
                                }
                                break;
                            case 'decimal':
                            case 'decimal2':
                            case 'decimal3':
                                if(parseFloat(data[element].value) === 0){
                                    valid=false
                                }
                                break;
                            default:
                                if(!data[element].value|| data[element].value === undefined || data[element].value === ""){
                                    valid=false
                                }
                                break;
                        }
                    })
                } else {
                    valid = false;
                }
            }

            if (valid) {
                valid = campos?.findIndex(campo => campo.isValid != undefined && !campo.isValid && campo.visible) == -1;
            }
 
            if(valid){
                const saved = await handleSave(data); 
                //setFinished(saved);
                setChanges(false);
            } else {
                //setFinished(false);
                setChanges(true);
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: translate('Tributos','FormularioError', terms),
                        variant: 'error',
                    }
                });
            }
            setLoading(false);

        })();
    }
    // ---------------------------------------------------------------------------------------------------------------------------
    useEffect(() => {
        (async()=> {
            try{
                setLoading(true)
                const delegacion = await modelGateway.getDelegacion(translate('Tributos', 'GetSelectDelegacionError', terms))
                const camposApartado = await modelGateway.getCamposApartado(idTipoApartado, idTipoModelo, translate('Tributos', 'GetCamposApartadoError', terms))
                //console.log('camposApartado', camposApartado)
                setCampos(camposApartado);
                let emptyObjt = null;
                let newData: GenericInitialObject | null = null;
                if(camposApartado && camposApartado?.length>0){
                    emptyObjt= getEmptyObject(camposApartado)
                    //console.log('emptyObjt', emptyObjt)
                    setEMPTY(emptyObjt);
                    const keys = getKeysRequiredCampos(camposApartado);
                    setCamposRequiredKeys(keys)
                    if(datosXml){
                        //console.log('USE datosXML')
                        newData = formatXMLtoObject(datosXml,emptyObjt);
                        setChanges(false);
                        setFinished(true); 
                    } else{
                        setChanges(true); 
                        newData = {...emptyObjt,
                        ejercicio: {...emptyObjt['ejercicio'], value: moment().year() }}
                    }
                }
                //console.log('newData', newData)
                setLoading(false)
                setData(newData);
                setInfoSelect({...infoSelect, delegacion: delegacion});
            } catch (error) {
                const result = (error as Error).message;
                setLoading(false)
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: result,
                        variant: 'error',
                    }
                }); 
            }
            checkExternal()
        })();
    },[idTipoApartado, idTipoModelo,loggedSujeto, datosXml]);

    useEffect(() => {
        if(changes){
            setFinished(false)
        }else{
            setFinished(true)
        }
    },[changes])

    useEffect(() => {
        if (validado && needsValidation) {
          onSave();
        }
      }, [data]);
         
    const FUNCIONES_VALIDATE: IFuncionesValidar = {
        '621': validatePrevios 
    }
    
    return(
        <div> 
            <div className={classes.rowAlignLeft}>
  
                <Button 
                    style={{marginLeft: 10}}
                    color='primary'
                    variant='contained' 
                    disabled={ data === null || data === EMPTY }
                    onClick={() => {
                        handleClear()
                    }}
                > 
                    {translate('Tributos','btnClean',terms)} 
                </Button>
                

            </div>
            <Dialog open={loading}>
                <DialogContent>
                    <CircularProgress size={35} />
                </DialogContent>
            </Dialog>
            {campos && campos.length>0 && camposRequiredKeys !== null && data && infoSelect &&
                renderCampos(campos, data, updateData, Object.values(datosXmlApartadosPrevios).join(''), idTipoApartado, infoSelect, undefined, classes )
            }
                    <FormControl className={fabricante ? '' : classes.hiddenSelector} style={{margin: 10, width: 360}}>
                        {/* <InputLabel >{translate('Tributos','modeloVehiculo',terms)}</InputLabel> */}
                        <TextField
                            value={fabricante}
                            disabled
                            placeholder='Modelo'
                            variant='outlined'
                            label={<Term component="Tributos" text="fabricante" />}
                        >
                        </TextField>
                    </FormControl>
                    <FormControl className={modelo ? '' : classes.hiddenSelector} style={{margin: 10, width: 360}}>
                        {/* <InputLabel >{translate('Tributos','modeloVehiculo',terms)}</InputLabel> */}
                        <TextField
                            value={modelo}
                            disabled
                            placeholder='Modelo'
                            variant='outlined'
                            label={<Term component="Tributos" text="Modelo" />}
                        >
                        </TextField>
                    </FormControl>
                    <FormControl className={cilindrada ? '' : classes.hiddenSelector} style={{margin: 10, width: 360}}>
                        {/* <InputLabel >{translate('Tributos','modeloVehiculo',terms)}</InputLabel> */}
                        <TextField
                            value={cilindrada}
                            disabled
                            placeholder='Modelo'
                            variant='outlined'
                            label={<Term component="Tributos" text="cilindrada " />}
                        >
                        </TextField>
                    </FormControl>
                    <FormControl className={fechaMatriculacion ? '' : classes.hiddenSelector} style={{margin: 10, width: 360}}>
                        {/* <InputLabel >{translate('Tributos','modeloVehiculo',terms)}</InputLabel> */}
                        <TextField
                            value={fechaMatriculacion}
                            disabled
                            placeholder='Modelo'
                            variant='outlined'
                            label={<Term component="Tributos" text="fechaMatri" />}
                        >
                        </TextField>
                    </FormControl>
                {showModelos &&  modelosVehiculo.length > 1 ?
                <>
                    <div style={{marginLeft: 11, fontSize:15}} dangerouslySetInnerHTML={{ __html: body + modeloBase }} />

                    <FormControl className={modeloSelected !== null ? '' : classes.hiddenSelector} style={{margin: 10, width: 360}}>
                        <InputLabel >{translate('Tributos','modeloVehiculo',terms)}</InputLabel>
                        <Select
                            value={modeloSelected || 0}
                            onChange={(e) => handleChangeModelo(e.target.value as number)}
                            placeholder='Modelo'
                        >
                            {(modelosVehiculo).map(option => {return(
                                <MenuItem key={option.nombre} value={option.idModelo}>
                                    <ListItemText primary={`${option.nombre}${option.anyoComercial && option.anyoComercial !== '' ? ` (${option.anyoComercial})` : ''}`} />
                                </MenuItem>
                            )})}
                        </Select>
                    </FormControl>
                    <FormControl className={modeloSelected === null ? '' : classes.hiddenSelector} style={{margin: "23px 10px", width: 360}}>
                        <TextField
                            value={modelosVehiculo[0].nombre}   
                            label={translate('Tributos','modeloVehiculo',terms)}
                            disabled
                        >
                        </TextField>
                    </FormControl>
                    { etiquetaEco !== '' && 
                    <FormControl className={etiquetaEco !== '' ? '' : classes.hiddenSelector} style={{margin: "23px 10px", width: 360}}>
                        <InputLabel >{translate('Tributos','etiquetaEco',terms)}</InputLabel>
                            <TextField
                                value={etiquetaEco}   
                                label={translate('Tributos','etiquetaEco',terms)}
                                disabled
                            >
                            </TextField>
                    </FormControl>
                    }
                    <FormControl className={tipoMotorSelected !== null ? '' : classes.hiddenSelector} style={{margin: 10, width: 360}}>
                        <InputLabel >{translate('Tributos','carburante',terms)}</InputLabel>
                        <Select
                            value={tipoMotorSelected || ''}
                            onChange={(e) => handleChangeTipoMotor(e.target.value as string)}
                            >
                            {(tipoMotor).map((option, i) => {return(
                                <MenuItem key={option + i} value={option}>
                                    <ListItemText primary={option} />
                                </MenuItem>
                            )})}
                        </Select>

                    </FormControl>
                </>
                : <></>}
            
            <div className={classes.rowAlignRight}>
            {
                VALIDAR_DATOS.includes(idTipoModelo) ? 
                <Button 
                style={{marginLeft: 10}}
                color='primary'
                disabled={!changes}
                variant='contained' 
                onClick={FUNCIONES_VALIDATE[idTipoModelo]}
                >
                {translate('Tributos','btnValidate',terms)} 
                </Button>
                :
                <Button 
                    style={{marginLeft: 10}}
                    color='primary'
                    disabled={!changes}
                    variant='contained' 
                    onClick={onSave}
                >
                    {translate('Tributos','btnSave',terms)} 
                </Button>
            }
            </div>
        </div>
    )
}
export default withLiterals(['Tributos'])(PreviosTecnicos);

import React, { useCallback, useContext } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, withStyles, WithStyles } from '@material-ui/core';
import getAllKeys from 'utils/wizard';
import { ALL_LITERALS, LiteralsContext, withLiterals } from 'containers/shared/literals';
import { translate } from 'utils/i18n';
import styles from "./styles";
import Term from 'components/term';
import Alert from '@material-ui/lab/Alert';


interface IProps<T> {
    data: T[];
    hiddenCells: (keyof T)[];
}
type StyledIProps<T> = IProps<T> & WithStyles<typeof styles>;
interface Error {
    key: string, 
    message: string
};

const GenericTableWizard = <T,>({ data, hiddenCells, classes}: StyledIProps<T>) => {
    
    const terms = useContext(LiteralsContext);

    //console.log('GenericTableWizard ', data)
    
    const getCellValueTitle = (index: number, key: string): any => {        
        if (key !== 'errors' && !hiddenCells.some(c => c === key)) {
            return (
                <TableCell key={`${key}-${index}`} className={`${classes.bold} ${classes.fixedWidth}`}>
                    <Term component={"Wizard"} text={key} />
                </TableCell>
            );
        }
    }

    const getRowValue = (item: any, index: number): any => {
        const hasErrors = item.errors && item.errors.length > 0;
        const colSpan = Object.keys(item).length - 1;
        
        return (
            <>
                <TableRow className={hasErrors ? classes.rowError : ''} key={`columnWizard${index}`}>
                    { getAllKeys(data as any[]).map(key => (getCellValue(item, key))) }
                </TableRow>
                {hasErrors && 
                    <TableRow key={'error'}>
                        <TableCell colSpan={colSpan} className={classes.alertCell}>
                        <Alert  severity='error'>
                            <div className={classes.column}>
                                {item.errors.map((error: Error, i: number) => (
                                    <div className={classes.row} >
                                        <span className={classes.bold} style={{marginRight: 5}}>
                                            <Term component={"Wizard"} text={error.key} />{': '}
                                        </span>
                                        <Term component={"Wizard"} text={error.message} />
                                    </div>
                                ))}
                            </div>
                        </Alert>
                    </TableCell>
                    </TableRow>
                }
            </>
        );
    }

    const getCellValue = (item: any, key: string): any => {
        if (key !== 'errors' && !hiddenCells.some(c => c === key)) {
            const value = key in item ? item[key as keyof typeof item] : '';

            return (
                <TableCell key={key}>
                    {value}
                </TableCell>
            );
        }
    }

    const getFileDataErrorNumber = useCallback((fileData: any[]) => {
        const errorList = fileData.filter((item) => item.errors && item.errors.length>0);
        return (
            <div>
                <span className={classes.totalNumLine}>{translate('Wizard', 'Total', terms)} {fileData.length} {translate('Wizard', 'Registros', terms)}{' /'}</span>  
                <span className={classes.errorLine}> {translate('Wizard', 'con_error', terms)} {errorList.length} {translate('Wizard', 'Registros', terms)}</span>
            </div>
        )
    },[]);

    return (
        <>
            <p className={classes.titleFile}>{getFileDataErrorNumber(data as any[])}</p>
            <Table>
                <TableHead>
                    <TableRow className={classes.header}>
                        {
                            data && getAllKeys(data as any[]).map((key, index) => getCellValueTitle(index, key))
                        }
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        data && data.sort((a:any, b:any) => (a.errors.length > b.errors.length ? -1 : 1)).map((item: any, index: any) => (getRowValue(item, index)))
                    }
                </TableBody>
            </Table>
        </>
    );
};
export default withLiterals(ALL_LITERALS)(withStyles(styles)(GenericTableWizard)) as <T>(props: IProps<T>) => JSX.Element;

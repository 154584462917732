import React, { FC, useContext, useMemo, useState, useEffect } from 'react';
import { ICampo } from '../../../gateways/model.new.interface';
import {  CircularProgress, Dialog, DialogContent} from '@material-ui/core';
import {  makeStyles } from '@material-ui/core';
import styles from './styles';

import { CAMPOS_PERIODO, GenericInitialObject, GenericObject, getEmptyObject, getInitialObject, getKeysRequiredCampos, MODELO_PERIODO, renderCampos } from '../utils';

import IoC from 'contexts/ioc.context';
import ModelGateway from 'gateways/model.new.gateway';
import { translate } from 'utils/i18n';
import { AlertsContext } from 'contexts/alerts.context';
import { LiteralsContext, withLiterals } from 'containers/shared/literals';
import FullLoading from 'components/full-loading';




const useStyles = makeStyles(styles);

interface Props {
    idTipoModelo: string,
    idTipoApartado: string,
    datosXml: string | null,
    datosXmlApartadosPrevios: GenericObject,
    setFinished: (value: boolean) => void,
    handleSave: (data: any) => Promise<boolean>,
}

const FechaFinalizacion: FC<Props> = (props) => {
    const {
        idTipoModelo,
        idTipoApartado,
        datosXmlApartadosPrevios,
        datosXml,
        setFinished,
        handleSave
    } = props;
    
    const classes = useStyles();
    const [, alertsDispatch] = useContext(AlertsContext);
    const terms = useContext(LiteralsContext);
    //Gateways
    const ioc = useContext(IoC);
    const modelGateway: ModelGateway = useMemo(() => ioc.get(ModelGateway), [ioc]);
    //Local data
    const [loading, setLoading] = useState(false); 
    const [data, setData] = useState<GenericInitialObject|null>(null);
    const [camposRequiredKeys, setCamposRequiredKeys] = useState<string[]| null>(null);
    const [campos, setCampos] = useState<ICampo[] | null>(null);
    
    
    // Functions - Get data ------------------------------------------------------------------------------------------------------
    const onSave = async (dataToSave: GenericInitialObject|null) => { 
        
        setLoading(true);
        // comprobar campos required 
        let valid= true;
        if(camposRequiredKeys && camposRequiredKeys.length>0 ){
            if(dataToSave){
                camposRequiredKeys.forEach( element => {
                    switch (dataToSave[element].tipo) {
                        case 'number':
                            if(Number(dataToSave[element].value) === 0){
                                valid=false
                            }
                            break;
                        case 'decimal':
                            if(parseFloat(dataToSave[element].value) === 0){
                                valid=false
                            }
                            break;
                        default:
                            if(!dataToSave[element] || dataToSave[element].value === undefined || dataToSave[element].value === ""){
                                valid=false
                            }
                            break;
                    }
                })
            } else {
                valid = false;
            }
        }
        let savedData = false;
        if(valid){
            const saved = await handleSave(dataToSave); 
            savedData  = saved
        } 
        setLoading(false);

        return savedData
    }
    // ---------------------------------------------------------------------------------------------------------------------------
    useEffect(() => {
        (async()=> {
            try{
                setLoading(true);
                const camposApartado = await modelGateway.getCamposApartado(idTipoApartado, idTipoModelo, translate('Tributos', 'GetCamposApartadoError', terms))
                //console.log('camposApartado',camposApartado)
                setCampos(camposApartado);
                let emptyObjt = null;
                let newData: GenericInitialObject | null = null;
                if(camposApartado && camposApartado?.length>0){
                    emptyObjt= getEmptyObject(camposApartado)
                    const keys = getKeysRequiredCampos(camposApartado);
                    setCamposRequiredKeys(keys)
                    let dataPrevios: GenericInitialObject | null = null;
                    const datosPrevString = Object.values(datosXmlApartadosPrevios).join('')

                    if(datosPrevString){
                        dataPrevios = getInitialObject(camposApartado, datosPrevString, idTipoApartado);
                        //console.log('useDatos datosPrevString', dataPrevios)
                        newData=dataPrevios
                    }else{
                        newData=emptyObjt
                    }
                }

                if(idTipoApartado === '656_F' && newData){
                    // Total a ingresar dinámico
                    // Aplica C71 => en declaración del último periodo en el que hay declarados saldos de dudoso cobro (656_C -> C63 tiene valor>0) 
                    //               TotalIngresar = C71.valor>0 ? C71_valor : 0.00 
                    // Aplica C26 => en declaración de periodos que no sea el último o en el último sin declaración de saldos de dudoso cobro (656_C -> C63 tiene valor =0)
                    //               TotalIngresar = C26.valor
                    // 1. Extraccion de periodo
                    const periodoCampo = camposApartado.filter( c => CAMPOS_PERIODO.includes(c.codigo)) 
                    // Ver si es último periodo
                    const miPeriodo = MODELO_PERIODO[idTipoModelo]
                    const periodoUserSelect =  newData[periodoCampo[0].codigo].value
                    const ejercicio = periodoUserSelect.substring(0,4)
                    const periodo = periodoUserSelect.replace(ejercicio, '') 
                    const esUltimoPeriodo = miPeriodo.includes(periodo)
                    const C99 = camposApartado.find( c => c.casilla===99)
                    const C26 = camposApartado.find( c => c.casilla===26) 
                    if(C99 && C26){
                        if(esUltimoPeriodo){
                            const casillasCodigo = camposApartado.map( c => [63,71].includes(c.casilla) ? ({[c.casilla]: c.codigo}): null).filter(itm => itm!== null).reduce((acc, obj) => ({ ...acc, ...obj }), {})
                            const value63 = Number(newData[casillasCodigo[63]].value ?? '0')
                            const value71 = Number(newData[casillasCodigo[71]].value ?? '0')

                            console.log('value63 ', value63, '  value71 ',value71)
                            newData = {...newData,
                                [C99.codigo]: {
                                    ...newData[C99.codigo], 
                                    value: value63 >0 
                                        ? value71 > 0 ? value71 : 0
                                        : newData[C26.codigo].value, 
                                    formula: value63 > 0 
                                        ? value71 > 0 ? newData[casillasCodigo[71]].formula : ''
                                        : newData[C26.codigo].formula
                                    }
                            }
                        } else {
                            newData = {...newData,
                                [C99.codigo]: {...newData[C99.codigo], value: newData[C26.codigo].value, formula:newData[C26.codigo].formula }
                            }
                        }
                    }
                    
                }
                //console.log('newData', newData)
                setData(newData);
                const isSaved = await onSave(newData);
                setFinished(isSaved);
            } catch (error) {
                const result = (error as Error).message;
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: result,
                        variant: 'error',
                    }
                }); 
            } finally {
                setLoading(false)
            }
        })();
    },[idTipoApartado, idTipoModelo, datosXml]);

    return(
        <div> 
            <FullLoading  loading={loading}/>
            {campos && campos.length>0 && camposRequiredKeys !== null && data &&
                renderCampos(campos, data, (name, value) => {}, Object.values(datosXmlApartadosPrevios).join(''), idTipoApartado, undefined, undefined, classes)
            } 
        </div>
    )
}
export default withLiterals(['Tributos'])(FechaFinalizacion);


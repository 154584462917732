import React, {FC, useCallback, useContext, useEffect, useState} from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { Grid, MenuItem, makeStyles } from "@material-ui/core";
import styles from "./styles";
import usePage from "hooks/page.hook";
import { MoreInfoContext } from "contexts/more_info.context";
import ModalAcceso from "components/modal-acceso";
import { handleInfoDispatch } from "utils/info-dispatch-mvl";
import { mdiFileAccount } from "@mdi/js";
import Term from "components/term";
import { TBoxHomeOptions } from "containers/inicio/helper/inicio.helper";
import BoxHome from "components/box-home";
import Menu1_IMG from "../../resources/dusti/dustiCasa.png";
import Menu3_IMG from "../../resources/dusti/dustiCarpeta.png";
import Menu2_IMG from '../../resources/abonoNacimiento/Menu5.png';
import Menu4_IMG from '../../resources/abonoNacimiento/Menu2.png'

interface IRouteParams {}
type Props = RouteComponentProps<IRouteParams>;
const useStyles = makeStyles(styles);


const DUTIHome : FC<Props> = ({match, history}) => {
    const classes = useStyles();
    const [pageState, pageDispatcher] = usePage();
    const [, infoDispatch] = useContext(MoreInfoContext);
    const [showLogin, setShowLogin] = useState(false);


    const MENU_ITEMS : TBoxHomeOptions[] = [
        {   
            title: { component: "DUTI", text: "DUSTIMasInfo" },
            description : {component: 'DUTI', text: 'DUSTIMasInfo_descripcion'},
            link: '/tributos/DUTI/contenidos',
            boxImage: Menu3_IMG,
            isExternLink: false,
            animated: true,
            disabled: false
        },
        {    
            title: { component:"DUTI" , text:"DUSTIAlta" },
            description : {component: 'DUTI', text: 'DUSTIAlta_descripcion'},
            link: '/tributos/DUTI/alta',
            boxImage: Menu1_IMG,
            isExternLink: false,
            animated: true,
            disabled: false 
        },
        {   
            title: { component: "DUTI", text: "DUSTIConsulta"},
            description : {component: 'DUTI', text: 'DUSTIConsulta_descripcion'},
            link: '/tributos/DUTI/consulta',
            boxImage: Menu2_IMG,
            isExternLink: false,
            animated: true,
            disabled: false 
        },
        { 
            title : {component: 'DUTI', text: 'DUTI_Representacion'},
            description : {component: 'DUTI', text: 'DUTI_RepreDescripcion'},
            link: '/perfil/representaciones/gestion-de-representaciones', 
            boxImage: Menu4_IMG,
            isExternLink: false,
            animated: true
        }   
    ];

    const handleShowMasInfo = useCallback((templateName: string) => {
        handleInfoDispatch(infoDispatch, 'show-info', templateName)
    }, [infoDispatch]);

    useEffect(() => {
        pageDispatcher({
            type: 'setHeader',
            header: {
                icon: mdiFileAccount,
                title: <Term component="DUTI" text="DUTI Titulo" />,
                text: <Term component="DUTI" text="DUTI Descripcion" />,
                // moreInfoTemplate: 'mas_info_duti_home',
                // right: (
                //     <>
                //         <MenuItem button onClick={() => handleShowMasInfo('mas_info_duti_home')}>
                //             <Term component="Global" text="Mas informacion" />
                //         </MenuItem>
                //     </>
                // )
            },
            menu: true,
            footer: true,
        });
    }, [pageDispatcher]);

    return(
        <div className={classes.container}>
        <Grid container direction="row" alignItems="center"  >
            {MENU_ITEMS.map( (carditem, idx) => (
                <Grid key={`card_${idx}`} item xs={12} sm={6} md={6} lg={6} xl={4} className={classes.widget}>
                    <BoxHome key={`box_optionItem${idx}`} item={carditem} />
                </Grid>
            ) )}
            
        </Grid>
        <ModalAcceso openModalAcceso={showLogin} setOpenModalAcceso={setShowLogin} history={history} />       
        </div>
    )
}

export default withRouter(DUTIHome);
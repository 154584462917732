import React, { FC, useContext, useEffect, useMemo, useState } from "react";
import InputNumber, { IInputNumber} from ".";
import { InputAdornment, WithStyles, withStyles } from "@material-ui/core";
import styles from "./styles";
import { translate } from "utils/i18n";
import { LiteralsContext } from "containers/shared/literals";
import PhonePrefixedNumber from "./phone-prefixed-number";


export type TExtraValidation = {
    error:boolean, 
    error_msg: string | undefined,
    componentIG?:string
    
}

type TInputCustom= Omit<IInputNumber, 'maxDeciaml'| 'onChangeValue'| 'children'|'value'>
interface ICommon extends TInputCustom {
    type: 'percentaje' |'tlf' |'year' | 'money'|'prefixed-tlf',
    
}
interface ITypedMoney extends ICommon {
    type: 'money',
    value: number|undefined;
    onChangeValue: (v:number|undefined, err: boolean) => void;
    allowZero:boolean;
    maxDecimal?: number,
}
interface ITypedPercentaje extends ICommon {
    type: 'percentaje'
    value: number;
    onChangeValue: (v:number, err: boolean) => void;
    allowZero:boolean;
    maxDecimal?: number,
}
interface ITypedNum extends ICommon {
    type:  'tlf'|'year'| 'prefixed-tlf'
    value: number | undefined,
    disableDropdown?: boolean,
    onChangeValue: (v: number|undefined, err: boolean) => void; 
     
}

type ITypedInputNumber = ITypedPercentaje | ITypedNum |ITypedMoney

const GenericTypedInputNumber: FC<ITypedInputNumber> = ({
    disableDropdown, classes ,labelTerm, onChangeValue ,type,value,className, 
    required,margin="dense",size="small",variant="outlined", min,max,maxDecimal, error, errorMsgExterno, disabled,
    moreInfo, componentIG, extraValidation,
    ...props
}) => {

    const terms = useContext(LiteralsContext);
   


    const typedProps = useMemo(() => {
        switch (type) {
            case 'tlf':
                return ({max: max ?? 999999999999, min: min ?? undefined, maxDecimal: 0, className: classes.input100, required: required, ...props})
            case 'percentaje':
                return ({max: max ?? 100, min: min ?? 0, maxDecimal: maxDecimal ?? 0, className: classes.inputDefault, required: required, ...props})
            case 'money':
                return ({max: max , min: min ?? 0, maxDecimal: 2, className: classes.inputDefault, required: required, ...props})
            case 'year':
                return ({max: undefined, min: undefined, maxDecimal: 0, className: classes.input160, required: required, ...props})
            default:
                return ({...props});
        }
    }, [type,max, min,maxDecimal, required])


    const handleChangeOrDefaultNum = (v: number| undefined, err: boolean)  => {
        onChangeValue(v ?? 0, err);
    }

    

    const validateZero= (v: number|undefined, {...props}) => {
        let result : TExtraValidation= {error: false, error_msg: undefined}
        if(props.allowZero === false){
            if(v === 0){
                result = {error: true, error_msg: 'err_notAllowedZero'}
            }
        }
        if(!result.error && extraValidation){
            result = extraValidation(v)
        }
        return result
    }

    // TODO NEUS - Determinar validacion TELEFONO
    const validateTLF = (v: number|undefined) => {
        let result : TExtraValidation= {error: false, error_msg: undefined}
        if(v && v.toString().length<9){
            result={error: true, error_msg: 'telefono_longiud_error'}
        }
        if(!result.error && extraValidation){
            result = extraValidation(v)
        }
        return result
    }

    // TODO NEUS - Determinar validacion YEAR
    const validateYear = (v: number|undefined) => {
        let result : TExtraValidation= {error: false, error_msg: undefined}
        if(v && v.toString().length !== 4){
            result = {error: true, error_msg: 'anyo_error'}
        }
        if(!result.error && extraValidation){
            result = extraValidation(v)
        }
        return result
    }


    
    switch (type) {
        case 'tlf': 
            return(
                <InputNumber 
                    {...typedProps}
                    margin={margin}
                    size={size}
                    varinat={variant}
                    labelTerm={labelTerm}
                    value={value} 
                    onChangeValue={onChangeValue} 
                    className={className}
                    extraValidation={validateTLF}
                    error={error}
                    disabled={disabled}
                    required={required}
                    moreInfo={moreInfo}
                    componentIG={componentIG}
                />
            )
        case 'prefixed-tlf': 
            return(
                <PhonePrefixedNumber 
                    {...typedProps}
                    margin={margin}
                    size={size}
                    varinat={variant}
                    labelTerm={labelTerm}
                    value={value} 
                    onChangeValue={onChangeValue} 
                    className={className}
                    extraValidation={validateTLF}
                    error={error}
                    disabled={disabled}
                    disableDropdown={disableDropdown}
                    required={required}
                    moreInfo={moreInfo}
                    componentIG={componentIG}
                />
            )
        case 'percentaje': 
            return(
                <InputNumber 
                    {...typedProps}
                    margin={margin}
                    size={size}
                    varinat={variant}
                    labelTerm={labelTerm}
                    value={value} 
                    onChangeValue={handleChangeOrDefaultNum} 
                    className={className}
                    extraValidation={(v: number) => validateZero(v, props)}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">%</InputAdornment>
                    }}
                    required={required}
                    error={error}
                    disabled={disabled}
                    moreInfo={moreInfo}
                    componentIG={componentIG}
                />
            )
        case 'money': 
            return(
                <InputNumber 
                    {...typedProps}
                    margin={margin}
                    size={size}
                    varinat={variant}
                    labelTerm={labelTerm}
                    value={value} 
                    onChangeValue={onChangeValue} 
                    className={className}
                    extraValidation={(v: number) => validateZero(v, props)}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">€</InputAdornment>
                    }}
                    required={required}
                    error={error}
                    errorMsgExterno={errorMsgExterno}
                    disabled={disabled}
                    isMoney={true}
                    moreInfo={moreInfo}
                    componentIG={componentIG}
                />
            )
        case 'year': 
            return(
                <InputNumber 
                    {...typedProps}
                    margin={margin}
                    size={size}
                    varinat={variant}
                    labelTerm={labelTerm}
                    value={value} 
                    onChangeValue={onChangeValue} 
                    className={className}
                    extraValidation={validateYear}
                    error={error}
                    disabled={disabled}
                    moreInfo={moreInfo}
                    componentIG={componentIG}
                    maxLength={4}
                />
            )
        default:
            return ( <></>);
    }
        
    
}

export default withStyles(styles)(GenericTypedInputNumber);






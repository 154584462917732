import React, { useContext, useMemo } from "react";

// Define el tipo de las props que tu componente original espera
import Autoliquidacion, {Props as AutoliquidacionProps} from '../autoliquidacion'
import { Grid } from "@material-ui/core";
import { GenericInitialObject } from "containers/tributos-autonomicos-locales/utils";
import ModelGateway from "gateways/model.new.gateway";
import IoC from "contexts/ioc.context";
import { AlertsContext } from "contexts/alerts.context";
import { LiteralsContext } from "containers/shared/literals";
import { translate } from "utils/i18n";


// Función del HOC que acepta un componente y devuelve un nuevo componente
export const withAutoliqEjerPeriod_656_666 = (Component: React.ComponentType<AutoliquidacionProps>) => {
  return function ComponentWithCustomSave(props: AutoliquidacionProps) {
    const terms = useContext(LiteralsContext);
    const [, alertsDispatch] = useContext(AlertsContext);

	//Gateways
	const ioc = useContext(IoC);
    const modelGateway: ModelGateway = useMemo(() => ioc.get(ModelGateway), [ioc]);

    const handleSave = async (value: GenericInitialObject) => {
		try {
			//console.log('with_656_666 handleSave : ', value, props.idTipoModelo)

			// 1. Obtener cuota actual - Requiere evaluar su fórmula para tener el valor actualizado
			const campo = value[props.idTipoModelo === '666' ? 'suma' : 'cuotaTotalEj']
			//console.log('campo', campo)

			// 1.1. Obtener casillas que intervienen en la fomrula
			const myRegExp: RegExp = /(?!\[)-?[a-zA-Z0-9_.]+(?=\])/g; //(?!\[)-?\d+(?=\])/g;
			const casillas = campo.formula.match(myRegExp);
			//console.log('casillas', casillas)

			// 1.2. Reemplazamos los numeros de casilla por sus valores:
			let formulaBraquets: string = campo.formula //.replaceAll(`${idTipoApartado}.`, "");
			casillas?.forEach((item) => {
				const find = Object.values(value).find(
					(v) => v.casilla === Number(item)
				  );
				const valueToReplace = find && find.value && !isNaN(find.value) ? find.value : "0";
				// console.log('find', valueToReplace)
				formulaBraquets = formulaBraquets.replace(`[${item}]`, valueToReplace);
			})

			// 1.3. Evaluamos fórmula
			//console.log('formulaBraquets', formulaBraquets)
			const cuota = formulaBraquets !== "" ? eval(formulaBraquets) : 0;
			
			// 2. Ir a buscar el valor de cuantía imputada por Saldo de Dudoso Cobro (SCD)
			const ejercicio = value[props.idTipoModelo === '666' ? 'ejerPeri': 'ejerTipoPeri'].value.substring(0,4)
			//console.log('cuota', cuota, 'ejercicio', ejercicio)
			const result = await modelGateway.getPorcentajeSDC(props.idTipoModelo,cuota,ejercicio)
			if(!result.ok || !result.porcentajeAplicable){ throw new Error('Err - getPorcentajeSDC')}

			// 3. Completar datos + save apartado
			const finalValue: GenericInitialObject = {
				...value,
				porcentSDC: {...value['porcentSDC'], value: result.porcentajeAplicable}
			}
			// ir a save apartado
			// console.log('finalValue ', finalValue)
			const response = await props.handleSave(finalValue);
			return response;
			
		} catch (error) {
			alertsDispatch({
				type: "show-alert",
				payload: {
				  message: translate('Tributos','notfount_SCD',terms),
				  variant: "error",
				},
			});
			return false
		}
      
    }

    // Propaga todas las props al componente original, pero sobrescribe `handleSave`
    return (
      <Grid container direction="column">
        <Component {...props} handleSave={handleSave} />
      </Grid>

    )
  }
}


// Exporta el componente envuelto por el HOC
// Componente
const AutoliqEjerPeriod_656_666 = withAutoliqEjerPeriod_656_666(Autoliquidacion)
export default  AutoliqEjerPeriod_656_666;



import React, { ChangeEvent, FC, useCallback, useContext, useEffect, useState } from 'react';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Typography, withStyles, WithStyles } from '@material-ui/core';
import { Icon } from '@mdi/react'
import { ALL_LITERALS, LiteralsContext, withLiterals } from 'containers/shared/literals';
import { translate } from 'utils/i18n';
import styles from "./styles";
import { IMessageResponse } from 'gateways/importer.interfaces';
import { mdiMicrosoftExcel, mdiPaperclip } from '@mdi/js';
import Term from 'components/term';
import { BLUE } from 'containers/shared/colors';
import FullLoading from 'components/full-loading';
import Alert from '@material-ui/lab/Alert';


interface IProps extends WithStyles<typeof styles> {
    open: boolean,
    title: string,
    file: File | null,
    dataElement: React.ReactNode,
    dataElementResult: React.ReactNode,
    disabledStep1: boolean;
    loadingUpload: boolean;
    hasErrors: boolean;
    importDisabled: boolean;
    showIcon?: boolean,
    errorsFile?: IMessageResponse[],
    errorData?: string,    
    onUpload: (file: File) => void,
    onSubmit: () => Promise<void>,
    onPrevious: () => void,
    onNew: () => void,
    onClose: () => void,
    withActionPrint: boolean,
    onPrintJustificante?: () => void
    allowedFormat?: string[]
}
export const DEFAULT_ALLOWED_FORMAT= ['CSV','ODS','OTS','XLS','XLSX','XLTX']
const GenericWizardImporter: FC<IProps> = ({
    open, title, file, dataElement, dataElementResult, disabledStep1, loadingUpload, hasErrors, importDisabled, showIcon, errorsFile, errorData,
    onUpload, onSubmit, onPrevious, onNew, onClose,withActionPrint, onPrintJustificante, allowedFormat= DEFAULT_ALLOWED_FORMAT, classes 
}) => {
    const terms = useContext(LiteralsContext);

    // States
    const [step, setStep] = useState(1);
    const [loading, setLoading] = useState(false);
    const [errorFormat, setErrorFormat] = useState<string | null>(null);
    const [openAdvice, setOpenAdvice] = useState(false);

    useEffect(() => {
        if (step === 3) {
            window.scrollTo(0, 0);
        }
    }, [step]);
    // Events
    const handleStepBackward = /*useCallback(*/() => {
        setStep(s => step - 1);
        onPrevious();
    }//, [onPrevious, step]);

    const handleStepForward = /*useCallback(*/() => {
        setStep(step + 1);        
    }//, [step]);

    const handleImport =/* useCallback(*/async () => {
        setLoading(true)
        setOpenAdvice(false);
        await onSubmit();    
        setStep(step => step + 1);    
        setLoading(false)
    }//, [onSubmit, step]);

    const handleSubmit = /*useCallback(*/async () => {
        setLoading(true)
        if (hasErrors) {
            setOpenAdvice(true);
        } else {
            await handleImport();
        }
        setLoading(false)
    }//, [handleImport, hasErrors]);

    const handleNew = /*useCallback(*/() => {
        setStep(1);
        setErrorFormat(null);
        onNew();
    }//, [onNew]);

    const handleFileAttach = /*useCallback(*/async (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target && event.target.files) {
            setErrorFormat(null);
            if (event.target.files.length === 0) {
                return;
            }
            const file = event.target.files[0];
            const fileFormat = file.name.split(".").slice(-1).pop()?.toUpperCase() || '';
            console.log('fileFormat',fileFormat);
            if (!allowedFormat.includes(fileFormat)) {
                const format=allowedFormat.map( f => '.'+f.toLocaleLowerCase()).join(', ')
                setErrorFormat(translate('Wizard', 'generic_fichero_no_valido', terms, [format]));
            } else {
                onUpload(event.target.files[0]);
            }
        }
    } // , [onUpload, terms]);

    const handlePrintJustificante = () => {
        onPrintJustificante && onPrintJustificante();
    };

    const handleClose = () => {
        setErrorFormat(null);
        onClose();
    }


    return (
        <>
        {(loadingUpload || loading )&& <FullLoading loading={loadingUpload ||loading}/>}
        <Dialog open={open} scroll="paper" fullWidth={true} maxWidth={"lg"} classes={{ paper: classes.dialog }}>
            <DialogTitle>
                <h1 className={classes.pageTitle}>
                    <span className={classes.iconContainer}>
                        <Icon
                            path={mdiMicrosoftExcel}
                            size={0.6}
                            color="white"
                        />                        
                    </span>
                    {title}
                </h1>
            </DialogTitle>
            <DialogContent>
                {
                    step === 1 &&
                    <div className={classes.container}>
                        <Typography variant="h6" className={classes.titleFile}>{translate('Wizard', 'generic_titulo_importador', terms)}</Typography>
                        <div style={{display:'flex', flexDirection: 'row', marginTop:5, marginLeft: 5, marginBottom: 20, alignItems: 'center'}}>
                            <input id={`file-input`}
                                style={{ display: 'none' }} 
                                type="file" 
                                onChange={handleFileAttach} 
                                className={classes.input}
                            />
                            <label htmlFor={`file-input`}>
                                <Button component="span" 
                                    variant="outlined" 
                                    color='primary'
                                    size="small"
                                    //disabled={disabled}
                                    startIcon={ <Icon path={mdiPaperclip} size={1} color={BLUE} /> }
                                >
                                    {translate('GenericComponents','btn_attach',terms)}
                                </Button>
                            </label> 
                            {dataElement && file 
                                ? <Typography style={{marginLeft: 10}}>{file.name}</Typography>
                                : <Typography style={{marginLeft: 10}}>{translate('Wizard', 'no_doc_attach', terms)}</Typography>
                            }
                        </div>
                        
                        {!loadingUpload && dataElement && file && !(errorFormat || (errorsFile && errorsFile.length > 0)) && 
                            <Alert severity='success'>{translate('Wizard', 'upload_file_success', terms)}</Alert>
                        }
                        
                        {
                           !loadingUpload && (errorFormat || (errorsFile && errorsFile.length > 0)) &&
                            <>
                                <p className={classes.titleFile}>
                                    {translate('Wizard', 'titulo_errores', terms)}
                                </p>
                                {errorFormat && <Alert severity='error'>{errorFormat}</Alert>}
                                {errorsFile && !errorFormat && errorsFile.map((e, i) => (
                                    <Alert key={i} severity='error'>{translate('Wizard', e.messages[i], terms)}</Alert>
                                ))}
                            </>
                        }
                    </div>
                }
                {
                    step === 2 &&
                    <>
                        {
                            dataElement
                        }
                    </>
                }
                {
                    step === 3 &&
                    <>
                        {
                            dataElementResult
                        }
                    </>
                }
            </DialogContent>
            <DialogActions className={classes.contentActions}>
                <div className={classes.rowSteps}>
                    <span className={step !== 1 ? classes.steps : [classes.steps, classes.activeStep].join(' ')} >
                        1
                    </span>
                    <span className={step !== 2 ? classes.steps : [classes.steps, classes.activeStep].join(' ')}>
                        2
                    </span>
                    <span className={step !== 3 ? classes.steps : [classes.steps, classes.activeStep].join(' ')}>
                        3
                    </span>
                </div>
                <div className={classes.rowButtons}>
                    {
                        step === 3 && withActionPrint &&
                            <Button
                                autoFocus
                                variant="contained"
                                color="inherit"
                                className={classes.button}
                                disabled={loading}                                
                                onClick={handlePrintJustificante}>
                                <Term component={"Global"} text={"Imprimir Justificante"} />
                            </Button>
                    }
                    <Button 
                        autoFocus 
                        variant="contained"
                        color="inherit"
                        className={classes.button}
                        disabled={loading || importDisabled}
                        onClick={() => {
                            setStep(1);
                            handleClose();
                        }}
                    >
                        <Term component={"Global"} text={step === 3 ? "Finalizar" : "Cerrar"} />
                    </Button>
                    {
                        step === 2 &&
                        <>
                            <Button
                                autoFocus
                                variant="contained"
                                color="inherit"
                                className={classes.button}
                                disabled={loading}                                
                                onClick={handleStepBackward}>
                                <Term component={"Global"} text={"Previous"} />
                            </Button>
                            <Button variant="contained" color="primary" onClick={handleSubmit}>
                                {loading ?
                                <CircularProgress size={16} />
                                :
                                <Term component={"Global"} text={"Importar"} />
                                }
                            </Button>
                        </>
                    }
                    {
                        step === 1 &&
                        <Button 
                            variant="contained"
                            color="primary"
                            disabled={
                                (errorsFile && errorsFile.length > 0) || dataElement === null || disabledStep1
                            }
                            onClick={handleStepForward}
                        >
                            {
                                loadingUpload ?
                                 <CircularProgress size={24} className={classes.buttonProgress} />
                                 :
                                 <Term component={"Global"} text={"Siguiente"} />
                            }
                        </Button>
                    }
                    {
                        step === 3 &&
                        <>
                            {
                                errorData && errorData !== "" &&
                                <Button variant="contained" color="inherit" onClick={handleStepBackward}>
                                    <Term component={"Global"} text={"Previous"} />
                                </Button>
                            }
                            {
                                (errorData === undefined || errorData === "") &&
                                <Button variant="contained" color="primary" onClick={handleNew} disabled={importDisabled}>
                                    <Term component={"Global"} text={"ImportarNuevo"} />
                                </Button>
                            }
                        </>

                    }
                </div>
            </DialogActions>
        </Dialog>
        <Dialog
            open={openAdvice}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={classes.identificacionDialog}
        >
            <DialogTitle
                id="responsive-dialog-title"
                style={{ marginTop: 20 }}
                className={classes.content}
            >
                <span className={classes.title}>
                    <Term component="GenericComponents" text="err_importacion_title" />
                </span>
            </DialogTitle>
            <DialogContent className={classes.content}>
                <div>
                    <span><Term component="GenericComponents" text={'err_importacion_text'} /></span>
                </div>
            </DialogContent>
            <DialogActions className={classes.actions}>
                <Button
                    onClick={() => {
                        setOpenAdvice(false)
                        setStep(1);
                        handleClose();
                    }}
                    color="primary"
                    autoFocus
                    style={{ width: "100px" }}
                    variant='outlined'
                >
                    <Term component={"Global"} text={"Finalizar"} />
                </Button>
                <Button
                    onClick={handleImport}
                    color="primary"
                    autoFocus
                    style={{ width: "100px" }}
                    variant='outlined'
                >
                    <Term component={"Global"} text={"Importar"} />
                </Button>
            </DialogActions>
        </Dialog>
        </>
    );
}

export default withLiterals(ALL_LITERALS)(withStyles(styles)(GenericWizardImporter));

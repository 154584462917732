
export const BLUE = '#2196f3'
export const BLUE_BORRADOR= '#6c9db7'
export const BLUE_SECONDARY = '#004f82'
export const NARANJA_CATASTRO= '#ff8406'
export const NARANJA_PAGO_CARTA= '#ff9100'
export const BLUE_DARK ="#004f84" // used in page header
export const BLUE_PLV = '#3587a9'
export const BLUE_RIGHT_HEADER = '#2caebb'
export const BLUE_LIGHT_BACKGROUND = '#e0eaf4'
export const RED_LIGHT= '#f6c6ca'
export const RED_TEXT = '#994343'
export const RED ='#ca261b'
export const WHITE = '#fff'
export const BLACK = '#000'



import { FC, useContext, useMemo, useState, useEffect } from 'react';
import { ICampo } from '../../../gateways/model.new.interface';
import {  CircularProgress, Dialog, DialogContent} from '@material-ui/core';
import { Button, makeStyles } from '@material-ui/core';
import styles from './styles';


import { CAMPOS_PERIODO, formatXMLtoObject, GenericInitialObject, GenericObject, getEmptyObject, getInitialObject, getKeysRequiredCampos, isSameData, mixData, MODELO_PERIODO, OPTIONS_mensual, OPTIONS_trimestre, renderCampos } from '../utils';

import IoC from 'contexts/ioc.context';
import ModelGateway from 'gateways/model.new.gateway';
import { translate } from 'utils/i18n';
import { AlertsContext } from 'contexts/alerts.context';
import { LiteralsContext, withLiterals } from 'containers/shared/literals';
import EndProcessMSG from 'components/Modelos/componentes/endProcessMSG';

const useStyles = makeStyles(styles);

export interface Props {
    idTipoModelo: string,
    idTipoApartado: string,
    datosXml: string | null,
    datosXmlApartadosPrevios: GenericObject,
    setFinished: (value: boolean) => void,
    handleSave: (data: any) => Promise<boolean>,
    withEjercicioPeriodo: boolean
}

const Autoliquidacion: FC<Props> = (props) => {
    const {
        idTipoModelo,
        idTipoApartado,
        datosXml,
        datosXmlApartadosPrevios,
        setFinished,
        handleSave, 
        withEjercicioPeriodo
    } = props;
    
    const classes = useStyles();
    const [, alertsDispatch] = useContext(AlertsContext);
    const terms = useContext(LiteralsContext);
    //Gateways
    const ioc = useContext(IoC);
    const modelGateway: ModelGateway = useMemo(() => ioc.get(ModelGateway), [ioc]);
    //Local data
    const [loading, setLoading] = useState(false);
    const [firstLoading,setFirstLoading] = useState(false);
    const [pressed, setPressed] = useState(false);

    const [data, setData] = useState<GenericInitialObject| null>(null);
    const [changes,setChanges]= useState<boolean|null>(null);
    const [campos, setCampos] = useState<ICampo[] | null>(null);
    const [EMPTY_AUTOLOQUIDACION,setEmptyAutoliquidacion] = useState<GenericInitialObject|null>(null);
    const [camposRequiredKeys, setCamposRequiredKeys] = useState<string[]| null>(null);

    const [isThisPeriod, setIsThisPeriod]= useState(false)
    const [paramPeriod, setParamPeriod]= useState<string[]>([])


  
    
    // Functions - Get data ------------------------------------------------------------------------------------------------------
    const ajustaValorPositivo = (value: number): number => Math.round(Math.max(value, 0) * 100) / 100;

    const updateData= (name:string, value: any) => {
        setChanges(true);
        if(EMPTY_AUTOLOQUIDACION){
            let newData= data 
                ? {...data, [name]: {...data[name], value: value}} 
                : {...EMPTY_AUTOLOQUIDACION, [name]: {...EMPTY_AUTOLOQUIDACION[name],value: value, valid: false}}
            if(newData && newData[name].tipo === 'exclusiveBool' && value === true){
                // 1. Obtener casillas que intervienen en la formula
                const myRegExp: RegExp = /(?!\[)-?[a-zA-Z0-9_.]+(?=\])/g; //(?!\[)-?\d+(?=\])/g;
                const casillas = newData[name].formula.match(myRegExp);
                //2. setear a falso todos los valores de la formula
                if(casillas){
                    for (const key in newData) {
                        if (Object.prototype.hasOwnProperty.call(newData, key)) {
                            const element = newData[key];
                            if(casillas.includes(element.casilla.toString())){
                                newData[key] = {...newData[key], value: false}
                            }
                            
                        }
                    }
                }
            }

            const isTotalIngresar = name === 'a051_c94' && newData.a051_c96;
            if (isTotalIngresar) {
                // calcula [93] - [94] del apartado E modelo 051
                const calculo = eval(newData.a051_c96.formula.replaceAll('[', `newData.a${idTipoModelo}_c`).replaceAll(']', '.value'));

                newData = {
                    ...newData,
                    totalIngre: { ...newData.totalIngre, value: ajustaValorPositivo(calculo) },
                };
            }

            setData(newData);
        }
    }


    const onSave = ()=> { 
        (async() => {
            setLoading(true);
            // comprobar campos required 
            let valid= true;
            if(camposRequiredKeys && camposRequiredKeys.length>0 ){
                if(data){
                    camposRequiredKeys.forEach( element => {
                        //console.log('element', element)
                        switch (data[element].tipo) {
                            case 'number':
                                if(Number(data[element].value) === 0){
                                    valid=false
                                }
                                break;
                            case 'decimal':
                            case 'decimal2':
                            case 'decimal3':
                                if(parseFloat(data[element].value) === 0){
                                    valid=false
                                }
                                break;
                            default:
                                if(!data[element].value || data[element].value === undefined || data[element].value === ""){
                                    valid=false
                                }
                                break;
                        }
                    })
                } else {
                    valid = false;
                    
                }
            }
            if (valid) {
                console.log(campos?.find(campo => campo.isValid != undefined && !campo.isValid && campo.visible))
                valid = campos?.findIndex(campo => campo.isValid != undefined && !campo.isValid && campo.visible) == -1;
            }
            if(valid){
                //console.log('call handleSave : ', data)
                const saved = await handleSave(data); 
                setChanges(!saved);
                setPressed(true)
            } else {
                setChanges(true);
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: translate('Tributos','FormularioError', terms),
                        variant: 'error',
                    }
                });   
            }
            setLoading(false);
        })();
    }

    // ---------------------------------------------------------------------------------------------------------------------------
    useEffect(() => {
        (async()=> {
            try{
                setFirstLoading(true)
                const camposApartado = await modelGateway.getCamposApartado(idTipoApartado, idTipoModelo, translate('Tributos', 'GetCamposApartadoError', terms))
                const periodoCampo = withEjercicioPeriodo
                    ? camposApartado.filter( c => CAMPOS_PERIODO.includes(c.codigo)) 
                    : undefined
                //console.log('periodoCampo', periodoCampo)
                let emptyObjt = null;
                let newData: GenericInitialObject | null = null;
                let dataXML: GenericInitialObject | null = null;
                let dataPrevios: GenericInitialObject | null = null;
                let changes= false;
                
                if(camposApartado && camposApartado?.length>0){
                    emptyObjt= getEmptyObject(camposApartado)
                    //console.log('emptyObjt', emptyObjt)
                    setEmptyAutoliquidacion(emptyObjt);
                    const keys = getKeysRequiredCampos(camposApartado);
                    setCamposRequiredKeys(keys)
                    const datosPrevString = Object.values(datosXmlApartadosPrevios).join('')
                    //console.log('datosXml', datosXml)
                    if(datosXml){
                        dataXML = formatXMLtoObject(datosXml,emptyObjt);
                        //console.log('useDatos xml', dataXML)
                    } 
                    if(datosPrevString){
                        dataPrevios = getInitialObject(camposApartado, datosPrevString, idTipoApartado);
                        //console.log('useDatos datosPrevString', dataPrevios)
                    }

                    // copare if dataXML!==dataPrevios => have changes
                    if(isSameData(dataXML, dataPrevios)){
                        //setChanges(false)
                        changes=false
                        newData= dataXML
                    } else {
                        let mixDataObjt = mixData(dataXML, dataPrevios, idTipoApartado);
                        if(mixDataObjt && Boolean(mixDataObjt.a051_c96)){
                            const { value } = mixDataObjt.a051_c96;
                            mixDataObjt.totalIngre.value = ajustaValorPositivo(value);
                        };
                        //setChanges( !isSameData(newData, mixDataObjt))
                        changes = !isSameData(dataXML, mixDataObjt)
                        newData = mixDataObjt ?? newData
                        // setChanges(true)
                        // newData =dataPrevios
                    }
                }


                // Condiciona el componente a todo disabled si aplica a un solo periodo del ejercicio
                let apply = true
                if(withEjercicioPeriodo && periodoCampo){
                    //console.log('newData ', newData, periodoCampo)
                    if(newData && MODELO_PERIODO[idTipoModelo]){
                        // Ver si es último periodo
                        const miData = periodoCampo[0].codigo
                        const miPeriodo = MODELO_PERIODO[idTipoModelo] //OPTIONS_trimestre.find( opt => opt.id === MODELO_PERIODO[idTipoModelo])?.nombre ?? 'not-found'
                        const periodoUserSelect =  newData[miData].value
                        const ejercicio = periodoUserSelect.substring(0,4)
                        const periodo = periodoUserSelect.replace(ejercicio, '') // OPTIONS_trimestre.find( opt => opt.id === MODELO_PERIODO[idTipoModelo])?.nombre ?? 'not-found'
                        //console.log('campo Val: ', newData[miData].value, 'periodo Usr: ', periodo, miPeriodo)
                        if(miPeriodo.length > 0  && periodo) {
                            const textPeriodo = periodo.includes('T') ? OPTIONS_trimestre.find(opt => opt.id === periodo) : OPTIONS_mensual.find(opt => opt.id === periodo)
                            //console.log('textPeriodo ', textPeriodo?.nombre)
                            const txt = textPeriodo 
                                ? periodo.includes('T') 
                                    ? `${ejercicio} - ${textPeriodo.nombre}` 
                                    : `${ejercicio}/${textPeriodo.id}` 
                                :'-'
                            setParamPeriod([txt])
                        }
                        apply = miPeriodo.includes(periodo) //periodoUserSelect.includes(miPeriodo)
                        changes = apply && !pressed ? changes : false
                        //console.log('campo: ', miData, 'periodoAplica: ', miPeriodo, ' es mi periodo ? ',apply, 'periodoSeelccionado:', periodo)
                    }
                }

                setCampos(
                    withEjercicioPeriodo && !apply 
                    ? camposApartado.map(c=> ({...c, soloLectura: true}))
                    : camposApartado
                );
                setIsThisPeriod(apply)
                setData(newData || emptyObjt);
                setChanges(changes)
 

            } catch (error) {
                const result = (error as Error).message;
                setLoading(false)
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: result,
                        variant: 'error',
                    }
                }); 
            } finally{
                setFirstLoading(false)
            }
        })();
    },[idTipoApartado, idTipoModelo, datosXml, datosXmlApartadosPrevios]);
    
    useEffect(() => {
        if(changes){
            setFinished(false)
        } else {
            setFinished(true)
        }
    },[changes, data])

    // console.log('withEjercicioPeriodo',withEjercicioPeriodo, ' isThisPeriod', isThisPeriod, 'renderMSG ', (withEjercicioPeriodo && isThisPeriod) || (!withEjercicioPeriodo) ?'no':'si')
    return(
        <div> 
            <Dialog open={loading}>
                <DialogContent>
                    <CircularProgress size={35} />
                </DialogContent>
            </Dialog>
     
            {(withEjercicioPeriodo && isThisPeriod) || (!withEjercicioPeriodo) || firstLoading
                ? null
                :   <EndProcessMSG 
                        type='info'
                        componentIG='Tributos'
                        termIG={'soloCumplimentarPeriodoUltimoPeriodo'}
                        paramTermIG={paramPeriod}  
                    />
                // <Typography 
                //         style={{margin: '10px 10px 25px 10px', color:'#004f82', fontSize: 18, fontStyle:'italic'}}
                //     >{translate('Tributos', `soloCumplimentarPeriodo${MODELO_PERIODO[idTipoModelo]}`, terms, paramPeriod)}
                //     </Typography>
        
            }
            {campos && campos.length>0 && camposRequiredKeys !== null && data &&
                renderCampos(campos, data, updateData, Object.values(datosXmlApartadosPrevios).join(''), idTipoApartado, undefined, undefined, classes )
            } 

            <div className={classes.rowAlignRight}>
                <Button 
                    disabled={!changes}
                    style={{marginLeft: 10}}
                    color='primary'
                    variant='contained' 
                    onClick={onSave}
                >
                    {translate('Tributos','btnConfirm', terms)}
                </Button>
            </div>
            

        </div>
    )
}
export default withLiterals(['Tributos'])(Autoliquidacion);


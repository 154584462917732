import React, { FC, useContext, useEffect, useMemo, useState } from "react";

import { Grid, makeStyles, TextField } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';

import { Option, OPTIONS_mensual, OPTIONS_tipoPeriodo, OPTIONS_trimestre } from "containers/tributos-autonomicos-locales/utils";
import { translate } from "utils/i18n";
import { ALL_LITERALS, LiteralsContext, withLiterals } from "containers/shared/literals";
import GenericTypedInputNumber from "components/generic-input-number/typed-input-number";
import { RenderSize } from "gateways/model.new.interface";

import styles from '.././styles'

const useStyles = makeStyles(styles);

interface IProps {
    casilla: string,
    obligatorio: boolean,
    soloLectura:boolean,
    value: string | undefined,
    onChange: (v:string|undefined, err:boolean) => void
    errorExterno: boolean,
    renderSizePeriodo?:RenderSize,
    renderSizeTipoPeriodo?:RenderSize,
    idTipoApartado?: string
}
const SelectEjerTipoPeriod : FC<IProps>= ({casilla, obligatorio, soloLectura, value, onChange, errorExterno, renderSizePeriodo, renderSizeTipoPeriodo,idTipoApartado}) => {
    const terms = useContext(LiteralsContext)
    const classes= useStyles();

    const [ ejercicio, setEjercicio ]= useState<number|undefined>(undefined)
    const [ tipoPeriodo, setTipoPeriodo ]= useState<Option|null>(null)
    const [ periodo, setPeriodo ]= useState<Option|null>(null)

    const [ errEjercicio, setErrEjercicio ] = useState(false)
    const [ errTipoPeriodo, setErrTipoPeriodo ] = useState(false)
    const [ errPeriodo, setErrPeriodo ] = useState(false)

    const idTipoModelo = useMemo(() => {
        if(idTipoApartado){
            const split = idTipoApartado.split('_')
            return split[0]
        } else {
            return ''
        }
    },[idTipoApartado])


    const periodoOPTS= useMemo( () => {
        if(tipoPeriodo){
            switch (tipoPeriodo.id) {
                case 'trimestral':
                    return OPTIONS_trimestre;
                case 'mensual':
                    return OPTIONS_mensual;
                default:
                    return [];
            }
        } else {
            return []
        }
    }, [tipoPeriodo]) 

    const tipoPeriodoOPTS = useMemo(() => {
        if (idTipoModelo === '666'){
            return OPTIONS_tipoPeriodo.filter(opt => opt.id === 'anual')
        }
        return OPTIONS_tipoPeriodo
    },[idTipoModelo])

    const {finalValue, errorFinalValue, completed} = useMemo(() => {
        //console.log('memo ', ejercicio, tipoPeriodo, periodo)
        const period = tipoPeriodo && tipoPeriodo.id === 'anual' 
            ? '0A'
            : periodo ? periodo.id : null;
        const concatValue = ejercicio ? `${ejercicio ?? ''}${period??''}`: undefined
        //console.log('completed  ', ejercicio, tipoPeriodo, period, ejercicio!==undefined && tipoPeriodo!==null && period!==null ? 'si':'no')
        return ({
            finalValue: concatValue, 
            errorFinalValue: errEjercicio|| errTipoPeriodo|| errPeriodo,
            completed: ejercicio!==undefined && tipoPeriodo!==null && period!==null ?true:false
        });

    },[ejercicio, tipoPeriodo, periodo, errEjercicio, errTipoPeriodo, errPeriodo])


    const evaluateIsEmpty  = (tp: Option|null) => {
        if(!tp){ 
            return true
        } else{
            return false
        }
    }

    useEffect( () => {
        //console.log('effect','finalValue ',finalValue, '  errorFinalValue ',errorFinalValue, ' completed ',completed )
        //console.log('value ', value, '   errorExterno', errorExterno)

        if((value !== finalValue && finalValue )|| !(errorFinalValue ||!completed )) {
            //console.log('call onChange: ', finalValue,errorFinalValue,completed, 'err: ', errorFinalValue ||!completed  )
            onChange(finalValue, errorFinalValue ||!completed )
        }
    },[ finalValue, errorFinalValue, completed])

    useEffect(() => {
        //console.log('Effect value ', value, errorExterno)
        if(value){
            const ejer = value && !errEjercicio ?  value.substring(0,4) : ejercicio?.toString();
            //console.log('ejer ', ejer)
            const period = value && !errEjercicio  && ejer && ejer.length === 4 ? value.replace(ejer,''): undefined;
            //console.log('period ', period)

            setEjercicio(ejer ? Number(ejer): undefined);
            if(period && period.length === 2){
                if(period.includes('T')){
                    setTipoPeriodo( OPTIONS_tipoPeriodo.find( item => item.id === 'trimestral') ?? null)
                    setPeriodo( OPTIONS_trimestre.find( item => item.id === period) ?? null) 
                } else if( period === '0A'){
                    setTipoPeriodo( OPTIONS_tipoPeriodo.find( item => item.id === 'anual') ?? null)
                    setPeriodo(null)
                    setErrPeriodo(false)
                } else {
                    setTipoPeriodo( OPTIONS_tipoPeriodo.find( item => item.id === 'mensual') ?? null)
                    setPeriodo( OPTIONS_mensual.find( item => item.id === period) ?? null)        
                }
            } else {
                //console.log('perido sin valor',tipoPeriodo)
                setPeriodo(null)
                setErrPeriodo(false)
            }
        } else {
            setEjercicio(undefined)
            setTipoPeriodo(null)
            setPeriodo(null)
            setErrEjercicio(false);
            setErrPeriodo(false);
            setErrTipoPeriodo(false);
        }
        
    },[value])

    useEffect(() => {
        if(errorExterno){
            //console.log('effect errorExterno', errorExterno, ejercicio, tipoPeriodo, periodo)
            setErrEjercicio( !ejercicio && errorExterno)
            setErrTipoPeriodo( !tipoPeriodo && errorExterno)
            setErrPeriodo( (!tipoPeriodo || (tipoPeriodo.id !== 'anual' && !periodo)) && errorExterno)
        }
    }, [errorExterno,ejercicio, tipoPeriodo, periodo])


    return(
        <Grid container direction='row' alignItems="flex-start">
            {/**Ejercicio */}
            <GenericTypedInputNumber
                type="year"
                componentIG="GenericComponents"
                labelTerm={"ejercicio"}
                labelStr={`[${casilla}] ${translate('GenericComponents','ejercicio',terms)} `}
                required={obligatorio}
                value={ejercicio}
                disabled={soloLectura}
                onChangeValue={(v:number|undefined, err: boolean) => {
                    setEjercicio(v);
                    setErrEjercicio(err);
                    setTipoPeriodo(null);
                    setPeriodo(null);
                    // clean external value
                    if(!v) onChange(undefined, false);
                }}
                helperText={''}
                error={errEjercicio}
                errorMsgExterno={translate('Tributos', 'RequiredField', terms)}
                InputLabelProps={{
                    shrink: true,
                    style: {
                        top: 4,
                        paddingRight: 5,
                        paddingLeft: 5,
                        backgroundColor: obligatorio && !soloLectura ? '#deebf7': 'white',
                    }
                }}
                InputProps={{
                    style: { 
                        backgroundColor: obligatorio && !soloLectura ? '#deebf7' : undefined ,
                        paddingTop: 8,
                        paddingBottom: 8,
                        borderTop: obligatorio && !soloLectura ? '4px solid #deebf7' : '4px solid transparent', 
                        marginTop: 0    
                    },
                }}
                
                classes={{root: classes.root}}
                className={[classes['small'], classes.marginGenericCompToModelos ].join(' ')} 
            /> 

            {/** Tipo de periodo */}
            <Autocomplete
                disabled={errEjercicio || soloLectura}
                disableClearable={obligatorio}
                value={tipoPeriodo}
                getOptionLabel={(option: Option) => `${option.nombre}`}
                renderOption={(option: Option) => `${option.nombre}`}
                onChange={(event: any, newValue: Option | null) => {
                    setTipoPeriodo(newValue);
                    setPeriodo(null);
                }}
                options={tipoPeriodoOPTS}
                className={renderSizeTipoPeriodo? classes[renderSizeTipoPeriodo]: classes['small'] }
                renderInput={(params) => (
                    <TextField {...params}
                        label={`[${casilla}] ${translate('GenericComponents','tipoPeriodo',terms)}`}
                        variant="outlined"
                        required={obligatorio}
                        onBlur={(e) => {
                            if(obligatorio){
                                setErrTipoPeriodo(evaluateIsEmpty(tipoPeriodo))
                            }
                        }}
                        error={errTipoPeriodo}
                        helperText={errTipoPeriodo
                            ? translate('Tributos', 'RequiredField', terms)
                            : ""
                        }
                        style={{
                            backgroundColor: obligatorio && !soloLectura ? '#deebf7' : undefined,
                            borderTop: obligatorio && !soloLectura ? '4px solid #deebf7' : '4px solid transparent'
                        }}
                        InputLabelProps={{
                            shrink: true,
                            style: {
                                paddingRight: 5,
                                paddingLeft: 5,
                                backgroundColor: obligatorio && !soloLectura ? '#deebf7': 'white',
                                borderColor: obligatorio && !soloLectura ? '#deebf7' : undefined
                            }
                        }}
                    />
                )}
            />

            {/** Periodo */}
            {idTipoModelo === '666' ? null :
            <Autocomplete
                disabled={(periodoOPTS.length === 0 || !tipoPeriodo ||  tipoPeriodo.id === 'anual')|| soloLectura}
                disableClearable={obligatorio}
                value={periodo}
                getOptionLabel={(option: Option) => `${option.nombre} - ${option.id}`}
                renderOption={(option: Option) => `${option.nombre} - ${option.id}`}
                onChange={(event: any, newValue: Option | null) => setPeriodo(newValue)}
                options={periodoOPTS}
                className={renderSizePeriodo? classes[renderSizePeriodo]: classes.field}
                renderInput={(params) => (
                    <TextField {...params}
                        label={`[${casilla}] ${translate('GenericComponents','periodo',terms)}`}
                        variant="outlined"
                        required={obligatorio}
                        onBlur={(e) => {
                            if(obligatorio){
                                setErrPeriodo(evaluateIsEmpty(periodo))
                            }
                        }}
                        error={errPeriodo}
                        helperText={errPeriodo
                            ? translate('Tributos', 'RequiredField', terms)
                            : ""
                        }
                        style={{
                            backgroundColor: obligatorio && !soloLectura ? '#deebf7' : undefined,
                            borderTop: obligatorio && !soloLectura ? '4px solid #deebf7' : '4px solid transparent'
                        }}
                        InputLabelProps={{
                            shrink: true,
                            style: {
                                paddingRight: 5,
                                paddingLeft: 5,
                                backgroundColor: obligatorio && !soloLectura ? '#deebf7': 'white',
                                borderColor: obligatorio && !soloLectura ? '#deebf7' : undefined
                            }
                        }}
                    />
                )}
            />
            }
                
        </Grid>
    )
}

export default withLiterals(ALL_LITERALS)(SelectEjerTipoPeriod);
// LIBRARY IMPORTS
import React, { useState, useEffect, useContext, FC, useMemo, useCallback, useReducer } from 'react';
import { withStyles, WithStyles, CircularProgress, Hidden, MenuItem, Card, CardContent, Button, Dialog, DialogContent, DialogTitle, Typography, DialogActions } from '@material-ui/core';
import { RouteComponentProps } from 'react-router';
import { mdiLaptop } from '@mdi/js';
import InfiniteScroll from 'react-infinite-scroller';
import IoC from 'contexts/ioc.context';

// LOCAL IMPORTS
import { IRecibo, IFilterData, SituacionCobroEnum } from 'gateways/recibo.interfaces';
import { TributosAutonomicosGateway } from 'gateways/tributos.autonomicos.gateway';
import FiltersReducer, { IFiltersState, QueryRequest } from './filters.reducer';
import SelectionReducer, { ISelectionState } from './selection.reducer';
import { ITributoAutonomico } from 'gateways/tributos.interfaces';
import { IDatosRecibo } from 'gateways/bienesSujeto.interface';
import { ContenidosGateway } from 'gateways/contenido.gateway';
import { LiteralsContext } from 'containers/shared/literals';
import { handleInfoDispatch } from 'utils/info-dispatch-mvl';
import { MoreInfoContext } from 'contexts/more_info.context';
import { FilterDataContext } from './filter.data.context';
import { RecibosGateway } from 'gateways/recibo.gateway';
import { AlertsContext } from 'contexts/alerts.context';
import { SelectionContext } from './selection.context';
import { FiltersContext } from './filters.context';
import { withRouter } from 'react-router-dom';
import { ScreenType } from './filters.form';
import { download } from 'utils/download';
import { translate } from 'utils/i18n';
import TributosAutonomicos from "./tributos.autonomicos";
import image from '../../resources/no-deuda.png';
import CustomTabs from "components/custom-tabs";
import NoContent from 'components/no-content';
import FiltersActive from './filters.active';
import FiltersDialog from './filters.dialog';
import usePage from '../../hooks/page.hook';
import TabPanel from "components/tab-panel";
import PanelDrawer from './panel.drawer';
import ReciboCard from './recibo.card';
import Term from 'components/term';
import Carrito from './carrito';

// STYLES
import styles from '../shared/tributos.styles';
import { ENABLE_PAGO_A_CUENTA } from 'constants/pago-a-cuenta';

const numberElementsPerPage = 50;

interface IParams {
    estado: 'pendiente' | 'pagado';
}

type Props = WithStyles<typeof styles> & RouteComponentProps<IParams>;

const CarteroVirtualDeuda: FC<Props> = ({ location, classes, match }) => {
    // HOOKS
    const [, pageDispatcher] = usePage();
    const [, infoDispatch] = useContext(MoreInfoContext);
    const [, alertsDispatch] = useContext(AlertsContext);

    // CONTEXT & GATEWAYS
    const terms = useContext(LiteralsContext);
    const ioc = useContext(IoC);
    const recibosGateway = useMemo(() => ioc.get(RecibosGateway) as RecibosGateway, [ioc]);
    const tributosGateway = useMemo(() => ioc.get(TributosAutonomicosGateway) as TributosAutonomicosGateway, [ioc]);
    const contenidoGateway: ContenidosGateway = ioc.get(ContenidosGateway);

    // STATES
    const [loadingRecibos, setLoadingRecibos] = useState(false);
    const [selectingAll, setSelectingAll] = useState(false);
    const [deSelectingAll, setDeSelectingAll] = useState(false);
    const [page, setPage] = useState(0);
    const [, setOpenSubMenuAnchorEl] = React.useState<HTMLElement | null>(null);
    const [tabActive, setTabActive] = useState(0);
    const [screen, setScreen] = useState<ScreenType>('cartero-virtual-pendiente');

    // DATA
    const [datosBienes,] = useState<IDatosRecibo | undefined>(location.state as IDatosRecibo)
    const [tributos, setTributos] = useState<ITributoAutonomico[]>([]);
    const [recibos, setRecibos] = useState<IRecibo[]>([]);

    // COUNTS
    const [recibosCount, setRecibosCount] = useState(0);
    const [tributosCount, setTributosCount] = useState(0);

    // FILTERS
    const filtersReducer = useReducer(FiltersReducer, datosBienes ? { objTributario: datosBienes.objeto, organismos: [datosBienes.municipio] } : {});
    const [filters, filtersDispatch] = filtersReducer;
    const [filterData, setFilterData] = useState<IFilterData>({});
    const [showFilters, setShowFilters] = useState(false);

    // MEMOS
    const pendiente = useMemo(() => match.params.estado === 'pendiente', [match.params.estado]);
    const hasMore = useMemo(() => recibosCount > (page === 0 ? (page + 1) : page) * numberElementsPerPage, [page, recibosCount]);
    const noContent = useMemo(() => (!loadingRecibos && recibos.length === 0) || (recibosCount === 0 && recibos.length > 0), [loadingRecibos, recibos.length, recibosCount]);

    // STATES PAGO A CUENTA
    const [showModalAvisoPagoACuenta, setShowModalAvisoPagoACuenta] = useState(false);
    const [controlAvisoPagoACuentaActivadoPreviamente, setControlAvisoPagoACuentaActivadoPreviamente] = useState(false);

    // CONTENTS
    const [body, setBody] = useState("");
    const [bodyInfoPagoACuenta, setBodyInfoPagoACuenta] = useState("");
    const [mensajeAclaratorio, setMensajeAclaratorio] = useState('');

    const [bodyAvisoPagoCartaPalma, setBodyAvisoPagoCartaPalma] = useState('');

    const [bodyAvisoProcedimientoEmbargo, setBodyAvisoProcedimientoEmbargo] = useState('');






    // ON LOAD PAGE

    const onLoadPagoACuentaContent = async () => {
        const msg = await contenidoGateway.getContent('aviso_mensaje_aclaratorio',
            {
                linkContacto: 'https://www.atib.es/General/Contacto.aspx',
                linkOficinas: 'https://www.atib.es/TL/oficinas.aspx',
            }
        );
        const bodyAux = await contenidoGateway.getContent("AvisoPagoCartaPalma", {});
        const bodyPagoACuenta = await contenidoGateway.getContent('info_pago_a_cuenta', {});

        setBody(bodyAux[0].contenido)
        setMensajeAclaratorio(msg[0].contenido);
        setBodyInfoPagoACuenta(bodyPagoACuenta[0].contenido);
    }

    const onLoadAvisoPagoCartaPalma = async () => {
        const bodyAux = await contenidoGateway.getContent(
            "AvisoPagoCartaPalma",
            {}
        );
        setBodyAvisoPagoCartaPalma(bodyAux[0].contenido)
    }

    const onLoadAvisoProcedimientoEmbargo = async () => {
        const bodyAux = await contenidoGateway.getContent(
            "AvisoProcedimientoEmbargo",
            {}
        );
        setBodyAvisoProcedimientoEmbargo(bodyAux[0].contenido)
    }

    // HANDLERS

    const handleModalAvisoPagoACuenta = () => {
        setShowModalAvisoPagoACuenta(!showModalAvisoPagoACuenta);
    }

    const handleSelectRecibo = useCallback((selectionState: ISelectionState<IRecibo>, recibosToSelected: IRecibo | IRecibo[]) => {
        const recibosArray = 'splice' in recibosToSelected ? recibosToSelected : [recibosToSelected];
        const recibosNoSeleccionados: IRecibo[] = recibosArray.filter(x => selectionState.selected.findIndex(y => y.id === x.id) === -1);
        const recibosToAppend: IRecibo[] = recibosNoSeleccionados.filter(x => [SituacionCobroEnum.Cobrable, SituacionCobroEnum.Domiciliado].includes(x.situacionCobro) || !pendiente);
        const recibosToExclude: IRecibo[] = recibosNoSeleccionados.filter(x => (
            (x.situacionCobro !== SituacionCobroEnum.Cobrable 
                && (!ENABLE_PAGO_A_CUENTA || x.situacionCobro !== SituacionCobroEnum.Domiciliado && ENABLE_PAGO_A_CUENTA)
            ) || !pendiente)
        );
        const maxRecibos = 100;

        // Comprueba que no se haya alcanzado el límite de recibos que se pueden pagar al mismo tiempo
        if (selectionState.selected.length + recibosToAppend.length > maxRecibos) {
            recibosToExclude.push(...recibosToAppend.splice(0, selectionState.selected.length + recibosToAppend.length - maxRecibos));
        }

        // Comprueba que durante la selección de múltiples recibos si se queda uno sin seleccionar y le pulsamos encima, si este ha sido domiciliado previamente no debe poder seleccionarse
        if (recibosToAppend.length === 1 && recibosToExclude.length > 0 && screen === 'cartero-virtual-pendiente') {
            const indexReciboExcluded = recibosToExclude.findIndex(reciboToExclude => reciboToExclude.id === recibosToAppend[0].id);
            console.log('indexReciboExcluded ', recibosToExclude, recibosToAppend[0].id)
            indexReciboExcluded >= 0 && alertsDispatch({
                type: 'show-alert',
                payload: {
                    variant: 'warning',
                    message: <div>
                        <p>
                            {
                                recibosToAppend[0].situacionCobro === SituacionCobroEnum.Domiciliado && recibosToAppend[0].organismo.codigo === "040" && recibosToAppend[0].pagoCarta
                                    ?
                                    <div dangerouslySetInnerHTML={{ __html: body }} />
                                    :
                                    <Term isHtml component='CarteroVirtual' text={`ErrorAnyadirPago_${recibosToAppend[0].situacionCobro}`} />
                            }
                        </p>
                    </div>
                }
            });
            return [];
        }

        // Mostrarmos modal de alerta de los recibos seleccionados y excluidos
        if ('splice' in recibosToSelected) {
            if (recibosToExclude.length > 0) {
                const tempList: any[] = [];

                recibosToAppend.forEach(recibo => recibosToExclude.findIndex(reciboExcluded => recibo.id === reciboExcluded.id) === -1 && tempList.push(recibo));
                contenidoGateway.getContent(
                    'aviso_alerta_deuda',
                    {
                        seleccionado: selectionState.selected.length + recibosToAppend.length,
                        descartado: recibosToExclude.length,
                        linkContacto: 'https://www.atib.es/General/Contacto.aspx',
                        linkOficinas: 'https://www.atib.es/TL/oficinas.aspx',
                    }
                ).then(msgAux => {
                    alertsDispatch({
                        type: 'show-alert',
                        payload: {
                            isHtml: true,
                            variant: 'warning',
                            message: msgAux[0].contenido,
                        }
                    });
                });
                return tempList
            }
            else {
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        variant: 'info',
                        message: `Se han seleccionado ${selectionState.selected.length + recibosToAppend.length} recibos`,
                    }
                });
            }
        }

        return recibosToAppend;
    }, [alertsDispatch, contenidoGateway, pendiente, body]);

    const selectionRe = SelectionReducer<IRecibo>({ isSelectable: handleSelectRecibo });
    const selectionReducer = useReducer(selectionRe, { selected: [] });
    const [, selectionDispatch] = selectionReducer;

    const buildQueryRequest = useCallback((newFilters: IFiltersState, newPage: number) => {
        return Object.assign({}, newFilters, {
            pendiente,
            skip: newPage * numberElementsPerPage,
            take: numberElementsPerPage
        }) as QueryRequest;
    }, [pendiente]);

    const handleCloseSubMenu = useCallback(() => setOpenSubMenuAnchorEl(null), []);

    const handleShowFilters = useCallback(() => {
        setShowFilters(true);
        handleCloseSubMenu();
    }, [handleCloseSubMenu]);

    const handleHideFilters = useCallback(() => setShowFilters(false), []);

    const handleLoadRecibos = useCallback(async (newFilters: IFiltersState, newPage: number) => {
        const query = buildQueryRequest(newFilters, newPage);

        if (!loadingRecibos) {
            if (screen === 'tributos-autonomicos') {
                setLoadingRecibos(true);

                try {
                    const tributosReceived = await tributosGateway.findAll(query);
                    if (tributosReceived) {
                        setTributos(tributosReceived);
                    }
                } catch (error) {
                    pageDispatcher({
                        type: 'show-notification',
                        payload: {
                            message: (error as any).message,
                            variant: 'error',
                        }
                    });
                } finally {
                    setLoadingRecibos(false);
                }
            } else {
                setLoadingRecibos(true);

                try {
                    const recibosReceived = await recibosGateway.findAll(query);
                    if (recibosReceived) {
                        setRecibos(newPage === 0 ? recibosReceived : recibos.concat(recibosReceived));
                    }
                } catch (error) {
                    pageDispatcher({
                        type: 'show-notification',
                        payload: {
                            message: (error as any).message,
                            variant: 'error',
                        }
                    });
                } finally {
                    setLoadingRecibos(false);
                }
            }
        }
    }, [buildQueryRequest, loadingRecibos, screen, tributosGateway, pageDispatcher, recibosGateway, recibos]);

    const handleLoadMoreRecibos = useCallback(() => {
        if (hasMore && !loadingRecibos) {
            setPage(page + 1);
            handleLoadRecibos(filters, page + 1);
        }
    }, [hasMore, loadingRecibos, page, handleLoadRecibos, filters]);

    const handleChange = useCallback((_E: React.ChangeEvent<{}>, newValue: number) => {
        setScreen(newValue === 1 ? 'tributos-autonomicos' : 'cartero-virtual-pagado');
        setTabActive(newValue);
        filtersDispatch({ type: 'clear' });
    }, []);

    const handleExportRequest = useCallback(async () => {
        const query = buildQueryRequest(filters, 1);
        delete (query as any).skip;
        query.take = 9999;
        let response = null;

        if (screen === 'tributos-autonomicos') {
            response = await tributosGateway.exportarTributos(query);
        } else {
            response = await recibosGateway.exportarDeuda(query);
        }

        download(response.blob, alertsDispatch,
            translate('Global', 'No se ha podido exportar', terms),
            translate('Global', 'Exportar', terms), response.fileName);
    }, [buildQueryRequest, filters, screen, alertsDispatch, terms, tributosGateway, recibosGateway]);

    const handleFiltersChanged = useCallback((newFilters: IFiltersState) => {
        setPage(0);
        selectionDispatch({ type: 'clear' });
        handleLoadRecibos(newFilters, 0);
    }, [handleLoadRecibos, selectionDispatch]);

    const handleSelectAll = useCallback(async () => {
        handleCloseSubMenu();
        setSelectingAll(true);
        let recibosFiltered = recibos.filter((x) => !(x.domiciliacion.pagoALaCartaPalma && x.organismo.codigo === "040"))
        let allRecibos: IRecibo[] = recibosFiltered;
        if (hasMore && filterData) {
            setLoadingRecibos(true);
            const query = buildQueryRequest(filters, page + 1);
            setPage((recibosCount / numberElementsPerPage) + 1);
            query.take = recibosCount - (query.skip ? query.skip : 0);
            const result = await recibosGateway.findAll(query);
            if (result) {
                allRecibos = allRecibos.concat(result);
                setRecibos(allRecibos);
            }
            setLoadingRecibos(false);
        }
        allRecibos = allRecibos.filter(recibo => recibo.desglose.pendiente < 1000000)
        selectionDispatch({ type: 'select', item: allRecibos });
        setSelectingAll(false);
    }, [handleCloseSubMenu, recibosCount, recibos, hasMore, filterData, selectionDispatch, buildQueryRequest, filters, page, recibosGateway]);

    const handleDeSelectAll = () => {
        setDeSelectingAll(true)
        selectionDispatch({ type: 'clear' });
        setDeSelectingAll(false)
    };

    const handleShowMasInfo = useCallback((templateName: string) => {
        handleInfoDispatch(infoDispatch, 'show-info', templateName)
    }, [infoDispatch])

    // USE EFFECTS

    useEffect(() => {
        (async () => {
            await onLoadPagoACuentaContent();
            await onLoadAvisoPagoCartaPalma();
            await onLoadAvisoProcedimientoEmbargo();
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        pageDispatcher({
            type: 'setHeader',
            header: {
                icon: mdiLaptop,
                title: <Term component="CarteroVirtual" text={pendiente ? 'Deuda pendiente' : 'Deuda pagada'} />,
                moreInfoTemplate: pendiente ? 'mas_info_deuda_pendiente' : 'mas_info_deuda_pagada',
                right: (
                    <>
                        {
                            pendiente ?
                                <MenuItem button onClick={handleSelectAll}><Term component="CarteroVirtual" text="PAGAR TODOS" /></MenuItem>
                                :
                                null
                        }
                        <MenuItem button onClick={handleShowFilters}><Term component="Global" text="Filtrar resultados" /></MenuItem>
                        <MenuItem button onClick={() => handleShowMasInfo(pendiente ? 'mas_info_deuda_pendiente' : 'mas_info_deuda_pagada')}>
                            <Term component="Global" text="Mas informacion" />
                        </MenuItem>
                    </>
                )
            },
            menu: true,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageDispatcher, terms, pendiente, recibos]);

    useEffect(() => {
        const query = buildQueryRequest(filters, 0);
        delete (query as any).skip;
        delete (query as any).take;
        const asyncEffect = async () => {
            if (screen === 'tributos-autonomicos') {
                const filterDataTemp = await tributosGateway.getFilterData(query);
                if (filterDataTemp) {
                    setFilterData(filterDataTemp);
                }
            } else {
                const filterDataTemp = await recibosGateway.getFilterData(query);
                if (filterDataTemp) {
                    if (!pendiente) {
                        filterDataTemp.situaciones = undefined;
                    } else if (filterDataTemp.situaciones) {
                        filterDataTemp.situaciones.forEach((value) => {
                            value.label = translate('Global', value.label, terms);
                        });
                    }
                    setFilterData(filterDataTemp);
                }
            }
        };

        asyncEffect();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [screen]);

    useEffect(() => {
        (async () => {
            handleFiltersChanged(filters);
            const query = buildQueryRequest(filters, 0);
            delete query.skip;
            delete query.take;
            if (screen === 'tributos-autonomicos') {
                setTributosCount(0);
                const countResult = await tributosGateway.getCount(query);
                setTributosCount(countResult ? countResult : 0);
            } else {
                setRecibosCount(0);
                const countResult = await recibosGateway.getCount(query);
                setRecibosCount(countResult ? countResult : 0);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [screen, filters]);

    useEffect(() => {
        setRecibos([]);
        setPage(0);
        selectionDispatch({ type: 'clear' });
        // handleLoadRecibos({}, 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [screen]);

    useEffect(() => {
        setRecibos([]);
        setPage(0);
        selectionDispatch({ type: 'clear' });
        setTabActive(0);
        setScreen(pendiente ? 'cartero-virtual-pendiente' : 'cartero-virtual-pagado');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pendiente]);

    useEffect(() => {
        if(ENABLE_PAGO_A_CUENTA){
            if (pendiente && selectionReducer[0].selected.length > 1 && !controlAvisoPagoACuentaActivadoPreviamente) {
                setShowModalAvisoPagoACuenta(true);
                setControlAvisoPagoACuentaActivadoPreviamente(true)
            }
            if (selectionReducer[0].selected.length < 2 && controlAvisoPagoACuentaActivadoPreviamente){
                setControlAvisoPagoACuentaActivadoPreviamente(false)
            }
    }

    }, [selectionReducer, pendiente, controlAvisoPagoACuentaActivadoPreviamente]);




    return (
        <>
            <FiltersContext.Provider value={filtersReducer}>
                <SelectionContext.Provider value={selectionReducer}>
                    <FilterDataContext.Provider value={filterData}>
                        <div className={classes.root}>
                            <div className={classes.recibosContainer}>
                                <FiltersActive
                                    onChange={handleFiltersChanged}
                                />

                                {/* TABS */}

                                {pendiente ?
                                    null :
                                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', marginBottom: '1%', backgroundColor: 'white', borderRadius: '3px' }}>
                                        <CustomTabs
                                            labelTab1={translate('CarteroVirtual', 'tributos_locales', terms)}
                                            labelTab2={translate('CarteroVirtual', 'tributos_autonomicos', terms)}
                                            onChange={handleChange}
                                            tabActive={tabActive}
                                        />
                                    </div>
                                }
                                <TabPanel value={tabActive} index={0}>
                                    <NoContent
                                        image={image}
                                        visible={noContent}
                                        component='CarteroVirtual'
                                        text={pendiente ?
                                            'No se ha encontrado deuda pendiente' :
                                            'No se ha encontrado deuda pagada'}
                                    />

                                    {
                                        recibosCount > 0
                                            ?
                                            <>
                                                <Card className={classes.cardContador}>
                                                    <CardContent className={classes.cardContadorContainer} >
                                                        <span className={classes.titleCardContador}>

                                                            <Term component="CarteroVirtual" text={pendiente ? 'TituloCarteroVirtualPendiente' : 'TituloCarteroVirtualPagado'} params={[recibosCount]} />

                                                        </span>
                                                        {
                                                            pendiente
                                                                ?
                                                                <>
                                                                    <Button disabled={selectingAll} variant="contained" color="primary" size="small" className={classes.buttonPagarTodos} onClick={handleSelectAll}>
                                                                        <Term component="CarteroVirtual" text="PAGAR TODOS" />
                                                                        {
                                                                            selectingAll
                                                                                ? <CircularProgress size={24} className={classes.buttonProgress} />
                                                                                : null
                                                                        }
                                                                    </Button>
                                                                    <Button disabled={deSelectingAll || !(selectionReducer[0].selected.length > 0)}
                                                                        variant="outlined" color="primary" size="small" className={classes.buttonPagarTodos}
                                                                        onClick={handleDeSelectAll}
                                                                    >
                                                                        <Term component="CarteroVirtual" text="DESSELECIONAR TODOS" />
                                                                        {
                                                                            deSelectingAll
                                                                                ? <CircularProgress size={24} className={classes.buttonProgress} />
                                                                                : null
                                                                        }
                                                                    </Button>
                                                                </>
                                                                :
                                                                null
                                                        }
                                                    </CardContent>
                                                </Card>
                                                <InfiniteScroll
                                                    pageStart={0}
                                                    initialLoad={false}
                                                    loadMore={handleLoadMoreRecibos}
                                                    hasMore={hasMore}
                                                    loader={<div key="infiniteProgress" style={{ display: hasMore ? 'block' : 'none' }} className={classes.centerContent}><CircularProgress className={classes.progress} /></div>}
                                                >
                                                    {recibos.map((recibo: IRecibo, index: number) => (
                                                        <ReciboCard
                                                            key={'Recibo_' + index}
                                                            recibo={recibo}
                                                            selectable={true && recibo.desglose.pendiente < 1000000}
                                                            mensajeAclaratorio={mensajeAclaratorio}
                                                            isFromConsultasExternas={false}
                                                            screen={screen}
                                                            bodyAvisoProcedimientoEmbargo={bodyAvisoProcedimientoEmbargo}
                                                            bodyAvisoPagoCartaPalma={bodyAvisoPagoCartaPalma}
                                                        />
                                                    ))}
                                                </InfiniteScroll>
                                            </>
                                            :
                                            noContent ?
                                                null
                                                :
                                                <div key="progress" className={classes.centerContent}><CircularProgress className={classes.progress} /></div>
                                    }
                                </TabPanel>
                                <TabPanel value={tabActive} index={1}>
                                    <TributosAutonomicos
                                        loading={loadingRecibos}
                                        tributosIni={tributos}
                                        tributosCountIni={tributosCount}
                                    />
                                </TabPanel>
                            </div>

                            <Carrito
                                items={selectionReducer[0].selected}
                                visible={selectionReducer[0].selected.length > 0}
                                pendiente={pendiente}
                                isFromConsultasExternas={false}
                            />

                        </div>

                        <div className={classes.drawer}>
                            <Hidden mdUp implementation="css">
                                <FiltersDialog
                                    open={showFilters}
                                    screen={screen}
                                    onClose={handleHideFilters}
                                    onChange={handleFiltersChanged}
                                    onExportRequest={handleExportRequest}
                                />
                            </Hidden>

                            <Hidden smDown implementation="css">
                                <PanelDrawer
                                    screen={screen}
                                    onFiltersChange={handleFiltersChanged}
                                    onExportRequest={handleExportRequest}
                                />
                            </Hidden>
                        </div>
                    </FilterDataContext.Provider>
                </SelectionContext.Provider>
            </FiltersContext.Provider >

            {/* MODAL AVISO PAGO CUENTA */}

            <Dialog open={showModalAvisoPagoACuenta}>
                <div style={{ padding: 20 }}>
                    <DialogTitle>
                        <Typography style={{ color: '#154b60', fontSize: 30, fontWeight: 100 }}>
                            <Term component='Global' text='Informacion' />
                        </Typography>
                    </DialogTitle>
                    <DialogContent>
                        <div dangerouslySetInnerHTML={{ __html: bodyInfoPagoACuenta }} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleModalAvisoPagoACuenta} color="primary">
                            <Term component='Global' text='Cerrar' />
                        </Button>
                    </DialogActions>
                </div>
            </Dialog>
        </>
    );
}

export default withRouter(withStyles(styles)(CarteroVirtualDeuda));
import { FC, useContext, useEffect, useState } from "react";
import { LiteralsContext } from "containers/shared/literals";
import { makeStyles, useTheme } from "@material-ui/core";
import { translate } from "utils/i18n";
import PhoneInput from 'react-phone-input-2';
import es from 'react-phone-input-2/lang/es.json';
import 'react-phone-input-2/lib/material.css';
import styles from "../perfil.styles";
import Term from "components/term";
import { ICountryObjectPhone } from "gateways/perfil.interfaces";

interface IProps {
    phoneBackUp: string | null;
    phoneConfirmationBackUp: string | null;
    validationMsgPhone: string | null;
    validationMsgPhoneConfirmation: string | null;
    onChangePhone: (phone: string, phoneCountryCode: string) => void;
    onChangePhoneConfirmation: (phoneConfirmation: string, countryDialCodePhoneConfirmation: string) => void;
}

const useStyles = makeStyles(styles);

const PhoneStepValidacion: FC<IProps> = ({ phoneBackUp, phoneConfirmationBackUp, validationMsgPhone, validationMsgPhoneConfirmation, onChangePhone, onChangePhoneConfirmation }) => {
    const terms = useContext(LiteralsContext);
    const theme = useTheme();
    const classes = useStyles(theme);
    // Params
    const [phone, setPhone] = useState('');
    const [phoneConfirmation, setPhoneConfirmation] = useState('');
    const [countryDialCodePhone, setCountryDialCodePhone] = useState('');
    const [countryDialCodePhoneConfirmation, setCountryDialCodePhoneConfirmation] = useState('');

    const handleChangePhone = (phone: string, country: ICountryObjectPhone) => {
        if (country.dialCode) setCountryDialCodePhone(`+${country.dialCode}`);
        setPhone(phone.startsWith('+') ? phone : `+${phone}`);
    };

    const handleChangePhoneConfirmation = (phoneConfirmation: string, country: ICountryObjectPhone) => {
        if (country.dialCode) setCountryDialCodePhoneConfirmation(`+${country.dialCode}`);
        setPhoneConfirmation(phoneConfirmation.startsWith('+') ? phoneConfirmation : `+${phoneConfirmation}`);
    }

    useEffect(() => {
        onChangePhone(phone, countryDialCodePhone);
    }, [phone, countryDialCodePhone]);

    useEffect(() => {
        onChangePhoneConfirmation(phoneConfirmation, countryDialCodePhoneConfirmation);
    }, [phoneConfirmation]);

    useEffect(() => {
        if (phoneBackUp) setPhone(phoneBackUp.startsWith('+') ? phoneBackUp : `+34${phoneBackUp}`);
        if (phoneConfirmationBackUp) setPhoneConfirmation(phoneConfirmationBackUp.startsWith('+') ? phoneConfirmationBackUp : `+34${phoneConfirmationBackUp}`);
    }, []);

    return (
        <div className={classes.containerPhoneInputs}>
            <div>
                <PhoneInput
                    inputProps={{
                        id: "outlined-sms-input"
                    }}
                    country={'es'}
                    countryCodeEditable={false}
                    specialLabel={translate('Perfil', 'Nuevo teléfono', terms)}
                    containerClass={classes.textField}
                    localization={es}
                    value={phone}
                    dropdownStyle={{ maxHeight: '80', top: '-40', position: 'fixed' }}
                    onChange={(phone: string, country: ICountryObjectPhone) => handleChangePhone(phone, country)}
                    isValid={validationMsgPhone ? false : true}
                />
                {validationMsgPhone &&
                    <div style={{ color: '#f44336', marginTop: 5 }}>
                        <Term component='Perfil' text={validationMsgPhone} />
                    </div>}
            </div>

            <div>
                <PhoneInput
                    inputProps={{
                        id: "outlined-sms-input",
                        autoComplete: "off",
                        onPaste: (e: React.ClipboardEvent<HTMLInputElement>) => {
                            e.preventDefault(); // Previene la acción de pegar
                            return false;
                        }
                    }}
                    country={'es'}
                    countryCodeEditable={false}
                    specialLabel={translate('Perfil', 'Confirmar teléfono', terms)}
                    containerClass={classes.textField}
                    localization={es}

                    value={phoneConfirmation}
                    dropdownStyle={{ maxHeight: '80', top: '-40', position: 'fixed' }}
                    onChange={(phoneConfirmation: string, country: ICountryObjectPhone) => handleChangePhoneConfirmation(phoneConfirmation, country)}
                    isValid={validationMsgPhoneConfirmation ? false : true}
                />
                {validationMsgPhoneConfirmation &&
                    <div style={{ color: '#f44336', marginTop: 5 }}>
                        <Term component='Perfil' text={validationMsgPhoneConfirmation} />
                    </div>}
            </div>
        </div>
    )
};

export default PhoneStepValidacion;
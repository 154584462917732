// LIBRARY IMPORTS
import { FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Button, Card, CircularProgress, Drawer, Hidden, MenuItem, Typography, makeStyles } from "@material-ui/core";
import { RouteComponentProps, } from "react-router-dom";
import { StaticContext } from "react-router";
import { mdiLaptop } from "@mdi/js";
// LOCAL IMPORTS
import { LiteralsContext } from "containers/shared/literals";
import { handleInfoDispatch } from "utils/info-dispatch-mvl";
import { MoreInfoContext } from "contexts/more_info.context";
import { TributosGateway } from "gateways/tributos.gateway";
import { translate } from "utils/i18n";
import { AlertsContext } from "contexts/alerts.context";
import { IRecibo } from "gateways/recibo.interfaces";
import { dateFormat } from "utils/dates";
import IoC from 'contexts/ioc.context';
import Term from "components/term";
import usePage from "hooks/page.hook";
import pagoUnicoPNG from 'resources/pago-a-cuenta/PagoUnico.png';
import pagoCuentaPNG from 'resources/pago-a-cuenta/PagoCuenta.png';
import cuentaDomiPNG from 'resources/pago-a-cuenta/CuentaDomi.png';
import * as recibosMethodGateway from 'gateways/recibo.gateway';
// STYLES
import styles from '../../shared/pago-a-cuenta.styles';
import moment from "moment";
import { BLACK, RED } from "containers/shared/colors";

const useStyles = makeStyles(styles);

interface IStateSeleccionModoPago {
    recibo: IRecibo;
}

type Props = RouteComponentProps<{}, StaticContext, IStateSeleccionModoPago>;

interface IFormasPago {
    id: number;
    nombre: string;
    descripcion: string;
    btn: string;
    icon: string;
}

const formasPago: IFormasPago[] = [
    {
        id: 1,
        nombre: 'pago_unico',
        descripcion: 'pago_unico_descripcion',
        btn: 'pagar_ahora',
        icon: pagoUnicoPNG
    },
    {
        id: 2,
        nombre: 'pago_a_cuenta',
        descripcion: 'pago_a_cuenta_descripcion',
        btn: 'configurar_pago',
        icon: pagoCuentaPNG
    },
    {
        id: 3,
        nombre: 'pago_a_cuenta_domiciliado',
        descripcion: 'pago_a_cuenta_domiciliado_descripcion',
        btn: 'configurar_pago',
        icon: cuentaDomiPNG
    },
];

const SeleccionModoPago: FC<Props> = ({ history, location }) => {
    const state = location.state;

    // HOOKS
    const terms = useContext(LiteralsContext);
    const classes = useStyles();
    const ioc = useContext(IoC);

    const [, infoDispatch] = useContext(MoreInfoContext);
    const [, alertsDispatch] = useContext(AlertsContext);
    const [, pageDispatcher] = usePage();

    // GATEWAYS
    const recibosGateway = ioc.get(recibosMethodGateway.RecibosGateway) as recibosMethodGateway.RecibosGateway;
    const tributosGateway = useMemo(() => ioc.get(TributosGateway) as TributosGateway, [ioc]);

    // STATES
    const [loading, setLoading] = useState<null | "pago_unico" | "pago_a_cuenta" | "pago_a_cuenta_domiciliado">(null);
    const [fechaUltimoDiaDomi, setFechaUltimoDiaDomi] = useState<string | null>(null);



    // -------- USE EFFECT --------

    useEffect(() => {
        pageDispatcher({
            type: 'setHeader',
            header: {
                icon: mdiLaptop,
                title: <Term component="CarteroVirtual" text='Seleccionar forma de pago' />,
                moreInfoTemplate: 'mas_info_forma_de_pago',
                right: (
                    <MenuItem button onClick={() => handleShowMasInfo('mas_info_forma_de_pago')}>
                        <Term component="Global" text="Mas informacion" />
                    </MenuItem>

                ),
            },

            menu: true,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageDispatcher, terms]);

    useEffect(() => {
        (async () => {
            const ultimoDiaDomi = await tributosGateway.getFechaUltimoDiaDomi(state.recibo.id);
            setFechaUltimoDiaDomi(ultimoDiaDomi);
        })()
    }, []);



    // -------- HANDLERS --------

    const handleActionBtnModoPago = (modoPago: string) => {
        if (modoPago === 'pago_unico') generarModelos();
        else if (modoPago === 'pago_a_cuenta') history.push('/cartero-virtual/deuda-pendiente/pago-a-cuenta', { recibo: state.recibo });
        else if (modoPago === 'pago_a_cuenta_domiciliado') handleNavigatePagoCuentaDomiciliado();
    };

    const handleNavigatePagoCuentaDomiciliado = async () => {
        if (!state.recibo.domiciliacion.idDomiciliacion) return;

        const diasVoluntaria = await tributosGateway.getFechasVoluntaria(state.recibo.id, state.recibo.domiciliacion.idDomiciliacion);

        if (!diasVoluntaria) {
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    variant: 'warning',
                    message: <Term component="CarteroVirtual" text="deuda_pendiente_periodo_voluntaria_no_encontrado" />
                }
            });
            return;
        }

        history.push('/cartero-virtual/deuda-pendiente/pago-a-cuenta-domiciliado', { recibo: state.recibo });
    }

    const generarModelos = async () => {
        setLoading('pago_unico');
        const result = await recibosGateway.generarModelos([state.recibo.id]);
        setLoading(null);

        if (result.ok)
            history.push('/pasarela-pago/pago/' + result.entidad);
        else {
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message: translate('PasarelaPago', 'No ha sido posible generar el modelo', terms),
                    variant: 'error',
                }
            });
        }
    };

    const handleShowMasInfo = useCallback((templateName: string) => {
        handleInfoDispatch(infoDispatch, 'show-info', templateName)
    }, [infoDispatch])

    const handleDisabledBtn = (formaPago: IFormasPago) => {
        if (loading !== null){ 
            return true;
        } else if (formaPago.nombre === 'pago_a_cuenta_domiciliado') {
            if (!state.recibo.domiciliacion.idDomiciliacion) return true;
            if (!fechaUltimoDiaDomi) return false;
            return moment(fechaUltimoDiaDomi).isBefore(moment()) || moment(fechaUltimoDiaDomi).isSame();
        } else if (formaPago.nombre === 'pago_a_cuenta' && state.recibo.desglose.recargo >0) {
            return true;
        } else if (state.recibo.estadoEtiqueta === 'pago_a_cuenta_domiciliado' && formaPago.nombre !== 'pago_a_cuenta_domiciliado'){
            return true;
        }

        return false;
    }



    return (
        <>
            <div className={classes.wFull}>
                {

                    // CARDS FORMAS DE PAGO

                    formasPago.map(formaPago => (
                        <Card key={formaPago.id} className={classes.cardSeleccionarFormaPago}>
                            <div className={[classes.flexRow, classes.gap20].join(' ')}>
                                <img className={classes.imgDimension} src={formaPago.icon} alt={formaPago.nombre} />
                                <div className={[classes.flexCol, classes.justifyCenter, classes.gap10].join(' ')}>
                                    <Typography className={[classes.bold, classes.fontL].join(' ')} style={{ fontWeight: 'bold', fontSize: 18 }}>
                                        <Term component="CarteroVirtual" text={formaPago.nombre} />
                                    </Typography>
                                    <Typography className={classes.fontM}>
                                        <Term component="CarteroVirtual" text={formaPago.descripcion} />
                                    </Typography>
                                </div>
                            </div>

                            <Button
                                variant="outlined"
                                color="primary"
                                disabled={handleDisabledBtn(formaPago)}
                                onClick={() => handleActionBtnModoPago(formaPago.nombre)}
                                className={[classes.fitContent, classes.w250].join(' ')}
                            >
                                <Typography className={classes.btnFormaDePagoPadding}>
                                    <Term component="CarteroVirtual" text={formaPago.btn} />
                                </Typography>
                                {
                                    loading === formaPago.nombre &&
                                    <CircularProgress size={24} className={[classes.buttonProgress, classes.loaderPrint, classes.centerTopBottom].join(' ')} />
                                }
                            </Button>
                        </Card>
                    ))
                }
            </div>

            {/* PANEL LATERAL - RECIBO SELECCIONADO */}

            <div className={classes.drawer}>
                <Hidden smDown implementation="css">
                    <Drawer
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        anchor="right"
                        open>
                        <div style={{ padding: 20 }}>
                            <h4 style={{ marginBottom: 0 }}>
                                <Term component="CarteroVirtual" text="seleccionado" />
                            </h4>
                            <div style={{ marginTop: 20, marginBottom: 5 }}>
                                <Typography style={{ fontWeight: 'bold', fontSize: 13 }}>{`${state.recibo.organismo.nombre} | ${state.recibo.ejercicio}`}</Typography>
                                <Typography style={{ fontWeight: 'bold', fontSize: 13 }}>{`${state.recibo.concepto.nombre}`}</Typography>
                                <Typography style={{ fontWeight: 'bold', fontSize: 13 }}>{`${state.recibo.referencia}`}</Typography>
                            </div>

                            <div style={{ marginTop: 5, marginBottom: 5 }}>
                                <Typography style={{ fontSize: 13 }}>{`${state.recibo.direccionTributaria}`}</Typography>
                                {
                                    state.recibo.fechaInicio && state.recibo.fechaFin &&
                                    <Typography style={{ fontSize: 13 }}>{`del ${dateFormat(state.recibo.fechaInicio)} al ${dateFormat(state.recibo.fechaFin)}`}</Typography>
                                }
                            </div>

                            <div>
                                <h5 style={{ color: '#038cbe', marginTop: 20, marginBottom: 0 }}><Term component="CarteroVirtual" text='total_a_pagar' /></h5>
                                <Typography style={{ fontWeight: 'bold' , color: state.recibo.desglose.recargo>0 ? RED : BLACK }}>
                                    {`${state.recibo.desglose.pendiente} €`}
                                </Typography>
                            </div>
                        </div>
                    </Drawer>
                </Hidden>
            </div>
        </>
    )
}

export default SeleccionModoPago;
// LIBRARY IMPORTS
import { FC, useState, useEffect, useMemo, Fragment, useContext, useCallback } from 'react';
import { Typography, useTheme, makeStyles, DialogContent, CircularProgress, Button } from '@material-ui/core';
import { mdiCheck, mdiChevronDown, mdiChevronRight, mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import moment from 'moment';

// LOCAL IMPORTS
import { ITributo, IDatosIban, IDatosIbanPagoCarta } from 'gateways/tributos.interfaces';
import { TributosGateway } from 'gateways/tributos.gateway';
import { FilterDataContext } from 'containers/cartero-virtual/filter.data.context';
import { AlertsContext } from 'contexts/alerts.context';
import { LiteralsContext } from 'containers/shared/literals';
import { GenericModal } from 'components/generic-modal';
import { translate } from 'utils/i18n';
import { download } from 'utils/download';
import { DomiciliacionCard } from '../../cards/domiciliacion-card';
import { ScreenType } from "containers/cartero-virtual/filters.form";
import { PagoCartaCard } from '../../cards/pago-carta-card';
import { IResultPagosAcuenta } from 'gateways/recibo.interfaces';
import { RecibosGateway } from 'gateways/recibo.gateway';
import DomicilaicionFuturaCard from '../../cards/domiciliacion-futura-card/domiciliacion-futura-card';
import ConceptoIcon from 'components/concepto.icon';
import TextNumber from 'components/text-number';
import IoC from 'contexts/ioc.context';
import Term from 'components/term';

// STYLES IMPORTS
import styles from './styles';

const useStyles = makeStyles(styles);

export interface IProps {
    open: boolean;
    tributo: ITributo;
    domiciliables?: boolean;
    screen: ScreenType;

    onHandleFechaCargo: (tributo: ITributo, domiciliacion: IDatosIban | null) => void;
    onHandleAltaDomiciliacion?: (tributo: ITributo) => void;
    onHandleAltaPagoCarta?: (tributo: ITributo) => void;
    onClose: () => void;
    onSelect: (tributo: ITributo) => void;
    onUnselect: (tributo: ITributo) => void;
    onModificarPagoCuenta?: (tributo: ITributo) => void;
}

const DialogDomiciliacionGestion: FC<IProps> = (props) => {
    const { open, tributo, domiciliables, screen, onClose, onHandleFechaCargo, onHandleAltaPagoCarta, onModificarPagoCuenta } = props;

    // HOOKS
    const filterData = useContext(FilterDataContext);
    const terms = useContext(LiteralsContext);
    const theme = useTheme();
    const classes = useStyles(theme);

    const [, alertsDispatch] = useContext(AlertsContext);

    // SERVICES
    const ioc = useContext(IoC);
    const gateway = useMemo(() => ioc.get(TributosGateway) as TributosGateway, [ioc]);
    const recibosGateway = useMemo(() => ioc.get(RecibosGateway) as RecibosGateway, [ioc]);

    // LOCAL STATES
    const [loading, setLoading] = useState(false);
    const [loadingPrint, setLoadingPrint] = useState(false);
    const [datosDomiciliaciones, setDatosDomiciliaciones] = useState<IDatosIban[] | IDatosIbanPagoCarta[]>([]);

    // LOCAL STATES - PAGO A CUENTA
    const [esPagoACuenta, setEsPagoACuenta] = useState(false);
    const [expand, setExpand] = useState(false);
    const [pagosACuentaList, setPagosACuentaList] = useState<IResultPagosAcuenta[]>([])


    // MEMOS
    const isDisabledGestionPagosACuenta = useMemo(() => pagosACuentaList.find(x => moment(x.fechaEnvio).isBefore(moment())) ? true : false, [pagosACuentaList]);
    const municipio = useMemo(() =>
        filterData
            ? (filterData.organismos || []).filter(x => tributo && x.value === tributo.organismo.codigo)[0]
            : null,
        [tributo, filterData]);



    // -------- USE EFFECT --------

    useEffect(() => {
        if (!tributo || !tributo.domiciliacion || !tributo.domiciliacion.idDomiRec || !open) return;
        getListaPagos(tributo.domiciliacion.idDomiRec);
    }, [open]);

    useEffect(() => {
        if (!open) return;
        else if (screen === 'pago-carta') getDatosPagoCarta(tributo.idRecibo);
        else getDatosDomiciliacion(tributo.idRecibo);
    }, [tributo, open, screen]);



    // -------- PAGOS A CUENTA --------

    const toggleExpand = () => {
        setExpand(!expand);
    };

    const getListaPagos = async (idDomiRec: number) => {
        const result = await recibosGateway.getListadoPagosACuenta(idDomiRec);
        //console.log('lista Pago cuenta Domi_gestion', result);
        result.sort( (a,b) => a.fechaEnvio < b.fechaEnvio ? -1 :1)
        //console.log('lista sort', result);
        setPagosACuentaList(result);
        result && result.length > 0
            ? setEsPagoACuenta(true)
            : setEsPagoACuenta(false);
    };



    // -------- HANDLERS --------

    const getDatosDomiciliacion = useCallback(async (idTributo: number) => {
        setLoading(true);
        const domiciliaciones = await gateway.getDatosDomiciliacion(idTributo);

        let domiciliacionesFiltradas = domiciliaciones.filter(domiciliacion => domiciliacion.vigencia && Number(domiciliacion.vigencia) >= new Date().getFullYear());
        domiciliacionesFiltradas.sort((a, b) => (a.idDomiciliacion < b.idDomiciliacion) ? 1 : -1);
        domiciliacionesFiltradas = domiciliacionesFiltradas.filter((domiciliacion) => domiciliacionesFiltradas.find(x => x.vigencia === domiciliacion.vigencia) === domiciliacion);

        setDatosDomiciliaciones(domiciliacionesFiltradas.sort((a, b) => (Number(a.vigencia) > Number(b.vigencia)) ? 1 : -1));
        setLoading(false);
    }, [gateway]);

    const getDatosPagoCarta = useCallback(async (idTributo: number) => {
        setLoading(true);
        const pagosCarta = await gateway.getDatosPagoCarta(idTributo);

        setDatosDomiciliaciones(pagosCarta);
        setLoading(false);
    }, [gateway]);


    const handlePrint = useCallback(async (idDomiciliacion?: number) => {
        if (!tributo.domiciliacion) return;
        if ((tributo && tributo.domiciliacion) || idDomiciliacion) {
            setLoadingPrint(true);
            const result = await gateway.getJustificanteDomiciliacion(idDomiciliacion ? idDomiciliacion : tributo.domiciliacion.idDomiciliacion);

            download(result, alertsDispatch,
                translate('Domiciliaciones', 'Ha ocurrido un error al generar el justificante de domiciliación', terms),
                translate('Global', 'BloqueoPantalla', terms));
            setLoadingPrint(false);
        }
        else {
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message: translate('Domiciliaciones', 'Ha ocurrido un error al generar el justificante de domiciliación', terms),
                    variant: 'error',
                }
            });
        }
    }, [alertsDispatch, gateway, terms, tributo]);

    const handleModificacionDomiciliacionFutura = () => {
        if (screen !== 'domiciliados') return false;
        else if (datosDomiciliaciones.length !== 1) return false;

        const domiciliacion = datosDomiciliaciones[0] as IDatosIban;

        if (parseInt(domiciliacion.vigencia) !== new Date().getFullYear()) return false;
        else if (domiciliacion.fechaCierrePeriodo && !moment().isAfter(moment(domiciliacion.fechaCierrePeriodo))) return false;

        return true;
    }



    return (
        <GenericModal
            open={open}
            maxWidth={"md"}

            // Dialog Title
            title='Gestión de la domiciliación'
            componentTitleTranslation='Domiciliaciones'

            // Dialog Actions
            onClose={onClose}
            disabledCloseBtn={loadingPrint}
        >
            <DialogContent>
                {loading &&
                    <div className={classes.centerContent}>
                        <CircularProgress size={75} />
                    </div>
                }

                {!loading && tributo &&
                    <Fragment>

                        {/* INFORMACIÓN DE LA DOMICILIACIÓN */}

                        <div className={classes.row}>
                            <div className={classes.iconConcepto}>
                                <ConceptoIcon concepto={tributo.concepto.idTipo} />
                            </div>
                            <div className={classes.column}>
                                <Typography component="p">
                                    <strong>{tributo.concepto.nombre}</strong>
                                </Typography>
                                <Typography component="p">
                                    <Term component="Global" text="Municipio / Organismo" />: <strong>{municipio ? municipio.label : null}</strong>
                                </Typography><Typography component="p">
                                    <Term component="Global" text="Objeto" />:  <strong>{tributo.objeto}</strong>
                                </Typography>
                                {
                                    tributo.direccionTributaria ?
                                        <Typography component="p">
                                            <Term component="Global" text="Direccion tributaria" />: <strong>{tributo.direccionTributaria}</strong>
                                        </Typography>
                                        : null
                                }
                            </div>
                        </div>

                        {/* DATOS DOMICILIACIÓN */}

                        {
                            tributo.domiciliacion && tributo.domiciliacion.activa &&
                            datosDomiciliaciones.map((domiciliacion: IDatosIban | IDatosIbanPagoCarta, index: number) => (
                                <DomiciliacionCard
                                    type='gestion'
                                    key={'Datos_Domiciliacion_' + index}
                                    domiciliacion={domiciliacion as IDatosIban}
                                    tributo={tributo}
                                    loadingPrint={loadingPrint}
                                    domiciliables={domiciliables}
                                    esPagoACuenta={esPagoACuenta}
                                    onPrint={handlePrint}
                                    onHandleFechaCargo={onHandleFechaCargo}
                                />
                            ))
                        }

                        {/* MODIFICACIÓN FUTURA DOMICILIACION */}

                        {
                            handleModificacionDomiciliacionFutura() &&
                            datosDomiciliaciones.map((domiciliacion: IDatosIban | IDatosIbanPagoCarta, index: number) => (
                                <DomicilaicionFuturaCard
                                    key={'Datos_Domiciliacion_' + index}
                                    domiciliacion={domiciliacion as IDatosIban}
                                    tributo={tributo}
                                    domiciliables={domiciliables}
                                />
                            ))
                        }

                        {/* DATOS PAGO CARTA */}

                        {
                            screen === 'pago-carta' && tributo.pagoCarta?.DomiciliadoPC &&
                            datosDomiciliaciones.map((domiciliacion: IDatosIban | IDatosIbanPagoCarta, index: number) => (
                                <PagoCartaCard
                                    type="gestion"
                                    key={'Datos_Domiciliacion_' + index}
                                    pagoCarta={domiciliacion as IDatosIbanPagoCarta}
                                    tributo={tributo}
                                    onHandleFechaCargo={onHandleAltaPagoCarta}

                                />
                            ))}
                    </Fragment>
                }

                {/* PAGO A CUENTA */}

                {esPagoACuenta &&
                    <div className={classes.pcBlueRim}>
                        <div onClick={toggleExpand} className={[classes.text, classes.containerToggleExpand].join(' ')}>
                            <div className={[classes.flexRow, classes.gap5].join(' ')}>
                                <Typography className={[classes.bold, classes.pcBlueTitle].join(' ')}>
                                    <Term component='Global' text="Informacion" />
                                </Typography>
                                <Typography className={classes.pcBlueTitle}>
                                    <Term component='CarteroVirtual' text="pago_a_cuenta_domiciliado" />
                                </Typography>
                            </div>
                            <Icon
                                path={expand ? mdiChevronDown : mdiChevronRight}
                                title="Pago a cuenta"
                                size={1}
                                color={"#006993"}
                                className={[classes.iconPC, classes.pcBlueTitle].join(' ')}
                            />
                        </div>

                        {expand &&
                            <>

                                {/* DESPLEGABLE - PAGO A CUENTA */}

                                <div className={[classes.flexCol, classes.gap5, classes.p20].join(' ')}>
                                    {pagosACuentaList.map(pagoACuenta => (
                                        <div className={classes.containerPeriodosPagoCuenta}>
                                            <Typography className={[classes.bold, classes.fontS].join(' ')}>
                                                {moment(pagoACuenta.fechaEnvio).format('DD/MM/YYYY')}
                                            </Typography>

                                            <div className={classes.containerImporteIcon}>
                                                <TextNumber
                                                    component="span"
                                                    className={classes.fontS}
                                                    value={pagoACuenta.importe}
                                                    options={{ isMoney: true, minDecimalLength: 2, maxDecimalLength: 2 }}
                                                />
                                                {
                                                    pagoACuenta.idEnv
                                                        ? <Icon color={'green'} size={0.6} path={mdiCheck} />
                                                        : <Icon color={'red'} size={0.6} path={mdiClose} />
                                                }
                                            </div>

                                            <Typography className={classes.fontS}>
                                                {tributo.domiciliacion?.iban}
                                            </Typography>
                                        </div>
                                    ))}
                                </div>

                                <Button
                                    variant="outlined"
                                    color="primary"
                                    disabled={isDisabledGestionPagosACuenta || loading}
                                    style={{ backgroundColor: '#fff', width: 'fit-content', marginLeft: 'auto', marginTop: 5 }}
                                    onClick={() => onModificarPagoCuenta && onModificarPagoCuenta(tributo)}
                                >
                                    <Term component='CarteroVirtual' text="modificar_pago_a_cuenta" />
                                </Button>
                            </>
                        }
                    </div>}

                {
                    !loading && !tributo &&
                    <Typography><Term component='Domiciliaciones' text='Tributo no especificado' /></Typography>
                }
            </DialogContent>
        </GenericModal >
    )
};

export { DialogDomiciliacionGestion };
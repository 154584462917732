import { IMunicipioIVTM } from "gateways/model.new.interface"
import { GenericInitialObject } from "../utils"

export interface IDatosXMLM071 {
    CodigoMunicipio: string | undefined,
    NifSujetoPasivo: string | undefined,
    PrimerApellidoSujetoPasivo: string | undefined,
    Clase: string | undefined,
    Marca: string | undefined,
    Modelo: string | undefined,
    Plazas: string | undefined,
    Potencia: string | undefined,
    Carga: string | undefined,
    Cilindrada: string | undefined,
    Bastidor: string | undefined,
}

export const objectBbddToXMLformat_071 = (data: GenericInitialObject, munis: IMunicipioIVTM[]) => {
    const locaMuni = munis.find(m => m.nombre === data["locaMuni"].value);
    return {
        Bastidor: data["bastidor"].value,
        Carga: data["carga"].value ?? "0.00",
        Cilindrada: data["cilindrada"].value ?? "0.00",
        Clase: data["clase"].value,
        CodigoMunicipio: locaMuni?.acodmunigest,
        Marca: data["marca"].value,
        Modelo: data["modeloVeh"].value,
        NifSujetoPasivo: data["nif"].value,
        Plazas: data["plazas"].value ?? "0",
        Potencia: data["potenciaFi"].value ?? "0.0",
        PrimerApellidoSujetoPasivo: data["apeNom"].value,
    } as IDatosXMLM071
}
import { StyleRules } from "@material-ui/styles";
import { RED_LIGHT, RED_TEXT } from "containers/shared/colors";

const styles = (): StyleRules => ({
    steps: {
        color: 'black',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 25,
        height: 25,
        cursor: 'default'
    },
    activeStep: {
        backgroundColor: '#004f84',
        color: 'white'
    },
    contentActions: {
        flexDirection: 'column'
    },
    rowButtons: {
        display: 'flex',
        alignSelf: 'flex-end',
    },
    rowSteps: {
        flex: 1,
        display: 'flex',
    },
    dialog: {
        height: 600,
        padding: 20
    },
    container: {
        margin: '0 15px'
    },
    title: {
        display: 'flex',
        alignItems: 'center',
        gap: '1rem'
    },
    titleFile: {
        marginBottom: 10,
    },
    input: {
        marginLeft: 15,
    },
    button: {
        marginRight: 10,
    },
    pageTitle: {
        fontWeight: 300,
        fontSize: 25,
        color: '#004f84',
        textTransform: 'uppercase',
    },
    iconContainer: {
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#004f82',
        padding: 7,
        borderRadius: '50%',
        marginRight: 10,
    },
    header: {
        backgroundColor: '#dcdcdc'
    },
    nowrap: {
        whiteSpace: 'nowrap',
    },
    bold: {
        fontWeight: 'bold',
        whiteSpace: 'nowrap'
    },
    fixedWidth: {
        width: 150
    },
    identificacionDate: {
        fontWeight: "bold",
        marginBottom: 20,
        textAlign: "right"
    },
    rowError: {
        backgroundColor: RED_LIGHT
    },
    totalNumLine: {
        fontWeight: "bold",
        marginBottom: 10
    },
    errorLine: {
        fontWeight: "bold",
        marginBottom: 10,
        color: RED_TEXT
    },
    alertCell: {
        padding: 0
    },
    alertCellColor: {
        backgroundColor: '#f8edeb',
        padding: '5px 20px'
    },
    divMsg: {
        display: 'flex',
        backgroundColor: '#e0eaf4',
        padding: '20px 10px'
    },
    row: {
        display:'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    column: {
        display:'flex',
        flexDirection: 'column',
    }
});

export default styles;
import { TImporterType } from ".";

export const getExtension = (t: TImporterType) => {
    switch (t) {
        case 'm071':
            return 'xml'
        default:
            return 'xlsx';
    }

}
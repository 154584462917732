import { Theme } from "@material-ui/core";
import { StyleRules } from "@material-ui/styles";
const styles = (theme: Theme): StyleRules => ({
    root: {
        width: '100%',
        marginRight: 15,
        marginLeft: 15,
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            marginTop: 0
        }
    },
    rootMenu: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        paddingRight: 15,
        paddingLeft: 15,
        [theme.breakpoints.down('sm')]: {
            marginTop: 0
        }
    },
    rowMenu: {
        display: 'flex',
        maxWidth: '100%',
        flexDirection: 'row',
        justifyContent: 'center',
        //flexGrow: 1,
        height: 220, // 445, 
        // [theme.breakpoints.down('xl')]: {
        //     height: 550
        // },
        [theme.breakpoints.down('sm')]: {
            maxWidth: '100%',
        }
    },
    container: {
        display: 'grid',
        width: '100%',
        gap: '1em',
        gridAutoRows: "max-content",
        paddingRight: 15,
        paddingLeft: 15,
        [theme.breakpoints.down('sm')]: {
            marginTop: "10px"
        }
    },
    widget: {
        marginTop: 10,
        marginBottom: 10,
    }

});

export default styles
import { useState, useEffect, FC, useContext, useMemo, useCallback } from 'react';
import { WithStyles, withStyles, IconButton } from '@material-ui/core';
import Term from 'components/term';
import { IPaymentMethod } from 'gateways/payment.method.interfaces';
import IoC from 'contexts/ioc.context';
import * as paymentGateway from 'gateways/payment.gateway';
import usePage from 'hooks/page.hook';
import { RouteComponentProps } from 'react-router-dom';
import { IModelGateway } from 'gateways/modelGateway.interface';
import { translate } from 'utils/i18n';
import styles from './pago.styles';
import { IParams } from './params.interfaces';
import { LiteralsContext } from 'containers/shared/literals';
import { download } from 'utils/download';
import { Icon } from '@mdi/react';
import { mdiInformation } from '@mdi/js';
import { AlertsContext } from 'contexts/alerts.context';
import { FormasPagoEnum } from "../../gateways/datos.pago.interface";
import { StaticContext } from 'react-router';
import ModelGateway from 'gateways/model.new.gateway';
import { AUX_TIPO_MODELOS_ACTIVOS } from 'containers/tributos-autonomicos-locales/constants';
import { handleInfoDispatch } from 'utils/info-dispatch-mvl';
import { MoreInfoContext } from 'contexts/more_info.context';
import MetodosPago from 'components/metodos.pago';

interface IState {
    from?: 'PagoACuenta' | 'ABO_NAC'
    idSolicitud?: number
    modeloMultiple?: boolean

}
const FormasPago: FC<WithStyles<typeof styles> & RouteComponentProps<IParams,StaticContext,IState> > = ({ classes, match, history}) => {
    const terms = useContext(LiteralsContext);
    const [paymentMethods, setPaymentMethods] = useState<IPaymentMethod[]>([]);
    const [creditCardMethod, setCreditCardMethod] = useState<IPaymentMethod>();
    const [bizumMethod, setBizumMethod] = useState<IPaymentMethod>();
    const [modelos, setmodelos] = useState<IModelGateway[]>([]);
    const [showDeuda, setShowDeuda] = useState(false);
    const [showModeloCaducado, setShowModeloCaducado] = useState(false);
    const [permitirPagoBanca,] = useState(true);
    const [permitirDocIngresoI, setPermitirDocIngresoI] = useState(true);
    const [permitirDocIngresoG, setPermitirDocIngresoG] = useState(true);

    const [loading, setLoading] = useState(false);
    // Global states
    const [, pageDispatcher] = usePage();
    const [, alertsDispatch] = useContext(AlertsContext);
   
    // Services 
    const ioc = useContext(IoC);
    const gateway = ioc.get(paymentGateway.PaymentGateway) as paymentGateway.PaymentGateway;
    const modelGateway: ModelGateway = useMemo(() => ioc.get(ModelGateway), [ioc]);
    const [, infoDispatch] = useContext(MoreInfoContext);

    const handlePayCreditCard = async () => {
        setLoading(true);
        const creditCard = paymentMethods.find(p => p.id === 'TJ');
        if (creditCard) {
            let result
            console.log(history.location.state?.from, creditCard)
            if(history.location.state?.from === 'ABO_NAC'){
                result = await gateway.pagarTributosAboNac(creditCard.id, modelos, history.location.state.idSolicitud);
            } else {
                result = await gateway.pagarTributos(creditCard.id, modelos);
            }
            
            if (result.ok) {
                window.location.href = result.entidad;
            } else {
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: result.literal ? translate('PasarelaPago', result.literal, terms) : translate('PasarelaPago', 'No ha sido posible realizar el pago con tarjeta de crédito', terms),
                        variant: 'warning',
                    }
                });
            }
        }
        setLoading(false);
    };

    const handlePayBank = async () => {
        history.push('/pasarela-pago/bancaElectronica/' + match.params.token);
    };

    const handlePrint = async (individual: boolean) => {
        setLoading(true);
        let tributos;
        if(modelos.length === 1 && !modelos[0].recibo){
            //Es un modelo TL o TA
            tributos = modelos.map(modelo => ({
                idTributo: modelo.modelo.id,
                voluntaria: null,
            }) as any);
        } else {
            //Es deuta pendent u otro
            const modelosconRecibo = modelos.filter(modelo => modelo.recibo !== undefined);
            tributos = modelosconRecibo.map(modelo => ({
                            idTributo: modelo.recibo.id,
                            voluntaria: modelo.recibo.voluntaria,
                        }) as any);
        }

        const result = await gateway.getCartaDePago(tributos, match.params.token, individual);
        if (result.ok) {
            const dwnlOK = download(result.data, alertsDispatch,
                translate('PasarelaPago', 'No es posible descargar la carta de pago', terms),
                translate('Global', 'BloqueoPantalla', terms));
            
            if(dwnlOK && modelos.length === 1 && AUX_TIPO_MODELOS_ACTIVOS.includes(modelos[0].modelo.idTipoModelo) ){
                await modelGateway.updateModeloState(modelos[0].modelo.id, 'IMP', translate('Tributos','UpdateModeloStateError',terms) )  
                //Refrescamos datos del modelo - Deberíamos tener state IMP 
                fetchmodelosData();           
            }  
        } else {
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message: translate('PasarelaPago', 'tributos_pagados', terms),
                    variant: 'error',
                }
            });
        
        }
        setLoading(false);
    };

    // const handlePayBizum = async(modelosAPagar: IModelGateway[]) => {
    //     setLoading(true);
    //     // recuperar datos para generar el pago por bizum
    //     const importeTotal = modelosAPagar.length > 0 ? modelosAPagar.map((x: IModelGateway) => x.modelo.importeAIngresar).reduce((a: number, b: number) => a + b) : 0;
    //     if (importeTotal <= BIZUM_MAX_IMPORTE) {
    //         const bizumPaymentFormData = await gateway.getBizumPaymentFormData(modelosAPagar);
    //         const bizumForm = document.getElementById("Ds_form") as HTMLFormElement;
    //         const Ds_MerchantParameters = document.getElementById('Ds_MerchantParameters') as HTMLInputElement;
    //         const Ds_Signature = document.getElementById('Ds_Signature') as HTMLInputElement;
    //         const Ds_SignatureVersion = document.getElementById('Ds_SignatureVersion') as HTMLInputElement;
    //         Ds_MerchantParameters.value = bizumPaymentFormData.Ds_MerchantParameters;
    //         Ds_Signature.value = bizumPaymentFormData.Ds_Signature;
    //         Ds_SignatureVersion.value = bizumPaymentFormData.Ds_SignatureVersion;
    //         bizumForm.action = bizumPaymentFormData.UrlPago;
    //         bizumForm.submit();
    //     } else {
    //         handleShowMasInfo('app_aviso_importe_maximo_bizum')
    //     }
    //     setLoading(false);
    // }

    const handlePayBizum = async (modelosAPagar: IModelGateway[]) => {
        setLoading(true);
        const bizum = paymentMethods.find(p => p.id === 'BZ');
        if (bizum) {
            const result = await gateway.pagarTributos(bizum.id, modelosAPagar);
            if (result.ok) {
                window.location.href = result.entidad;
            } else {
                alertsDispatch({
                    type: 'show-alert',
                    payload: {
                        message: result.literal ? translate('PasarelaPago', result.literal, terms) : translate('PasarelaPago', 'No ha sido posible realizar el pago con Bizum', terms),
                        variant: 'warning',
                    }
                });
            }
        }
        setLoading(false);
    };

    const handleShowMasInfo = (templateName: string) => {
        handleInfoDispatch(infoDispatch, 'show-info',templateName )
    }

    useEffect(() => {
        pageDispatcher({
            type: 'setHeader',
            header: {
                icon: null,
                title: <Term component="PasarelaPago" text="SELECCIONAR FORMA DE PAGO" />,
                right: (<IconButton color="inherit" onClick={() => setShowDeuda(true)}>
                    <Icon path={mdiInformation} title="Información" size={1} />
                </IconButton>)
            },
            menu: true,
        });
        const fetchPaymentMethodsData = async () => {
            const result = await gateway.getEntidades();
            const creditCard = result.find(p => p.tipoPago === 8);
            const bizum = result.find( p => p.tipoPago === 11);
            setCreditCardMethod(creditCard);
            setBizumMethod(bizum);
            setPaymentMethods(result);
        };
        fetchPaymentMethodsData();
        fetchmodelosData();
    }, [gateway, pageDispatcher, terms]);

    const fetchmodelosData = useCallback(() => {
        (async () => {
            const result = await gateway.getDatosPago(match.params.token);
    
            if (result.ok) {
                // const sinFechaVoluntaria = result.entidad.modelos.some(modelo => modelo.recibo.sinFechaFinVol);                
                setmodelos(result.entidad.modelos);
                if(history.location.state &&
                   history.location.state.from &&
                   (history.location.state.from === 'PagoACuenta' || history.location.state.from === 'ABO_NAC')){
                    setPermitirDocIngresoG(false);
                    setPermitirDocIngresoI(false);
                } else {
                    setPermitirDocIngresoI(history.location.state.modeloMultiple === true 
                        ? false
                        : result.entidad.formasPago.some(pago => pago === FormasPagoEnum.DII)
                    );
                    setPermitirDocIngresoG(history.location.state.modeloMultiple === true 
                        ? false
                        : result.entidad.formasPago.some(pago => pago === FormasPagoEnum.DIG)
                    );
                    // setPermitirPagoBanca(!sinFechaVoluntaria);
                }
            } else {
                setShowModeloCaducado(true);
            }
        })();
    }, [match.params.token,gateway]);   

    return (
        <MetodosPago 
            classes={classes} 
            creditCardMethod={creditCardMethod}
            bizumMethod={bizumMethod}
            modelos={modelos}
            showDeuda={showDeuda}
            showModeloCaducado={showModeloCaducado}
            permitirDocIngresoG={permitirDocIngresoG}
            permitirPagoBanca={permitirPagoBanca}
            permitirDocIngresoI={permitirDocIngresoI}
            loading={loading}
            showTotalPagar={true}
            setShowDeuda={setShowDeuda}
            handlePayCreditCard={handlePayCreditCard}
            handlePayBank={handlePayBank}
            handlePrint={handlePrint}
            handlePayBizum={handlePayBizum}
        />
    );
}

export default withStyles(styles)(FormasPago);


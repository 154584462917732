import React, { FC, useCallback, useContext } from "react";
import { colors } from "./styles";
import { TInfoTemplateSize } from "contexts/more_info.reducer";
import { IconButton } from "@material-ui/core";
import { Icon } from "atomic/atoms/icon/icon";
import { MoreInfoContext } from "contexts/more_info.context";

interface IInfoBtn {
  moreInfo: {
    template: string;
    size?: TInfoTemplateSize;
    asMessage?: boolean;
    title?: string
  };
  infoIconStyle?: React.CSSProperties | undefined;
}

const InfoBtn: FC<IInfoBtn> = ({ moreInfo, infoIconStyle }) => {
  const [, infoDispatch] = useContext(MoreInfoContext);

  const handleShowMoreInfo = useCallback(() => {
    infoDispatch({
      type: "show-info",
      payload: {
        templateName: moreInfo.asMessage ? undefined : moreInfo.template,
        message: moreInfo.asMessage ? moreInfo.template : undefined,
        size: moreInfo.size,
        title: moreInfo.title 
      },
    });
  }, [infoDispatch]);

  return (
    <IconButton onClick={handleShowMoreInfo} style={infoIconStyle}>
      <Icon name={"info-outline"} size={1} color={colors.blueIconInfo} />
    </IconButton>
  );
};

export default InfoBtn;

import { Theme } from "@material-ui/core";
import { StyleRules } from "@material-ui/styles";
import { BLUE } from "containers/shared/colors";

export const colors = {
   blueIconInfo: '#004f82', //10%, #dee2e6 ,
   white: '#FFF',
   blueBtn: BLUE,
}
const styles =  (theme: Theme): StyleRules => ({
   
    inputLabel: {
        backgroundColor: colors.white,
        paddingRight: 5,
        paddingLeft:5
    },
    input70: { // Small: puerta, esc
        width: 70
    },
    input100: { // Small: puerta, esc
        width: 100
    },
    input160: { // 
        width: 160
    },
    input200: { // 
        width: 200
    },
    input360: { // mediano: nombre
        width: 360
    },
    input545: {
        width: 545
    },
    inputDefault: { // mediano: nombre
        width: 260
    },
    inputFullWidth: { 
        width: '100%'
    },
    helperTextGerericComp :{
        display: 'block', // Asegura que ocupe el ancho completo del contenedor
        whiteSpace: 'normal', // Permitir múltiples líneas
        wordWrap: 'break-word', // Romper palabras largas si es necesario
        // whiteSpace: 'nowrap', // Evita que el texto ocupe varias líneas
        // overflow: 'hidden', // Oculta el texto que excede el ancho
        // textOverflow: 'ellipsis', // Agrega puntos suspensivos al final
    },
})

export default styles
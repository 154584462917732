import { FC, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Grid, Typography, CircularProgress, Button, makeStyles } from "@material-ui/core";
import TextNumber from '../../components/text-number';
import { IRecibo } from "gateways/recibo.interfaces";
import Term from "components/term";
import styles from '../shared/carrito.styles';
import IoC from 'contexts/ioc.context';
import * as recibosMethodGateway from 'gateways/recibo.gateway';
import { withRouter, RouteComponentProps } from "react-router-dom";
import { translate } from "utils/i18n";
import { LiteralsContext } from "containers/shared/literals";
import { AlertsContext } from "contexts/alerts.context";
import { PaymentGateway } from "gateways/payment.gateway";
import { download } from 'utils/download';
import { ENABLE_PAGO_A_CUENTA } from "constants/pago-a-cuenta";

type InheritsProps = RouteComponentProps

export interface IProps extends InheritsProps {
    items: IRecibo[];
    visible: boolean;
    pendiente: boolean;
    fullWidth?: boolean;
    isFromConsultasExternas: boolean;
    disableButton?: boolean;
    importeAbonado?: number;
}

const useStyles = makeStyles(styles);

const Carrito: FC<IProps> = ({ items, visible, history, pendiente, fullWidth, isFromConsultasExternas, disableButton = false, importeAbonado = undefined, location, match }) => {
    const classes = useStyles();
    const terms = useContext(LiteralsContext);
    const [, alertsDispatch] = useContext(AlertsContext);
    const [loading, setLoading] = useState(false);
    const totalPrice = items.length > 0 ? items.map((x: IRecibo) => x.desglose.pendiente).reduce((a: number, b: number) => a + b) : 0;
    // services 
    const ioc = useContext(IoC);
    const paymentGateway = ioc.get(PaymentGateway) as PaymentGateway;
    const recibosGateway = ioc.get(recibosMethodGateway.RecibosGateway) as recibosMethodGateway.RecibosGateway;
    // Indica que estamos en pantalla de pago a cuenta
    const isPagoACuenta = useMemo(() => ENABLE_PAGO_A_CUENTA && location.pathname.includes('pago-a-cuenta'), [location.pathname]);
    //const isPagoACuenta = false;

    // Habilita opción Pago a cuenta del Carrito
    const allowPagoACuenta: boolean = useMemo(() => ENABLE_PAGO_A_CUENTA && items.length < 2 && pendiente, [items]);
    //const allowPagoACuenta: boolean = false;

    const handlePrint = useCallback(async () => {
        if (items) {
            setLoading(true);
            const result = await paymentGateway.getJustificantePagoTributoGrupo(items.map((x) => { return x.id.toString() }));
            download(result, alertsDispatch,
                translate('PasarelaPago', 'No es posible descargar el justificante de pago', terms),
                translate('Global', 'BloqueoPantalla', terms));
            setLoading(false);
        }
    }, [items, paymentGateway, alertsDispatch, terms]);

    const generarModelos = async () => {
        setLoading(true);
        const isdRecibos = items.map(re => re.id as number);
        let result;

        if (isFromConsultasExternas)
            result = await recibosGateway.generarModelosConsultasExternas(isdRecibos)
        else if (isPagoACuenta)
            result = await recibosGateway.generarModelosPagoACuenta(isdRecibos[0], importeAbonado ?? 0)
        else
            result = await recibosGateway.generarModelos(isdRecibos);

        setLoading(false);

        if (result.ok) {
            history.push('/pasarela-pago/pago/' + result.entidad, { from: isPagoACuenta ? 'PagoACuenta' : undefined });
        } else {
            alertsDispatch({
                type: 'show-alert',
                payload: {
                    message: translate('PasarelaPago', 'No ha sido posible generar el modelo', terms),
                    variant: 'error',
                }
            });
        }
    };

    const onClick = () => {
        if (allowPagoACuenta && !isPagoACuenta) history.push('/cartero-virtual/deuda-pendiente/seleccion-modo-pago', { recibo: items[0] });
        else if (pendiente) generarModelos();
        else handlePrint()
    }

    return (
        <>
            <Grid container className={[
                fullWidth ? classes.rootWidth100 : classes.root,
                visible ? classes.display : ''].join(' ')}>
                <Grid item xs={6} className={classes.priceInfo}>
                    {
                        !isPagoACuenta &&
                        <Typography className={pendiente ? classes.totalPriceSelected : classes.totalPrice}>{items.length} <Term component="CarteroVirtual" text="seleccionado/s" /></Typography>
                    }
                    {pendiente && !isPagoACuenta
                        ? <TextNumber className={classes.totalPrice} value={totalPrice} options={{ isMoney: true, minDecimalLength: 2, maxDecimalLength: 2 }} />
                        : null
                    }
                </Grid>
                <Grid item xs={6} className={classes.payContainer}>
                    <div className={classes.wrapper}>
                        <Button
                            variant="contained"
                            color="primary"
                            className={allowPagoACuenta ? classes.buttonPagoACuenta : classes.buttonSuccess}
                            disabled={loading || disableButton}
                            onClick={onClick}
                        >
                            {
                                allowPagoACuenta && !isPagoACuenta
                                    ? <Term component="CarteroVirtual" text={"Modo Pago"} />
                                    : <Term component="CarteroVirtual" text={pendiente ? "Pagar" : "Imprimir"} />
                            }
                        </Button>
                        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </div>
                </Grid>
            </Grid>
        </>
    )
}


export default withRouter(Carrito);